<nb-card class="snooze-reminder">
  <nb-card-header>
    <span class="txt-clr">Remind me in:</span>
    <img class="close-btn" src="/assets/images/ic_close_24px.svg" (click)="close()">
  </nb-card-header>
  <nb-card-body>
    <div class="row time-row" *ngFor="let time of times" (click)="selectedTime(time.val)">
      <div class="time-div">
        {{time.label}}
      </div>
    </div>
  </nb-card-body>
</nb-card>
