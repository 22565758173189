import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-column-area-dialog',
  templateUrl: './column-area-dialog.component.html',
  styleUrls: ['./column-area-dialog.component.scss']
})
export class ColumnAreaDialogComponent implements OnInit {

  @Input() item = "";
  constructor(
    protected ref: NbDialogRef<ColumnAreaDialogComponent>,
  ) { }

  ngOnInit(): void {

    this.item = this.item;
  }

  cancel() {
    this.ref.close();
  }

  submit(){
    this.ref.close(this.item);
  }

}
