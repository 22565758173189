import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-params-list',
  templateUrl: './params-list.component.html',
  styleUrls: ['./params-list.component.scss']
})
export class ParamsListComponent implements OnInit {

  @Input() variableList = [];
  @Input() fromFilter = false;

  constructor(
    public dialogRef: NbDialogRef<ParamsListComponent>
  ) { }

  ngOnInit(): void {


  }

  ngAfterViewInit(): void {

  }

  close(){
    this.dialogRef.close();
  }


}
