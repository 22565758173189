import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'normalText',
})
export class NormalTextPipe implements PipeTransform {

  transform(value: string): string {
    return value.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, function (str) { return str.toUpperCase(); });
  }

}
