<nb-card
  class="main_layout"
  [nbSpinner]="!pageLoaded || !lookupDataCompiled"
  nbSpinnerSize="large"
  nbSpinnerStatus="primary"
>
  <div class="main new-inline-layout">
    <div
      class="right_side big-chat-page"
      style="width: 75%"
      [ngStyle]="{ 'margin-top': breadcrumbs.length > 0 ? '20px' : null }"
    >
      <div class="back-btn-view" [ngStyle]="{ color: systemConfigColor }">
        <div
          *ngFor="let id of eventEmiter.idField"
          class="d-flex backContainer"
        >
          <span
            class="txt-clr ml-2 d-flex align-items-center"
            (click)="backTo(id)"
            *ngIf="eventEmiter.idField[0]"
            >{{ id.idField
            }}<mat-icon style="top: 0 !important; color: black">
              keyboard_arrow_right</mat-icon
            ></span
          >
        </div>
      </div>

      <div id="grediant-title" class="grediant-title">
        <div class="grediant-title-main">
          <span
            *ngIf="checkURL(idValues)"
            [innerHTML]="idValues | renderImage"
          ></span>
          <span *ngIf="!checkURL(idValues)" [innerHTML]="idValues"></span>
          <div class="watchers d-flex align-items-center mt-3">
            <span class="watche-users-main d-flex">
              <span style="color: #fff">Watched by: </span>
              <span class="watche-users-inline">
                <span
                  id="watcher-span-{{ i }}"
                  *ngFor="let option of subscribers; let i = index"
                  [ngStyle]="{
                    background: option?.userColor
                      ? option?.userColor
                      : systemConfigColor
                  }"
                  nbTooltip="{{ option.firstName + ' ' + option.lastName }}"
                  nbTooltipPlacement="top"
                  nbTooltipStatus="basic"
                  nbTooltipTrigger="hover"
                  class="round-user"
                >
                  {{ option.firstName[0] + option.lastName[0] }}
                </span>
              </span>
            </span>
            <span
              class="material-icons-sharp border-dotted mat-custom add-watcher"
              [matMenuTriggerFor]="menu"
              (menuOpened)="watcherMenuOpened()"
              (menuClosed)="watcherMenuClosed()"
            >
              add
            </span>
          </div>
          <ng-container *ngIf="activeAction">
            <button
              *ngFor="let action of activeAction; let i = index"
              class="btn markAsComplete"
              style="margin-left: 10px"
              [ngStyle]="{
                'border-color': gradientColor,
                color: gradientColor,
                'background-color': gradientColor,
                color: 'white'
              }"
              (click)="markComplete(action)"
            >
              {{ action.actionName }}
            </button>
          </ng-container>
          <div style="height: 0px; width: 0px">
            <img id="barcodeImage" />
          </div>
        </div>

        <div class="centerWrap d-flex align-items-center mr-0">
          <div
            class="assignedTo d-flex assigned-to-con"
            *ngIf="assignedToField"
          >
            <span style="color: #fff">Assigned to: </span>
            <span class="watche-users-inline">
              <span
                *ngFor="let option of assignedToDetails; let i = index"
                [ngStyle]="{
                  background: option?.userColor
                    ? option?.userColor
                    : systemConfigColor
                }"
                nbTooltip="{{ option?.firstName + ' ' + option?.lastName }}"
                nbTooltipPlacement="top"
                nbTooltipStatus="basic"
                nbTooltipTrigger="hover"
                class="round-user"
              >
                {{ option?.firstName[0] + option?.lastName[0] | uppercase }}
              </span>
            </span>
            <span
              class="material-icons-sharp border-dotted mat-custom add-watcher"
              [matMenuTriggerFor]="matMenuForAssignedTo"
              (click)="clickOnAssignedToAdd()"
            >
              add
            </span>
          </div>
        </div>

        <mat-menu #menu="matMenu" yPosition="below">
          <button mat-menu-item first-top>
            <span class="li-head curson-pointer" (click)="selfSubsription()">
              <span class="icon-mar-lft"></span> {{ subscriptionText }}
            </span>
          </button>

          <div class="all-user-list">
            <button mat-menu-item *ngFor="let option of subscribers">
              <span class="li-head">
                <span
                  class="icon-mar-lft"
                  [ngStyle]="{ 'background-color': option?.userColor }"
                  >{{
                    option.firstName[0] + option.lastName[0] | uppercase
                  }}</span
                >
                {{ option.firstName + " " + option.lastName }}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span
                  class="left-margin curson-pointer"
                  (click)="cancelSubscription(option._id)"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </span>
              </span>
            </button>
          </div>

          <button
            style="background-color: #ececf0"
            mat-menu-item
            class="last-bottom"
            (click)="$event.stopPropagation()"
            (keydown)="$event.stopPropagation()"
            class="curson-pointer"
          >
            <ngx-watch-user
              [tableInfo]="tableInfo"
              [matMenu]="trigger"
              parentForm="dynamicForm"
              (valueChanged)="updateSubscribers($event)"
            ></ngx-watch-user>
          </button>
        </mat-menu>

        <mat-menu #matMenuForAssignedTo="matMenu" yPosition="below">
          <div class="all-user-list assigned-to-menu">
            <button
              mat-menu-item
              *ngFor="let option of assignedToDetails; let i = index"
            >
              <span class="li-head">
                <span
                  class="icon-mar-lft"
                  [ngStyle]="{ 'background-color': option?.userColor }"
                  >{{ option.firstName + option.lastName | uppercase }}</span
                >
                {{ option.firstName + " " + option.lastName }}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span
                  class="left-margin curson-pointer"
                  (click)="removeAssignedTo(option.id, i)"
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </span>
              </span>
            </button>
          </div>

          <button
            *ngIf="assignedToField"
            style="background-color: #ececf0"
            mat-menu-item
            class="last-bottom"
            (click)="$event.stopPropagation()"
            (keydown)="$event.stopPropagation()"
            class="curson-pointer assigned-to-btn"
          >
            <ng-select
              [items]="filteredOptions['assignedTo']"
              bindLabel="value"
              #in
              appendTo="body"
              [id]="'assignedTo'"
              clearable="true"
              placeholder="Assigned To"
              [(ngModel)]="assignedToDetails"
              class="ng-select-latest"
              (change)="setAssignedTo($event)"
              (ngModelChange)="updatedVal($event, assignedToField.name)"
              [ngModelOptions]="{ standalone: true }"
              [multiple]="true"
              [compareWith]="compareFn"
              [ngClass]="{
                'single-select':
                  assignedToField.allowMultipleValues == null ||
                  assignedToField.allowMultipleValues == false
              }"
            >
              <!-- <ng-template ng-option-tmp let-item="item" let-index="index" let-item$="item$">
                <div *ngIf="item.value">
                  <input type="checkbox" [checked]="item$.selected" />
                  <span class="checkmark"></span>
                  <label class="form-check-label ml-2">
                    {{ item.value }}
                  </label>
                </div>
              </ng-template> -->
              <ng-template ng-footer-tmp>
                <div
                  *ngIf="loadingAPI"
                  [nbSpinner]="true"
                  nbSpinnerStatus="info"
                >
                  Loading ...
                </div>
                <!-- <div (click)="
                in.close(); addSubForm(field, field.sequence)
              " class="hover-addnew">
                Add New {{ field.lookupTableName | ngxPlural : 1000 }}
              </div> -->
              </ng-template>
              <!-- <ng-template ng-label-tmp let-item="item">
                <div
                  (click)="onShowLookupDetail($event,field,item,item.id,editLoadAsDropdownLookupPopover, 'editLoadAsDropdownLookupPopover')"
                  [ngbPopover]="templateRef4" placement="bottom" container="body"
                  #editLoadAsDropdownLookupPopover="ngbPopover" [autoClose]="'outside'">
                  <i class="fa fa-window-close" (click)="removeSelected(item, field)">x</i>
                  <b style="font-size: 16px">{{ item.value }}</b>
                </div>
              </ng-template> -->
            </ng-select>
          </button>
        </mat-menu>
        <div class="grediant-lis pull-right d-flex" style="align-items: center">
          <ul>
            <li *ngIf="isEmailField[tableName] && dataToPassInForm.email">
              <a href="mailto:{{ dataToPassInForm.email }}">
                <span class="material-icons-outlined mat-icon-custom"
                  >mail</span
                >
              </a>
            </li>
            <li *ngIf="isEmailField[tableName] && !dataToPassInForm.email">
              <a href="mailto:">
                <span class="material-icons-outlined mat-icon-custom"
                  >mail</span
                >
              </a>
            </li>
            <li
              *ngIf="provideFiles"
              class="attachment-grad-icon mat-icon-custom"
            >
              <a>
                <span
                  (click)="clickTab('Files')"
                  class="material-icons-outlined mat-custom"
                  >attachment</span
                >
              </a>
            </li>
            <!-- <li><a href="#">
                <span class="material-icons-outlined mat-custom">dashboard</span>
              </a></li> -->
            <li>
              <a>
                <span
                  (click)="openReminderModal()"
                  class="material-icons-outlined mat-custom"
                  >notifications</span
                >
              </a>
            </li>
            <li>
              <a
                *ngIf="phoneField && dataToPassInForm[phoneField?.name]"
                [href]="'tel:' + dataToPassInForm[phoneField?.name]"
              >
                <span class="material-icons-outlined mat-custom system-color"
                  >call</span
                >
              </a>
            </li>
            <li class="pin-gred-icon">
              <a href="#">
                <span class="material-icons mat-custom">push_pin</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="tab_arrows" *ngIf="isTabScollable">
        <i
          class="fa fa-angle-left"
          aria-hidden="true"
          (click)="scollTab('left')"
        ></i>
        <i
          class="fa fa-angle-right"
          aria-hidden="true"
          (click)="scollTab('right')"
        ></i>
      </div>
      <div
        id="bread"
        style="position: fixed; z-index: 99999999; right: 50%"
        *ngIf="breadcrumbs.length && showBreadCrump"
        (click)="scrollToBottom()"
        class="bottom-arrow txt-bg"
      >
        <span class="material-symbols-outlined">
          keyboard_double_arrow_down
        </span>
      </div>

      <div id="content-tag" class="table-view-scroll">
        <nb-tabset
          id="tab-set"
          class="cus_tab projectstyle border-tab detail-view-page"
          *ngIf="pageLoaded"
          (changeTab)="selectTab($event)"
          (mousedown)="startDragging($event)"
          (mouseup)="stopDragging($event)"
          (mouseleave)="stopDragging($event)"
          (mousemove)="moveEvent($event)"
        >
          <nb-tab
            *ngIf="showOverview && lookupDataCompiled"
            tabTitle="Overview"
            [active]="tabTitle == 'Overview'"
          >
            <ngx-overview
              [tabs]="tabs"
              (emitTabChange)="clickTab($event)"
              [tableData]="tableDataFromViewToForm"
              [activePageLayout]="activePageLayout"
              [allFilters]="allFilters"
              [recordId]="id"
              [clientData]="clientData"
              [dataToPassInForm]="dataToPassInForm"
            >
            </ngx-overview>
          </nb-tab>

          <nb-tab
            *ngIf="lookupDataCompiled && !hideInfo"
            tabTitle="{{ singularTableName }} Information"
            [active]="tabTitle == singularTableName + ' Information'"
          >
            <div
              class="display-column"
              [ngClass]="{
                'left_side-dynamic':
                  this.sections[tableName] &&
                  this.sections[tableName].length == 0
              }"
              [ngStyle]="{
                'margin-top': breadcrumbs.length > 0 ? '20px' : null
              }"
            >
              <!-- <div class="profile_div display-flex-col" [ngClass]="{'not-visible': this.formHasSections[tableName]}">

              <ngx-dynamic-form-dialog-new-design
                *ngIf="this.dataFetched && this.dataToPassInForm && this.form[this.tableName]"
                [tableName]="this.tableName" [tableId]="this.tableId" [tableDataForForms]="tableDataFromViewToForm"
                [viewFlag]="true" [inlineView]="true" [Data]="this.dataToPassInForm" [form]="this.form[this.tableName]"
                [id]="this.id" (emitToView)="editFromForm()" [subFormLookupIds]="subFormLookupIds">
              </ngx-dynamic-form-dialog-new-design>

            </div> -->

              <div class="profile_div display-flex-col">
                <div class="client-information-tab display-flex-container">
                  <div class="display-column">
                    <div
                      class="client-information-column display-flex-col"
                      [ngStyle]="{
                        display: row && row.length > 1 ? 'block' : 'none'
                      }"
                      *ngFor="
                        let row of this.sections[tableName];
                        let sIndex = index
                      "
                    >
                      <div *ngIf="row && row.length > 1" class="infoLabels">
                        <h4
                          *ngIf="this.formHasSections[tableName]"
                          class="txt-clr"
                        >
                          {{ row[0].label }}
                          <span class="cus-divider txt-bg"></span>
                        </h4>
                        <a
                          class="pull-right edit-anchor"
                          (click)="editDynamicTable(sIndex)"
                        >
                          <!-- <i class="color_icon fas fa-pen"></i> -->
                          <svg
                            class="txt-clr"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            width="22px"
                            height="22px"
                            viewBox="0 0 22 22"
                            version="1.1"
                          >
                            <g id="surface1">
                              <path
                                style="
                                  stroke: none;
                                  fill-rule: nonzero;
                                  fill-opacity: 1;
                                "
                                d="M 4.125 17.875 L 5.132812 17.875 L 15.285156 7.722656 L 14.277344 6.714844 L 4.125 16.867188 Z M 18.195312 6.738281 L 15.261719 3.804688 L 16.226562 2.839844 C 16.484375 2.582031 16.804688 2.453125 17.1875 2.453125 C 17.570312 2.453125 17.890625 2.582031 18.148438 2.839844 L 19.160156 3.851562 C 19.417969 4.109375 19.546875 4.429688 19.546875 4.8125 C 19.546875 5.195312 19.417969 5.515625 19.160156 5.773438 Z M 17.234375 7.699219 L 5.683594 19.25 L 2.75 19.25 L 2.75 16.316406 L 14.300781 4.765625 Z M 14.78125 7.21875 L 14.277344 6.714844 L 15.285156 7.722656 Z M 14.78125 7.21875 "
                              />
                            </g>
                          </svg>
                        </a>

                        <div class="row" style="width: 98%">
                          <div
                            *ngFor="let field of row; let i = index"
                            [ngClass]="[
                              field.fieldSize == 'tiny' ? 'col-md-3' : '',
                              field.fieldSize == 'small' ? 'col-md-4' : '',
                              field.fieldSize == 'medium' ? 'col-md-6' : '',
                              field.fieldSize == 'large' ? 'col-md-12' : ''
                            ]"
                          >
                            <ngx-all-fields
                              *ngIf="field.name !== 'assignedTo'"
                              [field]="field"
                              [i]="i"
                              [dataToPassInForm]="dataToPassInForm"
                              [showEditFieldForInlineEdit]="
                                showEditFieldForInlineEdit
                              "
                              [lookupDataObject]="lookupDataObject"
                              [forkJoinLookupResponse]="forkJoinLookupResponse"
                              [formColumns]="this.form[tableName]"
                              [tableDataFromViewToForm]="
                                tableDataFromViewToForm
                              "
                              [recordGadgets]="recordGadgets"
                              [filteredOptions]="filteredOptions"
                              [tableId]="tableId"
                              [lookupValue]="lookupValue"
                              [tableName]="this.tableName"
                              [id]="id"
                              (emitSave)="reload()"
                              (lookupValueEmit)="getLookupValue($event)"
                            >
                            </ngx-all-fields>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="display-flex-col">
                    <ngx-activity-watchers [showChats]="showChats" [tableInfo]="tableInfo" [subscribers]="subscribers"
                      [subscriptionText]="subscriptionText" [isSelfSubscribed]="isSelfSubscribed"
                      [tableName]="tableName" [Data]="this.clientData" [formColumns]="this.form[tableName]"
                      [showWatchers]="false" [recordData]="clientData"></ngx-activity-watchers>
                  </div> -->
                  </div>
                </div>
              </div>
            </div>
          </nb-tab>

          <nb-tab
            tabTitle="{{ lookup.value[0] }}"
            class="tab_class filesTab"
            *ngFor="
              let lookup of tempLookupRelateData
                | filterTabs : tableDataFromViewToForm
                | keyvalue;
              let i = index
            "
            [active]="tabTitle == lookup.value[0]"
            id="addNewTab"
          >
            <ng-container *ngIf="lookup.value[0] != 'Files'">
              <!-- Render Tabs -->
              <div class="main_info new-inline-table-view">
                <!-- <ng-container *ngIf="lookup.value[0] != 'Tasks'"> -->
                <ngx-dynamic-tree-table
                  *ngIf="checkFlag && tabTitle == lookup.value[0]"
                  [getTableByNameObject]="getTableByNameObject"
                  [fromRecordPage]="true"
                  [renderAt]="id"
                  [tableName]="lookup.value[0]"
                  (treeUpdated)="treeUpdated($event)"
                  [count]="
                    lookup?.value[1] &&
                    lookup?.value[1]?.tempRecords?.length > 0
                      ? lookup?.value[1]?.tempRecords?.length
                      : 0
                  "
                  [parentTableNameFromView]="this.tableName"
                  [parentTableDataFromView]="this.clientData"
                  [lookUpNameId]="this.id"
                  [lookUpName]="this.tableName.slice(0, -1).toLowerCase()"
                  [getSystemConfigVariable]="getSystemConfigVariable"
                >
                </ngx-dynamic-tree-table>
              </div>
            </ng-container>
            <!-- <ng-container *ngIf="lookup.value[0] == 'Files'">
            <div class="main_info new-inline-table-view">
              <ngx-files-listing-component>
              </ngx-files-listing-component>
            </div>
          </ng-container> -->
          </nb-tab>

          <nb-tab
            *ngIf="lookupDataCompiled && provideFiles"
            tabTitle="Files"
            [active]="tabTitle == 'Files'"
            class="filesTab"
            style="overflow-x: hidden"
          >
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <ngx-files-listing-component
                  [parentTableName]="tableName"
                  [id]="id"
                  [fromRecordPage]="true"
                  [getSystemConfigVariable]="getSystemConfigVariable"
                  [tablesMenuData]="tableDataFromViewToForm"
                >
                </ngx-files-listing-component>
              </div>
            </div>
          </nb-tab>

          <nb-tab
            *ngIf="lookupDataCompiled && showChats"
            tabTitle="Chat & Activity"
            [active]="tabTitle == 'Chat & Activity'"
            class="activityTab overflow-hidden"
          >
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <ngx-activity-watchers
                  [showChats]="showChats"
                  [refreshChat]="tabTitle == 'Chat & Activity'"
                  [tableInfo]="tableInfo"
                  [subscribers]="subscribers"
                  [subscriptionText]="subscriptionText"
                  [isSelfSubscribed]="isSelfSubscribed"
                  [tableName]="tableName"
                  [Data]="this.clientData"
                  [formColumns]="this.form[tableName]"
                  [showWatchers]="false"
                  [isActivity]="true"
                  [recordData]="this.clientData"
                  [getSystemConfigVariable]="getSystemConfigVariable"
                >
                </ngx-activity-watchers>
              </div>
            </div>
          </nb-tab>
        </nb-tabset>

        <div class="breadcrumb-status" *ngIf="breadcrumbs.length" cdkDrag>
          <ngx-table-breadcrumb
            [breadcrumbs]="breadcrumbs"
            [tableName]="tableName"
            [tableId]="tableId"
            [id]="id"
            (callDataAgain)="refreshData($event)"
            [parentTableDataFromView]="this.clientData"
            [tableDataFromViewToForm]="tableDataFromViewToForm"
            [systemConfigColor]="systemConfigColor"
          >
          </ngx-table-breadcrumb>
        </div>
      </div>
    </div>
  </div>
</nb-card>
