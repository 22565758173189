import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { TableService } from '../../services/table.service';

@Component({
  selector: 'ngx-all-lookup-display',
  templateUrl: './all-lookup-display.component.html',
  styleUrls: ['./all-lookup-display.component.scss']
})
export class AllLookupDisplayComponent implements OnInit {

  @Input() itemForLookup;
  @Input() tempParentTableHeader;
  @Input() tableData;
  @Input() column;
  @ViewChild("templateRefInside", { static: false }) templateRefInside;
  lookupFieldName = "";
  recordItem;
  lookupDetailItem = null;
  tableForLookupDetail = "";
  dataForLookupDetail;
  showLookupDetail = {};
  constructor(private tableService:TableService,
    private cdr:ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  onShowLookupDetail(event, lookupData, columnName, item) {

    this.lookupFieldName = "";
    this.recordItem = item;
    this.lookupDetailItem = null;
    this.tableForLookupDetail = "";
    this.dataForLookupDetail = {};

    if (this.showLookupDetail[lookupData._id]) {
      this.dataForLookupDetail = this.showLookupDetail[lookupData._id];
    } else {
      this.tableService
        .getDynamicTreeDataById(lookupData.table, lookupData._id)
        .pipe(debounceTime(2000))
        .subscribe(
          (res: any) => {
            if (res.statusCode == 200) {
              this.dataForLookupDetail = res.data;
              this.showLookupDetail[lookupData._id] = res.data;
            }
          },
          (err) => {

          }
        );
    }
    this.lookupDetailItem = lookupData;
    this.lookupFieldName = lookupData.lookupName;
    this.tableForLookupDetail = lookupData.table;
    this.cdr.detectChanges();
    event.stopPropagation();
  }

}
