import { DatePipe, DOCUMENT, formatDate, Location } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { DynamicTableViewComponent } from "@app/pages/tables/dynamic-table-view/dynamic-table-view.component";
import { ShowcaseDialogComponent } from "@app/shared/components";
import { InfoDialogComponent } from "@app/shared/components/dialog/info-dialog/info-dialog.component";
import { NewReminderModalComponent } from "@app/shared/components/new-reminder-modal/new-reminder-modal.component";
import { DynamicFormCommonService } from "@app/shared/dynamic-form-common.service";
import { PhonePipe } from "@app/shared/pipes/phone.pipe";
import { AuthService } from "@app/shared/services";
import { EventEmiterService } from "@app/shared/services/event-emiter.service";
import { HelperService } from "@app/shared/services/helper.service";
import { MessageService } from "@app/shared/services/message.service";
import { SocketService } from "@app/shared/services/socket.service";
import {
  NbDialogService,
  NbMediaBreakpointsService,
  NbMenuService,
  NbPopoverDirective,
  NbSidebarService,
  NbThemeService,
  NbToastrService,
} from "@nebular/theme";
import { cloneDeep, sortBy } from "lodash";
import { NgPluralizeService } from "ng-pluralize";
import { forkJoin, Observable, Subject, Subscription } from "rxjs";
import "rxjs/add/observable/interval";
import { fromEvent } from "rxjs/internal/observable/fromEvent";
import { map, takeUntil, throttleTime } from "rxjs/operators";
import { UserData } from "../../../@core/data/users";
import { LayoutService } from "../../../@core/utils";
import { RippleService } from "../../../@core/utils/ripple.service";
import { ChatSubscriptionService } from "../../../shared/services/chat-subscription.service";
import {
  FilterForService,
  TableService,
} from "../../../shared/services/table.service";
@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  clickEventsubscription: Subscription;
  private destroy$: Subject<void> = new Subject<void>();
  public readonly materialTheme$: Observable<boolean>;
  userPictureOnly: boolean = false;
  user: any;
  condtion = false;
  timeout;
  keySubscription: Subscription;
  mouseSubscription: Subscription;
  isActive = true;
  userId = "";
  actionSubscription: Subscription;
  logoPathSubscription: Subscription;
  refreshHeaderSubscription: Subscription;
  notificationCount = 0;
  notificationBadgeText = "";
  showNotification = false;
  showUserCard: boolean = false;
  isAnnounceRead = true;
  announcement: any = [];
  allowedUserStatus = ["Online", "Away"];
  cavalryLogo = {
    path: "",
    height: 45,
    cssClass: "cavalry-logo",
  };
  audioSource: string = "assets/new_notification.mpeg";
  audioFile: HTMLAudioElement;
  reminderSubScription: Subscription;
  unreadNotificationCount = 0;
  userProfileMenu = [
    { title: "Call Log Configurations" },
    { title: "Profile" },
    { title: "Users" },
    { title: "Account" },
    { title: "Integrations" },
    { title: "System Configurations" },
  ];
  items = [
    { icon: { icon: "python", pack: "edit" } },
    // -- { icon: { icon: 'python', pack: 'view' } },
    // { icon: { icon: 'python', pack: 'delete' } },
  ];
  currentTheme = "default";
  userMenu = [{ title: "Profile" }, { title: "Log out" }];
  notificationMenu = [
    {
      notificationId: "",
      title: "No new notification!",
      tableId: "",
      tableName: "",
      reminderNotes: "",
      resourceId: "",
      subject: "",
      description: "",
      resourceName: "",
      notes: "",
      isNewMessage: false,
      subscribers: [],
      details: "",
      dateTime: new Date(),
      toDisplay: [],
      tableIcon: {},
    },
  ];

  isGearIcon = false;
  quickActionList = [];
  username = "";
  count = 0;
  numberOfPagesLoadedReminder = 0;
  numberOfPagesLoadedNotification = 0;
  @Input() getSystemConfigVariable;
  @Input() tableName: string;
  @Input() fromRecordPage = false;
  @Input() getTableByNameObject = {};
  @Input() fromDummy = false;
  @Input() layout = null;
  headerObj = {};
  parentTableHeader = [];
  tableNameWithoutS: string;
  tableData: any = [];
  getTableByNameFlagCalled = false;
  subFormLookupIds: any;
  taskStatus = [];
  taskRecordTypes: any = [];
  tableRecordTypes: any = [];
  condition = [false];
  headerWidth = 0;
  accessTableName = [];
  actions = [];
  formLayouts = [];
  tutorials = [];
  tutorial = "";
  recordGadgets;
  showChats;
  finalPageLayout = null;
  ejsPackage;
  parentTableData = [];
  displayRecordGadgets = false;
  pageLayoutSide = "";
  isCenterPageLayout = {
    mode: "",
    logic: "",
  };
  displayCenterLogic;
  isDefaultFilterApplied = false;
  pageLayout = [];
  customValidations;
  allowSubtree = {};
  subTreeTable = {};
  formHeight;
  formWidth;
  fieldAlignment;
  viewInModal = false;
  provideFiles = false;
  enableCSV = false;
  kanbanViewEnable = false;
  tableHasKanbanView = false;
  viewAllRecords = false;
  resColData = {};
  resultData = [];
  dynamicFilterData: any;
  canAddNewRecord = false;
  lookTable: any;
  lookupName: any;
  showAutocomplete = {};
  lookupData: any = [];
  lookupValue = {};
  showFilterBox = {};
  hideColumn = {};
  filterObject: FilterForService;
  filteredOptions = {};
  search: any;
  demoData: [];
  dependsFields = [];
  allParentTableHeader;
  statuses = [];
  forkJoinTableDataObject: any = {};
  forkJoinDataForTables;
  tableId: string;
  datafetched = false;
  tempParentTableHeader = [];
  isEmailField = false;
  phoneField = {};
  refButtonField = {};
  allLookupsList = [];
  selectedGroupBy = "";
  recordTypeFieldName: string = null;
  recordTypes = [];
  statusArray = [];
  statusName = "";
  filteredStatus = {};
  finalList = [];
  sortObject: any = null;
  allFilters = [];
  readyForPageLayout = false;
  defaultStyleFlag = null;
  fromOverview = false;
  hasUserPreference = false;
  tagList = [];
  allUsers = [];
  searchString: string = null;
  viewCompletedRecords = false;
  searchMode = "no";
  loading = false;
  fromDashboard = false;
  pageNo = 0;
  @Input() directViewFlag;
  @Input() directViewId = null;
  @Input() directTableName;
  @Input() rowId;
  @Input() parentTableNameFromView;
  @Input() parentTableDataFromView;
  @Input() lookUpNameId;
  @Input() lookUpName;
  @Input() renderAt;
  isPopupOpen = false;
  currentUser;
  recordType: string = null;
  pager: any = {};
  newViewFlag = false;
  // tableIcons = "";
  previousLocation: string;
  chartFilter = [];
  filterDefaultList;
  userPreferences = [];
  restoreObj = {
    filterKey: [],
    tagList: [],
  };
  kanbanLoader = false;
  lookupObj = {};
  isDefaultValueEnable: boolean = false;
  usersWithIds = [];
  callCount: any = 0;
  recordTypeFilter;
  taskedRecordsOnly = false;
  watchedIssue = false;
  listingDataObj = {};
  idFieldsArray = [];
  otherFieldsArray = [];
  finalStatusArray = [];
  dataForApiResponseOfIndividualStatus = {};
  individualStatusPageCount = {};
  loadingIndividual = {};
  pageOfItems = [];
  checkCount = 0;
  public masterSearchTab: any[] = [];
  public isSearchPage: boolean = false;
  noDataFound = false;
  gadgetFieldValue = [];
  activCallData: any;

  @ViewChild(NbPopoverDirective) popover;
  systemConfigColor: any;
  systemShadow: any;
  userEmail: any;
  firstName: any;
  sub: any;
  tableIcon: any;
  anounceList: any = [];
  // @HostListener('window:keyup', ['$event'])
  // keyEvent(event: KeyboardEvent) {
  //   if (this.popover && document.getElementById("dialContainer")) {
  //     if (event.code === "Backspace") {
  //       this.leftArrowClick()
  //     } else if (event.code.search("Numpad") > -1 || event.code.search("Digit") > -1) {
  //       let num = ""
  //       if (event?.code === "Digit8" && event?.shiftKey
  //         || event?.code.split("Numpad")[1] === 'Multiply') {
  //         num = "*"

  //       } else if (event?.code === "Digit3" && event?.shiftKey) {
  //         num = "#"
  //       } else if (event?.code.search("Numpad") > -1) {
  //         num = event?.code.split("Numpad")[1]
  //       } else if (event?.code === "Digit1") {
  //         num = "1"
  //       }
  //       else if (event?.code === "Digit2") {
  //         num = "2"
  //       }
  //       else if (event?.code === "Digit3") {
  //         num = "3"
  //       }
  //       else if (event?.code === "Digit4") {
  //         num = "4"
  //       }
  //       else if (event?.code === "Digit5") {
  //         num = "5"
  //       }
  //       else if (event?.code === "Digit6") {
  //         num = "6"
  //       }
  //       else if (event?.code === "Digit7") {
  //         num = "7"
  //       }
  //       else if (event?.code === "Digit8") {
  //         num = "8"
  //       }
  //       else if (event?.code === "Digit9") {
  //         num = "9"
  //       }
  //       else if (event?.code === "Digit0") {
  //         num = "0"
  //       }
  //       if (!num) {
  //         return
  //       }

  //       this.onDigitClick(num)
  //     }
  //   }
  // }

  userData: any;

  public constructor(
    @Inject(DOCUMENT) private document: Document,
    private toastrService: NbToastrService,
    public route: ActivatedRoute,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private rippleService: RippleService,
    private socketService: SocketService,
    private authService: AuthService,
    private router: Router,
    private chatSubscriptionService: ChatSubscriptionService,
    private tableService: TableService,
    private messageService: MessageService,
    private renderer: Renderer2,
    private dialogService: NbDialogService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private eventEmiter: EventEmiterService,
    private service: NgPluralizeService,
    private title: Title,
    private sanitizer: DomSanitizer,
    private location: Location,
    private helperService: HelperService,
    private nbMenuService: NbMenuService,
    private elem: ElementRef,
    private formService: DynamicFormCommonService
  ) {
    this.materialTheme$ = this.themeService.onThemeChange().pipe(
      map((theme) => {
        const themeName: string = theme?.name || "";
        return themeName.startsWith("material");
      })
    );
    this.tableService.$systemConfig.subscribe((res: any) => {
      if (res) {
        this.systemConfigColor = res.IconColors;
        if (this.systemConfigColor != undefined) {
          this.systemShadow = this.hexToRgb(this.systemConfigColor);
        }
      }
    });

    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.userId = JSON.parse(localStorage.getItem("userData"))._id;
    this.userEmail = JSON.parse(localStorage.getItem("userData")).email;
    this.actionSubscription = this.menuService
      .onItemClick()
      .subscribe((event: any) => {
        if (event.item.title === "Log out") {
          this.authService.logout();
        }
        if (event.item.title === "Profile") {
          const userTableId = "5f60cb6e10fd660a92e9b416";
          this.router.navigateByUrl(
            `pages/tables/dynamic/${userTableId}/Users/${this.userId}`
          );
        }
      });

    this.renderer.listen("window", "click", (e: Event) => {
      const ele = document.getElementById("rem-not");
      if (ele && ele.contains(<Node>e.target)) {
        this.showNotification = true;
      } else {
        this.showNotification = false;
      }
    });
    if (
      JSON.parse(localStorage.getItem("userData")) &&
      localStorage.getItem("isFirstTime")
    ) {
      this.getUserDetail();
    }

    if (
      JSON.parse(localStorage.getItem("userData")) &&
      (localStorage.getItem("isButtonClick") === "true" ||
        localStorage.getItem("openAnnounceModal") == "true")
    ) {
      this.openAnnouncementBox();
    }

    this.logoPathSubscription = tableService.logo.subscribe((count) => {
      this.cavalryLogo.path = count;
    });

    this.clickEventsubscription = this.eventEmiter
      .getReminderClick()
      .subscribe(() => {
        this.openReminderModal();
      });
    this.tableService.getTablesForMenu().subscribe((res) => {
      if (res) {
        let announcement = res["data"].find(
          (x: any) => x.tableName == "Announcements"
        );
        if (
          announcement &&
          announcement?.displayInMenu &&
          announcement?.displayInMenu == true
        ) {
          this.eventEmiter.Announcments = true;
        }
      }
    });
  }

  hexToRgb(hex) {
    let res = hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => "#" + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));
    if (res.length > 0) {
      return "rgba(" + res[0] + "," + res[1] + "," + res[2] + "," + "50%)";
    }
  }
  showSettings = true;
  getTablesForMenu() {
    this.tableService.getTablesForMenu().subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.getTablesForMenuData = res.data;
        this.quickActionList = this.getTablesForMenuData
          .filter((v) => v.quickAction)
          .map((ele) => ele.tableName);
        let setting = this.getTablesForMenuData.filter(
          (v) => v.tableName == "Assigned Permissions"
        );
        if (setting.length == 0) {
          this.showSettings = false;
        } else {
          this.userProfileMenu.splice(2, 0, { title: "Settings" });
          this.userProfileMenu.splice(3, 0, { title: "Field Restrictions" });
          this.userProfileMenu.splice(4, 0, { title: "Assigned Permissions" });
        }
      }
    });
  }

  @HostListener("document:click", ["$event"])
  public documentClick(event: any): void {
    let icon = document.getElementById("menu-id");
    let ele = document.getElementsByClassName("menu-sidebar")[0];
    console.log("Clicked ", event.target.id);
    if (event.target.id === "announcements-btn") {
      localStorage.setItem("isButtonClick", "true");
    }
    if (icon) {
      if (icon.contains(event.target)) {
        if (ele) {
          if (ele.classList.contains("expanded")) {
            this.tableService.setSearchInput(true);
          } else {
            this.tableService.setSearchInput(false);
          }
        }
      } else {
        if (ele) {
          if (ele.classList.contains("expanded")) {
            this.sidebarService.toggle(true, "menu-sidebar");
            this.layoutService.changeLayoutSize();
            this.tableService.setSearchInput(false);
          } else {
            this.tableService.setSearchInput(false);
          }
        }
      }
    }

    if (event.target.id == "tableSearch") {
      if (ele) {
        if (!ele.classList.contains("expanded")) {
          this.sidebarService.toggle(true, "menu-sidebar");
          this.layoutService.changeLayoutSize();
          this.tableService.setSearchInput(true);
          setTimeout(() => {
            document.getElementById("search_tab_input").focus();
            this.tableService.runCustomStyling();
          }, 0);
        }
      }
    }

    setTimeout(() => {
      this.tableService.runCustomStyling();
    }, 260);
  }

  messageList = [];
  async ngOnInit() {
    this.tableName = "Call Log";
    this.changeUserStatus();
    this.getTablesForMenu();
    this.getActiveCall();
    this.getTableByName();
    this.getTasks();
    this.currentTheme = this.themeService.currentTheme;
    // this.getUnreadMessages();
    if (localStorage.getItem("userData")) {
      const userData = JSON.parse(localStorage.getItem("userData"));
      this.isGearIcon = userData.isGearIcon;
      this.username = (
        userData.firstName +
        " " +
        userData.lastName
      ).toUpperCase();
      this.firstName = userData.firstName;
    }

    await this.getAnnouncementList();

    this.socketService.listen("room_message").subscribe((res: any) => {
      let id = res.sender?._id ? res.sender?._id : res.sender;
      // if (id !== this.userId) {
      this.chatSubscriptionService
        .getSubscribers(res.resourceDetails.resourceId)
        .subscribe((resp: any) => {
          if (resp.data) {
            let user = resp.data.find((v) => v._id == this.userId);

            if (user) {
              if (res.messageType == "log") {
                this.notificationList.unshift(res);
                this.notificationBadgeText = ".";
                this.unreadNotificationCount++;
                // if (data.creator !== this.userId)
                this.emitMessageSound();
                // this.tableService.getNotificationsList(this.notificationList.slice(0, 5));

                //-- Refresh the real time message layout ( config based )
                this.tableService.refreshMessageLayout();
              } else {
                this.messageList.unshift(res);
                this.tableService.getRecordSpecificMessages(
                  this.messageList.slice(0, 3)
                );
                //-- Refresh the real time message layout ( config based )
                this.tableService.refreshMessageLayout();
              }
            }

            // this.emitMessageSound();
          }
        });
      // }
    });

    //new record
    this.socketService.listen("new_record_created").subscribe((data: any) => {
      let checkUser = data.data?.lookup.find(
        (item) => item.lookupId == this.userId
      );
      if (data.data?.lookup.length > 0 && checkUser) {
        if (
          data.tableName == "Call Log" &&
          data?.data?.callType == "Active Call"
        ) {
          this.activCallData = data.data;
          setTimeout(() => {
            this.tableService.runCustomStyling();
          }, 1000);
          // this.tableService.refreshHeaderComponent();
        }
      }
      if (this.announcement) {
        if (data.tableName == "Announcements") {
          setTimeout(() => {
            // this.getUserDetail();
            this.getAnnouncementList();
            if (localStorage.getItem("openAnnounceModal") == "false") {
              localStorage.setItem("openAnnounceModal", "true");
            }
            // localStorage.setItem('isFirstTime', 'true');
            this.tableService.runCustomStyling();
          }, 2000);
        }
      }
    });
    //delete record
    this.socketService.listen("delete_record").subscribe((data: any) => {
      if (data.activeData && data.activeData == 1) {
        delete data.activeData;
        let checkUser = data?.lookup.find(
          (item) => item.lookupId == this.userId
        );
        if (data?.lookup.length > 0 && checkUser) {
          this.activCallData = data;
        }
      } else {
        this.activCallData = null;
        setTimeout(() => {
          this.tableService.runCustomStyling();
        }, 1000);
      }
    });

    //update record
    this.socketService.listen("update_record").subscribe((data: any) => {
      let checkUser = data.data?.lookup.find(
        (item) => item.lookupId == this.userId
      );
      if (data.data?.lookup.length > 0 && checkUser) {
        if (
          data.tableName == "Call Log" &&
          data?.data?.callType == "Active Call"
        ) {
          this.activCallData = data.data;
          setTimeout(() => {
            this.tableService.runCustomStyling();
          }, 1000);
          // this.tableService.refreshHeaderComponent();
        } else {
          this.activCallData = null;
        }
      }
      if (data.tableName == "Announcements") {
        setTimeout(() => {
          this.getUserDetail();
          this.tableService.runCustomStyling();
        }, 2000);
      }
    });

    this.userService
      .getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => (this.user = users.nick));

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => {
        this.currentTheme = themeName;
        this.rippleService.toggle(themeName?.startsWith("material"));
      });
    // for chat functions
    this.setStatusChangeTimer();
    this.keyPressSubscription();
    this.mouseMoveSubscription();
    this.chatSubscriptionService.getSubscriptions();
    this.getChatSubscription();
    this.getAllUsers();

    setTimeout(() => {
      this.getNotificationsList();
      this.getRemindersList();
      this.listenGeneralReminders();
      this.listenRecurring();
    }, 3000);

    // setTimeout(() => {
    //   this.getAnnouncement();
    // }, 1000)

    this.sub = Observable.interval(1000).subscribe((val) => {
      this.fireReminder();
    });

    this.tableService.refreshHeader.subscribe((res: any) => {
      this.getTablesForMenu();
    });

    this.bufferAudioFile();
  }
  async getAnnouncementList() {
    let responce: any =
      await this.tableService.GetDynamicTableWithoutPagination(
        "Announcements",
        "no",
        true
      );
    this.anounceList = responce.data;
  }
  async getActiveCall() {
    let response: any = await this.tableService.getDynamicTreeData(
      "Call Log",
      1,
      "",
      { column: "timeOfCall", direction: "desc" },
      [{ assignedTo: [this.userId] }, { callType: ["Active Call"] }]
    );
    if (
      response &&
      response.data?.pageOfItems &&
      response.data.pageOfItems.length > 0
    ) {
      this.activCallData = response.data.pageOfItems[0];
    }
  }

  async openCallDetailModal(data) {
    let tableData: any = await this.tableService.getTableByName("Call Log");
    this.tableId = tableData.data[0]._id;
    this.tableName = tableData.data[0].tableName;
    this.viewInModal = tableData.data[0].viewInModal
      ? tableData.data[0].viewInModal
      : null;
    if (tableData.data[0] && this.tableId && this.tableName) {
      if (tableData.data[0].tableName == this.tableName) {
        this.tdClick(this.tableId, this.tableName, data._id, this.viewInModal);
        this.backDrop();
      }
    }
  }

  // -- Open record for view in modal or old view mode
  tdClick(tableId, tableName, _id, modal) {
    if (tableId) {
      this.previousLocation = this.location.path();
      if (modal) {
        this.tableService
          .getDynamicTreeDataById(tableName, _id)
          .pipe(takeUntil(this.destroy$))
          .subscribe((res: any) => {
            if (res.statusCode == 200) {
              this.openViewModal(res.data, _id);
              this.isPopupOpen = false;
              this.actionSubscription.unsubscribe();
            }
          });
      } else {
        this.location.replaceState(this.previousLocation);
      }
    }
    this.eventEmiter.idField = this.eventEmiter.idField.filter(
      (v, i, a) => a.findIndex((t) => t.idField === v.idField) === i
    );
    localStorage.setItem("IdFields", JSON.stringify(this.eventEmiter.idField));
  }

  async getTasks() {
    let tableData: any = await this.tableService.getTableByName("Call Log");
    if (tableData.data[0].viewInModal) {
      this.viewInModal = tableData.data[0].viewInModal
        ? tableData.data[0].viewInModal
        : null;
    }
  }

  backDrop() {
    let previousPopover =
      document.getElementsByTagName("ngb-popover-window")[0];
    if (previousPopover) {
      previousPopover.remove();
    }
  }
  getTaskStatus(data) {
    const taskTable = data.find((x) => x.tableName == "Call Log");

    if (taskTable) {
      const taskColumns = taskTable.columns;
      if (taskColumns && taskColumns.length) {
        const statusData = taskColumns.find((x) => x.type == "status");
        if (
          statusData &&
          statusData.statusOptions &&
          statusData.statusOptions.length
        ) {
          this.taskStatus = statusData.statusOptions;
        }
      }
    }
  }

  getRecordType(cols, tableName) {
    let findRecordType = cols.find((x) => x.type == "recordType");
    if (
      typeof findRecordType != "undefined" &&
      findRecordType != "undefined" &&
      findRecordType !== null
    ) {
      const name = findRecordType.name;
      findRecordType = findRecordType.options;
      const recordArray = [];
      findRecordType &&
        findRecordType.forEach((element) => {
          const obj = {
            title: element,
            data: {
              menu: tableName,
              name: name,
            },
          };
          recordArray.push(obj);
          if (tableName == "Tasks") {
            this.taskRecordTypes.push(obj);
          }
        });
      this.tableRecordTypes[tableName] = recordArray;
    }
  }

  getSavedHeaderWidth() {
    const headerColumnsWidth = JSON.parse(localStorage.getItem("col-size"));
    headerColumnsWidth &&
      headerColumnsWidth.forEach((col) => {
        if (col.tableName === this.tableName) {
          this.condition[col.index] = true;
          this.headerWidth = col.headerWidth;
        }
      });
  }

  getTableByName() {
    this.headerObj = {};
    this.parentTableHeader = [];
    this.tableName = this?.tableName?.replace(/_/g, " ")?.split("?")?.[0];
    this.tableService
      .getTablesForMenu()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (res: any) => {
        if (res.statusCode === 200) {
          this.tableData = res.data;
          this.getTableByNameFlagCalled = true;
          let data = res.data;
          data = data.filter((item) => item.tableName == this.tableName);
          this.subFormLookupIds = data[0]?.subFormLookups;
          if (data[0]?.masterDetail) {
            const id = data[0].masterDetail.lookupId;
            data = data[0].columns.filter(
              (item) => item.type == "lookup" && item._id == id
            );
            this.headerObj = {
              name: data[0]?.lookupTableName,
              fieldName: data[0]?.lookupTableFieldNames,
            };
          }
          this.getTaskStatus(res.data);
          if (this.tableData && this.tableData.length) {
            this.tableData.forEach((ele) => {
              if (ele && ele.columns && ele.columns.length) {
                this.getRecordType(
                  Object.assign([], ele.columns),
                  ele.tableName
                );
              } else {
                this.tableRecordTypes[ele.tableName] = [];
              }
            });
          }
          this.accessTableName = res.data.map((d) => d.tableName);
          let tableAccessed = this.accessTableName.includes(this.tableName);
          if (tableAccessed) {
            this.getSavedHeaderWidth();
            if (this.tableName.endsWith("s" || "S")) {
              this.tableNameWithoutS = this.service.singularize(this.tableName);
            } else {
              this.tableNameWithoutS = this.tableName;
            }

            if (this.fromRecordPage) {
              if (this.getTableByNameObject[this.tableName]) {
                let res = this.getTableByNameObject[this.tableName];
                this.getTableByNameFnCode(res);
              } else {
                let res: any = await this.tableService.getTableByName(
                  this.tableName
                );
                if (res) {
                  this.getTableByNameObject[this.tableName] = res;
                  this.getTableByNameFnCode(res);
                }
              }
            } else {
              let res: any = await this.tableService.getTableByName(
                this.tableName
              );
              if (res) {
                const idField =
                  res.data &&
                  res.data.length &&
                  res.data[0].columns.find((f) => f.idField)?.name;
                this.tableService.currentIdField = idField;

                if (this.getSystemConfigVariable) {
                  this.title.setTitle(
                    this.tableName
                      .toLowerCase()
                      .replace(/\b(\w)/g, (s) => s.toUpperCase()) +
                      " - " +
                      this.getSystemConfigVariable["Company Name"]
                  );
                } else {
                  this.tableService.$systemConfig.subscribe((configs) => {
                    this.title.setTitle(
                      this.tableName
                        .toLowerCase()
                        .replace(/\b(\w)/g, (s) => s.toUpperCase()) +
                        " - " +
                        configs["Company Name"]
                    );
                  });
                }

                this.getTableByNameFnCode(res);
              }
            }
          }
        }
      });
  }

  loadTutorial() {
    let tutorialTable = this.tutorials.filter((v) => v.tutorialFor == "Table");
    if (tutorialTable && tutorialTable.length) {
      this.tutorial = tutorialTable[0];
    }
  }

  // async getRecordGadgetsFrompageLayout(element) {
  //   this.finalPageLayout = element;

  //   if (this.finalPageLayout.layoutStyle == 'center') {

  //     await System.import('../../../../../node_modules/ejs/ejs.js').then(async res => {
  //       this.ejsPackage = res;

  //       this.finalPageLayout['display'] = await this?.ejsPackage?.render(element?.centerLogic?.logic, { tableData: this?.parentTableData }, { async: true });
  //       this.finalPageLayout['display'] = this.sanitizer.bypassSecurityTrustHtml(element.display);

  //     });
  //   }
  // }

  // -- Create Tags based on Filters applied.
  createTagList(list?) {
    let data = [];
    if (list && list.length) {
      data = list;
    } else {
      data = { ...this?.filterObject?.filterKey };
    }
    this.tagList = [];
    if (data && data.length) {
      data.forEach((ele) => {
        let keys = [];
        keys = Object.keys(ele);
        if (keys && keys.length) {
          keys.forEach((item) => {
            const obj = {
              id: null,
              name: "",
              type: "",
              value: null,
            };
            if (item == "watchedBy") {
              obj.name = "Watched By";
              obj.type = "watchedBy";
              obj.value = [];
              obj.id = [];
              this.makeTagList(ele, item, obj, this.allUsers);
            } else {
              this.finalList.forEach((v) => {
                if (v.name == item) {
                  obj.name = v.name;
                  obj.type = v.type;
                  obj.id = [];
                  if (obj.type == "lookup") {
                    obj.value = [];
                    this.makeTagList(
                      ele,
                      item,
                      obj,
                      this?.filterObject?.lookupValue?.[obj.name]
                    );
                  }
                  // else if (obj.type == "dateTime") {
                  //   const start = this.datePipe.transform(this.filterObject.DateTimerange.start, "shortDate");
                  //   const end = this.datePipe.transform(this.filterObject.DateTimerange.end, "shortDate");
                  //   obj.value = start + " - " + end;
                  // } else if (obj.type == "date") {
                  //   const start = this.datePipe.transform(new Date(this.filterObject.range.start), "shortDate");
                  //   const end = this.datePipe.transform(new Date(this.filterObject.range.end), "shortDate");
                  //   obj.value = start + " - " + end;
                  // }
                  else {
                    obj.value = ele[item];
                  }
                }
              });
            }
            if (obj.value && obj.name) this.tagList.push(obj);
          });
        }
      });
    }
  }

  makeTagList(ele, item, obj, temp) {
    ele[item].forEach((value, ind) => {
      if (value == "" || value == null) {
        if (ind == 0 && ind == ele[item].length - 1) {
          obj.value.push("  " + "Me");
          obj.id.push(value);
        } else if (ind == ele[item].length - 1) {
          obj.value.push("  " + "Me");
          obj.id.push(value);
        } else {
          obj.value.push("  " + "Me" + ",");
          obj.id.push(value);
        }
      } else {
        if (temp && temp.length) {
          temp.forEach((element) => {
            let id = element.id ? element.id : element._id;
            if (value == id) {
              if (ind == 0 && ind == ele[item].length - 1) {
                obj.value.push("  " + element.value);
                obj.id.push(element.id);
              } else if (ind == ele[item].length - 1) {
                obj.value.push("  " + element.value);
                obj.id.push(element.id);
              } else {
                obj.value.push("  " + element.value + ",");
                obj.id.push(element.id);
              }
            }
          });
        }
      }
    });
  }

  bindingFilterKeyVariables(list) {
    list?.forEach((element) => {
      if (element.type === "lookup") {
        let temp = [];
        element.value.forEach((item, i) => {
          temp.push({
            id: element.id[i],
            value: item.trim().endsWith(",")
              ? item.trim().substr(0, item.trim().length - 1)
              : item.trim(),
          });
        });
        let lookup = this?.filterObject?.lookupValue?.[element.name];
        lookup = temp;
      } else if (element.type === "status") {
        this.filteredStatus[element.name] = element.value;
      }
    });
  }

  isEqual(value, other) {
    var type = Object.prototype.toString.call(value);
    if (type !== Object.prototype.toString.call(other)) return false;
    if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;
    var valueLen =
      type === "[object Array]" ? value.length : Object.keys(value).length;
    var otherLen =
      type === "[object Array]" ? other.length : Object.keys(other).length;
    if (valueLen !== otherLen) return false;
    if (type === "[object Array]") {
      for (var i = 0; i < valueLen; i++) {
        if (this.compare(value[i], other[i]) === false) return false;
      }
    } else {
      for (var key in value) {
        if (value.hasOwnProperty(key)) {
          if (this.compare(value[key], other[key]) === false) return false;
        }
      }
    }
    return true;
  }

  compare(item1, item2) {
    var itemType = Object.prototype.toString.call(item1);
    if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
      if (!this.isEqual(item1, item2)) return false;
    } else {
      if (itemType !== Object.prototype.toString.call(item2)) return false;
      if (itemType === "[object Function]") {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }
    }
  }

  async getTableByNameFnCode(res) {
    if (res?.data && res.data[0].columns) {
      this.actions = res.data[0].actions ? res.data[0].actions : [];
      this.formLayouts = res.data[0].formLayouts ? res.data[0].formLayouts : [];
      this.tutorials = res.data[0].tutorials ? res.data[0].tutorials : [];
      this.loadTutorial();
      this.recordGadgets = res.data[0].recordGadgets
        ? res.data[0].recordGadgets
        : [];
      if (res.data[0].hasOwnProperty("showChats")) {
        this.showChats = res.data[0].showChats;
      }

      if (this.fromDummy) {
        if (this.layout.layoutForDropdown == "listing") {
          // this.getRecordGadgetsFrompageLayout(this.layout);
          this.displayRecordGadgets = true;
          if (this.layout.layoutStyle == "center3Top") {
            this.pageLayoutSide = "top";
          } else {
            this.pageLayoutSide = "right";
          }
          this.isCenterPageLayout = this.layout.centerLogic;

          if (this.layout.centerLogic?.mode == "isCustom") {
            this.displayCenterLogic = this.layout.centerLogic.logic;
          }
        }
      } else {
        this.pageLayout =
          res.data[0].pageLayout && res.data[0].pageLayout.length
            ? res.data[0].pageLayout
            : [];
        if (this.pageLayout && this.pageLayout.length) {
          this.pageLayout.forEach((element) => {
            if (element.layoutForDropdown == "listing") {
              if (element.isActive) {
                this.displayRecordGadgets = true;
                if (element.layoutStyle == "center3Top") {
                  this.pageLayoutSide = "top";
                } else if (element.layoutStyle == "center") {
                  this.pageLayoutSide = "";
                } else {
                  this.pageLayoutSide = "right";
                }
                this.isCenterPageLayout = element.centerLogic;

                if (element.centerLogic?.mode == "isCustom") {
                  this.displayCenterLogic = element.centerLogic.logic;
                }

                // this.getRecordGadgetsFrompageLayout(element);
              }
            }
          });
        }
      }

      this.customValidations = res.data[0].customValidations
        ? res.data[0].customValidations
        : [];
      this.allowSubtree[this.tableName] = res.data[0].allowSubtree
        ? res.data[0].allowSubtree
        : false;
      this.subTreeTable[this.tableName] = res.data[0].subTreeTable
        ? res.data[0].subTreeTable
        : "";

      // this.tableIcons = res.data[0].iconLocation ? res.data[0].iconLocation : '';
      this.formHeight = res.data[0].formHeight ? res.data[0].formHeight : null;
      this.formWidth = res.data[0].formWidth ? res.data[0].formWidth : null;

      if (this.formWidth) {
        let width = Number(this.formWidth);
        if (width < 410) {
          width = 410;
        }
        let wid: any = this.sanitizer.bypassSecurityTrustStyle(
          String(width + 350)
        );
        this.formWidth = wid.changingThisBreaksApplicationSecurity;
      }

      this.fieldAlignment = res.data[0].fieldAlignment
        ? res.data[0].fieldAlignment
        : null;
      this.viewInModal = res.data[0].viewInModal
        ? res.data[0].viewInModal
        : null;
      this.enableCSV = res.data[0].enableCSV ? res.data[0].enableCSV : false;
      this.tableHasKanbanView = res.data[0].kanbanBoard
        ? res.data[0].kanbanBoard
        : false;
      //-- Check if provide files are there
      this.provideFiles = res.data[0].provideFiles
        ? res.data[0].provideFiles
        : false;

      if (this.tableHasKanbanView) {
        this.kanbanViewEnable = true;
        this.viewAllRecords = true;
        const element = document.getElementById("main_body");
        element.classList.add("kanbanView");
      }
      this.resultData = res.data[0].columns;
      this.resColData = res.data;

      this.canAddNewRecord = res.data[0].hasOwnProperty(
        "addRecordFromMainListing"
      )
        ? res.data[0].addRecordFromMainListing
        : true;
      this.dynamicFilterData = res.data[0].columns;

      if (this.tableName === "tables") {
        this.canAddNewRecord = false;
      }

      if (this.dynamicFilterData) {
        for (const data of this.dynamicFilterData) {
          this.hideColumn[data.name] = true;
          this.showFilterBox[data.name] = false;
          if (data.type === "lookup") {
            this.lookTable = data.lookupTableName;
            this.lookupName = data.name;
            if (this.filterObject && this.filterObject?.lookupValue) {
              this.filterObject.lookupValue[data.name] =
                this.filterObject.lookupValue[data.name] &&
                this.filterObject.lookupValue[data.name].length
                  ? this.filterObject.lookupValue[data.name]
                  : [];
            }

            this.filteredOptions[data.name] = [];
            this.showAutocomplete[data.name] = false;

            const lookUpArray = [];
            data.options.forEach((el) => {
              const temp: [] = Object.assign([], el);
              temp.shift();
              if (data.loadAsDropDown) {
                if (el.length > 1) {
                  this.filteredOptions[data.name] = [
                    ...this.filteredOptions[data.name],
                    {
                      id: el[0],
                      value: temp.toString().replace(/,/g, " "),
                    },
                  ];
                }
              }
              if (data.name == this.lookupName) {
                lookUpArray.push({
                  id: el[0],
                  value: temp.toString().replace(/,/g, " "),
                });
              }
            });

            if (data.lookupTableName === "Users") {
              this.filteredOptions[data.name].push({ id: "", value: "Me" });
            }
            this.demoData = data;
            this.lookupData.push(this.demoData);
          }

          if (data.type == "status") {
            if (data.statusOptions) {
              this.statuses = data.statusOptions.map((m) => {
                return m.status;
              });
            }
          }
          // if (data.type == "checkbox") {
          //   data.options.forEach((element) => {
          //     this.filterObject.check.push(false);
          //   });
          // }
          if (
            data.isVisibilityOn &&
            data.fieldValuesData &&
            data.fieldValuesData.length > 0
          ) {
            this.dependsFields.push(data);
          }
        }

        this.lookupData.forEach((element) => {
          let table = this.tableData.filter(
            (v) => v.tableName == element.lookupTableName
          );
          if (table.length == 0) {
            if (!this.forkJoinTableDataObject[element.lookupTableName])
              this.forkJoinTableDataObject[element.lookupTableName] =
                this.tableService.getTableByName(element.lookupTableName);
          }
        });

        forkJoin(this.forkJoinTableDataObject).subscribe((res) => {
          this.forkJoinDataForTables = res;
        });
      }
    }
    if (res && res.statusCode == 404) {
      //-- Redirect to dashboard
      this.tableService.redirectToHomePage();
      return;
    }

    if (
      res &&
      res.data &&
      res.data[0] &&
      res.data[0].columns &&
      res.data[0].columns.length
    ) {
      this.tableId = res.data[0]._id;
      this.datafetched = true;
      this.tempParentTableHeader = Object.assign([], res.data[0].columns);
      let emailField = this.tempParentTableHeader.find(
        (v) => v.name == "email"
      );

      if (emailField) {
        this.isEmailField = true;
      }

      this.phoneField = this.tempParentTableHeader.find(
        (v) => v.isPhone === true
      );
      this.refButtonField = this.tempParentTableHeader.find(
        (v) => v.type === "refButton"
      );

      this.allLookupsList = this.tempParentTableHeader.filter(
        (v) => v.type == "lookup"
      );

      this.tempParentTableHeader.map((column) => {
        if (column.type == "recordType") {
          this.recordTypeFieldName = column.name;
          column.options.forEach((element) => {
            let plural = this.service.fromCount(element, 2, false);

            const obj = {
              title: element,
              pluralize: plural,
            };
            this.recordTypes.push(obj);
          });
        }
        if (column.type == "status") {
          this.statusName = column.name;
          column.statusOptions.forEach((element) => {
            this.statusArray.push(element);
          });
        }
      });

      let page = 1;
      if (this.route.snapshot.queryParamMap.get("page")) {
        page = parseInt(this.route.snapshot.queryParamMap.get("page"));
      }
      this.parentTableHeader = Object.assign(
        [],
        res.data[0].columns.filter((data) => data.displayInList == true)
      );
      let statusField = this.parentTableHeader.find((v) => v.type == "status");
      if (statusField) {
        this.filteredStatus[statusField.name] = [];
      }

      this.finalList = res.data[0].columns.filter((ele) => ele.isSearchable);
      this.finalList = this.finalList ? this.finalList : [];

      this.tableService.getFilterObject(this.filterObject, this.tableName);
      this.allParentTableHeader = res.data[0].columns.filter(
        (data) => data.displayInList == true
      );
      this.parentTableHeader.map((data) => (data.direction = ""));
      if (this.sortObject) {
        this.parentTableHeader.forEach((ele) => {
          if (ele.name == this.sortObject.column) {
            ele.direction = this.sortObject.direction;
          }
        });
      }

      let tableRe: any =
        await this.tableService.defaultFilterSelectedWithPromise(this.tableId);
      if (tableRe.statusCode == 200) {
        this.allFilters = cloneDeep([...tableRe.data]);
        this.readyForPageLayout = true;
        let defaultFilter = this.allFilters.filter(
          (v) => v.isDefault == true
        )[0];
        if (!defaultFilter) {
          defaultFilter = this.allFilters[0];
        }
        let key = this?.filterObject?.filterKey;
        const index = this.allFilters.findIndex(
          (v) => v._id == defaultFilter?._id
        );
        this.defaultStyleFlag = index;
        if (defaultFilter?.tableId === this.tableId && !this.fromOverview) {
          if (!this.hasUserPreference) {
            this.isDefaultFilterApplied = true;
            if (!this.kanbanViewEnable) {
              this.viewAllRecords = defaultFilter?.viewCompletedRecords;
            }

            if (defaultFilter?.filterObject.lookupValue) {
              const lookupValueRetrieved =
                defaultFilter?.filterObject.lookupValue;

              let keys = Object.keys(lookupValueRetrieved);
              if (keys && keys.length) {
                keys.forEach((ele) => {
                  if (
                    lookupValueRetrieved[ele] &&
                    lookupValueRetrieved[ele].length
                  ) {
                    lookupValueRetrieved[ele].forEach((element) => {
                      let lookup = this?.filterObject?.lookupValue?.[ele];
                      if (lookup && lookup?.length) {
                        let val = lookup.findIndex((v) => v.id == element.id);
                        if (val < 0) {
                          lookup = [...lookup, element];
                        }
                      } else {
                        let temp = [];
                        temp.push(element);
                        lookup = cloneDeep(temp);
                      }
                    });
                  }
                });
              }
            }

            this.createTagList(defaultFilter?.filterObject?.filterKey);

            const filterKeyRetrieved =
              defaultFilter?.filterObject?.filterKey &&
              defaultFilter?.filterObject?.filterKey.length > 0
                ? defaultFilter?.filterObject?.filterKey
                : [];

            let statusField = this.parentTableHeader.find(
              (v) => v.type == "status"
            );
            if (statusField) {
              this.filteredStatus[statusField.name] = [];
            }
            filterKeyRetrieved.forEach((element) => {
              let key = Object.keys(element)[0];

              if (statusField) {
                if (statusField.name == key) {
                  this.filteredStatus[statusField.name] =
                    element[statusField.name];
                }
              }
            });

            // First the data is binded to the filterObject variables then we are pushing it inside filterKey
            // Then tags are created and all? api for listing is called
            this?.bindingFilterKeyVariables(this?.tagList);

            if (!this.isEqual(key, filterKeyRetrieved)) {
              key = filterKeyRetrieved.concat(key);
            }

            // set filterObject for caching purpose
            this.tableService.getFilterObject(
              this.filterObject,
              this.tableName
            );
            if (this.fromRecordPage) {
              if (this.count > 0 || this.kanbanViewEnable) {
                if (key && key.length) {
                  this.createTagList();
                  this.getTableDataByName(
                    page,
                    this.searchString,
                    this.sortObject,
                    key,
                    this.searchMode,
                    true,
                    ""
                  );
                }
              }
            } else {
              if (key && key.length) {
                this.createTagList();
                this.getTableDataByName(
                  page,
                  this.searchString,
                  this.sortObject,
                  key,
                  this.searchMode,
                  this.kanbanViewEnable ? true : this.viewCompletedRecords,
                  ""
                );
              } else {
                this.getTableDataByName(
                  page,
                  this.searchString,
                  this.sortObject,
                  key,
                  this.searchMode,
                  this.kanbanViewEnable ? true : this.viewCompletedRecords,
                  ""
                );
              }
            }
          } else {
            if (this.filterObject.lastFilterSelected !== "") {
              let defaultFilter = this.allFilters.filter(
                (v) => v._id == this.filterObject.lastFilterSelected
              )[0];
              this.defaultFilterSelection(defaultFilter, true);
            } else {
              this.isDefaultFilterApplied = false;
              if (key && key.length) {
                this.createTagList();
                this.bindingFilterKeyVariables(this.tagList);
                this.getTableDataByName(
                  page,
                  this.searchString,
                  this.sortObject,
                  key,
                  this.searchMode,
                  this.kanbanViewEnable ? true : this.viewCompletedRecords,
                  ""
                );
              } else {
                this.getTableDataByName(
                  page,
                  this.searchString,
                  this.sortObject,
                  key,
                  this.searchMode,
                  this.kanbanViewEnable ? true : this.viewCompletedRecords,
                  ""
                );
              }
            }
          }
        } else {
          this.getAllApiCallFromGetTableByNameFn(page);
        }
      } else {
        this.toastrService.danger(tableRe.message);
      }

      this.loading = false;

      this.parentTableHeader = Object.assign(
        [],
        res.data[0].columns.filter((data) => data.displayInList == true)
      );

      // Ignore searchable fields for overview and dashboard
      if (this.fromOverview || this.fromDashboard) {
        this.finalList = res.data[0].columns;
      } else {
        this.finalList = res.data[0].columns.filter((ele) => ele.isSearchable);
      }
      this.finalList = this.finalList ? this.finalList : [];
      this.tableService.getFilterObject(this.filterObject, this.tableName);
      if (this.directViewFlag) {
        this.directViewFlag = false;

        if (this.directViewId) {
          this.tableService
            .getDynamicTreeDataById(this.directTableName, this.rowId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res: any) => {
              if (res.statusCode == 200) {
                this.location.replaceState(
                  "pages/tables/dynamic/" +
                    this.tableId +
                    "/" +
                    this.tableName +
                    "/" +
                    this.rowId
                );
                this.openViewModal(res.data, this.rowId);
                this.isPopupOpen = false;
              }
            });
        } else {
          this.onAddUpdate();
        }
      }

      this.getFieldRestrictions();
    }
  }

  async getFieldRestrictions() {
    let response: any = await this.tableService.getDynamicTreeData(
      "Field Restrictions"
    );
    if (
      response &&
      response.data &&
      response.data.pageOfItems &&
      response.data.pageOfItems.length
    ) {
      let allData = [...response.data.pageOfItems];
      let currentUserRoleRestrictions = [];
      let currentRoleId =
        this?.currentUser?.lookup && this?.currentUser?.lookup.length
          ? this?.currentUser?.lookup.find((a) => a?.lookupTableName == "Roles")
              ?.lookupId
          : null;

      allData?.forEach((ele) => {
        if (ele?.lookups && ele?.lookups?.length) {
          let rolesToCheck = [];
          rolesToCheck = ele?.lookups?.filter((val) => val.table == "Roles");
          if (rolesToCheck && rolesToCheck.length) {
            let isRoleOfCurrent = rolesToCheck.find(
              (alpha) => alpha._id == currentRoleId
            );
            if (isRoleOfCurrent) {
            } else {
              let fieldToBind = ele.lookups.find(
                (val) => val.table == "tablefields"
              );
              if (fieldToBind) {
                let field = this.parentTableHeader.find(
                  (el) => el._id == fieldToBind._id
                );
                if (field) {
                  if (ele.restriction == "Read Only") {
                    field.isReadOnly = true;
                  }

                  if (ele.restriction == "Hidden") {
                    field.isHidden = true;
                  }
                }
              }
            }
          }
        }
      });

      this.cdr.detectChanges();
    }
  }
  async onAddUpdate(data?, id?) {
    if (!data) {
      this.location.replaceState(
        "pages/tables/" + this.tableName + "/" + "new"
      );
    }

    let parentObj = {};
    if (this.fromRecordPage) {
      parentObj = {
        parentTableName: this.parentTableNameFromView,
        parentTableData: this.parentTableDataFromView,
        lookUpNameId: this.lookUpNameId,
        lookUpName: this.lookUpName,
      };
    }

    let resp: any = await this.formService.openAndCloseForm(
      data,
      this.tableName,
      this.tableData,
      false,
      this.recordType,
      this.fromRecordPage,
      this.fromRecordPage ? parentObj : null
    );

    this.openAgainOnAddUpdate(resp.formComp, resp.formObj);
  }

  openAgainOnAddUpdate(formComp, formObj) {
    const ref = this.dialogService
      .open(formComp, {
        closeOnEsc: false,
        context: formObj,
      })
      .onClose.subscribe((name) => {
        let url = "/pages/tables/" + this.tableName;
        this.location.replaceState(url);

        if (name && name.close == "yes") {
          if (name.continue) {
            this.getTableDataByName(
              this.pager.currentPage,
              this.searchString,
              this.sortObject,
              this.filterObject.filterKey,
              "",
              this.kanbanViewEnable ? true : this.viewCompletedRecords,
              ""
            );

            this.tableService
              .getDynamicTreeDataById(this.tableName, name.data._id)
              .pipe(takeUntil(this.destroy$))
              .subscribe((res: any) => {
                if (res.statusCode == 200) {
                  this.isPopupOpen = true;
                  this.location.replaceState(
                    "pages/tables/dynamic/" +
                      this.tableId +
                      "/" +
                      this.tableName +
                      "/" +
                      name.data._id
                  );
                  this.openViewModal(res.data, name.data._id);
                  this.isPopupOpen = false;
                  this.actionSubscription.unsubscribe();
                  if (this.actions && this.actions.length) {
                    this.actions.forEach((element) => {
                      if (element.onSave == "yes") {
                        const obj = new DynamicTableViewComponent(
                          this.chatSubscriptionService,
                          this.route,
                          this.dialogService,
                          this.tableService,
                          this.service,
                          this.helperService,
                          this.toastrService,
                          this.nbMenuService,
                          null,
                          this.router,
                          null,
                          null,
                          null,
                          this.sidebarService,
                          this.messageService,
                          this.title,
                          null,
                          this.elem,
                          null,
                          null
                        );
                        obj.oldData = res.data;
                        obj.newData = res.data;
                        obj.onActionsClick("", element);
                      }
                    });
                  }
                  this.loading = false;
                }
              });
          } else {
            this.isPopupOpen = false;
            this.getTableDataByName(
              1,
              this.searchString,
              this.sortObject,
              this.filterObject.filterKey,
              "",
              this.kanbanViewEnable ? true : this.viewCompletedRecords,
              ""
            );

            if (this.fromRecordPage) {
              this.tableService.refreshOverview();
            } else {
              this.tableService.refreshSumCountLayout();
            }
          }
        }
      });
  }

  // -- Open view mode of record
  async openViewModal(data, id) {
    let val: any = await this.formService.openAndCloseForm(
      data,
      this.tableName,
      this.tableData,
      true,
      this.recordType
    );

    const ref = this.dialogService
      .open(val.formComp, {
        closeOnEsc: false,
        context: val.formObj,
      })
      .onClose.subscribe((resp) => {
        this.location.replaceState(this.previousLocation);
      });
  }

  getAllApiCallFromGetTableByNameFn(page) {
    if (this.fromRecordPage) {
      if (this.count > 0) {
        if (this.fromOverview) {
          if (this.chartFilter.length > 0) {
            this.getTableDataByName(
              page,
              this.searchString,
              this.sortObject,
              this.chartFilter
            );
          }
        } else {
          if (this.recordTypes && this.recordTypes.length === 0) {
            if (this.chartFilter.length > 0) {
              this.getTableDataByName(
                page,
                this.searchString,
                this.sortObject,
                this.chartFilter,
                "",
                true
              );
              this.createTagList();
            } else if (this.filterDefaultList) {
              this.getTableDataByName(
                page,
                this.searchString,
                this.sortObject,
                this.filterDefaultList["filterList"],
                "",
                true,
                ""
              );
            } else {
              this.getTableDataByName(
                page,
                this.searchString,
                this.sortObject,
                "",
                "",
                true
              );
            }
          }
        }
      }
    } else if (this.fromDashboard) {
      if (this.filterObject.filterKey && this.filterObject.filterKey.length) {
        this.createTagList();
        this.getTableDataByName(
          page,
          this.searchString,
          this.sortObject,
          this.filterObject.filterKey,
          this.searchMode,
          this.kanbanViewEnable ? true : this.viewCompletedRecords,
          ""
        );
      }
    } else {
      if (this.fromOverview) {
        if (this.chartFilter.length > 0) {
          this.getTableDataByName(
            page,
            this.searchString,
            this.sortObject,
            this.chartFilter
          );
        }
      } else {
        if (this.recordTypes && this.recordTypes.length === 0) {
          if (this.chartFilter.length > 0) {
            this.getTableDataByName(
              page,
              this.searchString,
              this.sortObject,
              this.chartFilter
            );
            this.createTagList();
          } else if (this.filterDefaultList) {
            this.getTableDataByName(
              page,
              this.searchString,
              this.sortObject,
              this.filterDefaultList["filterList"],
              "",
              this.kanbanViewEnable ? true : this.viewCompletedRecords,
              ""
            );
          } else {
            this.getTableDataByName(page, this.searchString, this.sortObject);
          }
        }
      }
    }
  }

  // On change of default filter this method is used
  defaultFilterSelection(data, firstTime) {
    let filterKeyRetrieved = [];
    this.isDefaultFilterApplied = true;

    this.tableService
      .defaultFilterSelected(this.tableId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((tableRe: any) => {
        if (tableRe.statusCode == 200) {
          this.allFilters = cloneDeep([...tableRe.data]);
          const index = this.allFilters.findIndex((v) => v._id == data._id);

          if (index > -1) {
            this.filterObject.lastFilterSelected = data._id;
            const currentFilter = this.allFilters[index];

            if (currentFilter?.tableId === this.tableId) {
              filterKeyRetrieved =
                currentFilter?.filterObject.filterKey &&
                currentFilter?.filterObject.filterKey.length > 0
                  ? currentFilter.filterObject.filterKey
                  : [];

              this.clearAll(true);

              let statusField = this.parentTableHeader.find(
                (v) => v.type == "status"
              );
              if (statusField) {
                this.filteredStatus[statusField.name] = [];
              }

              if (filterKeyRetrieved && filterKeyRetrieved.length) {
                this.filterObject.filterKey = [...filterKeyRetrieved];
                let statusChanged = false;

                for (const element of this.filterObject.filterKey) {
                  let key = Object.keys(element)[0];
                  if (statusField) {
                    if (statusField.name == key) {
                      this.filteredStatus[key] = element[statusField.name];
                      statusChanged = true;
                    }
                  }
                }

                if (statusField) {
                  if (!statusChanged)
                    this.filteredStatus[statusField.name] = [];
                }
              } else {
                this.filterObject.filterKey = [];
                if (statusField) {
                  this.filteredStatus[statusField.name] = [];
                }
              }

              if (currentFilter?.filterObject?.lookupValue) {
                const lookupValueRetrieved =
                  currentFilter?.filterObject?.lookupValue;

                let keys = Object.keys(lookupValueRetrieved);
                if (keys && keys.length) {
                  keys.forEach((ele) => {
                    if (
                      lookupValueRetrieved[ele] &&
                      lookupValueRetrieved[ele].length
                    ) {
                      lookupValueRetrieved[ele].forEach((element) => {
                        if (
                          this?.filterObject?.lookupValue[ele] &&
                          this.filterObject?.lookupValue?.[ele]?.length
                        ) {
                          let val = this?.filterObject?.lookupValue[
                            ele
                          ].findIndex((v) => v.id == element.id);
                          if (val < 0) {
                            this.filterObject?.lookupValue[ele].push(element);
                          }
                        } else {
                          let temp = [];
                          temp.push(element);
                          this.filterObject.lookupValue[ele] = temp;
                        }
                      });
                    }
                  });
                }
              }

              if (
                currentFilter?.filterObject.filterKey &&
                currentFilter?.filterObject.filterKey.length
              ) {
                this.createTagList(currentFilter?.filterObject.filterKey);
                this.bindingFilterKeyVariables(this.tagList);
              }
              if (data?.viewCompletedRecords) {
                this.viewAllRecords = true;
              }
              if (!data?.viewCompletedRecords) {
                if (this.kanbanViewEnable) {
                  this.viewAllRecords = true;
                } else {
                  this.viewAllRecords = false;
                }
              }
              this.createTagList();
              this.getTableDataByName(
                1,
                this.searchString,
                this.sortObject,
                this.filterObject.filterKey,
                "",
                this.kanbanViewEnable ? true : this.viewCompletedRecords,
                ""
              );
            }
            this.defaultStyleFlag = index;
          }

          this.tableService.getFilterObject(this.filterObject, this.tableName);

          if (!firstTime) this.saveUserPreference(false, true);
        }
      });
  }

  fireUserPrefUpdate() {
    this.tableService
      .updateDynamicFormData(this.currentUser._id, "Call Log", {
        userPreferences: this.userPreferences,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.statusCode === 200) {
        }
      });
  }

  saveUserPreference(unset = false, fromDefult?) {
    if (!fromDefult) this.filterObject.lastFilterSelected = "";

    if (this.userPreferences && this.userPreferences.length) {
      let tableDataIndex = this.userPreferences.findIndex(
        (ele) => ele.tableName == this.filterObject.tableName
      );
      if (tableDataIndex > -1) {
        // if (unset) {
        //   this.userPreferences.splice(tableDataIndex, 1);
        //   this.fireUserPrefUpdate();
        //   return;
        // }
        this.userPreferences[tableDataIndex] = this.filterObject;
      } else {
        this.userPreferences.push(this.filterObject);
      }

      this.fireUserPrefUpdate();
    } else {
      // if (!unset) {
      this.userPreferences.push(this.filterObject);
      this.fireUserPrefUpdate();
      // }
    }

    if (!fromDefult) this.isDefaultFilterApplied = false;

    this.cdr.detectChanges();
  }

  clearAll(value?) {
    if (this.fromRecordPage) {
      this.restoreObj.filterKey = [...this.filterObject.filterKey];
      this.restoreObj.tagList = [...this.tagList];
      this.filterObject.filterKey = [];
      this.tagList = [];
    } else {
      this.kanbanLoader = this.kanbanViewEnable ? true : false;
      this.filterObject.filterKey = [];
      this.tagList = [];
      this.filterObject.lookupValue = {};
      this.lookupObj = [];
      this.isDefaultValueEnable = false;

      let statusField = this.parentTableHeader.find((v) => v.type == "status");
      if (statusField) {
        this.filteredStatus[statusField.name] = [];
      }
      // if (!this.fromRecordPage) {
      //   let url = "/pages/tables/" + this.tableName;
      //   this.location.replaceState(url);
      // }
    }

    if (!value) {
      this.getTableDataByName(
        1,
        this.searchString,
        this.sortObject,
        "",
        "",
        this.kanbanViewEnable ? true : this.viewCompletedRecords,
        ""
      );

      this.tableService.getFilterObject(this.filterObject, this.tableName);
      this.usersWithIds = this.usersWithIds;

      this.isDefaultFilterApplied = false;
      this.saveUserPreference(false, false);
    }
  }

  async getTableDataByName(
    page,
    search?,
    sort?,
    filterKey?,
    searchMode?,
    viewCompletedRecords?,
    statusFilter?,
    limit?
  ) {
    this.pageNo = page;
    if (this.callCount != 1 && this.pageNo != 1) {
      return;
    }
    this.callCount = 0;
    const IDs = [];

    let filterArray =
      filterKey && filterKey.length ? cloneDeep([...filterKey]) : [];

    if (filterArray && filterArray.length) {
      filterArray?.forEach((item) => {
        if (item !== undefined) {
          let objKeys = Object?.keys(item);
          let temp = [];
          if (
            item[objKeys[0]] &&
            typeof item[objKeys[0]] == "object" &&
            item[objKeys[0]].length
          ) {
            item[objKeys[0]].forEach((val) => {
              if (val == "Me") val = this.currentUser?._id;
              temp.push(val);
            });
            item[objKeys[0]] = temp;
          }

          let field = this.tempParentTableHeader.find(
            (v) => v.name == objKeys[0]
          );
          if (field) {
            item = this.tableService.queryParamGeneration(field, item, objKeys);
          }
        }
      });
    }

    if (!this.kanbanViewEnable) {
      this.loading = true;
      let response = await this.tableService.getDynamicTreeData(
        this.tableName,
        page,
        search,
        sort,
        cloneDeep(filterArray),
        this.recordTypeFilter,
        this.taskedRecordsOnly,
        this.watchedIssue
          ? this.watchedIssue
          : { watchedIssue: true, noFilter: true },
        "",
        "",
        "",
        "",
        searchMode,
        viewCompletedRecords,
        statusFilter,
        "",
        this.renderAt,
        limit,
        this.kanbanViewEnable ? "kanban" : ""
      );

      // this.tableService.getStoreDataFromTree(this.tableName, response, "allApi");
      this.handleAPIResponse(IDs, response, filterKey);
    } else {
      this.kanbanLoader = this.kanbanViewEnable ? true : false;
      this.kanbanViewCodeFormation(filterArray);
    }
  }

  responseOfAllStatus = [];
  async kanbanViewCodeFormation(filterArray) {
    this.idFieldsArray = [];
    this.otherFieldsArray = [];
    this.finalStatusArray = [];
    this.responseOfAllStatus = [];
    this.listingDataObj = {};

    if (!this.tableHasKanbanView && !this.kanbanViewEnable) {
      return;
    }

    this.statusArray.forEach((ele) => {
      this.individualStatusPageCount[ele.status] = 1;
      this.dataForApiResponseOfIndividualStatus[ele.status] = [ele.status];
      this.loadingIndividual[ele.status] = false;
    });
    let responce: any =
      await this.tableService.GetDynamicTableWithoutPagination(
        "Announcements",
        "no",
        true
      );
    this.tableService.announcementList(responce.data);
    let res: any = await this.tableService.getDynamicTreeData(
      this.tableName,
      1,
      this.searchString,
      this.sortObject,
      cloneDeep(filterArray),
      this.recordTypeFilter,
      this.taskedRecordsOnly,
      this.watchedIssue
        ? this.watchedIssue
        : { watchedIssue: true, noFilter: true },
      "",
      "",
      "",
      "",
      "",
      this.kanbanViewEnable ? true : this.viewCompletedRecords,
      "",
      "",
      this.renderAt,
      5,
      this.kanbanViewEnable ? "kanban" : "",
      this.selectedGroupBy ? this.selectedGroupBy : ""
    );

    this.responseOfAllStatus = res?.data?.pageOfItems;
    let temp = [];
    if (this.responseOfAllStatus && this.responseOfAllStatus.length) {
      this.responseOfAllStatus.forEach((val: any) => {
        temp.push(val);
      });
    } else {
      temp = [];
    }

    temp = this.formObjectToPassInGroupBy(temp);

    this.listingDataObj = this.groupBy(temp, this.statusName, false);

    this.idFieldsArray = this.dynamicFilterData
      .filter((ele) => ele.idField)
      .map((a) => a.name);
    this.otherFieldsArray = this.dynamicFilterData
      .filter((ele) => !ele.idField)
      .map((a) => a.name);

    this.finalStatusArray = [];
    this.statusArray.forEach((ele) => {
      let statusObj = {
        status: ele.status,
        value: [],
      };

      if (
        this.listingDataObj[ele.status] &&
        this.listingDataObj[ele.status].length
      ) {
        this.listingDataObj[ele.status] = sortBy(
          this.listingDataObj[ele.status],
          "createdAt"
        ).reverse();

        if (this.selectedGroupBy) {
          let lookupGroupObject = {};
          this.listingDataObj[ele.status].forEach((element) => {
            let isGroupByTablePresent = element.lookups.find(
              (v) => v.table == this.selectedGroupBy
            );
            if (isGroupByTablePresent) {
              if (
                lookupGroupObject[isGroupByTablePresent.lookupVal] &&
                lookupGroupObject[isGroupByTablePresent.lookupVal].length
              ) {
                lookupGroupObject[isGroupByTablePresent.lookupVal].push(
                  element
                );
              } else {
                lookupGroupObject[isGroupByTablePresent.lookupVal] = [];
                lookupGroupObject[isGroupByTablePresent.lookupVal].push(
                  element
                );
              }
            }
          });

          let lookupKeys = Object.keys(lookupGroupObject);
          if (lookupKeys && lookupKeys.length) {
            lookupKeys.forEach((val) => {
              let obj = {
                name: null,
                value: [],
              };
              if (lookupGroupObject[val] && lookupGroupObject[val].length) {
                lookupGroupObject[val].forEach((item) => {
                  let valueObj = {};
                  valueObj = this.formingKanbanRecords(ele, item);
                  obj.value.push(valueObj);
                });
              }

              obj.name = val;
              statusObj.value.push(obj);
            });
          }
        } else {
          this.listingDataObj[ele.status].forEach((element) => {
            let valueObj = {};
            valueObj = this.formingKanbanRecords(ele, element);
            statusObj.value.push(valueObj);
          });
        }
      } else {
      }

      this.finalStatusArray.push(statusObj);
    });
    this.kanbanLoader = false;
    this.cdr.detectChanges();
  }

  groupBy(objectArray, property, allowed?) {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      // Add object to list for given key's value
      if (allowed) {
        if (obj.allowed) acc[key].push(obj);
      } else {
        acc[key].push(obj);
      }

      return acc;
    }, {});
  }

  formObjectToPassInGroupBy(temp, element?) {
    if (element) {
      element.expand = false;
      // -- set lookup data...
      if (element.lookups && element.lookups.length) {
        element.lookups.forEach(async (lookup) => {
          lookup["allowed"] = true;
          const inde = this.lookupData.findIndex(
            (f) => f.name == lookup.lookupName
          );
          this.setIsPhoneForLookup(inde, lookup, element);
        });
      }

      return element;
    } else {
      temp = Object.assign([], temp);
      temp.forEach((element, i) => {
        element.expand = false;
        // -- set lookup data...
        if (element.lookups && element.lookups.length) {
          element.lookups.forEach(async (lookup) => {
            lookup["allowed"] = true;
            const inde = this.lookupData.findIndex(
              (f) => f.name == lookup.lookupName
            );
            this.setIsPhoneForLookup(inde, lookup, element);
          });
        }
      });
      return temp;
    }
  }

  setIsPhoneForLookup(inde, lookup, element) {
    if (inde > -1) {
      if (this.lookupData[inde].isReference) {
        const col = this.parentTableHeader.filter((v) => v.type == "refButton");
        if (col && col.length) {
          if (!element[col[0].label]) element[col[0].label] = [];
          element[col[0].label].push(lookup);
        }
      }
      if (!element[lookup.lookupName]) element[lookup.lookupName] = [];
      element[lookup.lookupName].push(lookup);

      let idFields = [];
      let valueToDisplay = [];
      let table = this.tableData.filter(
        (v) => v.tableName == this.lookupData[inde].lookupTableName
      );
      if (table && table.length) {
        this.setIsPhoneData(idFields, valueToDisplay, lookup, table);
      } else {
        let res: any =
          this.forkJoinDataForTables[this.lookupData[inde].lookupTableName];
        let data = res.data;
        this.setIsPhoneData(idFields, valueToDisplay, lookup, data);
      }
    }
  }

  setIsPhoneData(idFields, valueToDisplay, lookup, data) {
    if (data && data.length) {
      idFields = data[0].columns.filter((a) => a.idField);
      if (idFields && idFields.length) {
        idFields.forEach((item) => {
          let valVar = "";
          let obj = {
            val: "",
            isPhone: false,
          };
          if (lookup[item.name]) {
            obj.val = lookup[item.name];
          }

          if (item.isPhone) {
            obj.isPhone = true;
          }

          if (obj.val) {
            valueToDisplay.push(obj);
          }
        });
      }
      lookup["valueToDisplay"] = valueToDisplay;
    }
  }

  formingKanbanRecords(status, record) {
    let valueObj = {
      statusColor: status.color,
      idValue: [],
      otherValue: [],
      status: status.status,
      data: null,
    };
    valueObj.data = record;
    let keys = Object.keys(record);
    let tempKeys = [];
    if (keys && keys.length) {
      keys.forEach((ele: any) => {
        let field = this.dynamicFilterData.find((v) => v.name == ele);
        if (field) {
          let obj = {
            name: "",
            displayPriority: null,
          };

          obj.name = field.name;
          obj.displayPriority = field.displayPriority;

          tempKeys.push(obj);
        }
      });
    }

    keys = sortBy(tempKeys, "displayPriority");

    if (keys && keys.length) {
      keys.forEach((item: any) => {
        let typeObj = {
          type: "",
          val: [],
        };
        if (this.idFieldsArray.includes(item.name)) {
          let field = this.dynamicFilterData.find((v) => v.name == item.name);
          if (field && field.type !== "refButton" && field.type !== "status") {
            typeObj.type = field.type;
            if (field.type == "lookup") {
              if (record[field.name] && record[field.name].length) {
                record[field.name].forEach((value) => {
                  typeObj.val.push(value.valueToDisplay);
                });
                if (typeObj.val && typeObj.val.length)
                  valueObj.idValue.push(typeObj);
              }
            } else if (field.type == "date" || field.type == "dateTime") {
              let value = this.datePipe.transform(
                record[field.name],
                "shortDate"
              );
              typeObj.val.push(value);
              if (typeObj.val && typeObj.val.length)
                valueObj.idValue.push(typeObj);
            } else {
              typeObj.val.push(record[field.name]);
              if (typeObj.val && typeObj.val.length)
                valueObj.idValue.push(typeObj);
            }
          }
        }
        if (this.otherFieldsArray.includes(item.name)) {
          let field = this.dynamicFilterData.find((v) => v.name == item.name);
          if (field && field.type !== "refButton" && field.type !== "status") {
            typeObj.type = field.type;
            if (field.type == "lookup") {
              if (record[field.name] && record[field.name].length) {
                record[field.name].forEach((value) => {
                  typeObj.val.push(value.valueToDisplay);
                });
                if (typeObj.val && typeObj.val.length)
                  valueObj.otherValue.push(typeObj);
              }
            } else if (field.type == "date" || field.type == "dateTime") {
              let value = this.datePipe.transform(record[field.name], "short");
              typeObj.val.push(value);
              if (typeObj.val && typeObj.val.length)
                valueObj.idValue.push(typeObj);
            } else {
              typeObj.val.push(record[field.name]);
              if (typeObj.val && typeObj.val.length)
                valueObj.otherValue.push(typeObj);
            }
          }
        }
      });
    }

    return valueObj;
  }

  handleAPIResponse(IDs, response, filterKey) {
    let items = [];
    items =
      response?.data?.pageOfItems && response.data.pageOfItems.length
        ? response.data.pageOfItems
        : [];

    if (items && items.length) {
      const maxChars = 23;
      this.parentTableData = [];
      this.pager = this.kanbanViewEnable ? {} : response.data.pager;
      this.pageOfItems = items;

      items.forEach((element) => {
        IDs.push(element._id);
        if (
          this.resColData &&
          this.resColData[0] &&
          this.resColData[0].columns
        ) {
          for (const col of this.resColData[0].columns) {
            this.hideColumn[col.name] = true;
            if (filterKey) {
              filterKey.forEach((e) => {
                if (col.name === Object.keys(e)[0]) {
                  this.filterObject.filterKey[col.name] = Object.values(e)[0];
                }
              });
            }
            if (col.type === "file") {
              if (typeof element[col.name] == "string") {
                element[col.name] = element[col.name];
              } else {
                if (element[col.name]) {
                  element[col.name] = element[col.name];
                }
              }
            }
          }
        }
      });

      if (!this.kanbanViewEnable) {
        this.parentTableData = Object.assign([], items);

        this.checkCount = 0;
        this.parentTableData.forEach((element, i) => {
          element.expand = false;
          element["showOnListing"] = false;

          // -- set lookup data...
          if (element.lookups && element.lookups.length) {
            element.lookups.forEach(async (lookup) => {
              lookup["allowed"] = true;
              const inde = this.lookupData.findIndex(
                (f) => f.name == lookup.lookupName
              );
              this.setIsPhoneForLookup(inde, lookup, element);
            });

            let objectGroup = this.groupBy(element.lookups, "lookupName", true);
            let keys = Object.keys(objectGroup);
            let obj = {};
            keys.forEach((ele) => {
              if (objectGroup[ele].length == 0) {
                let index = element.lookups.findIndex(
                  (v) => v.lookupName == ele
                );
                element.lookups[index].allowed = true;
              }
              let totalChar = "";

              obj[ele] = false;
              if (element[ele] && element[ele].length > 0) {
                let copyMaxChar = maxChars;
                if (element[ele].length > 1) {
                  element[ele].forEach((item) => {
                    item["allowed"] = true;
                    let localCount = "";
                    if (item.valueToDisplay && item.valueToDisplay.length) {
                      item.valueToDisplay.forEach((item2) => {
                        totalChar = totalChar + item2.val + "aaa";
                        localCount = localCount + item2.val + "aa";
                      });
                    }

                    if (localCount.length < copyMaxChar) {
                      copyMaxChar = copyMaxChar - localCount.length;
                    } else {
                      item["allowed"] = false;
                    }
                  });
                  if (element[ele].filter((v) => v.allowed).length == 0) {
                    element[ele][0].allowed = true;
                  }
                  if (totalChar.length > maxChars) {
                    if (
                      element[ele].filter((v) => v.allowed == false).length == 0
                    ) {
                      obj[ele] = false;
                    } else {
                      obj[ele] = true;
                    }
                  }
                  element["showEllipsis"] = obj;
                } else {
                  element[ele][0]["allowed"] = true;
                  obj[ele] = false;
                  element["showEllipsis"] = obj;
                }
              }
            });
          }
          this.getRecordGadgets(element, i);
          this.loading = false;
        });
      }

      if (IDs.length > 0) {
        // this.getTasksCount(IDs);
      }
      if (this.isSearchPage) {
        this.setSearchItemTabCount();
      }
      this.cdr.detectChanges();
    } else {
      this.noDataFound = true;
      this.parentTableData = [];
      this.loading = false;
    }
  }

  setSearchItemTabCount() {
    this.masterSearchTab.forEach((data) => {
      if (data.title === this.tableName) {
        data.parentTableData = this.parentTableData;
      }
    });
  }

  async getRecordGadgets(item, index) {
    await System.import("../../../../../node_modules/ejs/ejs.js").then(
      async (res) => {
        this.ejsPackage = res;

        if (this.recordGadgets && this.recordGadgets.length) {
          if (item.gadget) {
            let currentGadget = this.recordGadgets.filter(
              (v) => v.name == item.gadget
            )[0];
            this.gadgetFieldValue[index] = this.ejsPackage.render(
              currentGadget.logic
            );
          } else {
            this.gadgetFieldValue[index] = "";
          }
          return this.gadgetFieldValue[index];
        }
      }
    );
  }

  dateDiffToString(a, b) {
    let diff = Math.abs(a - b);
    let ms = diff % 1000;
    diff = (diff - ms) / 1000;
    let ss: any = diff % 60;
    diff = (diff - ss) / 60;
    let mm = diff % 60;
    diff = (diff - mm) / 60;
    let hh = diff % 24;
    let days = (diff - hh) / 24;
    if (ss < 10) {
      ss = "0" + ss;
    }
    if (days > 0) {
      return days + ":" + hh + ":" + mm + ":" + ss;
    } else if (hh > 0) {
      return hh + ":" + mm + ":" + ss;
    } else {
      return mm + ":" + ss;
    }
  }

  getActiveTime(startTime) {
    let currentTime: any = new Date();
    let expireTime: any = new Date(startTime);
    return this.dateDiffToString(currentTime, expireTime);
  }

  getPhoneNumber(phoneNumber) {
    if (phoneNumber) {
      const phonePipe = new PhonePipe();
      const finalPhone = phonePipe.transform(phoneNumber);
      return finalPhone;
    } else {
      return phoneNumber;
    }
  }

  showCalendar(e) {
    this.router.navigateByUrl(`pages/calendar`);
  }

  logOut() {
    this.authService.logout();
  }

  help() {
    this.showUserCard = false;
  }

  profileMenuClick(menu: any) {
    this.showUserCard = false;
    if (menu == "Profile") {
      const userTableId = "5f60cb6e10fd660a92e9b416";
      this.router.navigateByUrl(
        `pages/tables/dynamic/${userTableId}/Users/${this.userId}`
      );
    } else if (menu == "Settings") {
      this.router.navigate(["/pages/tables/list"]);
    } else if (menu == "Field Restrictions") {
      this.router.navigate(["/pages/tables/Field_Restrictions"]);
    } else if (menu == "Assigned Permissions") {
      this.router.navigate(["/pages/tables/Assigned_Permissions"]);
    } else if (menu == "Users") {
      this.router.navigate(["/pages/tables/Users"]);
    } else if (menu == "System Configurations") {
      this.router.navigate(["/pages/tables/System_Config"]);
    } else if (menu == "Call Log Configurations") {
      this.router.navigate(["/pages/tables/Call_Log_Configurations"]);
    }
  }

  private bufferAudioFile(): void {
    this.audioFile = new Audio();
    this.audioFile.src = this.audioSource;
    this.audioFile.load();
  }

  openReports() {
    this.router.navigate(["pages/reports"]);
  }

  emitMessageSound(): void {
    this.audioFile.play();
  }
  fireReminder() {
    if (this.reminderList.length != 0) {
      let currentTime = this.datePipe.transform(
        new Date(),
        "EEEE, MMMM d, y, h:mm:ss a zzzz"
      );
      let Reminder = this.reminderList.filter(
        (a) =>
          this.datePipe.transform(
            a.dueDate,
            "EEEE, MMMM d, y, h:mm:ss a zzzz"
          ) == currentTime
      );
      if (Reminder.length != 0) {
        this.redirect(Reminder[0]);
      }
    }
  }
  listenGeneralReminders() {
    this.socketService.listen("generic_reminder").subscribe((res: any) => {
      if (res._id !== this.userId) return;
      this.dialogService.open(InfoDialogComponent, {
        context: {
          title: `You have ${res.unreadReminders} new reminders`,
          text: '<a href="" [routerLink]="[\'/pages/tables/notifications\']>View Reminders</a>',
          dialogType: "reminder",
        },
      });
    });
  }

  listenRecurring() {
    this.socketService.listen("recurring_reminder").subscribe((res: any) => {
      if (res?.createdBy !== this.userId) return;
      this.redirect(res);
    });
  }

  reminderList = [];
  notificationList = [];

  readNotifications(e) {
    this.getRemindersList();
    setTimeout(() => {
      this.showNotification = !this.showNotification;
      //this.notificationCount = 0;
      //this.notificationBadgeText = ".";
      //this.notificationBadgeText = "";
      //this.updateMessageStatus();
      this.popover.hide();
      e.stopPropagation();
      this.tableService.runCustomStyling();
    }, 0);
  }

  unreadReminderCount = 0;
  getRemindersList(offset = 20, skip = 0) {
    this.loader = true;
    this.tableService.myReminders(offset, skip).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.tableService.runCustomStyling();
        this.unreadReminderCount = res.data.overDueReminders;
        if (res.data.reminders) {
          this.numberOfPagesLoadedReminder++;
          res.data.reminders.forEach((element) => {
            this.updateReminders(element);
          });

          this.loader = false;
        }
      }
    });
  }

  loader = false;
  getNotificationsList(offset = 20, skip = 0) {
    this.loader = true;
    this.tableService
      .myNotifications("log", offset, skip)
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.tableService.runCustomStyling();
          if (res.data.notifications) {
            this.numberOfPagesLoadedNotification++;
            this.notificationList = this.notificationList.concat(
              res.data.notifications
            );
            // this.tableService.getNotificationsList(res.data.notifications.slice(0, 5));
            this.unreadNotificationCount = res.data.unreadCount;
            this.notificationList.forEach((ele) => {
              if (ele.visibility == "unread") {
                this.notificationBadgeText = ".";
              }
            });
          }
        }

        this.loader = false;
      });
  }

  updateReminders(data, isNewMessage = false) {
    let apiData;
    let temp = [];
    if (data?.tableName && data?.resourceId) {
      apiData = this.getTablesForMenuData.find(
        (table) => table.tableName == data.tableName
      );

      if (!apiData) return false;

      let IDFields = [];
      apiData.columns.forEach((element) => {
        if (element.idField && element.idField == true) {
          IDFields.push(element.name);
        }
      });

      if (IDFields.length) {
        IDFields.forEach((el, i) => {
          if (data && data["record"] && data["record"][el]) {
            let IDElement = apiData["columns"].find((v) => v.name == el);

            if (IDElement.type == "date" || IDElement.type == "dateTime") {
              if (typeof data["record"][el] !== "object") {
                let val = this.datePipe.transform(data["record"][el], "M/d/yy");
                temp.push(" " + val);
              }
            } else {
              if (typeof data["record"][el] !== "object") {
                temp.push(" " + data["record"][el]);
              }
            }
          }
        });
      }
      let dataValue = this?.tableIcon?.[data?.tableName];
      if (dataValue) {
        dataValue = dataValue;
      } else {
        let iconData = this.getTablesForMenuData.filter(
          (v) => v.tableName == data?.tableName
        );
        if (iconData && iconData?.length) {
          dataValue = iconData?.[0]?.iconLocation
            ? iconData?.[0]?.iconLocation
            : "";
        }
      }

      let obj = {
        title: "You have new task reminder",
        tableId: data?.tableId,
        tableName: data?.tableName,
        reminderNotes: data?.reminderNotes,
        resourceId: data?.resourceId,
        subject: data?.resourceDetails?.subject,
        description: data?.resourceDetails?.description,
        resourceName: data?.resourceDetails?.resourceName,
        notes: data?.resourceDetails?.reminderNotes || data.notes,
        details: data?.details,
        isNewMessage: isNewMessage,
        subscribers: data?.subscribers,
        notificationId: data?._id,
        dateTime: data.dueDate,
        frequency: data.frequency,
        repeatEveryValue: data.repeatEveryValue,
        repeatEvery: data.repeatEvery,
        start: data.start,
        end: data.end,
        oneTimeDue: data.oneTimeDue,
        toDisplay: temp,
        tableIcon: this.tableIcon,
        createdAt: data.createdAt,
        dueDate: data?.dueDate,
        visibilityStatus: data.visibilityStatus,
      };

      this.reminderList.push(obj);
    } else {
      this.reminderList.push({
        title: "You have new task reminder",
        tableId: data?.tableId,
        tableName: data?.tableName,
        reminderNotes: data?.reminderNotes,
        resourceId: data?.resourceId,
        subject: data?.resourceDetails?.subject,
        description: data?.resourceDetails?.description,
        resourceName: data?.resourceDetails?.resourceName,
        notes: data?.resourceDetails?.reminderNotes || data.notes,
        details: data?.details,
        isNewMessage: isNewMessage,
        subscribers: data?.subscribers,
        notificationId: data?._id,
        dateTime: data.dueDate,
        frequency: data.frequency,
        repeatEveryValue: data.repeatEveryValue,
        repeatEvery: data.repeatEvery,
        start: data.start,
        end: data.end,
        oneTimeDue: data.oneTimeDue,
        toDisplay: temp,
        tableIcon: this.tableIcon,
        createdAt: data.createdAt,
        dueDate: data?.dueDate,
        visibilityStatus: data.visibilityStatus,
      });
    }

    this.cdr.detectChanges();

    // this.reminderList.push({
    //   title: "You have new task reminder",
    //   tableId: data?.tableId,
    //   tableName: data?.tableName,
    //   reminderNotes: data?.reminderNotes,
    //   resourceId: data?.resourceId,
    //   subject: data?.resourceDetails?.subject,
    //   description: data?.resourceDetails?.description,
    //   resourceName: data?.resourceDetails?.resourceName,
    //   notes: data?.resourceDetails?.reminderNotes || data.notes,
    //   details: data?.details,
    //   isNewMessage: isNewMessage,
    //   subscribers: data?.subscribers,
    //   notificationId: data?._id,
    //   dateTime: data.dueDate,
    //   createdAt: data.createdAt
    // });
    this.reminderList = this.reminderList.filter((obj, pos, arr) => {
      return (
        arr
          .map((mapObj) => mapObj["notificationId"])
          .indexOf(obj["notificationId"]) === pos
      );
    });
    this.cdr.detectChanges();
  }

  redirect(notification) {
    notification["reminderDetails"] = notification;
    this.dialogService
      .open(InfoDialogComponent, {
        context: {
          title: ``,
          text: "",
          dialogType: "reminder",
          notification: notification,
        },
      })
      .onClose.subscribe((res) => {
        if (res && res?.status == "markDone") {
          this.getRemindersList();
          let index = this.reminderList.findIndex(
            (x) => x.notificationId == res?.data?.notificationId
          );
          this.reminderList.splice(index, 1);
          this.showNotification = !this.showNotification;
          //this.notificationCount = 0;
          //this.notificationBadgeText = '';
          this.popover.hide();
        } else if (res && res.status == "stopRecurring") {
          this.getRemindersList();
          let index = this.reminderList.findIndex(
            (x) => x.notificationId == res?.data?.notificationId
          );
          this.reminderList[index].frequency = null;
        }
      });

    return false;
  }

  getTablesForMenuData = [];

  getChatSubscription() {
    this.chatSubscriptionService.chatSubscribersSub.subscribe((res: any) => {
      if (res.statusCode == 200 && res.data) {
        res.data.forEach((resourceId: any) => {
          this.messageService.Join(resourceId);
        });
      }
    });
  }

  getAllUsers() {
    this.tableService.getAllUsers().subscribe((res: any) => {
      if (res.statusCode == 200) {
        //let data = res.data.filter(v=> this.allowedUserStatus.includes(v.status));
        this.tableService.$users.next(res.data);
        //this.tableService.$allUsers.next(res.data);
      }
    });
  }

  updateMessageStatus(notification): void {
    const data = {
      receiver: this.userId,
      notification: notification._id,
      participantType: 2,
    };

    if (notification.visibility == "unread") {
      this.socketService.emit("message_status_change", data);
      notification.visibility = "read";
    }

    this.cdr.detectChanges();
    this.unreadNotificationCount--;
  }

  reminderCompleted($event) {
    this.unreadReminderCount--;
  }

  changeUserStatus() {
    const data = {
      id: this.userId,
      status: "Online",
    };
    // this.messageService.userStatusChange(data);
    this.socketService.emit("user_status_change", data);
  }

  keyPressSubscription() {
    this.keySubscription = fromEvent(this.document, "keydown")
      .pipe(throttleTime(1000))
      .subscribe((event) => {
        this.setStatusChangeTimer();
      });
  }

  mouseMoveSubscription() {
    this.mouseSubscription = fromEvent(document, "mousemove")
      .pipe(throttleTime(1000))
      .subscribe((e) => {
        this.setStatusChangeTimer();
      });
  }

  setStatusChangeTimer() {
    const data = { id: this.userId, status: "Online" };
    if (!this.isActive) {
      this.isActive = true;
      // this.messageService.userStatusChange(data);

      this.socketService.emit("user_status_change", data);
    }
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.isActive = false;
      const statusData = { id: this.userId, status: "Away" };
      // this.messageService.userStatusChange(statusData);

      this.socketService.emit("user_status_change", statusData);
    }, 60000);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.keySubscription) {
      this.keySubscription.unsubscribe();
    }

    if (this.mouseSubscription) {
      this.mouseSubscription.unsubscribe();
    }

    if (this.actionSubscription) {
      this.actionSubscription.unsubscribe();
    }

    if (this.logoPathSubscription) {
      this.logoPathSubscription.unsubscribe();
    }
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();
    this.tableService.toggleSidebar.next((this.condtion = !this.condtion));
    return false;
  }

  onEmailClick() {
    this.router.navigate(["/pages/tables/email"]);
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  manageTables() {
    this.router.navigate(["/pages/tables/list"]);
  }

  redirectToNotifications() {
    this.router.navigate(["/pages/tables/notifications"]);
  }

  formatDate(value) {
    if (!value) {
      return "";
    }

    return formatDate(value, "M/d/yy, h:mm a", "en");
  }

  commonUserDetails(res: any) {
    if (
      res.message.announcements != null &&
      res.message.me.lastClickedOnAnnouncement != undefined &&
      res.message.me.lastClickedOnAnnouncement
    ) {
      const announceClicked = new Date(
        res.message.me.lastClickedOnAnnouncement
      ).getTime();
      const announceDate = new Date(
        res.message.announcements.updatedAt
      ).getTime();
      this.isAnnounceRead = !(
        announceClicked == 0 || announceDate > announceClicked
      );
    } else {
      this.isAnnounceRead = false;
    }
    this.announcement = res.message.announcements;
    if (res.message.me) {
      if (!this.isAnnounceRead && localStorage.getItem("isFirstTime")) {
        localStorage.setItem("openAnnounceModal", "true");
        this.tableService.announcementSeen().subscribe((res: any) => {
          if (res.statusCode == 200) {
            this.isAnnounceRead = true;
          }
        });
        // this.openAnnouncementBox();
      }
    }
  }
  getAnnouncement() {
    this.socketService.listen("announce_reminder").subscribe((res: any) => {
      let currentDate = this.formatDate(new Date()).toString();
      let activatedDate = this.formatDate(
        new Date(res.message.announcements.activateDate)
      ).toString();
      console.log(
        "currentDate === activatedDate",
        currentDate === activatedDate,
        currentDate,
        activatedDate
      );

      if (currentDate === activatedDate) {
        this.tableService.announcementSeen().subscribe((res: any) => {
          if (res.statusCode == 200) {
            this.isAnnounceRead = true;
          }
        });
        this.openActivateAnnouncement(res.message.announcements);
      }
    });
  }

  @HostListener("window:beforeunload") openActivateAnnouncement(res: any) {
    if (res) {
      console.log(
        "🚀 ~ file: header.component.ts:2868 ~ HeaderComponent ~ @HostListener ~ res",
        res
      );
      if (
        localStorage.getItem("openAnnounceModal") === "true" ||
        localStorage.getItem("isButtonClick") === "true"
      ) {
        this.dialogService.open(ShowcaseDialogComponent, {
          context: {
            list: this.anounceList,
            button:
              this.anounceList && this.anounceList.length
                ? { text: "Next Announcement" }
                : { text: "Close" },
            isAnnouncment_table: true,
            firstTimeLogin: true,
          },
          hasBackdrop: true,
        });
      }
    }
  }

  getUserDetail() {
    this.loading = true;
    this.tableService.userDetail().subscribe((res: any) => {
      this.loading = false;
      this.commonUserDetails(res);
    });
  }

  async urlAnnounceTitle() {
    let res: any = await this.tableService.getTableByName("Announcements");
    if (res) {
      const idField =
        res.data &&
        res.data.length &&
        res.data[0].columns.find((f) => f.idField)?.name;
      this.tableService.currentIdField = idField;

      if (this.getSystemConfigVariable) {
        this.title.setTitle(
          "Announcements" + " - " + this.getSystemConfigVariable["Company Name"]
        );
      } else {
        this.tableService.$systemConfig.subscribe((configs) => {
          this.title.setTitle(
            "Announcements" + " - " + configs["Company Name"]
          );
        });
      }

      // this.getTableByNameFnCode(res);
    }
  }

  async openAnnouncementBox() {
    this.urlAnnounceTitle();
    let temp = [];
    await this.getAnnouncementList();
    // this?.anounceList?.filter((data) => {
    //   let lastAnnounceDate = this.formatDate(new Date(this.userData.lastClickedOnAnnouncement)).toString();
    //   let activatedDate = this.formatDate(new Date(data.activateDate)).toString();
    //   if (activatedDate < lastAnnounceDate) {
    //   } else {
    //     temp.push(data);
    //   }
    // })
    //if (temp[0] !== undefined && !this.loading && temp[0].subject !== undefined && temp[0].message !== undefined) {
    if (this.anounceList && this.anounceList.length) {
      // commented for this task CA-615
      if (!this.isAnnounceRead) {
        this.tableService.announcementSeen().subscribe((res: any) => {
          if (res.statusCode == 200) {
            this.isAnnounceRead = true;
          }
        });
      }
      this.openActivateAnnouncement(this.anounceList);
    } else {
      this.router.navigate(["/pages/tables/Announcements"]);
      localStorage.setItem("openAnnounceModal", "false")
      localStorage.setItem("isButtonClick", "false");
    }
    // }
  }

  public onSearch(searchStr) {
    if (!!searchStr === true) {
      this.router.navigateByUrl(
        `/pages/tables/master-search?search=${searchStr}`
      );
    }
  }

  openReminderModal(notification?) {
    if (notification) {
      if (notification.tableId && notification.resourceId) {
        this.dialogService
          .open(NewReminderModalComponent, {
            context: {
              tableId: notification.tableId,
              tableName: notification.tableName,
              resourceId: notification.resourceId,
              IdFieldData: notification.toDisplay,
              fromViewPage: true,
              tableIcon: notification.tableIcon[notification.tableName],
              updateReminder: notification,
            },
          })
          .onClose.subscribe((res) => {
            if (res && res == "Done") {
              this.getRemindersList();
            }
          });
      } else {
        this.dialogService
          .open(NewReminderModalComponent, {
            context: {
              fromViewPage: false,
              updateReminder: notification,
            },
          })
          .onClose.subscribe((res) => {
            if (res && res == "Done") {
              this.getRemindersList();
            }
          });
      }
    } else {
      this.dialogService
        .open(NewReminderModalComponent, {
          context: {
            fromViewPage: false,
          },
        })
        .onClose.subscribe((res) => {
          if (res && res == "Done") {
            this.getRemindersList();
          }
        });
    }
  }

  // onDigitClick(num) {
  //   num = num.toString()
  //   if (this.count < 11 && num) {
  //     const output = document.getElementById("output")
  //     var span = document.createElement('span')
  //     span.innerHTML = num;
  //     output.append(span);
  //     this.count++
  //   }
  // }

  // leftArrowClick() {
  //   if (this.count > 0) {
  //     const output = document.getElementById("output")
  //     output.removeChild(output.lastChild);
  //     this.count--;
  //   } else {
  //     let dialerSpan = document.getElementsByClassName('dailerSpan')[0] as HTMLElement;
  //     if (dialerSpan) {
  //       dialerSpan.innerHTML = dialerSpan.innerHTML.slice(0, -1)
  //     }
  //   }
  // }

  onScrollReminder(value) {
    if (value) this.getRemindersList(20, this.numberOfPagesLoadedReminder * 20);
  }

  onScrollNotification(value) {
    if (value)
      this.getNotificationsList(20, this.numberOfPagesLoadedNotification * 20);
  }
}
