import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'renderImage',
})
export class RenderImagePipe implements PipeTransform {
  transform(value: any,forOptions:any) {

    if(Array.isArray(value)){
      value = value[0];
    }

    if (this.checkURL(value)) {

      let arr = value.split(' ');
      let tempArray = [];
      if (arr && arr.length) {
        arr.forEach(element => {
          if (element.includes('http')) {

            let temp = '';
            if(forOptions){
              temp = `    <img src="${element}" alt="Not image" width="30" height="30">`;
            } else{
              temp = `    <img src="${element}" alt="Not image" width="50" height="50">`;
            }

            tempArray.push(temp);
          } else {
            tempArray.push(element);
          }
        });
      }

      value = tempArray.join(' ');
      return value;
    } else {
      return value;
    }

  }

  checkURL(url) {
    if (typeof url == 'string') {
      return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
    } else {
      return false;
    }
  }
}
