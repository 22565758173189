<nb-card class="modal-body" style="min-width: 470px;
min-height: 250px;" nbSpinnerSize="large" nbSpinnerStatus="primary">
  <nb-card-header *ngIf="!isAnnouncment_table" class="border-0 text-center font-weight-bold">{{ title
    }}</nb-card-header>
  <!-- <nb-card-body [innerHTML]="body | inlineHtml" [ngClass]="isAnnouncment_table ? 'announcement-body' : 'all_table'"
    *ngIf="!list"></nb-card-body> -->

  <nb-card-body *ngIf="list && list.length > 0 && !isAnnouncment_table">

    <nb-list *ngFor="let obj of list">
      <nb-list-item>
        {{ obj.firstName + " "+ obj.lastName }}
      </nb-list-item>
    </nb-list>
  </nb-card-body>

  <ng-container *ngIf="isAnnouncment_table">

    <ng-container *ngIf="!list[announceIndex]">
        <span class="close" #mySpan (click)="dismiss()">&times;</span>
    </ng-container>
    <ng-container *ngIf="list[announceIndex]">
      <nb-card-header style="padding: 5px 2px;" class="border-0 text-center font-weight-bold">{{
        list[announceIndex].subject }}
        <span class="close" #mySpan (click)="dismiss()">&times;</span>
      </nb-card-header>

      <nb-card-body class="announcement-body">
        <span style="white-space: pre-wrap;" [innerHTML]="list[announceIndex].message | inlineHtml"></span>
      </nb-card-body>
    </ng-container>
  </ng-container>


  <nb-card-footer class="text-center border-0 d-flex flex-wrap justify-content-center">
    <button (click)="this.announceIndex < this.list.length ? next() : dismiss()" class="btn w-50 dismissBtn rounded-pill"
      [ngStyle]="{'background':systemConfigColor,'color':'white'}">{{ this.announceIndex == this.list.length ? 'Close' : button.text }}</button>
    <a class="text-decoration-none w-100 mt-3 cursor-pointer" [ngStyle]="{'color':systemConfigColor}"
      (click)="manageNotifications()">Manage notifications</a>
  </nb-card-footer>
</nb-card>
