import { TableService } from '@app/shared/services/table.service';
import { Component, Input, OnInit, ChangeDetectorRef, ViewChild, EventEmitter, Output, OnChanges, SimpleChanges, HostListener, OnDestroy, AfterViewInit } from '@angular/core';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { cloneDeep, filter } from 'lodash';
import { ConfirmDeleteComponent } from '@app/pages/tables/confirm-delete/confirm-delete.component';
import { OneFieldComponent } from '../dialog/one-field/one-field.component';
import { Observable, Subscription } from 'rxjs';
import * as moment from 'moment';
import { FilePreviewDialogComponent } from '../file-preview-dialog/file-preview-dialog.component';

@Component({
  selector: 'ngx-all-fields',
  templateUrl: './all-fields.component.html',
  styleUrls: ['./all-fields.component.scss']
})
export class AllFieldsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() field;
  @Input() i;
  @Input() dataToPassInForm;
  @Input() showEditFieldForInlineEdit;
  @Input() lookupDataObject;
  @Input() forkJoinLookupResponse;
  @Input() formColumns;
  @Input() tableId;
  @Input() tableDataFromViewToForm;
  @Input() recordGadgets = [];
  @Input() oneTime = false;
  @Output() emitSave = new EventEmitter();
  @Output() emitToOneField = new EventEmitter();
  @Output() lookupValueEmit = new EventEmitter();

  inputFields: string[] = ["password", "number", "text", "email", "currency"];
  statuses = {};
  colorSetter = {};
  colorForFont;
  showEditIconForField = {}
  showSaveIconForField = {}
  showCancelIconForField = {}
  dataForLookupDetail;

  lookupName: any;
  tempData = {};
  dataForCheckbox = {};
  checkListArray = [];
  show = {};

  private eventsSubscription: Subscription;
  @Input() events: Observable<void>;

  constructor(private changeDetector: ChangeDetectorRef,
    private tableService: TableService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService) {

    // this.eventsSubscription = this.tableService.emitField.subscribe((ele) => {
    //   this.cancelIconForFieldClicked(ele);
    //   this.eventsSubscription.unsubscribe();
    // });
  }

  dateTimePickerToggeledOn = false;

  ngAfterViewInit(): void {

    this.tableService.dateTimePickerFocused.subscribe((res) => {
      setTimeout((_) => (this.dateTimePickerToggeledOn = res));
    });

    setTimeout(() => {
      this.tableService.runCustomStyling();
    }, 10)
  }

  ngOnInit(): void {

    this.tempData = Object.assign({}, this.dataToPassInForm);

    let status = this.formColumns.find(v => v.type == "status");
    if (status) {

      if (this.dataToPassInForm) {
        if (status.statusOptions && Array.isArray(status.statusOptions)) {
          const i = status.statusOptions.find(
            (j) => j.status == this.dataToPassInForm[status.name]
          );
          if (i) {
            this.colorSetter[status.name] = i.color;
            this.colorForFont = i.labelColor ? i.labelColor : "";
          }
        }
        this.statuses[status.name] = this.dataToPassInForm[status.name];
      }

    }

    if (this.field.type === "lookup") {

      this.dropDownText[this.field.name] = "Type to search";
      // this.removeLookup[this.field.name] = true;
      // this.lookTable = this.field.lookupTableName;
      this.lookupName = this.field.name;
      this.lookupValue[this.field.name] = [];
      // this.editData[this.field.name] = [];
      this.filteredOptions[this.field.name] = [];
      // this.lookupFieldRequired[this.field.name] = false;

      const lookUpArray = [];
      this.field.options.forEach((el) => {
        const temp: [] = Object.assign([], el);
        temp.shift();
        if (this.field.loadAsDropDown) {
          if (el.length > 1) {
            this.filteredOptions[this.field.name].push({
              id: el[0],
              value: temp.toString().replace(/,/g, " "),
            });
          }
        }

        if (this.field.name == this.lookupName) {
          lookUpArray.push({
            id: el[0],
            value: temp.toString().replace(/,/g, " "),
          });
        }
      });

      if (!this.field.loadAsDropDown) {
        this.filteredOptions[this.field.name] = [];
        if (this.dataToPassInForm && this.dataToPassInForm.lookup && this.dataToPassInForm.lookup.length) {
          const lookups = this.dataToPassInForm.lookup.filter((f) => f.lookupTableName === this.field.lookupTableName);
          if (lookups.length) {
            for (let lookUP of lookups) {
              let value = "";
              if (lookUP && lookUP[this.field.name] && lookUP[this.field.name].length > 2) {
                for (let i = 1; i < lookUP[this.field.name].length; i++) {
                  value += lookUP[this.field.name][i] + " ";
                }
                value = value.trim();
              } else if (lookUP && lookUP[this.field.name] && lookUP[this.field.name].length == 2) {
                value = lookUP[this.field.name][1];
              }

              if (lookUP[this.field.name] && lookUP[this.field.name].length) {
                this.filteredOptions[this.field.name].push({
                  id: lookUP[this.field.name][0],
                  value: value,
                });
              } else if (value) {
                this.filteredOptions[this.field.name].push({
                  id: lookUP.lookupId,
                  value: value,
                });
              }
            }
          }
        }
      }

      // this.lookUpOptions = lookUpArray;
      this.field.options?.map((res) => {
        res.splice(3, 1);
      });
      // this.demoData = this.field;
      // this.lookupData.push(this.demoData);

      if (this.dataToPassInForm && this.field.loadAsDropDown) {
        this.dataToPassInForm.lookup.forEach((el1) => {
          if (!this.field.isReference) {
            this.field.options.forEach((element) => {
              if (
                this.dataToPassInForm.lookup &&
                this.field.name === el1.lookupName &&
                element[0] === el1.lookupId
              ) {
                const displayValue = this.field.options.filter(
                  (f) => f[0] === el1.lookupId
                );
                if (displayValue.length > 0) {
                  const temp: [] = Object.assign([], displayValue[0]);
                  temp.shift();
                  this.lookupValue[this.field.name].push({
                    id: el1.lookupId,
                    value: temp.toString().replace(/,/g, " "),
                  });
                } else {
                  if (element[0] === el1.lookupId) {
                    this.lookupValue[this.field.name].push({
                      id: el1.lookupId,
                      value: element[1],
                    });
                  }
                }

                // this.editData[this.field.name] = this.lookupValue[this.field.name];
                // this.editData[this.field.name].forEach((element) => {
                //   if (element.name) element.name = element.value;
                // });
              }
            });
          }
        });
      } else if (this.dataToPassInForm) {
        if (!this.field.isReference) {
          if (this.filteredOptions[this.field.name].length) {
            this.lookupValue[this.field.name] = this.filteredOptions[this.field.name];
            // this.editData[this.field.name] = this.lookupValue[this.field.name];
            // this.editData[this.field.name].forEach((element) => {
            //   if (element.name) element.name = element.value;
            // });
          }
        }
      }

      this.lookupValueEmit.emit(this.lookupValue);
    }

    if (this.field.type === "checkbox") {
      this.field["this.checkBoxValidateForDynamicForm"] = false;
      const check = [];
      let checkedData = [];
      if (this.tempData) {
        checkedData = this.tempData[this.field.name]?.split(",");
      } else if (this.field.defaultOptionValue) {
        checkedData = [this.field.defaultOptionValue];
      }
      if (this.field.options) {
        this.field.options.forEach((option) =>
          check.push((checkedData && checkedData.length > 0) ? checkedData?.includes(option) : false)
        );
      }
      this.dataForCheckbox[this.field.name] = check;
    }
    if (this.field.type == "checkList") {

      if (this.dataToPassInForm && this.dataToPassInForm[this.field.name]) {

        this.checkListArray = cloneDeep(this.dataToPassInForm[this.field.name]);
        this.checkListArray.forEach((ele, i) => {
          let key = Object.keys(ele);

          ele['name'] = key[0];
          ele['value'] = ele[key[0]];
          delete ele[key[0]];

          this.showList[i] = true;
          this.showNameInput[i] = false;
          for (var x = 0; x < this.checkListArray[i].value.length; x++) {
            this.showItemInput[i] = new Array(this.checkListArray[i].value.length);
            this.showDeleteIcon[i] = new Array(this.checkListArray[i].value.length);
          }

          // Loop to initialize 2D array elements.
          for (var y = 0; y < this.checkListArray[i].value.length; y++) {
            for (var j = 0; j < this.checkListArray[i].value.length; j++) {
              this.showItemInput[i][j] = false;
              this.showDeleteIcon[i][j] = false;
            }
          }
        });

        console.log(this.checkListArray);
      }
    }

    if (this.field.type === "gadget") {

      this.currentGadgetValue = this.field.gadget ? this.field.gadget : "";
      try {
        this.getRecordGadgets();
      } catch (e) {
        console.log("Error proccessing gadget ", e);
      }
    }

    this.formColumns.forEach(data => {
      this.show[data.name] = true;
    });

    if (!this.oneTime)
      this.visibilityFieldsFn();

  }

  ngOnChanges(changes: SimpleChanges): void {

    this.ngOnInit();
  }

  ngOnDestroy() {
    // this.eventsSubscription.unsubscribe();
  }

  showEditIcon(field) {
    // if (!this.showEditFieldForInlineEdit[field.name])
    // this.showEditIconForField[field.name] = true;

    let keys = Object.keys(this.showEditFieldForInlineEdit);
    if (keys && keys.length) {
      keys.forEach(ele => {
        this.showEditFieldForInlineEdit[ele] = false;
      });
    }

    this.showEditFieldForInlineEdit[field.name] = true;

  }

  hideEditIcon(field) {
    // this.showEditIconForField[field.name] = false;
    this.showEditFieldForInlineEdit[field.name] = false;
  }

  // @HostListener('window:keyup', ['$event'])
  // keyEvent(event: KeyboardEvent) {

  //   if (event.keyCode === 13) {

  //     let valid = true;
  //     let field;
  //     let keys = Object.keys(this.showEditFieldForInlineEdit);
  //     if(keys && keys.length){

  //       keys.forEach(ele => {
  //         if(!this.showEditFieldForInlineEdit[ele]){
  //           valid = false;
  //         } else{
  //           field = ele;
  //         }
  //       });
  //     }

  //     if(valid){
  //       this.saveIconForFieldClicked(field);
  //     }

  //   }
  // }

  // editIconForFieldClicked(field) {

  //   setTimeout(() => {
  //     this.formColumns.forEach(ele => {

  //       if (ele.name !== field.name) {
  //         this.showEditFieldForInlineEdit[ele.name] = false;
  //         this.showEditIconForField[ele.name] = false;
  //         this.showCancelIconForField[ele.name] = false;
  //         this.showSaveIconForField[ele.name] = false;
  //         this.changeDetector.detectChanges();
  //       }

  //     });
  //   }, 500);

  //   this.showCancelIconForField[field.name] = true;
  //   this.showSaveIconForField[field.name] = true;
  //   this.showEditFieldForInlineEdit[field.name] = true;

  //   this.changeDetector.detectChanges();
  // }

  cancelIconForFieldClicked(field) {

    this.showEditFieldForInlineEdit[field.name] = false;

    this.tempData[field.name] = this.dataToPassInForm[field.name];
    if (!field.loadAsDropDown) {
      this.filteredOptions[field.name] = [];
      if (this.dataToPassInForm && this.dataToPassInForm.lookup && this.dataToPassInForm.lookup.length) {
        const lookups = this.dataToPassInForm.lookup.filter((f) => f.lookupTableName === field.lookupTableName);
        if (lookups.length) {
          for (let lookUP of lookups) {
            let value = "";
            if (lookUP && lookUP[field.name] && lookUP[field.name].length > 2) {
              for (let i = 1; i < lookUP[field.name].length; i++) {
                value += lookUP[field.name][i] + " ";
              }
              value = value.trim();
            } else if (lookUP && lookUP[field.name] && lookUP[field.name].length == 2) {
              value = lookUP[field.name][1];
            }

            if (lookUP[field.name] && lookUP[field.name].length) {
              this.filteredOptions[field.name].push({
                id: lookUP[field.name][0],
                value: value,
              });
            } else if (value) {
              this.filteredOptions[field.name].push({
                id: lookUP.lookupId,
                value: value,
              });
            }
          }
        }
      }
    }

    if (this.dataToPassInForm && field.loadAsDropDown) {
      this.dataToPassInForm.lookup.forEach((el1) => {
        if (!field.isReference) {
          field.options.forEach((element) => {
            if (
              this.dataToPassInForm.lookup &&
              field.name === el1.lookupName &&
              element[0] === el1.lookupId
            ) {
              const displayValue = field.options.filter(
                (f) => f[0] === el1.lookupId
              );
              if (displayValue.length > 0) {
                const temp: [] = Object.assign([], displayValue[0]);
                temp.shift();
                this.lookupValue[field.name].push({
                  id: el1.lookupId,
                  value: temp.toString().replace(/,/g, " "),
                });
              } else {
                if (element[0] === el1.lookupId) {
                  this.lookupValue[field.name].push({
                    id: el1.lookupId,
                    value: element[1],
                  });
                }
              }

              // this.editData[data.name] = this.lookupValue[data.name];
              // this.editData[data.name].forEach((element) => {
              //   if (element.name) element.name = element.value;
              // });
            }
          });
        }
      });
    } else if (this.dataToPassInForm) {
      if (!field.isReference) {
        if (this.filteredOptions[field.name].length) {
          this.lookupValue[field.name] = this.filteredOptions[field.name];
          // this.editData[data.name] = this.lookupValue[data.name];
          // this.editData[data.name].forEach((element) => {
          //   if (element.name) element.name = element.value;
          // });
        }
      }
    }
    // this.showEditIconForField[field.name] = true;
    // this.showCancelIconForField[field.name] = false;
    // this.showSaveIconForField[field.name] = false;
    this.showEditFieldForInlineEdit[field.name] = false;

    this.changeDetector.detectChanges();

    // setTimeout(() => {
    //   this.tableService.runCustomStyling();
    // },100)

    this.tableService.runCustomStyling();
  }

  @Input() id;
  timeout;
  saveIconForFieldClicked(field, event?) {

    let temp = {};

    if (field.type === "date") {
      temp[field.name] = moment(this.tempData[field.name]).format("YYYY-MM-DD");
      this.tempData[field.name] = moment(this.tempData[field.name]).format("YYYY-MM-DD");
    }

    let valid = true;
    if (field.type == "lookup") {

      if (this.lookupValue[field.name] && this.lookupValue[field.name].length == 0 && field.isRequired) {
        this.toastrService.warning("Enter Required Fields");
        valid = false;
      }

      let tableData = [];

      let keys = Object.keys(this.lookupValue);
      if (keys && keys.length) {

        keys.forEach(ele => {

          let lookupField = this.formColumns.find(v => v.name == ele);
          if (lookupField) {

            let lookTable = lookupField.lookupTableName;
            if (this.lookupValue[ele] && this.lookupValue[ele].length) {
              this.lookupValue[ele].forEach((element) => {
                if (element["id"]) {
                  tableData.push({
                    lookupTableName: lookTable,
                    lookupId: element["id"],
                    lookupName: ele,
                  });
                }
              });
            }
          }

        });

      }


      temp["lookup"] = tableData;

    } else if (field.type == 'checkbox' && field.isRequired) {
      if (!this.tempData[field.name].includes(true)) {
        this.toastrService.danger("", "Required Fields Are Missing");
        valid = false;
      }
    } else if (field.type == 'checkList') {

      let checkArray = cloneDeep(this.checkListArray);

      checkArray.forEach(ele => {

        ele[ele.name] = ele.value;
        delete ele['name'];
        delete ele['value'];
      });
      temp[field.name] = checkArray;
    } else {

      if (field.isRequired) {

        if (this.tempData[field.name]) {
          temp[field.name] = this.tempData[field.name];
        } else {
          this.toastrService.warning("Enter Required Fields");
          valid = false;
        }
      } else {
        temp[field.name] = this.tempData[field.name];
      }

    }

    if (valid) {

      if (this.showEditFieldForInlineEdit[field.name] && (this.inputFields.includes(field.type) || field.type == 'area')) {

        if (event && event.keyCode == 13) {
          this.updateData(field, temp);
        } else if (!event) {
          this.updateData(field, temp);
        }

      } else {

        if (this.showEditFieldForInlineEdit[field.name]) {
          this.updateData(field, temp);
        }
      }

    }

    this.tableService.runCustomStyling();

  }

  updateData(field, temp) {
    this.tableService.updateDynamicFormData(this.id, this.tableName, temp, this.tableId).subscribe((res: any) => {

      if (res.statusCode === 200) {

        this.visibilityFieldsFn(field);
        this.toastrService.success(res.message, "Success");
        this.showEditFieldForInlineEdit[field.name] = false;
        this.changeDetector.detectChanges();

        if (this.oneTime) {
          this.emitToOneField.emit();
        } else {
          this.emitSave.emit();
        }
      }
    })
  }


  lookupDetailClicked = false;
  useHostListener = false;
  lookupDetailItem = {};
  tableForLookupDetail = '';
  tableIdFromView = '';
  fieldNameForLookup = '';
  @ViewChild("notLoadAsDropdownLookupPopover", { static: false }) notLoadAsDropdownLookupPopover;
  @ViewChild("referenceLookupPopover", { static: false }) referenceLookupPopover;
  @ViewChild("loadAsDropdownLookupPopover", { static: false }) loadAsDropdownLookupPopover;
  onShowLookupDetail(event, field, item, index, p1, pop: string) {

    this.lookupDetailClicked = false;
    this.useHostListener = false;
    this.lookupDetailItem = {};
    this.tableForLookupDetail = '';
    this.tableIdFromView = '';
    this.fieldNameForLookup = '';
    this.dataForLookupDetail = {};


    let data;
    if (this.lookupDataObject[field.name]) {
      data = this.lookupDataObject[field.name].filter(
        (v) => v.lookupId == index
      )[0];
    }

    if (data) {
      this.dataForLookupDetail = this.forkJoinLookupResponse[
        data.lookupId
      ].data;
      this.lookupDetailItem = data;
      this.tableForLookupDetail = data.lookupTableName;
      this.tableIdFromView = field.lookupTableId;
      this.fieldNameForLookup = field.name;
      this.lookupDetailClicked = true;
    }
    else {

      let lookupData = this.formColumns.filter(v => v.name == field.name);
      if (lookupData && lookupData.length) {
        let id = item._id ? item._id : item.id
        this.tableService.getDynamicTreeDataById(lookupData[0].lookupTableName, id).subscribe((res: any) => {
          if (res.statusCode == 200) {
            this.dataForLookupDetail = res.data;
            this.lookupDetailItem = lookupData[0];
            this.tableForLookupDetail = lookupData[0].lookupTableName;
            this.lookupDetailClicked = true;
            this.useHostListener = true;
          }
        }, (err) => {

        });
      }

    }

    switch (pop) {
      case "notLoadAsDropdownLookupPopover":
        this.notLoadAsDropdownLookupPopover = p1;
        break;
      case "referenceLookupPopover":
        this.referenceLookupPopover = p1;
        break;
      case "loadAsDropdownLookupPopover":
        this.loadAsDropdownLookupPopover = p1;
        break;
    }
  }

  lookupArray = [];
  lookupTableName = [];
  otherDataKeys = [];
  @Input() lookupValue = {};
  arrayForLookup = [];
  lookupObj = {};
  @Input() filteredOptions;
  valueToMapInField;
  onSelectionChange(data1, field, index?) {

    let lookupfields = this.formColumns.filter((f) => f.type == "lookup");
    if (lookupfields && lookupfields.length) {
      lookupfields.forEach((element, i) => {
        if (element.isReference) {
          this.lookupArray.push(element);
        }
      });
    }

    if (this.lookupArray && this.lookupArray.length) {
      this.lookupArray.forEach((ele) => {
        this.lookupTableName.push(ele.lookupTableName);
        this.otherDataKeys.push({
          name: ele.name,
          value: ele.lookupTableFieldNames,
        });
      });
    }

    if (field.mappedFields) {
      let keys = Object.keys(field.mappedFields);
      if (keys && keys.length) {
        keys.forEach((ele) => {
          this.valueToMapInField = field.mappedFields[ele];
          let mappedFieldIndex = this.formColumns.findIndex(
            (v) => v.name == this.valueToMapInField
          );
          if (mappedFieldIndex > -1) {
            if (
              this.formColumns[mappedFieldIndex].type == "lookup" &&
              this.formColumns[mappedFieldIndex].isReference
            ) {
              this.tableService
                .getDynamicTreeDataById(field.lookupTableName, data1[0].id).subscribe((res: any) => {
                  if (res.data) {
                    let mainField = res.data.lookup.filter((all) => all[ele]);

                    if (mainField && mainField.length) {
                      this.tableService
                        .getDynamicTreeDataById(
                          mainField[0].lookupTableName,
                          mainField[0].lookupId
                        ).subscribe((response: any) => {
                          if (response.data) {
                            // this.getReferList(
                            //   response,
                            //   this.formColumns[mappedFieldIndex].name,
                            //   data1[0].id
                            // );
                          }
                        });
                    }
                  }
                });
            } else {
              this.codeBeforeForEachInOnSelectionChange(field, data1, index)
            }
          }
        });
      }
    } else {
      this.codeBeforeForEachInOnSelectionChange(field, data1, index);
    }

    // this.dependenciesCheck();

    //Check if filter => call api for that field and set filtered opetions.
    this.formColumns.forEach((element) => {
      if (element.filters && element.filters.length) {
        let filter = [...element.filters];
        filter.forEach((f) => {
          if (f.baseTableField === field.name) {
            this.dynamicSearchForLoadAsDropDown(element);
          }
        });
      }
    });

    this.saveIconForFieldClicked(field);
  }

  codeBeforeForEachInOnSelectionChange(field, data1, index) {

    field.showOption = false;
    this.lookupValue[field.name] = [];
    this.arrayForLookup = [];
    if (index && !data1.length) {
      // Cancel clicked.
      this.removeText(field.name);
    } else {
      let name = field.name;
      if (data1 && !data1[0]) {
        let obj = data1;
        data1 = [];
        data1.push(obj);
      }
      this.onSelectionChangeForEachCode(data1, name, field);
      this.arrayForLookup = this.lookupValue[field.name];
    }
  }

  onSelectionChangeForEachCode(data1, name, field) {

    data1.forEach((data, index) => {
      if (data && data.id) {
        this.tableService
          .getDynamicTreeDataById(
            field.lookupTableName,
            data.id
          ).subscribe((res: any) => {
            if (res.statusCode === 200) {
              let listData = this.formColumns.filter((item) => {
                if (item.name == name) {
                  return true;
                } else {
                  return false;
                }
              });

              if (listData && listData.length && listData[0].mappedFields) {
                let keys = Object.keys(listData[0].mappedFields);
                if (keys && keys.length) {
                  keys.forEach((ele) => {
                    this.valueToMapInField = listData[0].mappedFields[ele];
                    let mappedFieldIndex = this.formColumns.findIndex(
                      (v) => v.name == this.valueToMapInField
                    );
                    if (mappedFieldIndex > -1) {
                      if (
                        this.formColumns[mappedFieldIndex].type == "lookup"
                      ) {
                        if (res.data["lookup"] && res.data["lookup"].length) {
                          let lookupList = res.data["lookup"].filter(
                            (element) => element.lookupDataName == ele
                          );
                          if (lookupList && lookupList.length) {
                            this.tempData[this.valueToMapInField] = lookupList[0].lookupId;
                            let obj = {
                              id: lookupList[0].lookupId,
                              value: lookupList[0].lookupName,
                            };
                            if (
                              this.filteredOptions[
                              this.valueToMapInField
                              ] &&
                              this.filteredOptions[this.valueToMapInField]
                                .length
                            ) {
                              this.filteredOptions[
                                this.valueToMapInField
                              ].push(obj);
                            } else {
                              this.filteredOptions[
                                this.valueToMapInField
                              ] = [];
                            }
                            this.lookupValue[this.valueToMapInField] = [];
                            // this.editData[this.valueToMapInField][
                            //   index
                            // ] = {};
                            this.lookupValue[this.valueToMapInField] = [
                              ...this.lookupValue[this.valueToMapInField],
                              {
                                ...obj,
                              },
                            ];
                            // this.editData[this.valueToMapInField][index] = {
                            //   ...obj,
                            // };
                            // this.editData[this.valueToMapInField][
                            //   index
                            // ].name = obj.value;
                            this.tempData[this.valueToMapInField] =
                              lookupList[0].lookupId;
                          }
                        }
                      } else {
                        if (res.data[ele] && res.data[ele] != "") {
                          this.tempData[this.valueToMapInField] =
                            res.data[ele];
                        }
                      }
                    }

                    this.visibilityFieldsFn();
                  });
                }
              }
              //show Error Message for Lookup
              // this.showErrorMessage(listData);
            }
          });
      }

      if (data && data.id && data.value) {
        if (!this.lookupObj[name]) {
          this.lookupObj[name] = [];
        }
        this.lookupObj[name].push(data);
        // this.removeLookup[name] = true;
        for (const d of this.formColumns) {
          if (d.type === "lookup" && name === d.name) {
            if (d.loadAsDropDown) {
              const displayValue = d.options.filter(
                (f) => f[0] === data.id
              );
              if (displayValue.length > 0) {
                const temp: [] = Object.assign([], displayValue[0]);
                temp.shift();
                this.lookupValue[name] = [
                  ...this.lookupValue[name],
                  {
                    id: data.id,
                    value: temp.toString().replace(/,/g, " "),
                  },
                ];
              } else {
                d.options.forEach((el) => {
                  if (data.id === el[0]) {
                    this.lookupValue[name] = [
                      ...this.lookupValue[name],
                      {
                        id: data.id,
                        value: el[1],
                      },
                    ];
                  }
                });
              }
            } else {
              if (
                this.lookupValue[name].findIndex((f) => f.id == data.id) ==
                -1
              ) {
                this.lookupValue[name] = [
                  ...this.lookupValue[name],
                  { id: data.id, value: data.value },
                ];
              }
            }
            // this.editData[name] = this.editData[name].concat(
            //   this.lookupValue[name]
            // );

            // Filterout only unique values
            // this.editData[name] = this.editData[name].filter(
            //   (ob, i, arr) => arr.findIndex((t) => t.id === ob.id) === i
            // );

            // Filterout only unique values
            this.lookupValue[name] = this.lookupValue[name].filter(
              (ob, i, arr) => arr.findIndex((t) => t.id === ob.id) === i
            );
            this.lookupValue[name] = [...this.lookupValue[name]];

            // this.editData[name].forEach((element) => {
            //   if (element.name) {
            //     element.name = element.value;
            //   }
            // });
          }
        }
        // this.lookupFieldRequired[name] = false;
      }
    });
  }

  removeText(value, isLoadAsDropDown?) {
    // this.count++;
    this.lookupValue[value] = [];
    this.lookupObj[value] = [];
    // this.removeLookup[value] = false;
    if (isLoadAsDropDown) {
      this.filteredOptions[value] = [];
    }
    // this.dependenciesCheck();
  }

  @Input() tableName;
  unwantedFieldsInOptions = ["displayInTree", "lookups", "_id"];
  dropDownText = {};
  loadingAPI = false;
  async dynamicSearchCommonFn(field, filterKey, formDataObj, lookupVal, e?) {
    let response = await this.tableService.getDynamicTreeData(field.lookupTableName, 1, e ? e.target.value : "", 0, filterKey,
      "", "", "", this.tableName, field.name, formDataObj, lookupVal);

    if (response) {
      let res = response as any;
      this.filteredOptions[field.name] = [];
      if (res && res.data && res.data.pageOfItems) {
        res.data.pageOfItems.forEach((data) => {
          let val = "";
          Object.keys(data).forEach((lookupele) => {
            if (
              !!data[lookupele] &&
              !this.unwantedFieldsInOptions.includes(lookupele)
            ) {
              val = val + " " + data[lookupele];
            }
          });
          const obj = {
            id: data._id,
            value: val,
          };
          this.filteredOptions[field.name] = [
            ...this.filteredOptions[field.name],
            obj,
          ];
        });
        this.loadingAPI = false;
      } else {
        this.loadingAPI = false;
      }
      if (this.filteredOptions[field.name].length) {
        this.dropDownText[field.name] = "Type to search";
      } else {
        this.dropDownText[field.name] = "No record found";
      }
    }
  }

  dynamicSearch(e, field) {
    this.dropDownText[field.name] = "";
    this.loadingAPI = true;
    let filterKey;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      if (field.name === "dependentTask" && field.lookupTableName === "Tasks") {
        filterKey = [{ isSubtask: "No" }];
      } else {
        filterKey = 0;
      }
      if (!e.target.value) {
        this.filteredOptions[field.name] = [];
        this.loadingAPI = false;
        this.dropDownText[field.name] = "Type to search";
      } else {
        let lookupVal = this.lookupValue;
        let formDataObj = {};
        this.formColumns.forEach((e) => {
          if (e.type != "injectSubForm" && e.type != "lookup") {
            formDataObj[e.name] = this.dataToPassInForm[e.name];
          }
        });
        this.dynamicSearchCommonFn(field, filterKey, formDataObj, lookupVal, e);
      }
    }, 800);
  }

  dynamicSearchForLoadAsDropDown(field) {
    this.loadingAPI = true;
    let filterKey;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      if (field.name === "dependentTask" && field.lookupTableName === "Tasks") {
        filterKey = [{ isSubtask: "No" }];
      } else {
        filterKey = 0;
      }
      let lookupVal = this.lookupValue;
      let formDataObj = {};
      this.formColumns.forEach((e) => {
        if (e.type != "injectSubForm" && e.type != "lookup") {
          formDataObj[e.name] = this.dataToPassInForm[e.name];
        }
      });
      this.dynamicSearchCommonFn(field, filterKey, formDataObj, lookupVal);
    }, 800);
  }

  updatedVal(e, value) {
    if (e && e.length) {
      // this.editData[value] = [];
      e.forEach((element) => {
        if (element && !element.$ngOptionLabel) {
          // this.editData[value].push(element);
        }
      });
    }
    // this.count++;
    // this.checkData = false;
    if (e && e.length >= 0) {
      // this.checkData = true;
    }
  }

  compareFn(c11: any, c22: any): boolean {
    return c11 && c22 ? c11.id === c22.id : c11 === c22;
  }

  removeSelected(val, field) {
    field.showOption = false;
    let index = this.lookupValue[field.name].findIndex((f) => f.id == val.id);
    if (index > -1) {
      if (index == 0 && this.lookupValue[field.name].length == 1) {
        this.lookupValue[field.name] = [];
        // this.editData[field.name] = [];
      } else {
        this.lookupValue[field.name].splice(index, 1);
        // this.editData[field.name].splice(index, 1);
      }
      // this.editData[field.name] = [...this.editData[field.name]];
      this.lookupValue[field.name] = [...this.lookupValue[field.name]];
    }

    field.showOption = true;

    this.onSelectionChange(
      this.lookupValue[field.name],
      field,
      field.sequence
    )
  }


  onDone(status, field) {
    this.colorSetter[field.name] = status.color;

    if (status.labelColor) {
      this.colorForFont = status.labelColor;
    } else {
      this.colorForFont = "";
    }
    this.statuses[field.name] = status.status;

    this.tempData[field.name] = status.status;

    this.saveIconForFieldClicked(field);

  }

  onDependentFieldChanged(event, fieldWhole, type, fieldName, section, index, field?) {
    let value,
      isChecked = true;
    if (type === "checkbox") {
      this.formColumns.filter((ele) => {
        if (ele.name == fieldName) ele.checkBoxValidateForDynamicForm = false;
        value = event.currentTarget.textContent.trim();
        isChecked = event.target.checked;
        if (!this.dataForCheckbox[fieldName].includes(true)) {
          ele.checkBoxValidateForDynamicForm = true;
        }
      });
    } else if (type === "dropdown") {
      value = event;
    } else if (type === "gadget") {
      value = event.name;
    } else if (type === "dropdownWithImage") {
      value = event;
    } else if (type === "recordType") {
      value = event.value;
    } else if (type === "radio") {
      value = event;
    }
    if (field) {
      let string = '';
      field.options.forEach((element, i) => {
        if (this.dataForCheckbox[fieldName][i] == true) {
          string = string + element + ',';
        }
      });
      this.tempData[fieldName] = string;
    } else {
      this.tempData[fieldName] = value;
    }

    this.saveIconForFieldClicked(fieldWhole);
    this.changeDetector.detectChanges();

    // this.evalExpressionForFormulaField();
    setTimeout(() => {
      this.tableService.runCustomStyling();
    }, 10)
  }

  showNameInput = [];
  showItemInput = [];
  showList = [];
  addNewCheckList() {
    this.checkListArray.push(new checkListClass());
    this.showList.push(false);
    this.showNameInput.push(true);

    this.changeDetector.detectChanges();
  }

  bindNameOfCheckList(event, i) {

    this.checkListArray[i].name = event.target.value;

    if (event.keyCode == 13) {

      if (this.checkListArray[i].name == '') {
        this.toastrService.warning("Enter Checklist Name", "Validate");
      } else {
        this.showNameInput[i] = false;
        this.showList[i] = true;

        this.updateCheckList();

      }
    }
  }

  addItemInsideCheck(i) {
    this.checkListArray[i].value.push(new checkListItemClass());

    if (this.showItemInput[i] && this.showItemInput[i].length) {
      for (var a = this.showItemInput[i].length; a < this.checkListArray[i].value.length; a++) {
        this.showItemInput[i].push(new Array(1));
        this.showDeleteIcon[i].push(new Array(1));
      }

      // Loop to initialize 2D array elements.

      this.showItemInput[i][this.showItemInput[i].length - 1] = true;
      this.showDeleteIcon[i][this.showDeleteIcon[i].length - 1] = false;

    } else {
      for (var x = 0; x < this.checkListArray[i].value.length; x++) {
        this.showItemInput[i] = new Array(this.checkListArray[i].value.length);
        this.showDeleteIcon[i] = new Array(this.checkListArray[i].value.length);
      }

      // Loop to initialize 2D array elements.
      for (var y = 0; y < this.checkListArray[i].value.length; y++) {
        for (var j = 0; j < this.checkListArray[i].value.length; j++) {
          this.showItemInput[i][j] = true;
          this.showDeleteIcon[i][j] = false;
        }
      }
    }

    // this.showItemInput[i].push([true]);
  }

  bindItemOfCheckList(event, i, ind) {
    if (event.keyCode == 13) {

      if (event.target.value == "") {
        this.toastrService.warning("Enter Item Name", "Validate");
      } else {
        this.checkListArray[i].value[ind].item = event.target.value;
        this.showItemInput[i][ind] = false;
        this.showDeleteIcon[i][ind] = false;

        this.updateCheckList();

      }

    }

  }

  onCheckedItem(event, item, ind, i) {

    this.checkListArray[i].value[ind].checked = event.target.checked;

    this.updateCheckList();

  }

  removeWholeCheckList(i) {

    const chkList: any = this.checkListArray[i].name;

    this.dialogService.open(ConfirmDeleteComponent, {
      context: {
        heading: "Delete " + chkList + " checklist",
        body: "Are you sure you want to delete this checklist?",
        btnText: "Delete"
      }
    })

      .onClose.subscribe(res => {
        if (res) {
          this.showList.splice(i, 1);
          this.checkListArray.splice(i, 1);
          this.showNameInput.splice(i, 1);
          this.showItemInput.splice(i, 1);
          this.showDeleteIcon.splice(i, 1);
          this.changeDetector.detectChanges();

          this.updateCheckList();

        }
      })

  }

  removeItemFromCheckList(i, ind) {

    this.dialogService.open(ConfirmDeleteComponent, {
      context: {
        heading: "Delete checklist",
        body: "Are you sure you want to delete this checklist?",
        btnText: "Delete"
      }
    }).onClose.subscribe(res => {
      if (res) {
        this.checkListArray[i].value.splice(ind, 1);
        this.changeDetector.detectChanges();

        this.updateCheckList();

      }
    })
  }

  hideNameInput(i) {
    this.showNameInput[i] = false;
  }

  showDeleteIcon = [];
  showDeleteIconForItem(i, ind) {
    this.showDeleteIcon[i][ind] = true;
  }

  hideDeleteIconForItem(i, ind) {
    this.showDeleteIcon[i][ind] = false;
  }

  updateCheckList() {

    let temp = {};
    let data = this.formColumns.find(v => v.type == 'checkList');
    if (data) {
      let checkArray = cloneDeep(this.checkListArray);

      checkArray.forEach(ele => {

        ele[ele.name] = ele.value;
        delete ele['name'];
        delete ele['value'];
      });

      console.log(checkArray);
      temp[data.name] = checkArray;

      this.tableService.updateDynamicFormData(this.dataToPassInForm._id, this.tableName, temp).subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.toastrService.success("CheckList updated", "Success");
        }
      })
    }

  }

  dependsFields = [];
  visibilityData: any;
  visibilityFieldsFn(val?) {

    this.dependsFields = [];
    let visibilityFields = this.formColumns.filter(
      (item) => item.isVisibilityOn == true
    );
    visibilityFields.forEach((ele) => {
      if (
        ele.isVisibilityOn &&
        ele.fieldValuesData &&
        ele.fieldValuesData.length > 0
      ) {

        this.show[ele.name] = false;
        this.visibilityData = this.formColumns
          .filter((col) => col._id === ele.visibilityData)
          .map((col) => col.name);
        if (this.visibilityData && this.visibilityData.length) {
          this.visibilityData = this.visibilityData[0];
          this.dependsFields.push(ele);

          ele.fieldValuesData.forEach((item) => {
            let value = this.formColumns.filter((val) => {
              if (this.visibilityData == val.name) return true;
            });
            if (value && value.length) {
              if (value[0].type == "checkbox") {
                let index = value[0].options.findIndex((v) => v == item);
                if (index > -1) {
                  let arr = this.dataForCheckbox[this.visibilityData];
                  arr.forEach((element, i) => {
                    if (i == index) {
                      if (element == true) {
                        this.show[ele.name] = true;
                      }
                    }
                  });
                }
              } else {
                if (this.visibilityData && this.tempData[this.visibilityData] == item) {
                  this.show[ele.name] = true;
                }
              }
            }
          });

        }

      }
    });

    if (val) {
      this.dependsFields.forEach((element) => {
        if (element.visibilityData === val._id) {
          if (this.show[element.name]) {
            this.openOneDependentField(element);
            this.show[element.name] = false;
          }
        }
      });

    }
  }

  checkURL(url) {
    if (typeof url == 'string') {
      return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
    } else {
      return false;
    }

  }


  onFilePreivew(filename, item) {

    const fileDialog = this.dialogService.open(FilePreviewDialogComponent, {
      context: {
        Data: decodeURI(filename),
        Ext: decodeURI(filename).split(".").pop(),
      },
    });

    // fileDialog.componentRef.instance.saveTo.subscribe((data: any) => {
    //   this.tableService.getDynamicTreeDataById(this.tableName, item._id).subscribe(async (res: any) => {
    //     if (res.statusCode == 200) {
    //       res.data.relatedTo = data;
    //       res.data.lookup = [];
    //       delete res.data._id;

    //       let resp: any = await this.formService.openAndCloseForm(null, this.tableName, this.tableData, false, this.recordType, this.fromRecordPage);

    //       this.openAgainOnAddUpdate(resp.formComp, resp.formObj);

    //     }
    //   });
    // });
  }

  openOneDependentField(field) {

    this.dialogService.open(OneFieldComponent, {
      context: {
        field: field,
        dataToPassInForm: this.dataToPassInForm,
        showEditFieldForInlineEdit: this.showEditFieldForInlineEdit,
        lookupDataObject: this.lookupDataObject,
        forkJoinLookupResponse: this.forkJoinLookupResponse,
        formColumns: this.formColumns,
        tableDataFromViewToForm: this.tableDataFromViewToForm,
        id: this.id,
        tableName: this.tableName,
        filteredOptions: this.filteredOptions,
        oneTime: true
      }
    }).onClose.subscribe(res => {
      if (res) {
        this.show[res.name] = true;
        this.emitSave.emit();
      }
    })

  }


  ejsPackage;
  recordGadgetValue;
  currentGadgetValue;
  gadgetFieldValue;
  async getRecordGadgets() {

    await System.import('../../../../../node_modules/ejs/ejs.js').then(async res => {
      this.ejsPackage = res;
      let $Table = this.tempData;
      if (this.recordGadgets && this.recordGadgets.length) {
        let activeGadget = this.recordGadgets.filter((v) => v.active == true)[0];
        if (activeGadget) this.recordGadgetValue = this.ejsPackage.render(activeGadget.logic, { $Table: $Table });

        let currentGadget = this.recordGadgets.filter(
          (v) => v.name == this.currentGadgetValue
        )[0];
        if (currentGadget) this.gadgetFieldValue = this.ejsPackage.render(currentGadget.logic, { $Table: $Table });

        this.changeDetector.detectChanges();
      }
    });


  }

}

export class checkListClass {

  name: string;
  value: checkListItemClass[];

  constructor() {
    this.name = '';
    this.value = [];
  }
}

export class checkListItemClass {

  item: string;
  checked: boolean;

  constructor() {
    this.item = '';
    this.checked = false;
  }
}
