<nb-card
  [ngClass]="dialogType != 'notification' && dialogType != 'reminder' ? 'info-card notification-card' : 'custom_height info-card notification-card'"
  [nbSpinner]="loading" nbSpinnerSize="large" nbSpinnerStatus="primary" class="reminder-new-popup">
  <nb-card-header>
    <span *ngIf="(dialogType !== 'notification' || dialogType !== 'notification')"> {{ title }}</span>
    <!-- <button nbButton *ngIf="dialogType == 'notification' && notification && !plainAlert" size="tiny"
      (click)="onLinkClicked()" class="float-right">View Full
      Record</button> -->
    <span *ngIf="dialogType && (dialogType == 'notification' || dialogType == 'reminder')" class="txt-clr alarm-header-content">
      <span class="material-icons-outlined txt-clr alarm-icon-header">
        alarm
      </span>
      Reminder - {{ triggerDate }} - {{ triggerTime }}</span>
    <img class="close-btn pull-right" *ngIf="!['firstTimeReminder'].includes(dialogType)"
      src="/assets/images/ic_close_24px.svg" (click)="onClose()">
  </nb-card-header>

  <nb-card-body *ngIf="!this.notification && (dialogType != 'reminder' && dialogType != 'notification')" [ngClass]='{"plainAlert":plainAlert}'>
    <span [innerHTML]="text" *ngIf='!plainAlert'></span>
    <ng-container *ngIf="plainAlert">
      <nb-alert status="primary">
        <i class="fas fa-exclamation-triangle"></i> {{ text }}
      </nb-alert>
    </ng-container>
  </nb-card-body>

  <nb-card-body *ngIf="dialogType && (dialogType == 'notification' || dialogType == 'reminder')">
    <ng-container *ngIf="this.notification && !this.text.includes('due yet')">
      <div class="menu_action" style="cursor: pointer;">
        <img [nbPopover]="templateRef" class="option-image" nbPopoverTrigger="click" nbPopoverPlacement="bottom"
          src="/assets/images/more-vertical-outline.svg">
      </div>

      <ng-template #templateRef>
        <div class="d-flex flex-column">
          <nb-icon style="width: 35px;height: 30px;cursor: pointer;" icon="edit-outline" (click)="openReminderModal()">
          </nb-icon>
          <nb-icon style="width: 35px;height: 30px;cursor: pointer;" icon="close-outline" (click)="markComplete(this.notification)">
          </nb-icon>
        </div>
      </ng-template>
      <div class="notifi-row reminder-title">{{this.notification.reminderDetails.notes}} </div>
      <div class="notifi-row reminder-id" *ngIf="notification.reminderDetails && notification.reminderDetails.tableId"
        (click)="remindNavigate(notification)">
        <img *ngIf="notification.tableName && this.notification.tableIcon && notification.tableIcon[notification.tableName]" [src]="notification.tableIcon[notification.tableName]">
        {{ notification.reminderDetails.tableName }} {{ notification.reminderDetails.toDisplay }}
      </div>
      <div class="notifi-row reminder-text">{{this.notification.reminderDetails.details}} </div>
    </ng-container>
  </nb-card-body>

  <nb-card-body *ngIf="dialogType && (dialogType == 'firstTimeReminder')">
    <!-- <div class="notifi-row"><strong>Table name : </strong> {{this.notification.tableName}}</div> -->
    <!-- <div class="notifi-row reminder-title">You have Reminders! </div> -->
    <!-- <div class="notifi-row reminder-id"><img src="/assets/images/phone-icon-img.png"> Call log #23091</div> -->
    <div class="notifi-row reminder-text">{{text}} </div>
  </nb-card-body>

  <nb-card-footer class="d-flex" style="justify-content:space-around;padding: 1rem 0rem">
    <button *ngIf="dialogType && (dialogType == 'notification' || dialogType == 'reminder')" type="button"
      (click)="openSnoozeModal(this.notification)" nbButton status="basic" class="btn btn-cancel outline-dynamic-color d-flex">
      <span class="material-icons-outlined txt-clr">
        alarm
      </span>
      <span class="txt-clr ml-1"> Snooze</span>
    </button>

    <button class="btn btn-danger rounded-pill" *ngIf="notification.frequency != null" (click)="stopRecurring(this.notification)">Stop</button>
    <button *ngIf="dialogType && (dialogType == 'notification' || dialogType == 'reminder')"
      class="save pull-right btn filled-dynamic-color" type="submit" (click)="markComplete(this.notification)" nbButton
      status="primary">Mark Complete
    </button>

    <button *ngIf="dialogType && dialogType == 'firstTimeReminder'" class="save pull-right btn btn-primary btn-save"
      type="submit" (click)="onFirstTimeReminder()" nbButton status="primary">View Reminders!
    </button>

  </nb-card-footer>
</nb-card>
