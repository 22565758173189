import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicFormCommonService } from '@app/shared/dynamic-form-common.service';
import { TableService } from '@app/shared/services/table.service';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { forkJoin } from 'rxjs';
import { PhonePipe } from '../../pipes/phone.pipe';

@Component({
  selector: 'ngx-add-ref',
  templateUrl: './add-ref.component.html',
  styleUrls: ['./add-ref.component.scss']
})
export class AddRefComponent implements OnInit, AfterViewInit {


  searchString;
  showDataFlag = false;
  timeout;
  finalArray = [];
  @Input() fieldsData;
  @Input() tableName;
  @Input() list = [];
  @Input() mapData = {};
  @Input() tableDataForForms;
  @Input() fromRelated = false;
  @Input() parentTableNameFromView = '';
  resData: any;
  placehold = '';
  count = 0;
  loading = false;
  lookupArray = [];
  lookupTableName = [];
  otherDataKeys = [];
  lookupList = [];
  constructor(protected ref: NbDialogRef<AddRefComponent>,
    private tableService: TableService,
    private toastrService: NbToastrService,
    private dialogService: NbDialogService,
    private sanitizer: DomSanitizer,
    private formService: DynamicFormCommonService) { }

  ngOnInit(): void {
    this.getTableDetails();
    console.log(this.mapData);
  }

  close() {
    this.ref.close(false);
  }

  //-- Add theme specific color
  ngAfterViewInit() {
    this.tableService.runCustomStyling()
  }

  getTableDetails() {

    let lookupfields = this.fieldsData.filter(f => f.type == "lookup");
    if (lookupfields && lookupfields.length) {
      lookupfields.forEach((element, i) => {
        if (element.isReference) {

          this.lookupArray.push(element);

          if (lookupfields.length == 1) {
            this.placehold = this.placehold + element.label;
          }
          else if (lookupfields.length != 1 && i == lookupfields.length - 1) {
            this.placehold = this.placehold + element.label;
          }
          else {
            this.placehold = this.placehold + element.label + ', ';
          }
        }
      });
    }

    if (this.lookupArray && this.lookupArray.length) {
      this.lookupArray.forEach(ele => {
        this.lookupTableName.push(ele.lookupTableName);
        this.otherDataKeys.push({ name: ele.name, value: ele.lookupTableFieldNames, lookupTableName: ele.lookupTableName });
      });
    }

    // console.log(this.placehold);
  }

  getData(value, event) {
    if (value) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {

        // let val = [];
        // val = this.fieldsData.map(f => f.idField ? f.name : '');

        if (this.searchString) {
          this.loading = true;

          let obj = {};

          for (let ele of this.lookupArray) {
            obj[ele.name] = this.tableService.getDynamicTreeData(ele.lookupTableName, null, value ? value : '', null,
              null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, true);
          }

          let mainArray = [];
          let keys1 = Object.keys(obj);
          if (keys1 && keys1.length) {
            forkJoin(obj).subscribe((res: any) => {
              console.log(res);


              let dataObjectKeys = Object.keys(res);
              if (dataObjectKeys && dataObjectKeys.length) {

                dataObjectKeys.forEach((ele: any) => {

                  if (res[ele].data && res[ele].data?.pageOfItems && res[ele].data?.pageOfItems.length) {
                    let obj = {
                      name: '',
                      value: []
                    }

                    obj.name = ele;
                    obj.value = res[ele].data.pageOfItems;

                    mainArray.push(obj);
                  }
                })
              }

              this.count = 0;
              this.resData = mainArray;

              this.finalArray = [];

              if (!this.fromRelated) {
                this.resData.forEach(item => {

                  let refLookupIdFields = [];
                  let response;
                  let refLookupField = this.lookupArray.filter(f => f.name == item.name);
                  if (refLookupField && refLookupField.length) {
                    let refLookTable = refLookupField[0].lookupTableName;
                    response = this.tableDataForForms.filter(ele => ele.tableName == refLookTable);
                    if (response && response.length) {
                      refLookupIdFields = response[0].columns.filter(v => v.idField);
                    }

                  }

                  if (item.value && item.value.length) {
                    let obj = {};
                    obj["name"] = item.name;
                    obj["value"] = [];
                    obj["lookupTableName"] = refLookupField[0].lookupTableName;
                    item.value.forEach(v => {
                      let valueObj = {
                        idField: [],
                        others: [],
                        id: null,
                        val: null
                      }
                      let arrayForId = [];
                      let arrForOther = [];
                      let keys = Object.keys(v);
                      let valueofOther = this.otherDataKeys.filter(valueELe => {
                        if (valueELe.name == item.name) {
                          return true;
                        }
                      });
                      if (keys) {
                        keys.forEach(element => {
                          let field = refLookupIdFields.find((v) =>
                            v.name == element
                          );
                          if (field) {
                            if (field.isPhone) {
                              if (typeof v[element] !== "object") {
                                const phonePipe = new PhonePipe();
                                const finalPhone = phonePipe.transform(v[element]);
                                arrayForId.push(" " + finalPhone);
                              }
                            } else {
                              if (typeof v[element] !== "object") {
                                arrayForId.push(" " + v[element]);
                              }
                            }
                          }
                          if (valueofOther && valueofOther.length) {
                            if (valueofOther[0].value.includes(element)) {
                              let fieldOther = response[0].columns.find(v => v.name == element);
                              if (fieldOther) {
                                if (fieldOther.isPhone) {
                                  if (typeof v[element] !== "object") {
                                    const phonePipe = new PhonePipe();
                                    const finalPhone = phonePipe.transform(v[element]);
                                    arrForOther.push(" " + finalPhone);
                                  }
                                } else {
                                  if (typeof v[element] !== "object") {
                                    arrForOther.push(" " + v[element]);
                                  }
                                }
                              }
                            }
                          }
                        })
                      }
                      valueObj.id = v._id;
                      valueObj.idField.push(arrayForId);
                      valueObj.others.push(arrForOther);
                      valueObj.val = v;
                      obj["value"].push(valueObj);
                      this.count++;

                    })
                    this.finalArray.push(obj);
                    this.loading = false;
                  }
                })
              } else {

                let refLookupIdFields = [];
                let response;
                // let refLookupField = this.lookupArray.filter(f => f.lookupTableName == this.parentTableNameFromView);
                // if (refLookupField && refLookupField.length) {
                // let refLookTable = refLookupField[0].lookupTableName;
                response = this.tableDataForForms.filter(ele => ele.tableName == this.resData.name);
                if (response && response.length) {
                  refLookupIdFields = response[0].columns.filter(v => v.idField);
                }

                if (this.resData.value && this.resData.value.length) {
                  let obj = {};
                  obj["name"] = this.resData.name;
                  obj["value"] = [];
                  obj["lookupTableName"] = this.resData.name;
                  this.resData.value.forEach(v => {
                    let valueObj = {
                      idField: [],
                      others: [],
                      id: null,
                      val: null
                    }
                    let arrayForId = [];
                    let arrForOther = [];
                    let keys = Object.keys(v);
                    let valueofOther = this.otherDataKeys.filter(valueELe => {
                      if (valueELe.lookupTableName == this.parentTableNameFromView) {
                        return true;
                      }
                    });
                    if (keys) {
                      keys.forEach(element => {
                        let field = refLookupIdFields.find((v) =>
                          v.name == element
                        );
                        if (field) {
                          if (field.isPhone) {
                            if (typeof v[element] !== "object") {
                              const phonePipe = new PhonePipe();
                              const finalPhone = phonePipe.transform(v[element]);
                              arrayForId.push(" " + finalPhone);
                            }
                          } else {
                            if (typeof v[element] !== "object") {
                              arrayForId.push(" " + v[element]);
                            }
                          }
                        }
                        if (valueofOther && valueofOther.length) {
                          if (valueofOther[0].value.includes(element)) {
                            let fieldOther = response[0].columns.find(v => v.name == element);
                            if (fieldOther) {
                              if (fieldOther.isPhone) {
                                if (typeof v[element] !== "object") {
                                  const phonePipe = new PhonePipe();
                                  const finalPhone = phonePipe.transform(v[element]);
                                  arrForOther.push(" " + finalPhone);
                                }
                              } else {
                                if (typeof v[element] !== "object") {
                                  arrForOther.push(" " + v[element]);
                                }
                              }
                            }
                          }
                        }
                      })
                    }
                    valueObj.id = v._id;
                    valueObj.idField.push(arrayForId);
                    valueObj.others.push(arrForOther);
                    valueObj.val = v;
                    obj["value"].push(valueObj);
                    this.count++;

                  })
                  this.finalArray.push(obj);
                  this.loading = false;
                }
              }


              this.showDataFlag = true;
            });
          }

          // this.tableService.getSearchDataForRef(this.tableName, value ? value : '', this.fromRelated ? true : false).subscribe((res: any) => {
          //   if (res.data) {

          // }
          this.loading = false;
          // });
        }

      }, 1000)


    } else {
      this.showDataFlag = false;
    }
  }

  setData(val, ele) {

    if (this.fromRelated) {

      this.ref.close(val.val);

    } else {
      let invalid = false;
      let temp = [];
      let obj = {
        name: '',
        value: [],
        lookupTableName: ''
      }
      obj.name = ele["name"];
      obj.lookupTableName = ele["lookupTableName"];
      obj.value.push(val);
      temp.push(obj);
      if (this.list && this.list.length) {

        this.list.forEach(ele => {
          if (ele.name == obj.name) {
            let field = this.fieldsData.filter(v => v.name.toLowerCase() == obj.name.toLocaleLowerCase());
            if (field && field.length) {
              if (field[0].allowMultipleValues) {
                if (!ele.value.includes(val)) {
                  ele.value.push(val);
                  invalid = true;
                  return;
                }
                else {
                  this.toastrService.warning("Value already selected", "Exist");
                  invalid = true;
                }

              }
              else {
                this.toastrService.warning("Field is not MultiSelect", "Exist");
                invalid = true;
              }
            }
          }
        })
        if (!invalid) {
          this.list.push(obj);
          this.ref.close(this.list);
        }
        else {
          // this.ref.close(this.list);
        }
      }
      else {
        this.list = temp;
        this.ref.close(this.list);
      }
    }



  }

  actions = [];
  recordGadgets = [];
  showChats;
  customValidations = [];
  formHeight;
  formWidth;
  fieldAlignment;
  subFormLookupIds = [];
  tableRecordTypes = [];
  taskRecordTypes = [];
  tempParentTableHeader;
  recordTypeFieldName;
  recordTypes = [];
  recordType = '';
  tableIcon;
  refFlag = true;
  lookupSeelcted = "1";
  selectedName;

  addRef() {
    this.refFlag = true;
  }

  setValue(val) {
    this.lookupSeelcted = val.lookupTableName;
    this.selectedName = val.name;
    this.openLookupForm();
  }
  async openLookupForm() {

    let mapDataArray = this.mapData ? this.mapData[this.selectedName] ? this.mapData[this.selectedName] : [] : [];

    if (this.lookupSeelcted) {
      let tableName = this.lookupSeelcted;

      if (this.tableDataForForms && this.tableDataForForms.length) {
        this.tableDataForForms.forEach(ele => {
          if (ele && ele.columns && ele.columns.length) {
            this.getRecordType(Object.assign([], ele.columns), ele.tableName);
          } else {
            this.tableRecordTypes[ele.tableName] = [];
          }
        });
      }

      let val: any = await this.formService.openAndCloseForm(null, tableName, this.tableDataForForms, false, this.recordType,
        null, null, null, null, null, mapDataArray);

      const ref = this.dialogService.open(val.formComp, {
        closeOnEsc: false,
        context: val.formObj,
      }).onClose.subscribe((name) => {

        if (name && name.close == 'yes') {
          console.log(name.data);
          let refLookupIdFields = [];
          let refLookupField = this.lookupArray.filter(f => f.name == this.selectedName);
          if (refLookupField && refLookupField.length) {
            let refLookTable = refLookupField[0].lookupTableName;
            let response = this.tableDataForForms.filter(ele => ele.tableName == refLookTable);
            if (response && response.length) {
              refLookupIdFields = response[0].columns.map(v => v.idField ? v.name : '');
            }

          }

          let obj = {};
          obj["name"] = this.selectedName;
          obj["value"] = [];
          obj["lookupTableName"] = refLookupField[0].lookupTableName;

          let valueObj = {
            idField: [],
            others: [],
            id: null,
            val: null
          }
          let arrayForId = [];
          let arrForOther = [];
          let keys = Object.keys(name.data);
          let valueofOther = this.otherDataKeys.filter(valueELe => {
            if (valueELe.name == this.selectedName) {
              return true;
            }
          });
          if (keys) {
            keys.forEach(element => {
              if (refLookupIdFields.includes(element)) {
                if (typeof (name.data[element]) !== 'object')
                  arrayForId.push(' ' + name.data[element]);
              }
              if (valueofOther && valueofOther.length) {
                if (valueofOther[0].value.includes(element)) {
                  arrForOther.push(' ' + name.data[element]);
                }
              }
            })
          }
          valueObj.id = name.data._id;
          valueObj.idField.push(arrayForId);
          valueObj.others.push(arrForOther);
          valueObj.val = name.data;
          obj["value"].push(valueObj);
          this.finalArray.push(obj);
          this.showDataFlag = true;
          this.count++;
          this.refFlag = false;
          // this.setData(valueObj,obj);
        } else {
          this.lookupSeelcted = "1";
        }

      });
    }

  }

  getRecordType(cols, tableName) {
    let findRecordType = cols.find(x => x.type == 'recordType');
    if (typeof findRecordType != 'undefined' && findRecordType != 'undefined' && findRecordType !== null) {
      const name = findRecordType.name;
      findRecordType = findRecordType.options;
      const recordArray = [];
      findRecordType && findRecordType.forEach(element => {
        const obj = {
          title: element,
          data: {
            menu: tableName,
            name: name,
          },
        };
        recordArray.push(obj);
        if (tableName == 'Tasks') {
          this.taskRecordTypes.push(obj);
        }
      });
      this.tableRecordTypes[tableName] = recordArray;
    }
  }
}
