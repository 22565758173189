import {
  ExtraOptions,
  RouterModule,
  Routes,
  CanActivate,
} from "@angular/router";
import { NgModule } from "@angular/core";
import {
  AuthGuard,
  checkSynccosGuard,
  CompanyRegistrationGuard,
} from "./helpers/auth.guard";
import { TableService } from "./shared/services/table.service";
import { RedirectGuard } from "./helpers/redirect.guard";
import { PrivacyPolicyComponent } from "./pages/privacy-policy/privacy-policy.component";
import { TermsAndConditionsComponent } from "./pages/terms-and-conditions/terms-and-conditions.component";

export const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    loadChildren: () =>
      import("./pages/home/home.module").then((m) => m.HomeModule),
    canActivate: [CompanyRegistrationGuard],
  },
  {
    path: "synccos",
    loadChildren: () =>
      import("./pages/registrations/registrations.module").then(
        (m) => m.RegistrationsModule
      ),
    canActivate: [CompanyRegistrationGuard],
  },
  {
    path: "payment",
    loadChildren: () =>
      import("./pages/payments/payments.module").then((m) => m.PaymentsModule),
    canActivate: [CompanyRegistrationGuard],
  },
  {
    path: "road-map",
    loadChildren: () =>
      import("./pages/road-map/road-map.module").then((m) => m.RoadMapModule),
  },
  {
    path: "features",
    loadChildren: () =>
      import("./pages/features/features.module").then((m) => m.FeaturesModule),
  },
  {
    path: "terms-and-conditions",
    component: TermsAndConditionsComponent,
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
  },
  {
    path: "contact-us",
    loadChildren: () =>
      import("./pages/contact-us/contact-us.module").then(
        (m) => m.ContactUsModule
      ),
  },
  {
    path: "pages",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
    canActivate: [AuthGuard],
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./pages/auth/auth.module").then((m) => m.AuthModule),
    canActivate: [checkSynccosGuard],
  },
  { path: "**", pathMatch: "full", children: [], canActivate: [RedirectGuard] },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private tableService: TableService) {}
}
