import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'CustomNumber' })
export class CustomNumber implements PipeTransform {

  constructor(private _decimalPipe: DecimalPipe) {

  }

  transform(input) {

    if (typeof input === 'number') {
      return this._decimalPipe.transform(input);
    } else {
      return input;
    }
  }
}
