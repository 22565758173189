import { Component, Input, OnInit, Optional } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-one-field',
  templateUrl: './one-field.component.html',
  styleUrls: ['./one-field.component.scss']
})
export class OneFieldComponent implements OnInit {

  @Input() field;
  @Input() dataToPassInForm;
  @Input() showEditFieldForInlineEdit;
  @Input() lookupDataObject;
  @Input() forkJoinLookupResponse;
  @Input() formColumns;
  @Input() tableDataFromViewToForm;
  @Input() id;
  @Input() filteredOptions;
  @Input() tableName;
  @Input() oneTime;

  openFlag = false;
  constructor(@Optional() public ref: NbDialogRef<OneFieldComponent>) { }

  ngOnInit(): void {

    if(this.dataToPassInForm){
      this.openFlag = true;
    }
  }

  closeOneField(){
    this.ref.close(this.field);
  }

  close() {
    this.ref.close(false);
  }

}
