import { DatePipe, Location } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmDialogComponent } from "@app/shared/components/confirm-dialog/confirm-dialog.component";
import { DeleteDialogComponent } from "@app/shared/components/delete-dialog/delete-dialog.component";
import { InfoDialogComponent } from "@app/shared/components/dialog/info-dialog/info-dialog.component";
import { DynamicFormDialogNewDesignComponent } from "@app/shared/components/dynamic-form-dialog-new-design/dynamic-form-dialog-new-design.component";
import { NewReminderModalComponent } from "@app/shared/components/new-reminder-modal/new-reminder-modal.component";
import { DynamicFormCommonService } from "@app/shared/dynamic-form-common.service";
import { PhonePipe } from "@app/shared/pipes/phone.pipe";
import { EventEmiterService } from "@app/shared/services/event-emiter.service";
import { MessageService } from "@app/shared/services/message.service";
import { TableService } from "@app/shared/services/table.service";
import { environment } from "@env/environment";
import {
  NbDialogService,
  NbMenuService,
  NbSidebarService,
  NbToastrService
} from "@nebular/theme";
import { FilePreviewDialogComponent } from "@shared/components/file-preview-dialog/file-preview-dialog.component";
import { iconList } from "@shared/iconData/iconList";
import { jsPDF } from "jspdf";
import { cloneDeep, filter, orderBy } from "lodash";
import { NgPluralizeService } from "ng-pluralize";
import { NgxLinkifyjsService } from "ngx-linkifyjs";
import { forkJoin, Subject, Subscription } from "rxjs";
import { ChatSubscriptionService } from "./../../../shared/services/chat-subscription.service";
import { HelperService } from "./../../../shared/services/helper.service";

@Component({
  selector: "ngx-dynamic-table-view",
  templateUrl: "./dynamic-table-view.component.html",
  styleUrls: ["./dynamic-table-view.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DynamicTableViewComponent
  implements OnInit, OnDestroy, AfterViewInit {
  showEditFieldForInlineEdit = {};
  showEditIconForField = {};
  showSaveIconForField = {};
  showCancelIconForField = {};

  idFieldValue: any = "";
  idValues: any = "";
  tableId;
  page = 0;
  tableName;
  tabTitle = "";
  clientData;
  loading = false;
  id;
  singularTableName;
  breadcrumbs = [];
  orderedArray = [];
  profileData = [];
  status = "";
  profileImage;
  profileImagePath = "assets/images/cv-placeholder.png";
  profileImageColor = "";
  showProfileImage = true;
  profileImageData;
  upLoadingImage = false;
  inputFields: string[] = ["password", "number", "text", "email", "currency"];
  doNotRenderFields: string[] = ["_id", "__v", "isActive", "file"];
  tempParentTableHeader = [];
  tempParentTableHeaders = [];
  tableColumnsForView = {};
  parentTableHeader = [];
  documentTemplateList = [];
  recordTypeFieldName: string = "";
  parentTableData = [];
  taskColumns = [];
  canAddNewRecord = true;
  enableEdit: boolean = false;
  singularTab: any;
  droppedFilesOuter: {};
  editRecordType: boolean = false;
  hideInfo: boolean = false;
  isActive: boolean;
  onDragData: any;
  droppedFiles: string[] = [];
  uploadProgress = 0;
  fileFormData: any = [];
  showArrayData: {};
  taskData: any;
  recordTypes = [];
  recordType: string = "";
  primaryLink: string = null;
  actionSubscription: Subscription;
  taskRecordTypes = [];
  taskRecordType: string = null;
  relatedTableRecordTypes = [];
  isActiveTable = "";
  tableRecordTypes = [];
  pageLoaded = false;
  isDocumentTemplate = false;
  includeTasks = false;
  private routeSub: any;
  tableInfo: any;
  chatOpened = false;
  groupChatTitle = "";
  startPage: Number;
  paginationLimit: any;
  showLess: boolean;
  showMore: boolean;
  lookupRelateData: any = [];
  dataForLookupDetail;
  lookupWithTaskData: [];
  firstTimeRender = true;
  pageRefreshed = true;
  tutorial = "";
  messageCount;
  subscribers = [];
  currentUser = null;
  @Input() uiColor = "#598bff";
  isWatcherOpened = false;
  isSelfSubscribed = false;
  subscriptionText = "Start watching the issue";
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  childResourceId = "";
  statusColorArray = [];
  lookupDataCompiled = false;

  actionItems = [
    { icon: { icon: "python", pack: "edit" } },
    { icon: { icon: "python", pack: "view" } },
    { icon: { icon: "python", pack: "delete" } },
  ];
  statusOption;
  isPopupOpen = false;
  tableData;
  loadChat = false;
  isTabClick = false;
  parentMessage = true;
  formColumns: any;
  form: any = {};
  isDraggedFromParent = false;
  openTasked = [];
  subFormLookupIds: any;
  recordGadgets;
  customValidations;
  showChats;
  lookupData: any = {};
  public actions = [];
  activeAction = [];
  public actionsMenu = [];
  actionsTriggerSubscription: Subscription;
  private destroy$: Subject<void> = new Subject<void>();
  pager: any = {};
  isTodoFlag: boolean = false;
  refreshPageActive = "";
  filterFields: string[] = [
    "autoNumber",
    "file",
    "area",
    "richTextArea",
    "currency",
  ];
  taskedRecordsOnly = false;
  checkboxArray = [];
  showFilterBox = {};
  hideColumn = {};
  filterHide = {};
  filterKey = [];
  searchString: string = null;
  sortObject: any = null;
  showAutocomplete = {};
  canShowClearFilter = {};
  lookupValue = {};
  filteredOptions = {};
  textValue = {};
  model = {};
  isActionRunning = false;
  showNotesTab: boolean = false;
  pageUrlReset: boolean = false;
  relatedFieldObject = {};
  rollUpFields = [];
  selectedListCardId;
  tableDataFromViewToForm;
  tableIcon = "";
  formHeight;
  formWidth;
  pageLayout = [];
  fieldAlignment;
  customLabelForAddForm = "";
  customLabelForEditForm = "";
  oldData;
  newData;
  parentTableIcon;
  getTableByNameObject = {};
  gradientColor = "";
  systemConfigColor = "";
  allFilters = [];
  checkOverviewTab = false;
  provideFiles: boolean;
  assignedToDetails = [];
  getSystemConfigVariable;
  isTabScollable: boolean = false;
  @Input() currentTableData: any = {};
  @HostListener("wheel", ["$event"])
  public onScroll(event: WheelEvent) {
    let tabScroll = (<HTMLElement>this.elem.nativeElement).querySelector(
      ".tabset"
    );
    if (tabScroll) {
      tabScroll.scrollLeft += event.deltaY;
    }
  }
  mouseDown = false;
  startX: any;
  scrollLeft: any;
  ejs;
  constructor(
    private chatSubscriptionService: ChatSubscriptionService,
    private route: ActivatedRoute,
    private dialogService: NbDialogService,
    private tableService: TableService,
    private service: NgPluralizeService,
    private helperService: HelperService,
    private toastrService: NbToastrService,
    private nbMenuService: NbMenuService,
    private datePipe: DatePipe,
    private router: Router,
    public linkifyService: NgxLinkifyjsService,
    private changeDetector: ChangeDetectorRef,
    private location: Location,
    private sidebarService: NbSidebarService,
    private messageService: MessageService,
    private title: Title,
    private sanitizer: DomSanitizer,
    private elem: ElementRef,
    public eventEmiter: EventEmiterService,
    public formService: DynamicFormCommonService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem("userData"));

    this.actionsTriggerSubscription = this.nbMenuService
      .onItemClick()
      .subscribe((event) => {
        if (event) this.onActionsClick(event);
      });
  }

  checkFlag = false;
  ngOnInit(): void {
    const val = document.getElementById("main_body");
    val.classList.add("fixed-header-layout");

    this.eventEmiter.isDynamicTableView = true;
    this.sidebarService.compact("menu-sidebar");
    this.tableService.getTablesForMenu().subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.tableDataFromViewToForm = res.data;
      }
    });

    this.routeSub = this.route.params.subscribe(async (params) => {
      if (Object.keys(params).length) {
        this.id = params.id;
        const page = this.route.snapshot.queryParamMap.get("page");
        this.page = (page && Number(page)) || 0;
        this.tableId = params.tableId;
        this.tableName = params.tableName;
        this.tableName = decodeURI(this.tableName);
        this.urlTitle();

        if (params.tabTitle) {
          this.tabTitle = params.tabTitle;
          this.refreshPageActive = params.tabTitle;
          this.showOverview = false;
          this.checkOverviewTab = false;
        } else {
          this.checkOverviewTab = true;
        }

        this.pageLoaded = false;
        this.dataToPassInForm = {};
        this.dataFetched = false;
        this.pageUrlReset = true;

        if (this.tableName.endsWith("s" || "S")) {
          this.singularTableName = this.service.singularize(this.tableName);
        } else {
          this.singularTableName = this.tableName;
        }

        this.overview = {};

        if (this.id) {
          this.getTableByName(this.tableName, true);
          // this.getTableData();
        }

        if (params.childResourceId) {
          this.childResourceId = params.childResourceId;
        }
      }

      this.getTableRecords();
      this.startPage = 0;
      this.paginationLimit = 5;
      this.showMore = true;
      this.isActiveTab(0, "To Do");
      // this.loadTutorial();
    });

    this.tableService.gradientColor.subscribe((color: any) => {
      this.gradientColor = color;
    });

    this.tableService.iconColors.subscribe((color: any) => {
      this.systemConfigColor = color;
    });

    const grediant = document.getElementById("grediant-title");
    grediant.style.background = `linear-gradient(90deg, ${this.systemConfigColor} 0%, ${this.gradientColor} 100%)`;

    const element = document.getElementById("main_body");

    // if (this.tabTitle != 'Overview' && this.tabTitle != 'Chat & Activity') {
    element.classList.add("body_scrollable");
    //}

    this.elem.nativeElement.style.setProperty(
      "--text-color",
      this.systemConfigColor
    );
    if (this.eventEmiter.idField.length == 0) {
      let idFieldArray = JSON.parse(localStorage.getItem("IdFields"));
      this.eventEmiter.idField = idFieldArray;
    }

    this.tableService.refreshViewTree.subscribe((res) => {
      if (res) {
        this.refreshData(true);
      }
    });
  }

  async urlTitle() {
    let res: any;
    if (
      this.currentTableData &&
      this.currentTableData.data[0].tableName == this.tableName
    ) {
      res = this.currentTableData;
    } else {
      res = await this.tableService.getTableByName(this.tableName);
    }

    if (res) {
      const idField =
        res.data &&
        res.data.length &&
        res.data[0].columns.find((f) => f.idField)?.name;
      this.tableService.currentIdField = idField;
      if (this.getSystemConfigVariable) {
        setTimeout(() => {
          this.title.setTitle(
            this.tableName
              .toLowerCase()
              .replace(/\b(\w)/g, (s) => s.toUpperCase()) +
            " - " +
            this.getSystemConfigVariable["Company Name"]
          );
        }, 2000);
      } else {
        setTimeout(() => {
          this.tableService.$systemConfig.subscribe((configs) => {
            this.title.setTitle(
              this.tableName
                .toLowerCase()
                .replace(/\b(\w)/g, (s) => s.toUpperCase()) +
              " - " +
              configs["Company Name"]
            );
          });
        }, 2000);
      }
    }
  }

  ngAfterViewInit() {
    this.tabScrollDetect();

    setTimeout(() => {
      this.tableService.runCustomStyling();
      // this.changeDetector.detectChanges();
    }, 10);
  }

  tabs;
  clickTab(val) {
    this.tabs = document.querySelectorAll(".tab");

    this.tabs.forEach((tab) => {
      if (tab.innerHTML.includes(val)) {
        tab.click();
      }
    });
  }

  async openForm(status, Data?, actionListObj?) {
    let url = this.location.path();

    if (!Data) {
      this.location.replaceState(
        "pages/tables/" + actionListObj.relatedTable + "/" + "new"
      );
    }

    let parentObj = {
      parentTableName: this.tableName,
      parentTableData: this.clientData,
      lookUpNameId: this.id,
      lookUpName: this.tableName.slice(0, -1).toLowerCase(),
    };

    let val: any = await this.formService.openAndCloseForm(
      null,
      actionListObj.relatedTable,
      this.tableDataFromViewToForm,
      false,
      null,
      actionListObj.actionType == "Open New Record" ? true : false,
      actionListObj.actionType == "Open New Record" ? parentObj : null,
      null,
      null,
      null,
      null,
      null,
      status?.label
    );

    const ref = this.dialogService
      .open(val.formComp, {
        closeOnEsc: false,
        context: val.formObj,
      })
      .onClose.subscribe((res) => {
        this.location.replaceState(url);
      });
  }

  async markComplete(action) {
    if (action.actionsList && action.actionsList.length) {
      let actionType = null;
      if (action.actionsList !== undefined) {
        action.actionsList.filter((item) => {
          if (item.actionType === "Preview Doc") {
            actionType = item;
          }
        });
      }
      if (actionType && actionType.document != "") {
        // actionType["skuCode"] = this.clientData.skuCode;
        // actionType["actionName"] = action.actionName;
        await this.viewDocumentPDF(actionType);
      } else {
        let obj = {
          item: action,
        };
        this.onActionsClick(obj, this.clientData, this.clientData);
      }
    } else {
      if (action.actionType == "Preview Doc" && action.document != "") {
        // action["skuCode"] = this.clientData.skuCode;
        // action["actionName"] = action.actionName;
        await this.viewDocumentPDF(action);
      } else {
        let obj = {
          item: action,
        };
        this.onActionsClick(obj, this.clientData, this.clientData);
      }
    }
  }

  getHeaders(value) {
    return value[0];
  }

  testLinkify(item) {
    try {
      this.linkifyService.test(item);
      return true;
    } catch (_e) {
      return false;
    }
  }

  getUrl(item, column) {
    if (
      !(typeof item == "number") &&
      column.type != "number" &&
      column.type != "richTextArea" &&
      this.testLinkify(item)
    ) {
      return this.linkifyService.linkify(item);
    } else {
      let fractionPoint = column.fraction;
      if (item > 0) {
        if (fractionPoint > 0 || (fractionPoint === 0 && column.isCurrency)) {
          if (column.isCurrency) {
            return "$ " + Number(item).toFixed(fractionPoint);
          } else {
            return Number(item).toFixed(fractionPoint);
          }
        } else {
          if (column.isCurrency) {
            if (!(Number(item) % 1 != 0)) {
              fractionPoint = 2;
              return "$ " + Number(item).toFixed(fractionPoint);
            }
          } else {
            if (!(Number(item) % 1 != 0)) {
              fractionPoint =
                column.type === "autoNumber" ? 0 : fractionPoint === 0 ? 0 : 2;
              if (fractionPoint === 0) {
                return item;
              }
              return Number(item).toFixed(fractionPoint);
            }
          }
        }
      } else {
        return item;
      }
    }
  }

  loadTutorial() {
    const query = `tutorialFor=Record&tableName=${this.tableName}`;
    this.tableService.getTutorials(query).subscribe((res: any) => {
      if (res.data) {
        this.tutorial = res.data;
      }
    });
  }

  setTableInfo() {
    let clientName = "";
    if (this.clientData && this.clientData.name) {
      clientName = this.clientData.name;
    }
    this.tableInfo = {
      resourceId: this.id,
      tableId: this.tableId,
      tableName: this.tableName,
      resourceName: this.groupChatTitle,
    };
    this.loadSubscribers();
  }

  getTableRecords() {
    const that = this;
    this.actionSubscription = this.nbMenuService
      .onItemClick()
      .subscribe(function (event) {
        if (
          event &&
          event.item &&
          event.item.data &&
          that.tableRecordTypes[event.item.data.menu]
        ) {
          that.tableRecordTypes[event.item.data.menu].forEach(function (rc) {
            if (rc.title == event.item.title) {
              that.taskRecordType = rc.title;
              // that.addNewLookUp(event.item.data);
            }
          });
        }
      });
  }

  isActiveChatTab() {
    return false;
  }

  onActionsClickNew(event, oldVlaue?, newVlaue?, actionObj?, callback?) {
    let action1;
    if (actionObj) {
      action1 = actionObj;
    } else {
      if (event.item.actionName) {
        action1 = this.actions.find(
          (f) => f.actionName == event.item.actionName.toString()
        );
      }
    }
    if (!action1) return;

    let $Table = oldVlaue ? oldVlaue : null;
    let $Form = newVlaue ? newVlaue : null;
    let $Token = localStorage.getItem("userToken");
    let $api = `${environment.apiUrl}`;

    try {

      if (!eval(action1.displayCondition)) {
        if (action1.onSave == "yes") {
          if (callback) {
            callback();
          }
          return;
        }

        let notAvilableMessage = "This action is not available on this record";
        let notAvilableMessageColr = "";

        if (Array.isArray(action1.actionNotAvailable)) {
          action1.actionNotAvailable.forEach((action) => {
            if (eval(action.condition)) {
              notAvilableMessage = action.message;
              notAvilableMessageColr = action.color;
            }
          });
        } else {
          notAvilableMessage = action1.actionNotAvailable;
        }

        this.dialogService.open(InfoDialogComponent, {
          context: {
            text: notAvilableMessage,
            title: "Action not available",
          },
        });
        if (callback) {
          callback();
        }
        return;
      }

      if (action1.warningMessage) {
        this.dialogService
          .open(ConfirmDialogComponent, {
            context: {
              actionObj: action1,
              title: action1.actionName,
              warningMessage: action1.warningMessage,
              text: "",
              actionFlag: true,
              warningColor: action1.warningMessageColor,
            },
          })
          .onClose.subscribe((column) => {
            if (column) {
              this.triggerAction(action1, oldVlaue, newVlaue);
            }
          });
      } else {
        this.triggerAction(action1, oldVlaue, newVlaue);
      }

    } catch (e) {

    }


    if (callback) {
      callback();
    }
  }

  async triggerAction(action1, oldVlaue?, newVlaue?) {
    this.loading = true;
    let $Table = oldVlaue ? oldVlaue : null;
    let $Form = newVlaue ? newVlaue : null;
    let $Token = localStorage.getItem("userToken");
    let $api = `${environment.apiUrl}`;

    if (action1.actionsList && action1.actionsList) {
      action1.actionsList.forEach(async (element) => {
        let URL = "";

        if (element.actionType == "Email Template") {
          this.tableService
            .getDynamicTreeDataById(this.tableName, this.id, action1.actionName)
            .subscribe();
        } else if (element.actionType == "Open New Record") {
          this.openForm(null, null, element);
        } else if (element.actionType == "Update Current Record") {
          let tempObject = JSON.parse(element.jsonEditor);
          this.tableService
            .updateDynamicFormData(
              this.id,
              this.tableName,
              tempObject,
              this.tableId
            )
            .subscribe((res: any) => {
              if (res.statusCode == 200) {
                this.toastrService.success("Done", "Updated Successfully!");
              }
            });
        } else if (element.actionType == "Craete Related Record") {
          this.checkFlag = false;
          let $Table = this.clientData;

          if ($Table.lookup && $Table.lookup.length) {
            $Table.lookup.forEach(element => {
              $Table[element.lookupName ? element.lookupName : element.lookupDataName] = element.lookupId;
            });
          }

          while (element.jsonEditorForRelatedRecord.includes('$Table.')) {

            let wordIndex = element.jsonEditorForRelatedRecord.split('$Table.');
            let word = '';
            if (wordIndex && wordIndex.length > 1) {
              let wordCutted = wordIndex[1].substring(0, wordIndex[1].indexOf('"'));
              let wordToReplace = $Table[wordCutted];

              element.jsonEditorForRelatedRecord = element.jsonEditorForRelatedRecord.replace('$Table.' + wordCutted, wordToReplace);

            }
          }

          let tempObject = this.ejsPackage.render(
            element.jsonEditorForRelatedRecord,
            { $Table: $Table }
          );
          tempObject = JSON.parse(tempObject);

          let table = this.tableDataFromViewToForm.find(
            (v) => v.tableName == element.relatedTable
          );
          let id = null;
          let lookups = [];
          let reqLookup = null;
          if (table) {
            id = table._id;
            lookups = table.columns.filter(
              (v: any) =>
                v.type == "lookup" && v.lookupTableName == this.tableName
            );

            let obj = {
              lookupTableName: this.tableName,
              lookupId: this.id,
              lookupName: lookups[0].name,
            };

            if (
              tempObject &&
              tempObject["lookup"] &&
              tempObject["lookup"].length
            ) {
              tempObject["lookup"].push(obj);
            } else {
              tempObject["lookup"] = [];
              tempObject["lookup"].push(obj);
            }

            let keys = Object.keys(tempObject);
            if (keys && keys.length) {
              keys.forEach(val => {
                let find = this.form[this.tableName].find(it => it.name == val);
                if (find && find.type == 'lookup') {
                  if (tempObject[val]) {

                    let lookup = table.columns.find(
                      (v: any) =>
                        v.type == "lookup" && v.name == val
                    );

                    if (lookup) {
                      let obj = {
                        lookupTableName: lookup.lookupTableName,
                        lookupId: tempObject[val],
                        lookupName: val,
                      };

                      tempObject['lookup'].push(obj);
                    }


                  }

                  delete tempObject[val];
                }
              })
            }
          }

          tempObject["lookup"] = tempObject["lookup"].map((ele) => ({
            lookupId: ele.lookupId,
            lookupName: ele.lookupDataName ? ele.lookupDataName : ele.lookupName,
            lookupTableName: ele.lookupTableName,
          }));

          this.tableService
            .saveDynamicFormData(tempObject, element.relatedTable, id)
            .subscribe((res: any) => {
              if (res.statusCode == 201) {
                this.toastrService.success("Done", "Created Successfully!");
                this.checkFlag = true;
              } else {
                this.checkFlag = true;
              }
            });
          console.log(tempObject);
        } else if (element.actionType == "Update Lookup Record") {

          if (element.jsonEditorForLookupRelatedRecord && element.jsonEditorForLookupRelatedRecord.length) {

            this.checkFlag = false;
            for (let item of element.jsonEditorForLookupRelatedRecord) {


              let $Table = this.clientData;

              if ($Table.lookup && $Table.lookup.length) {
                $Table.lookup.forEach(element => {
                  $Table[element.lookupName ? element.lookupName : element.lookupDataName] = element.lookupId;
                });
              }

              while (item.jsonEditorForLookupRelatedRecord.includes('$Table.')) {

                let wordIndex = item.jsonEditorForLookupRelatedRecord.split('$Table.');
                let word = '';
                if (wordIndex && wordIndex.length > 1) {
                  let wordCutted = wordIndex[1].substring(0, wordIndex[1].indexOf('"'));
                  let wordToReplace = $Table[wordCutted];

                  item.jsonEditorForLookupRelatedRecord = item.jsonEditorForLookupRelatedRecord.replace('$Table.' + wordCutted, wordToReplace);

                }
              }

              let tempObject = this.ejsPackage.render(
                item.jsonEditorForLookupRelatedRecord,
                { $Table: $Table }
              );
              tempObject = JSON.parse(tempObject);

              let table = this.tableDataFromViewToForm.find(v => v.tableName == item.lookupTableName);
              let id = null;
              if (table) {
                id = table._id;
              }

              tempObject['lookup'] = [];
              let keys = Object.keys(tempObject);
              if (keys && keys.length) {
                keys.forEach(val => {
                  let find = this.form[this.tableName].find(it => it.name == val);
                  if (find && find.type == 'lookup') {
                    if (tempObject[val]) {

                      let lookup = table.columns.find(
                        (v: any) =>
                          v.type == "lookup" && v.name == val
                      );

                      if (lookup) {
                        let obj = {
                          lookupTableName: lookup.lookupTableName,
                          lookupId: tempObject[val],
                          lookupName: val,
                        };

                        tempObject['lookup'].push(obj);
                      }
                    }
                    let temp = [];
                    temp = this.forkJoinLookupResponse[tempObject[val]]?.data?.lookup;
                    tempObject['lookup'] = [...tempObject['lookup'], ...temp];
                    delete tempObject[val];
                  }
                })
              }

              tempObject["lookup"] = tempObject["lookup"].map((ele) => ({
                lookupId: ele.lookupId,
                lookupName: ele.lookupDataName ? ele.lookupDataName : ele.lookupName,
                lookupTableName: ele.lookupTableName,
              }));

              let ids = [];

              if (this.clientData) {

                if (this.clientData.lookup && this.clientData.lookup.length) {
                  ids = this.clientData.lookup.filter(v => v.lookupTableName == item.lookupTableName).map(a => a.lookupId);
                }
              }
              for (let val of ids) {

                let res: any = await this.tableService.updateDynamicFormDataWithPromise(val, item.lookupTableName, tempObject, id);
                if (res.statusCode == 200) {
                  this.toastrService.success("Done", "Updated Successfully!");
                }

              }



            }

            this.reload();

            this.checkFlag = true;

          }
        } else {
          URL = eval(element.actionUrl);

          //-- Perform Action
          this.tableService.actionPerform(URL).subscribe((res) => {
            if (res) {
            }
            (error) => { };
          });
        }
      });
    } else {
      let URL = "";

      if (action1.actionType == "Email Template") {
        this.tableService
          .getDynamicTreeDataById(this.tableName, this.id, action1.actionName)
          .subscribe();
      } else {
        URL = eval(action1.actionUrl);

        //-- Perform Action
        this.tableService.actionPerform(URL).subscribe((res) => {
          if (res) {
          }
          (error) => { };
        });
      }
    }
    //-- Set timeout according to waitTime field
    setTimeout(() => {
      //-- Refresh page..

      // this.refreshData(newVlaue);

      let $Table = oldVlaue ? oldVlaue : null;
      let $Form = newVlaue ? newVlaue : null;

      if (action1.messageList && action1.messageList.length > 0) {
        for (let k = 0; k < action1.messageList.length; k++) {
          if (eval(action1.messageList[k].condition)) {
            if (action1.messageList[k].message) {
              this.toastrService.show(
                action1.messageList[k].message,
                action1.actionName,
                { status: action1.messageList[k].color.toLowerCase() }
              );
            }
          }
        }
      }
      this.loading = false;
    }, action1.waitTime);
  }

  onActionsClick(event, oldVlaue?, newVlaue?, actionObj?, callback?) {
    let action1;
    if (actionObj) {
      action1 = actionObj;
    } else {
      if (event.item.actionName) {
        action1 = this.actions.find(
          (f) => f.actionName == event.item.actionName.toString()
        );
      }
    }

    if (!action1) return;

    let $Table = oldVlaue ? oldVlaue : null;
    let $Form = newVlaue ? newVlaue : null;
    let $Token = localStorage.getItem("userToken");
    let $api = `${environment.apiUrl}`;

    try {

      if (!eval(action1.displayCondition)) {
        if (action1.onSave == "yes") {
          if (callback) {
            callback();
          }
          return;
        }

        let notAvilableMessage = "This action is not available on this record";
        let notAvilableMessageColr = "";

        if (Array.isArray(action1.actionNotAvailable)) {
          action1.actionNotAvailable.forEach((action) => {
            if (eval(action.condition)) {
              notAvilableMessage = action.message;
              notAvilableMessageColr = action.color;
            }
          });
        } else {
          notAvilableMessage = action1.actionNotAvailable;
        }

        this.dialogService.open(InfoDialogComponent, {
          context: {
            text: notAvilableMessage,
            title: "Action not available",
          },
        });
        if (callback) {
          callback();
        }
        return;
      }

      if (action1.warningMessage) {
        this.dialogService
          .open(ConfirmDialogComponent, {
            context: {
              actionObj: action1,
              title: action1.actionName,
              warningMessage: action1.warningMessage,
              text: "",
              actionFlag: true,
              warningColor: action1.warningMessageColor,
            },
          })
          .onClose.subscribe((column) => {
            if (column) {
              this.triggerAction(action1, oldVlaue, newVlaue);
            }
          });
      } else {
        this.triggerAction(action1, oldVlaue, newVlaue);
      }

    } catch (e) {

    }

    if (callback) {
      callback();
    }
  }

  async viewDocumentPDF(action) {
    await this.tableService
      .viewDocumentPDF(this.tableName, this.clientData, action.document)
      .subscribe((resd: any) => {
        if (resd.statusCode === 200) {
          this.tableService.getAllDocumentsEvents(this.tableId).subscribe(
            (res: any) => {
              if (res.statusCode === 200) {
                let docList = res["data"].find(
                  (x) => x._id === action.document
                );
                if (docList) {
                  // docList["skuCode"] = action.skuCode;
                  // docList["actionName"] = action.actionName;
                  this.viewDocument(docList, resd.data.data);
                }
              }
            },
            (error) => { }
          );
        } else {
          this.toastrService.danger(`${this.tableName} is not assigned!`);
        }
      });
  }

  showBreadCrump = false;
  setFlag() {
    let elementOfScroll = document.getElementById("content-tag");
    if (elementOfScroll) {
      if (
        elementOfScroll.offsetHeight + elementOfScroll.scrollTop >=
        elementOfScroll.scrollHeight - 30
      ) {
        this.showBreadCrump = false;
      } else {
        const ele = document.getElementById("tab-set");
        if (ele && ele.clientHeight < 600) {
          this.showBreadCrump = false;
        } else {
          this.showBreadCrump = true;
        }
      }

      const ele = document.getElementById("bread");
      if (ele) {
        ele.style.setProperty(
          "bottom",
          elementOfScroll.clientHeight +
          30 -
          elementOfScroll.clientHeight +
          "px",
          "important"
        );
      }

      this.tableService.runCustomStyling();
      this.changeDetector.detectChanges();
    }
  }

  @HostListener("window:scroll", ["$event"]) onWindowScroll(e) {
    this.setFlag();
  }

  viewDocument(col, html_body) {

    const doc: any = new jsPDF("p", "pt", "a4");
    // if (col.actionName == "Barcode") {
    //   JsBarcode("#barcodeImage", col.skuCode);
    //   let img = document.getElementById("barcodeImage") as HTMLImageElement | null;
    //   doc.setFontSize(40);
    //   doc.addImage(img.src, "JPEG", 15, 40, 180, 160);
    // }

    doc.html(html_body, {
      filename: decodeURI(col.event.name),
      callback: (doc) => {
        const fileDialog = this.dialogService.open(FilePreviewDialogComponent, {
          context: {
            Data: doc.output("bloburi"),
            Ext: "bloblUrl",
            filename: decodeURI(col.event.name),
          },
        });

        const formData = new FormData();
        formData.append(
          "file",
          doc.output("blob"),
          decodeURI(col.event.name) + ".pdf"
        );

        this.tableService.formFileUpload(formData).subscribe((resp: any) => {
          if (resp && resp.body && resp.body.data && resp.body.data[0]) {
            fileDialog.componentRef.instance.saveTo.subscribe(
              async (data: any) => {
                let tableres: any = await this.tableService.getTableByName(
                  "Files"
                );

                if (tableres && tableres.data && tableres.data.length) {
                  const tempParentTableHeader = Object.assign(
                    [],
                    tableres.data[0].columns
                  );
                  const fileType = tableres.data[0].columns.find(
                    ({ type }) => type === "file"
                  );
                  let type = "file";
                  if (fileType && fileType.name) {
                    type = fileType.name;
                  }
                  const res = {
                    data: {
                      relatedTo: data,
                      lookup: [],
                      [type]: [],
                    },
                  };
                  res.data[type].push(resp.body.data[0]);
                  const ref = this.dialogService
                    .open(DynamicFormDialogNewDesignComponent, {
                      closeOnEsc: false,
                      context: {
                        title: "Add New File",
                        //headerDetail: this.headerObj,
                        isForceSave: true,
                        subFormLookupIds: "",
                        form: tempParentTableHeader,
                        button: { text: "Save" },
                        tableName: "Files",
                        Data: res.data,
                        recordType: "",
                        recordTypeFieldName: "",
                        action: "Add",
                        // allow_conditional_commentsmainTableData: [],
                        tableRecordTypes: [],
                        tableIcon: this.tableIcon,
                        actions: this.actions,
                      },
                    })
                    .onClose.subscribe((name) => { });
                }
              }
            );
          }
        });
      },
    });
  }
  onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.isActive = true;
    console.log("Drag over");
  }
  onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.isActive = false;
    console.log("Drag leave");
  }
  onDrop(event: any, fieldName) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.droppedFilesOuter) this.droppedFilesOuter = {};
    if (!this.droppedFilesOuter[fieldName])
      this.droppedFilesOuter[fieldName] = [];
    for (let i = 0; i < event.dataTransfer.files.length; i++) {
      this.droppedFilesOuter[fieldName].push(event.dataTransfer.files[i]);
    }
    this.isActive = false;
    this.isDraggedFromParent = true;
    // this.addNewLookUp("Files");
  }

  isActiveTab(i, tableName) {
    if (this.isActiveTable.toLowerCase() == tableName.toLowerCase()) {
      return true;
    } else if (i == 0 && this.isActiveTable == "") {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.actionSubscription) {
      this.actionSubscription.unsubscribe();
    }
    if (this.routeSub) {
      this.parentMessage = false;
      this.routeSub.unsubscribe();
    }

    if (this.actionsTriggerSubscription) {
      this.actionsTriggerSubscription.unsubscribe();
    }

    const element = document.getElementById("main_body");
    element.classList.remove("body_scrollable");
    element.classList.remove("fixed-header-layout");
    this.eventEmiter.isDynamicTableView = false;
    this.eventEmiter.realData = {};
  }

  showMoreItems() {
    this.paginationLimit = Number(this.paginationLimit) + 10;
    this.showLess = true;
  }
  showLessItems() {
    this.paginationLimit = Number(this.paginationLimit) - 10;
    this.showMore = true;
  }

  toEdit(lookup, id) {
    if (!this.pageLoaded) {
      return;
    }

    const column = this.taskColumns.find((x) => x.name == lookup.key);
    if (column) {
      if (column.type == "date") {
        if (lookup.value) {
          lookup.date = new Date(lookup.value);
        } else {
          lookup.date = lookup.value;
        }
      }
      lookup["id"] = id;
      lookup["edit"] = true;
      lookup[lookup.key] = lookup.value;
      lookup["column"] = column;
      lookup["dupValue"] = lookup.value;
    }
  }

  onCancel(lookup) {
    lookup["edit"] = false;
    lookup["value"] = lookup.dupValue;
  }

  onSave(lookup) {
    const data = {};
    if (lookup.column.type != "date") {
      data[lookup.key] = lookup.value;
    } else {
      data[lookup.key] = lookup.date;
      lookup.value = this.datePipe.transform(lookup.date);
    }
    if (!lookup.value && lookup.column.isRequired) {
      this.toastrService.danger("The field is required");
    } else {
      lookup["edit"] = false;
      let table = this.tableDataFromViewToForm.find(
        (v) => v.tableName == "Tasks"
      );
      this.tableService
        .updateDynamicFormData(lookup.id, "Tasks", data, table._id)
        .subscribe((res: any) => {
          if (res.statusCode == 200) {
            this.toastrService.success(res.message, "Action was  completed!");
          }
        });
    }
  }

  isProjectable(itemName) {
    const ignoreFields = [
      "lookupTableName",
      "lookupTableId",
      "_id",
      "__v",
      "isActive",
    ];
    if (ignoreFields.includes(itemName)) {
      return false;
    }
    if (itemName.indexOf("Image") > -1) {
      return false;
    }
    return true;
  }

  editDynamicTable(stepperIndex = "") {
    if (this.formHasSections[this.tableName]) {
      this.onUpdate(this.clientData, stepperIndex);
    } else {
      this.onUpdate(this.clientData);
    }
  }

  async onUpdate(data, stepperIndex = "") {
    if (!data) {
      this.location.replaceState(
        "pages/tables/" + this.tableName + "/" + "new"
      );
    }

    let resp: any = await this.formService.openAndCloseForm(
      data,
      this.tableName,
      this.tableDataFromViewToForm,
      false,
      this.recordType,
      "",
      null,
      null,
      null,
      null,
      null,
      null,
      stepperIndex,
      null,
      null,
      null
    );

    const ref = this.dialogService
      .open(resp.formComp, {
        closeOnEsc: false,
        context: resp.formObj,
      })
      .onClose.subscribe((name) => {
        if (name) {
          if (name.close && name.close == "yes") {
            this.getAllEmailEvents(this.tableId);
            this.getRelatedLookupData(this.tableName, this.id);
            this.getTableData();
          }
        }
      });
  }

  editFromForm() {
    this.getAllEmailEvents(this.tableId);
    this.getRelatedLookupData(this.tableName, this.id);
    this.getTableData();
  }

  getAllEmailEvents(id) {
    this.tableService
      .getAllDocumentsEvents(id, this.clientData)
      .subscribe((res: any) => {
        if (res.statusCode === 200) {
          this.documentTemplateList = res["data"];
        } else {
        }
      });
  }
  dataFetched = false;
  overview = {};
  formHasSections = {};
  sections = {};
  showOverview = false;
  activePageLayout;
  isEmailField = {};
  phoneField = null;
  getTableByNameFunctionForCode(tableName, res) {
    this.loading = true;

    this.form[tableName] =
      res && res.data && res.data.length && res.data[0].columns
        ? res.data[0].columns
        : [];

    this.phoneField =
      this.form[this.tableName] && this.form[this.tableName].length
        ? this.form[this.tableName].find((v) => v.isPhone)
        : null;

    let emailField = this.form[tableName].find((v) => v.name == "email");
    if (emailField) {
      this.isEmailField[tableName] = true;
    } else {
      this.isEmailField[tableName] = false;
    }

    if (this.form[tableName] && this.form[tableName].length) {
      this.form[tableName].forEach((element) => {
        this.showEditFieldForInlineEdit[element.name] = false;
        this.showEditIconForField[element.name] = false;
        this.showSaveIconForField[element.name] = false;
        this.showCancelIconForField[element.name] = false;
      });
    }

    let data = this.form[tableName].find((v) => v.type == "gadget");
    this.assignedToField = this.form[this.tableName].find(
      (a) => a.name == "assignedTo"
    );
    this.currentGadgetValue[tableName] = data?.gadget ? data?.gadget : "";
    this.formHasSections[tableName] = this.form[tableName].filter(
      (v) => v.type == "section"
    ).length
      ? true
      : false;
    this.sections[tableName] = [];
    if (this.formHasSections[tableName]) {
      const indices = this.form[tableName]
        .map((e, k) => (e.type === "section" ? k : ""))
        .filter(String);
      let i = 0;
      this.form[tableName].forEach((data) => {
        if (data.type == "section") {
          this.sections[tableName][i] = this.form[tableName].slice(
            indices[i],
            indices[++i]
          );
        }
      });
    } else {
      let i = 0;
      let temp = this.form[tableName].filter(
        (v) => v.type !== "section" && v.type !== "injectSubForm"
      );
      temp.forEach((data, j) => {
        this.sections[tableName][j] = [];
      });

      temp.forEach((data, j) => {
        if ((j + 1) % 10 !== 0) {
          this.sections[tableName][i].push(data);
        } else {
          this.sections[tableName][i].push(data);
          i++;
        }
      });
    }

    //-- Manage Add button for lookup tabs
    if (res && res.data && res.data.length) {
      this.relatedFieldObject[tableName] =
        res &&
          res.data &&
          res.data.length &&
          res.data[0].hasOwnProperty("addRecordFromRelatedListing")
          ? res.data[0].addRecordFromRelatedListing
          : true;

      if (
        res.data[0].hasOwnProperty("showChats") &&
        tableName == this.tableName
      ) {
        this.showChats = res.data[0].showChats;
      }

      if (
        res.data[0].hasOwnProperty("provideFiles") &&
        tableName == this.tableName
      ) {
        this.provideFiles = res.data[0].provideFiles;
      }

      if (res && res.data && res.data[0] && res.data[0].isDocumentTemplate) {
        this.isDocumentTemplate = true;
        setTimeout(() => this.getAllEmailEvents(res.data[0]._id), 2000);
      }

      if (res.data[0].iconLocation) {
        this.tableIcon = res.data[0].iconLocation;
      }

      if (res.data[0].displayOverview) {
        this.overview[tableName] = res.data[0].overview;
      }

      if (this.overview[this.tableName]?.length == 0 && this.checkOverviewTab) {
        this.tabTitle = this.singularTableName + " Information";
        let url = `/pages/tables/dynamic/${this.tableId}/${this.tableName}/${this.id}`;
        this.location.replaceState(url + "/" + `${this.tabTitle}`);
      }

      if (res.data[0].customLabelForAddForm) {
        this.customLabelForAddForm = res.data[0].customLabelForAddForm;
      } else {
        this.customLabelForAddForm = "";
      }

      if (res.data[0].customLabelForEditForm) {
        this.customLabelForEditForm = res.data[0].customLabelForEditForm;
      } else {
        this.customLabelForEditForm = "";
      }
    }

    if (
      res &&
      res.data &&
      res.data[0] &&
      res.data[0].columns &&
      res.data[0].columns.length
    ) {
      if (res.data[0].tableName == this.tableName) {
        this.includeTasks = !!res.data[0].includeTasks;
        this.actions = res.data[0].actions ? res.data[0].actions : [];
        if (this.actions && this.actions.length) {
          this.activeAction = this.actions.filter((v) => v.isActive);
        }

        if (this.activeAction && this.activeAction.length) {
          this.activeAction.forEach((ele) => {
            ele["hover"] = false;
          });
        }
        this.recordGadgets = res.data[0].recordGadgets
          ? res.data[0].recordGadgets
          : [];
        this.customValidations = res.data[0].customValidations
          ? res.data[0].customValidations
          : [];
        this.formHeight = res.data[0].formHeight
          ? res.data[0].formHeight
          : null;
        this.formWidth = res.data[0].formWidth ? res.data[0].formWidth : null;
        if (this.formWidth) {
          let width = Number(this.formWidth);
          if (width < 410) {
            width = 410;
          }
          let wid: any = this.sanitizer.bypassSecurityTrustStyle(
            String(width + 350)
          );
          this.formWidth = wid.changingThisBreaksApplicationSecurity;
        }
        this.fieldAlignment = res.data[0].fieldAlignment
          ? res.data[0].fieldAlignment
          : null;
        this.subFormLookupIds = res.data[0].subFormLookups;
        this.parentTableIcon = res.data[0].iconLocation;
        this.hideInfo = res.data[0].hideInfo ? res.data[0].hideInfo : null;

        let overviewTabTitle = false;
        this.showOverview = false;
        this.pageLayout =
          res.data[0].pageLayout && res.data[0].pageLayout.length
            ? res.data[0].pageLayout
            : [];
        if (this.pageLayout && this.pageLayout.length) {
          this.pageLayout.forEach((element) => {
            if (element.layoutForDropdown == "record") {
              if (element.isActive) {
                this.activePageLayout = element;
                setTimeout(() => {
                  this.showOverview = true;
                }, 200);

                this.getAllFilters();

                if (this.checkOverviewTab) {
                  this.tabTitle = "Overview";
                  let url = `/pages/tables/dynamic/${this.tableId}/${this.tableName}/${this.id}`;
                  this.location.replaceState(url + "/" + `${this.tabTitle}`);
                  overviewTabTitle = true;
                }
              }
            }
          });
        }

        if (!overviewTabTitle && this.checkOverviewTab) {
          this.tabTitle = this.singularTableName + " Information";
          let url = `/pages/tables/dynamic/${this.tableId}/${this.tableName}/${this.id}`;
          this.location.replaceState(url + "/" + `${this.tabTitle}`);
        }

        if (this.actions && this.actions.length) {
          this.actionsMenu = [];
          this.actions.forEach((e) => {
            this.actionsMenu.push(e);
          });
        }

        this.tableColumnsForView = res.data[0].columns
          .filter(
            ({ type, displayInRelatedPageView }) =>
              type === "lookup" && displayInRelatedPageView
          )
          .reduce((t, c) => {
            return (t = {
              ...t,
              [c.lookupTableName]: {
                isLookUpList: c.isLookUpList,
              },
            });
          }, {});

        this.rollUpFields = res.data[0].columns.filter(
          (column) => column.type == "rollUp"
        );
      }

      let columns = res.data[0].columns.filter(
        ({ lookupTableName, type }) =>
          type === "lookup" && lookupTableName === this.tableName
      );
      if (columns && columns.length > 0) {
        columns = columns.reduce((t, c) => {
          return (t = {
            ...t,
            [c.tableName]: this.tableColumnsForView[c.tableName] || {
              isLookUpList: c.isLookUpList,
            },
          });
        }, {});
        this.tableColumnsForView = {
          ...this.tableColumnsForView,
          ...columns,
        };
      }

      this.tempParentTableHeader[tableName] = Object.assign(
        [],
        res.data[0].columns
      );

      this.tempParentTableHeaders[tableName] = Object.assign(
        [],
        res.data[0].columns.reduce((t, c) => {
          this.showFilterBox[c.name] = false;
          this.hideColumn[c.name] = true;
          this.filteredOptions[c.name] = [];
          if (c.options) {
            c.options.forEach((el) => {
              const temp: [] = Object.assign([], el);
              temp.shift();
              this.filteredOptions[c.name].push({
                id: el[0],
                value: temp.toString().replace(/,/g, " "),
              });
            });
          }

          return (t = {
            ...t,
            [c.label]: c,
          });
        }, {})
      );

      this.getRecordType(this.tempParentTableHeader[tableName], tableName);

      this.parentTableHeader[tableName].map((data) => (data.direction = ""));
      if (tableName == "Tasks") {
        this.taskColumns = res.data[0].columns;
      }
      this.loading = false;
    }

    this.getTableData();
  }

  async getTableByName(tableName = "Tasks", isNotRelatedTable?) {
    this.loading = true;
    this.parentTableHeader[tableName] = [];
    if (this.getTableByNameObject[tableName]) {
      let res = this.getTableByNameObject[tableName];
      this.getTableByNameFunctionForCode(tableName, res);
    } else {
      if (
        this.currentTableData &&
        this.currentTableData.data[0].tableName == tableName
      ) {
        this.getTableByNameObject[tableName] = this.currentTableData;
        this.getTableByNameFunctionForCode(tableName, this.currentTableData);
      } else {
        let res: any = await this.tableService.getTableByName(tableName);
        if (res) {
          this.getTableByNameObject[tableName] = res;
          this.getTableByNameFunctionForCode(tableName, res);
        }
      }
    }
  }

  getRecordType(cols, tableName) {
    let findRecordType = cols.find((x) => x.type == "recordType");
    if (typeof findRecordType != "undefined" && findRecordType != "undefined") {
      const name = findRecordType.name;
      findRecordType = findRecordType.options;
      const recordArray = [];
      if (findRecordType) {
        findRecordType.forEach((element) => {
          const obj = {
            title: element,
            data: {
              menu: tableName,
              name: name,
            },
          };
          recordArray.push(obj);
          if (tableName == "Tasks") {
            this.taskRecordTypes.push(obj);
          }
        });
      }
      this.tableRecordTypes[tableName] = recordArray;
    }
  }

  onSelectedFile(event: any) {
    this.upLoadingImage = true;
    const formData = new FormData();
    const file = event.target.files[0];
    formData.append("file", file);
    this.tableService.uploadProfileImage(formData).subscribe((res: any) => {
      if (res.statusCode == 201) {
        this.profileImagePath = res.data[0];
        const data = {};
        data[this.profileImageData.name] = res.data[0];
        this.tableService
          .updateDynamicFormData(this.id, this.tableName, data, this.tableId)
          .subscribe((response: any) => {
            this.upLoadingImage = false;
          });
      }
    });
  }

  async refreshData(event) {
    if (event) {
      this.lookupDataCompiled = false;
      let res: any = await this.tableService.getDynamicTreeDataByIdForPromise(
        this.tableName,
        this.id
      );
      if (res.statusCode == 200) {
        this.dataToPassInForm = res.data;
        this.clientData = res.data;
        this.changeDetector.detectChanges();
      }

      this.loading = true;
      this.tableService
        .getRelatedDataById(this.tableName, this.tableId, this.id)
        .subscribe((res: any) => {
          if (res.statusCode == 200 && res.data) {
            this.loading = false;
            this.setBreadCrumb(res.data);
          }
        });

      this.lookupDataCompiled = true;
    }
  }

  dataToPassInForm;
  imageForDropdownWithImageView;
  async getTableData() {
    this.loading = true;
    this.pageLoaded = false;
    if (!this.tableName || !this.tableId || !this.id) {
      return;
    }
    let res: any = await this.tableService.getDynamicTreeDataByIdForPromise(
      this.tableName,
      this.id
    );
    if (res.statusCode == 200) {
      this.dataToPassInForm = res.data;
      if (this.form[this.tableName] && this.form[this.tableName].length) {
        let image = this.form[this.tableName].filter(
          (v) => v.type == "dropdownWithImage"
        );
        if (image && image.length) {
          image.forEach((element) => {
            if (element.options && element.options.length) {
              element.options.forEach((ele) => {
                if (this.dataToPassInForm[element.name] == ele.title) {
                  this.imageForDropdownWithImageView = ele.image;
                }
              });
            }
          });
        }
      }
      this.lookupDetailDataLoad();
      this.dataFetched = true;

      this.idFieldValue = res.data[this.tableService.currentIdField];
      this.tableService.$systemConfig.subscribe((configs) => {
        this.getSystemConfigVariable = configs;

        let title = "";
        if (this.idFieldValue) {
          // To check type if String
          if (this.idFieldValue.length) {
            title =
              this.idFieldValue
                .toLowerCase()
                .replace(/\b(\w)/g, (s) => s && s.toUpperCase()) + " - ";
          }
        }

        this.title.setTitle(title + configs["Company Name"]);
      });
    }

    this.tableService
      .getRelatedDataById(this.tableName, this.tableId, this.id)
      .subscribe((res: any) => {
        this.lookupData = {};
        if (res.statusCode == 200 && res.data) {
          this.loading = false;
          this.setBreadCrumb(res.data);
        }
      });

    this.lookupData = {};

    // this.setBreadCrumb(this.dataToPassInForm);
    this.clientData = this.dataToPassInForm;
    this.tabs = document.querySelectorAll(".tab");
    this.getRecordGadgets();
    this.changeDetector.detectChanges();
    this.setProfileData(this.dataToPassInForm);

    //Set lookup values list
    if (this.clientData.lookup && this.clientData.lookup.length) {
      this.clientData.lookup.forEach((lookup) => {
        if (typeof lookup.lookupName !== "object") {
          if (!this.lookupData[lookup.lookupFieldName])
            this.lookupData[lookup.lookupFieldName] = [];

          this.lookupData[lookup.lookupFieldName].push(lookup);
        }
      });
    }

    if (this.clientData && this.form[this.tableName]) {
      const recordTypes = this.form[this.tableName].find(
        (x) => x.type == "recordType"
      );
      if (recordTypes != undefined && recordTypes.options.length != 0) {
        this.recordTypes = recordTypes.options;
        this.recordTypeFieldName = recordTypes.name;
        this.recordType = this.clientData[this.recordTypeFieldName];
      }
    }

    this.setProfileImage(this.clientData);

    // -- Set Group chat title
    this.setGroupChatTitle();
    this.setTableInfo();
    this.pageLoaded = true;
  }

  assignedToField;
  allUsersData = [];
  async setGroupChatTitle() {
    if (this.clientData.IDField && this.clientData.IDField.length > 0) {
      let tempName = "";
      this.clientData.IDField.map((data) => {
        if (tempName == "") {
          let field = this.form[this.tableName].find((ele) => ele.name == data);
          if (field && field.type == "lookup") {
            if (this.clientData.lookup && this.clientData.lookup.length) {
              this.clientData.lookup.forEach((element) => {
                if (element.lookupDataName == data) {
                  tempName = element.lookupName;
                }
              });
            }
          } else {
            tempName = this.clientData[data];
          }

          this.idValues = tempName;
          this.eventEmiter.dynamicID = this.idValues;
        } else {
          if (this.clientData[data]) {
            let field = this.form[this.tableName].find(
              (ele) => ele.name == data
            );
            if (field && field.type == "lookup") {
              if (this.clientData.lookup && this.clientData.lookup.length) {
                this.clientData.lookup.forEach((element) => {
                  if (element.lookupDataName == data) {
                    this.clientData[data] = element.lookupName;
                  }
                });
              }
            } else {
              this.clientData[data] = this.clientData[data];
            }

            this.idValues = `${tempName} ${this.clientData[data]}`;
            this.eventEmiter.dynamicID = this.idValues;
            if (this.clientData[data] != this.recordType) {
              tempName = `${tempName} - ${this.clientData[data]}`;
            }
          }
        }
      });

      this.groupChatTitle = this.singularTableName + " - " + tempName;

      if (this.recordType) {
        this.groupChatTitle = this.recordType + " - " + tempName;
      } else {
        this.groupChatTitle = this.singularTableName + " - " + tempName;
      }
    }

    let assignTo = [];

    if (this.clientData.lookup) {
      assignTo = this.clientData.lookup.filter(
        (v) => v.lookupDataName == "assignedTo" || v.lookupName == "assignedTo"
      );
    }

    let userRes: any = await this.tableService.getAllUsersForPromise();
    if (userRes) this.allUsersData = userRes.data;

    if (assignTo && assignTo.length) {
      this.assignedToDetails = [];
      assignTo.forEach((element) => {
        let user = this.allUsersData.find((x) => x._id == element.lookupId);
        if (user) {
          this.assignedToDetails.push({
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            status: user.status,
            userColor: user.userColor,
            id: user._id,
            value: user.firstName + " " + user.lastName,
          });
        }
      });
    } else {
      this.assignedToDetails = [];
    }
  }

  showContextMenu(tableName) {
    this.singularTab = this.service.singularize(tableName);
    if (this.tableRecordTypes && this.tableRecordTypes[tableName]) {
      return this.tableRecordTypes[tableName].length > 0;
    }
    return false;
  }

  checkURL(url) {
    if (typeof url == 'string') {
      return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
    } else {
      return false;
    }
  }

  getRelatedData() {
    this.loading = true;
    this.tableService
      .getDynamicTreeDataById(this.tableName, this.id)
      .subscribe((res: any) => {
        if (res.statusCode == 200) this.clientData = res.data;
        this.loading = false;
      });
  }

  updateRecordType() {
    const id = this.id;
    const temp = { [this.recordTypeFieldName]: this.recordType };
    this.tableService
      .updateDynamicFormData(id, this.tableName, temp, this.tableId)
      .subscribe(
        (res: any) => {
          if (res.statusCode === 200) {
            this.toastrService.success(res.message, "Success");

            this.loading = false;
          } else {
            this.loading = false;
            this.toastrService.danger(res.message, "Error");
          }
        },
        (error) => {
          this.toastrService.danger(
            `${error.error && error.error.message}`,
            "Error"
          );
          this.loading = false;
        }
      );
    this.editRecordType = false;
  }

  setProfileData(data) {
    this.profileData = [];
    this.orderedArray.forEach((ele) => {
      const dataName = data[ele.name];
      if (dataName && this.isObject(dataName)) {
        const arr = {
          label: ele.label,
          name: ele.name,
          value: data[ele.name],
          type: ele.type,
          isCurrency: ele.isCurrency,
          fraction: ele.fraction,
        };
        this.profileData.push(arr);
      }
    });
    this.getRelatedLookupData(this.tableName, this.id);
  }

  setProfileImage(data) {
    if (data && this.form[this.tableName] && this.form[this.tableName].length) {
      this.profileImageData = this.form[this.tableName].find(
        (x) => x.type == "file" && x.isProfileImage
      );
    }
    if (this.profileImageData) {
      if (
        data[this.profileImageData.name] != null &&
        data[this.profileImageData.name] !== ""
      ) {
        this.profileImagePath = data[this.profileImageData.name];
      }
    } else if (data.userColor) {
      this.profileImageColor = data.userColor;
    }
  }

  setBreadCrumb(data) {
    this.breadcrumbs = [];
    if (data && data.tableColumns) {
      this.orderedArray = this.helperService.sortObject(
        data.tableColumns,
        "displayPriority"
      );

      const workflowData = data.tableColumns.find(
        (x) => x.isWorkFlowField && x.type === "status"
      );

      if (
        workflowData &&
        workflowData.statusOptions &&
        workflowData.statusOptions.length
      ) {
        workflowData.statusOptions.forEach((ele) => {
          const arr = [];

          if (data[workflowData.name] == ele.status) {
            this.status = ele.status;
            arr["active"] = true;
          } else {
            arr["active"] = false;
          }
          arr["color"] = ele.color;
          arr["label"] = ele.status;
          arr["name"] = workflowData.name;
          this.breadcrumbs.push(arr);
        });
      }
    }

    // this.breadcrumbs = this.breadcrumbs.splice(0,7);

    setInterval(() => {
      this.setFlag();
    }, 300);
  }

  setLookUpValues(res) {
    // this.res.data.lookups
  }

  isObject(val) {
    return typeof val !== "object";
  }

  clickTableLabel() {
    if (this.recordTypes.length) {
      this.editRecordType = true;
    }
  }

  showRecordEdit() {
    if (this.recordTypes.length && !this.editRecordType) {
      this.enableEdit = true;
    }
  }

  getPrimaryLink(obj) {
    const tableId = obj["lookupTableId"];
    const tableName = obj["lookupTableName"];
    const resourceId = obj["_id"];

    this.primaryLink =
      "/pages/tables/dynamic/" + tableId + "/" + tableName + "/" + resourceId;
  }

  onDeleteConfirm(data, table): void {
    this.dialogService.open(DeleteDialogComponent).onClose.subscribe((name) => {
      if (name) {
        this.tableService
          .deleteDynamicFormData(data._id, table)
          .subscribe((res: any) => {
            if (res.statusCode === 200) {
              this.getRelatedData();
              this.getRelatedLookupData(this.tableName, this.id);
              this.actionSubscription.unsubscribe();
            }
          });
      }
    });
  }

  clearTabVariables() {
    this.childResourceId = null;
    this.pageRefreshed = false;
    if (this.lookupRelateData && this.lookupRelateData.length > 0) {
      this.page = 0;
    }
  }

  selectTab(event) {
    if (this.refreshPageActive || this.pageUrlReset) {
      return;
    }
    let url = `/pages/tables/dynamic/${this.tableId}/${this.tableName}/${this.id}`;
    if (event?.tabTitle) {
      this.tabTitle = event.tabTitle;
    }
    if (this.pageRefreshed) {
      if (!this.tabTitle) {
        this.tabTitle = "To Do";
        this.page = 0;
        this.location.replaceState(url + "/" + `${this.tabTitle}`);
        this.clearTabVariables();
        return;
      }
    }

    if (event.tabTitle == "Notes" || this.tabTitle == "Notes") {
      this.isActiveTable = "Notes";
      this.chatOpened = true;
    }

    if (this.childResourceId && event.tabTitle == "To Do") {
      this.clearTabVariables();
      return;
    }

    if (event.tabTitle) {
      this.tabTitle = event.tabTitle;
      this.isActiveTable = this.tabTitle;
      this.location.replaceState(url + "/" + `${event.tabTitle}`);
    }

    this.clearTabVariables();
    setTimeout(() => {
      this.tableService.runCustomStyling();
    }, 10);

    const element = document.getElementById("main_body");

    // if (this.tabTitle != 'Overview' && this.tabTitle != 'Chat & Activity') {
    //   element.classList.add("body_scrollable");
    // } else {
    //   element.classList.remove("body_scrollable");
    // }

    this.setFlag();
  }

  getTaskCounts(value) {
    const array = value.reduce((t, o) => (t = t + o.totalInCompleteTasks), 0);
    this.openTasked = [...Array(array).keys()];
  }

  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    this.route.queryParams.subscribe((params) => {
      this.page = params.page || 0;
      this.getRelatedLookupData(this.tableName, this.id);
    });
  }
  tempLookupRelateData;
  getRelatedLookupData(tableName, resourceId, addTable?) {
    this.loading = true;
    this.lookupRelateData = [];
    this.tempLookupRelateData = [];
    this.tableService
      .getRelatedLookupData(tableName, resourceId, this.page)
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          if (this.refreshPageActive) {
            setTimeout(() => {
              const hasQuery = this.refreshPageActive.split("?");
              let page = this.page;
              if (hasQuery && hasQuery.length > 0) {
                this.refreshPageActive = hasQuery[0];
                if (hasQuery[1]) {
                  page = Number(
                    (hasQuery[1].split("=") && hasQuery[1].split("=")[1]) || 0
                  );
                }
              }
              this.tabTitle = this.refreshPageActive || this.tabTitle;
              const tabTitle = this.refreshPageActive || this.tabTitle;
              let url = `/pages/tables/dynamic/${this.tableId}/${this.tableName}/${this.id}`;
              this.location.replaceState(url + "/" + `${this.tabTitle}`);
              this.isActiveTable = this.refreshPageActive || this.isActiveTable;
              this.chatOpened = false;
              this.refreshPageActive = "";
              if (page) {
                this.page = page;
                setTimeout(() => {
                  this.onPage(page, false, tabTitle);
                  this.tabTitle = tabTitle;
                  this.isActiveTable = null;
                  this.isActiveTable = tabTitle;
                }, 500);
              } else {
                this.loading = false;
              }

              this.lookupDataCompiled = true;
            }, 1500);
          } else {
            this.loading = false;
            this.lookupDataCompiled = true;
          }

          this.lookupWithTaskData = res.data["Tasks"];
          if (this.lookupWithTaskData && this.lookupWithTaskData.length > 0) {
            this.openTasked = this.lookupWithTaskData.filter(
              (task) => task["closed"] != true && task["isSubtask"] != "Yes"
            );
          }

          let copylookupRelateData = res.data;

          let val: any[] = Object.entries(copylookupRelateData);
          val.sort((a, b) => b[1].length - a[1].length);
          this.lookupRelateData = [...val];

          this.lookupRelateData = this.lookupRelateData.map(
            ([tableName, { getRecords, pager }]) => {
              const isTaskTab = tableName === "Tasks";
              if (!pager) {
                return [
                  tableName,
                  { getRecords, pager, tempRecords: 0, isTaskTab, headers: [] },
                ];
              }
              getRecords = [...getRecords] || [];
              const headers = {};
              getRecords.forEach((r) => {
                Object.keys(r).forEach((k) => {
                  headers[k] = r[k];
                });
              });
              getRecords = getRecords.map((r) => {
                Object.keys(headers).forEach((k) => {
                  r[k] = r[k] || "";
                });
                return r;
              });
              const tempRecords = [...getRecords];
              const records = [...getRecords];
              getRecords =
                pager.pages.length === 1 || pager.pages.length === 0
                  ? records
                  : [
                    ...Array(
                      Math.ceil(
                        [...records].length / (pager.pages.length - 1)
                      )
                    ),
                  ].map((_) => records.splice(0, pager.pageSize))[
                  pager.currentPage - 1
                  ];
              return [
                tableName,
                { getRecords, pager, tempRecords, isTaskTab, headers },
              ];
            }
          );
          this.lookupRelateData = [...this.lookupRelateData];

          //-- sorting based on subForms
          if (this.subFormLookupIds) {
            let copy = [];
            this.lookupRelateData.forEach((ele) => {
              let idx = this.subFormLookupIds.findIndex(
                (f) => f.tableName == ele[0]
              );
              if (idx > -1) {
                copy.unshift(ele);
              } else {
                copy.push(ele);
              }

              //-- Handle rollup fields

              this.rollUpFields.forEach((ru) => {
                if (
                  ru.options[0].rollUptable == ele[0] &&
                  ele[1].getRecords &&
                  ele[1].getRecords.length > 0
                ) {
                  let invalid = false;

                  this.profileData.forEach((item) => {
                    if (item.name == ru.name) {
                      invalid = true;
                      return;
                    }
                  });
                  if (invalid) {
                  } else {
                    let newObj = {
                      fraction:
                        ru.isCurrency && ru.isCurrency === true ? 2 : null,
                      isCurrency: ru.isCurrency,
                      label: ru.label,
                      name: ru.name,
                      type: "rollUp",
                    };

                    let currency =
                      ru.isCurrency && ru.isCurrency === true ? "$ " : "";

                    if (ru.options[0].aggregation == "sum") {
                      newObj["value"] =
                        currency +
                        parseInt(
                          ele[1].getRecords.reduce(
                            (accu, val) =>
                              accu + val[ru.options[0].numberField],
                            0
                          )
                        );
                    } else if (ru.options[0].aggregation == "count") {
                      newObj["value"] =
                        currency +
                        ele[1].getRecords.reduce(
                          (accu, val) => accu + val[ru.options[0].numberField],
                          0
                        );
                    }
                    this.profileData.push(newObj);
                  }
                }
              });
            });
            this.lookupRelateData = [...copy];

            if (addTable) {
              let url = `/pages/tables/dynamic/${this.tableId}/${this.tableName}/${this.id}`;
              this.lookupRelateData.forEach((element) => {
                if (element[0] == addTable) {
                  this.tempLookupRelateData.push(element);
                  this.tabTitle = addTable;
                  this.location.replaceState(url + "/" + `${this.tabTitle}`);
                }
              });
            }

            this.tempLookupRelateData = [...this.lookupRelateData];
            this.changeDetector.detectChanges();
          }
        }

        if (this.lookupRelateData.length === 0) {
          this.showNotesTab = true;
        }
        this.pageUrlReset = false;

        if (this.rollUpFields.length > 0) {
        }
      });

    this.checkFlag = true;
  }

  getPaginationData(page) {
    this.lookupRelateData = this.lookupRelateData.map(
      ([tableName, { getRecords, pager, tempRecords, headers }]) => {
        const isTaskTab = tableName === "Tasks";
        if (!pager) {
          return [
            tableName,
            { getRecords, pager, tempRecords: [], isTaskTab, headers },
          ];
        }
        getRecords = [...tempRecords] || [];
        tempRecords = [...tempRecords];
        const records = [...getRecords];
        pager.currentPage = page;
        getRecords =
          pager.pages.length === 1 || pager.pages.length === 0
            ? records
            : [
              ...Array(
                Math.ceil([...records].length / (pager.pages.length - 1))
              ),
            ].map((_) => records.splice(0, pager.pageSize))[
            page === 0 ? 0 : page - 1
            ];
        return [
          tableName,
          { getRecords, pager, tempRecords, isTaskTab, headers },
        ];
      }
    );
    this.page = page;
  }

  lookupDetailClicked = false;
  useHostListener = false;
  lookupDetailItem = {};
  tableForLookupDetail = "";
  tableIdFromView = "";
  fieldNameForLookup = "";
  @ViewChild("notLoadAsDropdownLookupPopover", { static: false })
  notLoadAsDropdownLookupPopover;
  @ViewChild("referenceLookupPopover", { static: false })
  referenceLookupPopover;
  @ViewChild("loadAsDropdownLookupPopover", { static: false })
  loadAsDropdownLookupPopover;
  onShowLookupDetail(event, field, item, index, p1, pop: string) {
    this.lookupDetailClicked = false;
    this.useHostListener = false;
    this.lookupDetailItem = {};
    this.tableForLookupDetail = "";
    this.tableIdFromView = "";
    this.fieldNameForLookup = "";
    this.dataForLookupDetail = {};

    let data;
    if (this.lookupDataObject[field.name]) {
      data = this.lookupDataObject[field.name].filter(
        (v) => v.lookupId == index
      )[0];
    }

    if (data) {
      this.dataForLookupDetail =
        this.forkJoinLookupResponse[data.lookupId].data;
      this.lookupDetailItem = data;
      this.tableForLookupDetail = data.lookupTableName;
      this.tableIdFromView = field.lookupTableId;
      this.fieldNameForLookup = field.name;
      this.lookupDetailClicked = true;
    } else {
      let lookupData = this.formColumns.filter((v) => v.name == field.name);
      if (lookupData && lookupData.length) {
        let id = item._id ? item._id : item.id;
        this.tableService
          .getDynamicTreeDataById(lookupData[0].lookupTableName, id)
          .subscribe(
            (res: any) => {
              if (res.statusCode == 200) {
                this.dataForLookupDetail = res.data;
                this.lookupDetailItem = lookupData[0];
                this.tableForLookupDetail = lookupData[0].lookupTableName;
                this.lookupDetailClicked = true;
                this.useHostListener = true;
              }
            },
            (err) => { }
          );
      }
    }

    switch (pop) {
      case "notLoadAsDropdownLookupPopover":
        this.notLoadAsDropdownLookupPopover = p1;
        break;
      case "referenceLookupPopover":
        this.referenceLookupPopover = p1;
        break;
      case "loadAsDropdownLookupPopover":
        this.loadAsDropdownLookupPopover = p1;
        break;
    }
  }

  getFileExtension(filename) {
    const ext = filename.split(".").pop();
    const obj = iconList.filter((row) => {
      if (row.type === ext) {
        return true;
      }
    });
    if (obj.length > 0) {
      const icon = obj[0].icon;
      return icon;
    } else {
      return "fiv-cla fiv-icon-blank fiv-size-md";
    }
  }

  getFileName(filename) {
    filename = decodeURI(filename);
    return filename.match(/.*\/(.*)$/)[1];
  }

  onFilePreivew(filename) {
    this.dialogService.open(FilePreviewDialogComponent, {
      context: {
        Data: decodeURI(filename),
        Ext: decodeURI(filename).split(".").pop(),
      },
    });
  }

  selfSubsription(): void {
    if (this.isSelfSubscribed == true) {
      this.subscriptionText = "Start watching";
      this.isSelfSubscribed = false;
      this.cancelSubscription(this.currentUser);
    } else {
      this.subscriptionText = "Stop watching";
      this.isSelfSubscribed = true;
      this.activateSubscription();
    }
  }

  activateSubscription(idForCreate?, showToaster = true) {
    const data = {
      resourceId: this.id,
      userId: idForCreate ? idForCreate : this.currentUser._id,
      tableName: this.tableName,
      invitedBy: this.currentUser._id,
    };

    this.chatSubscriptionService.watch(data).subscribe(
      (res: any) => {
        if (res.statusCode === 201) {
          // this.loadSubscribers();
          if (showToaster) {
            this.toastrService.success(res.message, "Success");
            this.loading = false;
          }
        } else {
          this.loading = false;
          this.toastrService.danger(res.message, "Error");
        }
      },
      (error) => {
        this.toastrService.danger(
          `${error.error && error.error.message}`,
          "Error"
        );
        this.loading = false;
      },
      () => { }
    );
  }

  loadSubscribers() {
    this.chatSubscriptionService
      .getSubscribers(this.tableInfo.resourceId)
      .subscribe((res: any) => {
        if (res.data) {
          this.subscribers = res.data;
          if (res.data.length > 0) {
            res.data.map((data) => {
              if (data._id == this.currentUser._id) {
                this.subscriptionText = "Stop watching";
                this.isSelfSubscribed = true;
              }
            });
          }
        }
        // this.getResourceChatHistory();
      });
  }

  updateSubscribers(subscriber) {
    const checkAlreadyAdded = this.subscribers.findIndex(
      (s) => s._id == subscriber._id
    );
    if (checkAlreadyAdded == -1) {
      this.subscribers.push(subscriber);
      this.activateSubscription(subscriber._id);
    }
    this.subscribers.forEach((data) => {
      if (data._id == this.currentUser._id) {
        this.subscriptionText = "Stop watching";
        this.isSelfSubscribed = true;
      }
    });

    this.giveMarginToSubscriber();
  }

  giveMarginToSubscriber() {
    this.subscribers.forEach((element, i) => {
      const ele = document.getElementById("watcher-span-" + i);
      if (ele) {
        if (this.subscribers.length > 4) {
          let currentMarginLeft = this.subscribers.length * 2 + 3;
          currentMarginLeft = 0 - currentMarginLeft;
          ele.style.marginLeft = currentMarginLeft + "px";
        } else {
          ele.style.marginLeft = -8 + "px";
        }
      }
    });
  }

  getResourceChatHistory() {
    this.messageService
      .getResourceChatHistory(this.id)
      .subscribe(({ data }: any) => {
        this.messageCount =
          (data && data.pageOfItems && data.pageOfItems.length) || 0;
      });
  }

  clickOnAssignedToAdd() {
    this.tableService.runCustomStyling();
  }

  watcherMenuOpened() {
    this.isWatcherOpened = true;
  }

  watcherMenuClosed() {
    this.isWatcherOpened = false;
  }

  compareFn(c11: any, c22: any): boolean {
    return c11 && c22 ? c11.id === c22.id : c11 === c22;
  }

  setAssignedTo(value) {
    let temp = {};

    let assignedToFields = this.clientData.lookup.filter(
      (v) => v.lookupDataName !== "assignedTo"
    );
    let assignedToFieldsIndex = this.clientData.lookup.findIndex(
      (v) => v.lookupDataName == "assignedTo"
    );
    let assignedToField = this.form[this.tableName].find(
      (v) => v.name == "assignedTo"
    );

    temp["lookup"] = assignedToFields;
    temp["lookup"] = temp["lookup"].map((ele) => ({
      lookupId: ele.lookupId,
      lookupName: ele.lookupDataName,
      lookupTableName: ele.lookupTableName,
    }));

    if (value && value.length) {
      value.forEach((element) => {
        let obj = {};

        obj = {
          lookupId: element.id,
          lookupName:
            assignedToFieldsIndex > -1
              ? this.clientData.lookup[assignedToFieldsIndex].lookupDataName
              : assignedToField.name,
          lookupTableName:
            assignedToFieldsIndex > -1
              ? this.clientData.lookup[assignedToFieldsIndex].lookupTableName
              : assignedToField.lookupTableName,
        };

        this.assignedToDetails = [];
        let user = this.allUsersData.find((x) => x._id == element.id);
        if (user) {
          this.assignedToDetails.push({
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            status: user.status,
            userColor: user.userColor,
            id: user._id,
            value: user.firstName + " " + user.lastName,
          });
        }

        if (assignedToField) {
          if (assignedToField.allowMultipleValues) {
            temp["lookup"].push(obj);
          } else {
            if (assignedToFieldsIndex > -1) {
              temp["lookup"][assignedToFieldsIndex] = obj;
            } else {
              temp["lookup"].push(obj);
            }
          }
        }
      });
    }

    this.tableService
      .updateDynamicFormData(this.id, this.tableName, temp, this.tableId)
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.toastrService.success(res.message, "Action was  completed!");
          this.reload();
        }
      });
  }

  removeAssignedTo(id, i) {
    let temp = {};
    temp["lookup"] = this.clientData.lookup;

    let userIndex = temp["lookup"].findIndex((v) => v.lookupId == id);
    if (userIndex > -1) {
      temp["lookup"].splice(userIndex, 1);
    }

    temp["lookup"] = temp["lookup"].map((ele) => ({
      lookupId: ele.lookupId,
      lookupName: ele.lookupDataName,
      lookupTableName: ele.lookupTableName,
    }));

    this.tableService
      .updateDynamicFormData(this.id, this.tableName, temp, this.tableId)
      .subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.toastrService.success(res.message, "Action was  completed!");
          this.reload();
        }
      });
  }

  cancelSubscription(user: string): void {
    this.chatSubscriptionService
      .cancelSubscription({
        resourceId: this.tableInfo.resourceId,
        userId: user,
      })
      .subscribe((data: any) => {
        if (data.statusCode == 200) {
          // this.loadSubscribers();
          this.toastrService.success(data.message, "Action was  completed!");
        }
      });
    const itemToRemoveIndex = this.subscribers.findIndex(function (item) {
      return item._id === user;
    });

    if (itemToRemoveIndex !== -1) {
      this.subscribers.splice(itemToRemoveIndex, 1);
    }
  }

  openReminderModal() {
    let temp = [];
    this.clientData["IDField"].forEach((element) => {
      let IDElement = this.form[this.tableName].filter(
        (v) => v.name == element
      );
      if (IDElement && IDElement.length) {
        if (IDElement[0].type == "date" || IDElement[0].type == "dateTime") {
          if (
            this.clientData[element] &&
            typeof this.clientData[element] !== "object"
          ) {
            let val = this.datePipe.transform(
              this.clientData[element],
              "M/d/yy"
            );
            temp.push(" " + val);
          }
        } else {
          if (
            this.clientData[element] &&
            typeof this.clientData[element] !== "object"
          ) {
            temp.push(" " + this.clientData[element]);
          }
        }
      }
    });

    this.dialogService
      .open(NewReminderModalComponent, {
        context: {
          tableId: this.tableId,
          tableName: this.tableName,
          resourceId: this.tableInfo.resourceId,
          IdFieldData: temp,
          fromViewPage: true,
          tableIcon: this.tableIcon,
        },
        autoFocus: true,
      })
      .onClose.subscribe((res) => {
        if (res) {
          this.ngOnInit();
        }
      });
  }

  onPage(page, callAPi = true, tabTitle?) {
    const url = `/#/pages/tables/dynamic/${this.tableId}/${this.tableName}/${this.id
      }/${tabTitle || this.tabTitle}`;
    this.page = page;
    if (callAPi) {
      this.getPaginationData(page);
    } else {
      this.getPaginationData(page);
    }
    this.isActiveTable = tabTitle || this.tabTitle;
    history.pushState(null, null, url + "?page=" + page);
  }

  filterTable() {
    const tabTItle = this.tabTitle;
    console.log(
      this.lookupRelateData[this.tabTitle],
      this.page,
      this.filterKey,
      this.sortObject,
      this.searchString
    );
    const tableIndex = this.lookupRelateData.findIndex(
      ([tableName]) => tableName === this.tabTitle
    );
    if (tableIndex > -1) {
      let data = this.lookupRelateData[tableIndex][1].tempRecords;
      const headers = data[0];
      if (this.sortObject) {
        if (
          this.sortObject.type === "date" ||
          this.sortObject.type === "dateTime"
        ) {
          data = orderBy(
            data,
            (obj) =>
              obj[this.sortObject.column]
                ? new Date(obj[this.sortObject.column])
                : new Date(-1),
            [this.sortObject.direction]
          );
        } else {
          data = orderBy(
            data,
            [this.sortObject.column],
            [this.sortObject.direction]
          );
        }
      }
      if (this.filterKey && this.filterKey.length > 0) {
        this.filterKey.forEach((obj) => {
          data = filter(data, obj);
        });
      }
      this.lookupRelateData[tableIndex][1].getRecords = data.slice(0, 10);
      this.lookupRelateData = [...this.lookupRelateData];
      this.tabTitle = tabTItle;
      this.isActiveTable = tabTItle;
    }
  }

  onFilterSearch(data, filterDataKey, type?) {
    if (filterDataKey == "tasksRecordsOnly") {
      this.taskedRecordsOnly = data == true;
    }
    if (type == "date") {
      const Datedata = new Date(new Date(data).setHours(0, 0, 0));
      data = new Date(Datedata).toUTCString();
    }
    if (type == "checkbox") {
      if (
        data.target.checked &&
        !this.checkboxArray.includes(data.currentTarget.textContent.trim())
      ) {
        this.checkboxArray.push(data.currentTarget.textContent.trim());
      } else {
        const index = this.checkboxArray.indexOf(
          data.currentTarget.textContent.trim()
        );
        this.checkboxArray.splice(index, 1);
      }
      data = this.checkboxArray.join(",");
    }

    if (filterDataKey != "tasksRecordsOnly") {
      if (this.filterKey.length > 0) {
        const index = this.filterKey.findIndex((v) =>
          v.hasOwnProperty(filterDataKey)
        );
        this.filterKey.forEach((e) => {
          if (index >= 0) {
            data && data.length > 0
              ? (e[filterDataKey] = data)
              : this.filterKey.splice(index, 1);
          } else if (data) {
            this.filterKey.push({ [filterDataKey]: data });
          }
        });
      } else if (data && data.length > 0) {
        this.filterKey.push({ [filterDataKey]: data });
      }
    }

    if (filterDataKey != "tasksRecordsOnly") {
      if (data && data.length > 0) {
        this.filterHide[filterDataKey] = true;
        this.hideColumn[filterDataKey] = false;
        this.showFilterBox[filterDataKey] = false;
      } else {
        this.filterHide[filterDataKey] = false;
        this.hideColumn[filterDataKey] = true;
      }
    }
    this.filterTable();
    this.hideColumn[filterDataKey] = true;
  }

  onSort(sortObject, event, column) {
    this.sortObject = sortObject;
    if (!sortObject || event.direction == "" || event.direction == "desc") {
      event.direction = "asc";
      this.sortObject = {
        direction: "asc",
        column,
        type: event.type,
      };
      this.filterTable();
    } else if (event.direction == "asc") {
      (this.sortObject = {
        direction: "desc",
        column,
        type: event.type,
      }),
        (event.direction = "desc");
      this.filterTable();
    } else {
      event.direction = "";
      this.filterTable();
    }
  }

  updatedVal(e, value) {
    if (e && e.length >= 0) {
      this.showAutocomplete[value] = true;
    } else {
      this.showAutocomplete[value] = false;
    }
  }

  clearFilter(label) {
    const index = this.filterKey.findIndex((obj) =>
      Object.keys(obj)
        .map((i) => i.toLowerCase())
        .includes(label.toLowerCase())
    );
    if (index > -1) {
      this.filterKey.splice(index, 1);
      this.filterTable();
    }
  }

  removeText(value, type?) {
    const index = this.filterKey.findIndex((v) => v.hasOwnProperty(value));
    this.filterKey.splice(index, 1);
    this.searchString = "";
    if (type === "lookup") {
      this.lookupValue[value] = "";
    } else if (type === "date") {
      this.model = "";
    } else {
      this.textValue = {};
    }

    this.filterHide[value] = false;
    this.hideColumn[value] = true;

    if (this.filterKey.length > 0) {
      this.filterTable();
    } else {
      this.filterTable();
    }
  }

  onSelectionChange(data, name, dynamicFilterData) {
    if (data.id && data.value) {
      this.lookupValue[name] = data.value;
      for (const d of dynamicFilterData) {
        if (
          d.type === "lookup" &&
          name.toLowerCase() === d.name.toLowerCase()
        ) {
          const displayValue = d.options.filter((f) => f[0] === data.id);
          if (displayValue.length > 0) {
            const temp: [] = Object.assign([], displayValue[0]);
            temp.shift();
            this.lookupValue[name] = temp.toString().replace(/,/g, " ");
          } else {
            d.options.forEach((el) => {
              if (data.id === el[0]) {
                this.lookupValue[name] = el[1];
              }
            });
          }
          this.onFilterSearch(data.id, name);
        }
      }
    }
  }

  viewMoreData(lookupData) {
    if (this.selectedListCardId === lookupData._id) {
      this.selectedListCardId = undefined;
      return false;
    }
    this.selectedListCardId = lookupData._id;
  }

  treeUpdated($e) {
    this.getRelatedLookupData(this.tableName, this.id);
  }

  callRelatedLookup() {
    this.getRelatedLookupData(this.tableName, this.id);
  }

  getOverviewSelected(tableName) {
    this.getRelatedLookupData(this.tableName, this.id, tableName);
  }

  getGradientbackground() {
    return `{background: #790975; background: linear-gradient(90deg, #790975 0%, ${this.gradientColor} 100%);}`;
  }

  lookupDataObject = {};
  forkJoinLookupResponse;
  forkJoinTableResponse: any;
  lookupDetailDataLoad() {
    let lookupList = [];
    let forkJoinLookupObject = {};
    let forkJoinTableDataObject = {};

    if (this.form[this.tableName] && this.form[this.tableName].length) {
      lookupList = this.form[this.tableName].filter((v) => v.type == "lookup");

      if (lookupList && lookupList.length) {
        lookupList.forEach((ele) => {
          if (
            this.dataToPassInForm &&
            this.dataToPassInForm.lookup &&
            this.dataToPassInForm.lookup.length
          ) {
            this.lookupDataObject[ele.name] =
              this.dataToPassInForm.lookup.filter((v) =>
                v.lookupDataName
                  ? v.lookupDataName == ele.name
                  : v.lookupName == ele.name
              );
            if (
              this.lookupDataObject[ele.name] &&
              this.lookupDataObject[ele.name].length
            ) {
              this.lookupDataObject[ele.name].forEach(async (element) => {
                forkJoinLookupObject[element.lookupId] =
                  this.tableService.getDynamicTreeDataById(
                    element.lookupTableName,
                    element.lookupId
                  );
                if (
                  this.tableDataFromViewToForm &&
                  this.tableDataFromViewToForm.length
                ) {
                  let fieldIndex = this.tableDataFromViewToForm.findIndex(
                    (v) => v.tableName == element.lookupTableName
                  );
                  if (fieldIndex < 0) {
                    if (!forkJoinTableDataObject[element.lookupTableName])
                      forkJoinTableDataObject[element.lookupTableName] =
                        this.tableService.getTableByName(
                          element.lookupTableName
                        );
                  }
                } else {
                  this.tableService.getTablesForMenu().subscribe((res: any) => {
                    if (res.statusCode == 200) {
                      this.tableDataFromViewToForm = res.data;
                      let fieldIndex = this.tableDataFromViewToForm.findIndex(
                        (v) => v.tableName == element.lookupTableName
                      );
                      if (fieldIndex < 0) {
                        if (!forkJoinTableDataObject[element.lookupTableName])
                          forkJoinTableDataObject[element.lookupTableName] =
                            this.tableService.getTableByName(
                              element.lookupTableName
                            );
                      }
                    }
                  });
                }
              });
              this.loading = true;
            }
          }
        });
      }

      let keys1 = Object.keys(forkJoinTableDataObject);
      if (keys1 && keys1.length) {
        forkJoin(forkJoinTableDataObject).subscribe((res) => {
          this.forkJoinTableResponse = res;
          this.getIdFields(lookupList);
          this.callForkJoinFn(forkJoinLookupObject, lookupList);
        });
      } else {
        this.getIdFields(lookupList);
        this.callForkJoinFn(forkJoinLookupObject, lookupList);
      }
    }
  }

  getIdFields(lookupList) {
    if (lookupList && lookupList.length) {
      lookupList.forEach((ele) => {
        if (
          this.lookupDataObject[ele.name] &&
          this.lookupDataObject[ele.name].length
        ) {
          this.lookupDataObject[ele.name].forEach(async (element) => {
            let idFields = [];
            element["idFields"] = [];

            if (
              this.tableDataFromViewToForm &&
              this.tableDataFromViewToForm.length
            ) {
              let table = this.tableDataFromViewToForm.filter(
                (val) => val.tableName == element.lookupTableName
              );

              if (table && table.length) {
                idFields = table[0].columns
                  .filter((v) => v.idField)
                  .map((a) => a.name);
              } else {
                idFields = this.forkJoinTableResponse[
                  element.lookupTableName
                ].data[0].columns
                  .filter((v) => v.idField)
                  .map((a) => a.name);
              }

              if (idFields && idFields.length) {
                element["idFields"] = idFields;
              }
            } else {
              this.tableService.getTablesForMenu().subscribe((res: any) => {
                if (res.statusCode == 200) {
                  this.tableDataFromViewToForm = res.data;
                  let table = this.tableDataFromViewToForm.filter(
                    (val) => val.tableName == element.lookupTableName
                  );

                  if (table && table.length) {
                    idFields = table[0].columns
                      .filter((v) => v.idField)
                      .map((a) => a.name);
                  } else {
                    idFields = this.forkJoinTableResponse[
                      element.lookupTableName
                    ].data[0].columns
                      .filter((v) => v.idField)
                      .map((a) => a.name);
                  }

                  if (idFields && idFields.length) {
                    element["idFields"] = idFields;
                  }
                }
              });
            }
          });
        }
      });
    }
  }

  callForkJoinFn(forkJoinLookupObject, lookupList) {
    forkJoin(forkJoinLookupObject).subscribe((response) => {
      this.forkJoinLookupResponse = response;

      if (lookupList && lookupList.length) {
        lookupList.forEach((ele) => {
          if (
            this.lookupDataObject[ele.name] &&
            this.lookupDataObject[ele.name].length
          ) {
            this.lookupDataObject[ele.name].forEach((element) => {
              this.createDisplayForLookup(element);
            });
          }
        });
      }
      this.lookupDataCompiled = true;
      this.changeDetector.detectChanges();
      this.loading = false;
    });
  }

  createDisplayForLookup(element) {
    if (element.lookupId) {
      let temp = [];
      let data = this.forkJoinLookupResponse[element.lookupId].data;

      if (!data) return;

      if (element.idFields && element.idFields.length) {
        element.idFields.forEach((item) => {
          let table = this.tableDataFromViewToForm.filter(
            (val) => val.tableName == element.lookupTableName
          );
          let find: any;
          if (table && table.length) {
            find = table[0].columns.find((v) => v.name == item);
          } else {
            find = this.forkJoinTableResponse[
              element.lookupTableName
            ].data[0].columns.find((v) => v.name == item);
          }

          if (find) {
            if (find.type == "date" || find.type == "dateTime") {
              if (data[item]) {
                let dataToStore = this.datePipe.transform(
                  data[item],
                  "shortDate"
                );
                temp.push(dataToStore);
              }
            } else if (find.type == "file") {

              if (data[item] && data[item].length) {
                // let string = '';
                temp.push(data[item].join(' '));
              }

            } else {
              if (find.isPhone) {
                const phonePipe = new PhonePipe();
                const finalPhone = phonePipe.transform(data[item]);
                if (finalPhone) {
                  temp.push(finalPhone);
                }
              } else {
                if (data[item]) {
                  temp.push(data[item]);
                }
              }
            }
          }
        });
      }
      element["valueToDisplay"] = temp;

      let temp1 = [];
      temp1.push(element[element.lookupName].shift());

      temp1.push(...element["valueToDisplay"]);
      element[element.lookupName] = [...temp1];
    }
  }

  onEmitFromLookupPopoverForModal(event) {
    if (event.close) {
      if (event.refer) {
        event.refer.close();
      }
    }
    if (this.notLoadAsDropdownLookupPopover) {
      this.notLoadAsDropdownLookupPopover.close();
    }
    if (this.referenceLookupPopover) {
      this.referenceLookupPopover.close();
    }
    if (this.loadAsDropdownLookupPopover) {
      this.loadAsDropdownLookupPopover.close();
    }
    this.changeDetector.detectChanges();
  }

  finalRecordGadget;
  recordGadgetValue;
  currentGadgetValue = {};
  gadgetFieldValue;
  ejsPackage;
  async getRecordGadgets() {
    let $Table = this.clientData;
    try {
      await System.import("../../../../../node_modules/ejs/ejs.js").then(
        async (res) => {
          this.ejsPackage = res;

          if (this.recordGadgets && this.recordGadgets.length) {
            let activeGadget = this.recordGadgets.filter(
              (v) => v.active == true
            )[0];
            if (activeGadget)
              this.recordGadgetValue = this.ejsPackage.render(
                activeGadget.logic,
                { $Table: $Table }
              );

            let currentGadget = this.recordGadgets.filter(
              (v) => v.name == this.currentGadgetValue[this.tableName]
            )[0];
            if (currentGadget)
              this.gadgetFieldValue = this.ejsPackage.render(
                currentGadget.logic,
                { $Table: $Table }
              );

            this.changeDetector.detectChanges();
          }
        }
      );
    } catch (e) {
      console.log("Error parsing gadget logic => ", e);
    }
  }

  backTo(location: any) {
    let index = this.eventEmiter.idField.findIndex((object) => {
      return location.actual ? object.actual === location.actual : object.idField === location.idField;
    });

    if (index == 0) {
      this.eventEmiter.idField = [];
    }

    let url = `/pages/tables/${location.actual ? location.actual : location.idField}`;

    this.router.navigate([url]);
    this.eventEmiter.idField.splice(index);

    localStorage.setItem("IdFields", JSON.stringify(this.eventEmiter.idField));
  }

  reload() {
    this.getTableData();
  }

  getAllFilters() {
    this.tableService
      .defaultFilterSelected(this.tableId)
      .subscribe((tableRe: any) => {
        if (tableRe.statusCode == 200) {
          this.allFilters = cloneDeep([...tableRe.data]);
          this.changeDetector.detectChanges();
        }
      });
  }

  scrollToBottom() {
    const ele = document.getElementById("content-tag");
    ele.scrollTo(0, ele.scrollHeight);
  }

  getLookupValue(value) {
    this.lookupValue = value;
  }
  tabScrollDetect() {
    const tabScroll = document.getElementsByClassName(
      "tabset"
    )[0] as HTMLElement;
    const leftArrow = document.getElementsByClassName(
      "fa-angle-left"
    )[0] as HTMLElement;
    const rightArrow = document.getElementsByClassName(
      "fa-angle-right"
    )[0] as HTMLElement;
    if (tabScroll && tabScroll.scrollWidth > tabScroll.clientWidth) {
      this.isTabScollable = true;
    } else {
      this.isTabScollable = false;
    }
    if (leftArrow && tabScroll && tabScroll.scrollLeft != 0) {
      leftArrow.style.visibility = "visible";
    }
    if (leftArrow && tabScroll && tabScroll.scrollLeft == 0) {
      leftArrow.style.visibility = "hidden";
    }
    if (
      rightArrow &&
      tabScroll &&
      tabScroll.scrollLeft + tabScroll.offsetWidth >= tabScroll.scrollWidth
    ) {
      rightArrow.style.visibility = "hidden";
    }
    if (
      rightArrow &&
      tabScroll &&
      tabScroll.scrollLeft + tabScroll.offsetWidth < tabScroll.scrollWidth
    ) {
      rightArrow.style.visibility = "visible";
    }
  }
  scollTab(direction: any) {
    let tabScroll = (<HTMLElement>this.elem.nativeElement).querySelector(
      ".tabset"
    );
    if (tabScroll && direction == "right") {
      tabScroll.scrollLeft += 60;
    } else {
      tabScroll.scrollLeft -= 60;
    }
    this.tabScrollDetect();
  }
  startDragging(e) {
    const tabScroll = document.getElementsByClassName(
      "tabset"
    )[0] as HTMLElement;
    const tablink = document.getElementsByClassName("tab-link");
    for (var i = 0; i < tablink.length; i++) {
      let newLink = tablink[i] as HTMLElement;
      newLink.style.cursor = "grab";
    }
    this.mouseDown = true;
    this.startX = e.pageX - tabScroll.offsetLeft;
    this.scrollLeft = tabScroll.scrollLeft;
  }
  stopDragging(e) {
    const tablink = document.getElementsByClassName("tab-link");
    for (var i = 0; i < tablink.length; i++) {
      let newLink = tablink[i] as HTMLElement;
      newLink.style.cursor = "pointer";
    }
    this.mouseDown = false;
  }
  moveEvent(e) {
    const tabScroll = document.getElementsByClassName(
      "tabset"
    )[0] as HTMLElement;
    e.preventDefault();
    if (!this.mouseDown) {
      return;
    }
    const x = e.pageX - tabScroll.offsetLeft;
    const scroll = x - this.startX;
    tabScroll.scrollLeft = this.scrollLeft - scroll;
  }
}
