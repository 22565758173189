<nb-card style="width: 800px;height:700px">
  <nb-card-header style="display:flex;justify-content: space-between;">
    <h4 *ngIf="!fromFilter"> Available methods in editor</h4>
    <h4 *ngIf="fromFilter">How to use Filter</h4>
    <span (click)="close();$event.preventDefault();" class="material-icons-outlined pull-right cursor-pointer">
      close
    </span>
  </nb-card-header>

  <nb-card-body>

    <nb-accordion>
      <nb-accordion-item style="display:contents;margin: 1opx;" *ngFor="let item of variableList">
        <nb-accordion-item-header>
          <h5>{{item.functionName}}</h5>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <div *ngFor="let inner of item.params" class="row">
            <div class="param-div">
              <span>Parameter Name : <strong>{{inner.name}}</strong></span>
              <hr>
              <p>Description Name : <strong>{{inner.desc}}</strong></p>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>

  </nb-card-body>
</nb-card>
