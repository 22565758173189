<nb-tabset class="reminder-notifications-popup" (changeTab)="changedTab($event)">
  <nb-tab id="scrollReminder" tabTitle="Reminders ({{unreadReminderCount}})" (scroll)="scrollHandler('reminder',$event)">

    <!-- <div style="display: flex;justify-content: space-between;padding: 0px 5px; font-weight: 600;" class="txt-clr mb-13">
      <div style="display: flex;align-items: center;">
        <span class="material-icons-outlined">
          alarm
          </span>
          &nbsp; Reminders ({{unreadReminderCount}})
      </div>
      <div>
        <span style="cursor: pointer;" (click)="emitToEdit.emit()" class="material-icons-outlined">
          add_circle
          </span>
      </div>
    </div> -->

    <div *ngFor="let reminder of reminderList;let i = index" [ngClass]="{'unread-message': reminder.isNewMessage}"
      class="rem-not-block" (click)="openReminder(reminder)">
      <div class="rem-not-time">
        <span class="rem-title">{{reminder.notes}}</span>
        <div>
          <span style="font-size: 20px;" class="material-icons-outlined" placement="left" [ngbPopover]="templateRef" (click)="$event.stopPropagation()"
           >more_vert</span>
        </div>
        <span (click)="deleteReminder(reminder,i);$event.stopPropagation()" class="material-icons-outlined txt-clr rem-close">close</span>
        <span class="rem-date">
          <span class="material-icons-outlined clock-icon">
          schedule
          </span> {{ formatDate(reminder.dateTime)}}</span>
      </div>
      <div class="rem-not-text">{{reminder.details}}</div>
      <div class="recurring float-right" *ngIf="reminder.frequency != null">
        <svg width="30pt" height="30pt" version="1.1" viewBox="0 0 752 752" [ngStyle]="{'fill':systemConfigColor}">
          <g>
            <path
              d="m372.93 472.37c-13.297-29.867-7.1211-63.953 15.73-86.82 22.863-22.855 56.969-29.035 86.855-15.738 0.28516 0.125 0.57812 0.17578 0.86328 0.28516l-14.645 11.488c-6.1094 4.793-7.1797 13.629-2.3828 19.742 2.7734 3.5312 6.8984 5.3828 11.074 5.3828 3.0391 0 6.0938-0.98047 8.6719-3.0039l37.113-29.117c4.207-3.2969 6.1758-8.6953 5.0859-13.934l-9.6562-46.336c-1.582-7.5977-9.0156-12.48-16.637-10.898-7.6016 1.582-12.484 9.0312-10.902 16.637l4.4609 21.406c-0.125-0.0625-0.22656-0.14844-0.35156-0.19922-41.797-18.598-89.535-9.8945-121.64 22.188-32.074 32.098-40.781 79.836-22.18 121.62 2.5859 5.8164 8.293 9.2695 14.277 9.2695 2.1211 0 4.2812-0.4375 6.3398-1.3555 7.8906-3.5039 11.434-12.738 7.9258-20.617z" />
            <path
              d="m543.43 396.49c-3.5-7.8867-12.734-11.434-20.613-7.9336-7.8867 3.5-11.438 12.73-7.9336 20.613 13.285 29.898 7.1055 63.984-15.746 86.832-22.395 22.395-55.547 28.742-84.984 16.488l14.613-11.461c6.1094-4.793 7.1797-13.629 2.3828-19.742-4.793-6.1094-13.633-7.1797-19.742-2.3828l-37.125 29.121c-4.207 3.2969-6.1797 8.6953-5.0859 13.93l9.6523 46.336c1.3828 6.6367 7.2305 11.195 13.754 11.195 0.94531 0 1.9141-0.097656 2.8789-0.29297 7.6016-1.5859 12.484-9.0312 10.902-16.637l-4.4414-21.305c13.895 5.8203 28.387 8.6992 42.723 8.6992 28.184 0 55.688-10.98 76.57-31.867 32.062-32.051 40.777-79.785 22.195-121.59z" />
            <path
              d="m239.05 283.46h227.64v0.03125h24.391v-53.766c0-8.957-7.3203-16.262-16.277-16.262h-36.574v-12.047c0-8.1562-6.6914-14.844-14.848-14.844h-10.949c-8.1758 0-14.848 6.6914-14.848 14.844v12.047l-89.434-0.003907v-12.047c0-8.1562-6.6719-14.844-14.848-14.844h-10.949c-8.1562 0-14.848 6.6914-14.848 14.844v12.047h-36.578c-8.9375 0-16.262 7.3086-16.262 16.262v243.88c0 8.957 7.3203 16.277 16.262 16.277h76.375v-27.559l-68.254 0.003906z" />
          </g>
        </svg>
      </div>

      <ng-template #templateRef>
        <ul>
          <li style="padding: 5px;cursor: pointer;" (click)="openReminder(reminder);$event.stopPropagation()">View</li>
          <li style="padding: 5px;cursor: pointer;" (click)="openReminderModal(reminder);$event.stopPropagation()">Reschedule</li>
          <li style="padding: 5px;cursor: pointer;" (click)="editReminder(reminder);$event.stopPropagation()">Edit</li>
          <li style="padding: 5px;cursor: pointer;" (click)="openSnoozeModal(reminder);$event.stopPropagation()">Snooze</li>
          <li style="padding: 5px;cursor: pointer;" (click)="markComplete(reminder,i);$event.stopPropagation()">Mark as Complete</li>
        </ul>
      </ng-template>
    </div>

    <div class="spinner-div">
      <span [nbSpinner]="loader" nbSpinnerSize="small" nbSpinnerStatus="primary"></span>
    </div>

  </nb-tab>
  <nb-tab id="scrollNotification" tabTitle="Notifications ({{ unreadNotificationCount }})" (scroll)="scrollHandler('notification',$event)">

    <div [ngClass]="[notification.visibility == 'unread' ? 'unread': '',
    notification.visibility == 'read' ? 'read': '']"
      (click)="tdClick(notification,notification.resourceDetails.tableId, notification.resourceDetails.tableName, notification.resourceDetails.resourceId)"
      *ngFor="let notification of notificationList;let i = index" class="rem-not-block">
      <div class="rem-not-time">
        <span class="rem-title">{{notification.resourceDetails.resourceName}}</span>
        <span (click)="deleteNotification(notification,i);$event.stopPropagation()" class="material-icons-outlined txt-clr rem-close">close</span>
        <span class="rem-date">
          <span class="material-icons-outlined clock-icon">
            schedule
            </span>
            {{ formatDate(notification.createdAt)}}</span>

      </div>
      <div *ngIf="notification?.fieldType && (notification?.fieldType == 'dateTime' || notification?.fieldType == 'date'); else nonDateTime"  class="rem-not-text">
         {{ notification.sender.firstName + ' has changed ' + notification.fieldUpdated+ ' from '}} {{ notification.oldValue | dateCustomPipe }}  to {{ notification.newValue | dateCustomPipe }}
      </div>
      <ng-template #nonDateTime>
        <div class="rem-not-text">
          {{notification.text}}
        </div>
      </ng-template>

    </div>

    <div class="spinner-div">
      <span [nbSpinner]="loader" nbSpinnerSize="small" nbSpinnerStatus="primary"></span>
    </div>

  </nb-tab>
</nb-tabset>
