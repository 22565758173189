<nb-card class="csv-upload-sec csv-radio" style="width: 400px;height: 270px;">
  <!-- <span class="csv-close material-icons material-symbols-outlined cursor-pointer" (click)="cancel()">
    cancel
  </span> -->
  <nb-card-body class="add-date-time-form">

    <div class="csv-heading">How do you want to Archive? <span class="txt-bg"></span></div>

    <div class="row" style="margin: 0px;">

      <nb-radio-group [(ngModel)]="selectedOption">
        <nb-radio
          *ngFor="let status of items"
          [value]="status.status">
          {{ status.status }}
        </nb-radio>
      </nb-radio-group>

    </div>

    <div class="csv-btns" style="margin-top: 25px;">
      <button [ngStyle]="{'background-color':systemConfigColor}" style="color: white;margin-right: 10px;" nbButton
        matRipple (click)="submit(selectedOption)" class="csv-btn-style">Archive</button>
      <button nbButton matRipple (click)="cancel()" class="csv-btn-style"
        style="background: #EBEBEB">Cancel</button>

    </div>

  </nb-card-body>

</nb-card>
