import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventEmiterService } from '@app/shared/services/event-emiter.service';
import { TableService } from '@app/shared/services/table.service';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-showcase-dialog',
  templateUrl: './showcase-dialog.component.html',
  styleUrls: ['./showcase-dialog.component.scss'],
})
export class ShowcaseDialogComponent implements OnInit {

  @Input() title: string;
  @Input() body: string;
  @Input() list: any[];
  @Input() button: { text: string };
  @Input() firstTimeLogin: boolean = false;
  @Input() isAnnouncment_table;
  userId = '';
  systemConfigColor: any;
  isAnnouncments: boolean = false;

  constructor(protected ref: NbDialogRef<ShowcaseDialogComponent>,
    private tableService: TableService,
    private eventEmiter: EventEmiterService,
    private router: Router,) {
    this.tableService.$systemConfig.subscribe((res: any) => {
      if (res) {
        this.systemConfigColor = res.IconColors;
      }
    })
    if (this.eventEmiter.Announcments == true) {
      this.isAnnouncments = true;
    }
  }

  announceIndex = 0;
  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem('userData'));
    if (user && user._id) {
      this.userId = user._id;
    }

    if (this.isAnnouncment_table) {
      if (this.list && this.list.length) {
        this.announceIndex = 0;
      }
    }
  }

  next() {
    if(this.announceIndex > this.list.length) {
      this.dismiss();
    }
    this.tableService.announcementSeen(this.list[this.announceIndex]._id).subscribe((res:any) => {
      if (res.statusCode == 200) {
        if(this.announceIndex == this.list.length){
          this.dismiss();
        } else{
          this.announceIndex++;
        }

      }
    })

  }

  dismiss() {
    localStorage.setItem("openAnnounceModal", "false")
    localStorage.setItem("isButtonClick", "false");
    if (localStorage.getItem("openAnnounceModal") === "false") {
      this.ref.close();
      localStorage.removeItem("isUserAnnoncement");
      localStorage.removeItem("isFirstTime")
      if (this.firstTimeLogin) {
        this.tableService.setWelcomeMessageSeen(this.userId).subscribe(
          res => {

          }
        )
      }
    }
  }

  manageNotifications() {
    this.dismiss()
    this.router.navigate(["/pages/tables/Announcements"]);
  }
}
