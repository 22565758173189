<nb-card [nbSpinner]="loading" nbSpinnerSize="small" nbSpinnerStatus="primary" class="pop-over">
  <nb-card-body>
    <div class="left_side-dynamic">
      <div class="profile_div">
        <span class="profile_text icon-setting">
          <div *ngFor="let hfiled of clientData?.IDField">
            <h6 *ngIf="checkURL(clientData[hfiled])" style="cursor: pointer;"
              (click)="onFilePreivew(clientData[hfiled])" class="ml-3" [innerHTML]="clientData[hfiled] | renderImage">
            </h6>
            <h6 *ngIf="!checkURL(clientData[hfiled])" class="ml-3" [innerHTML]="clientData[hfiled]"></h6>
          </div>
          <!-- <h6 class="ml-3" *ngIf="clientData?.firstName && clientData?.lastName">
                  {{ clientData?.firstName + ' ' + clientData?.lastName }}
                </h6>
                <h6 class="ml-3" *ngIf="clientData?.name">{{ clientData?.name }}</h6>
                <h6 class="ml-3" *ngIf="clientData?.purposeOfCall">{{ clientData?.purposeOfCall }}</h6> -->
          <i *ngIf="!loading" style="cursor: pointer;color:#323232;" class="fa fa-external-link pull-right"
            aria-hidden="true" (click)="onShowLookupDetail()"></i>
        </span>
        <div class="client_info">
          <!-- <span class="client_name">Abouts this {{ singularTableName }}</span> -->
          <ng-container *ngFor="let item of profileData">
            <div *ngIf="item && item.value" [ngClass]="{'font-bold': true}" class="data_list">
              <span class="popover-image" *ngIf="item.icon">
                <!-- <img [src]="item.icon" > -->
                <nb-icon icon="email-outline" style="font-size: 16px;color:#323232;"></nb-icon>
              </span>
              <a style="cursor: pointer;" (click)="onFilePreivew(item.value)" *ngIf="item.type !== 'email' && !item.isPhone && checkURL(item.value)" class="value_text"
                [innerHTML]="item.type == 'date' ? (item.value | date) :  item.value | renderImage"></a>
              <a *ngIf="item.type !== 'email' && !item.isPhone && !checkURL(item.value)" class="value_text"
                [innerHTML]="item.type == 'date' ? (item.value | date) :  item.value | renderImage"></a>
              <a *ngIf="item.type == 'email' && !item.isPhone" class="value_text"
                href="mailto:{{item.value}}">{{item.value}}</a>
              <a *ngIf="item.isPhone" class="value_text">{{item.value | phone}}</a>
            </div>
          </ng-container>
          <ng-container>
            <div *ngIf="tableInfo.tableName == 'Users'" class="data_list" style="cursor: pointer;"
              (click)="mentionRedirect()">
              <span class="popover-image">
                <!-- <nb-icon icon="message-square-outline"></nb-icon> -->
                <!-- <img src="https://cavalry.s3.amazonaws.com/1633104795573message.png"> -->
                <span class="new-nav-icon">
                  <span class="material-icons-outlined small-icon" style="color:#323232;">message</span>
                </span>
              </span>
              <span class="value_text">Messages</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
