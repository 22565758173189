<ng-container *ngIf="uploadedFiles && uploadedFiles.length">
  <ngb-carousel
    #myCarousel="ngbCarousel"
    [activeId]="currentFile+1"
    [showNavigationArrows]="true"
    [showNavigationIndicators]="false"
    [pauseOnFocus]="true"
  >
    <ng-template
      ngbSlide
      *ngFor="let file of uploadedFiles; let i = index"
      [id]="i+1"
    >
      <nb-card
        style="background: transparent; border: none"
        [ngClass]="{
          main_layout:
            !imageShowForUploadedFiles[file.toString().split('/').pop()]
        }"
        class="file-preview-dialog"
        [nbSpinner]="eventService.loading"
        nbSpinnerSize="large"
        nbSpinnerStatus="primary"
      >
        <div class="upper_div_download">
          <div class="save_as_but">
            <i
              class="fa fa-search-plus"
              *ngIf="
                imageShowForUploadedFiles[file.toString().split('/').pop()]
              "
              (click)="onZoomIn(file)"
              aria-hidden="true"
            ></i>
            <i
              class="fa fa-search-minus ml-3 mr-3"
              *ngIf="
                imageShowForUploadedFiles[file.toString().split('/').pop()]
              "
              (click)="onZoomOut(file)"
              aria-hidden="true"
            ></i>
            <i
              class="fa fa-download"
              [matMenuTriggerFor]="menu"
              aria-hidden="true"
            ></i>
            <i
              class="fa fa-close"
              style="margin-left: 30px"
              (click)="this.ref.close()"
              aria-hidden="true"
            ></i>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="onImage(file)">
                Direct Download
              </button>
              <button mat-menu-item [matMenuTriggerFor]="menu1">Save to</button>
            </mat-menu>
            <mat-menu #menu1="matMenu">
              <button
                mat-menu-item
                *ngFor="let lookup of lookupList"
                (click)="addNewFile(lookup)"
              >
                {{ lookup }}
              </button>
            </mat-menu>
          </div>
        </div>
        <div
          class="main"
          *ngIf="imageShowForUploadedFiles[file.toString().split('/').pop()]"
        >
          <div [ngClass]="!isZoom ? 'all_image' : 'onZoom'">
            <img
              [src]="
                this.DataForUploadedFiles[file.toString().split('/').pop()]
              "
              alt=""
              id="image"
            />
            <div class="imglayout">
              <i
                class="fas fa-times close_icon mr-2"
                (click)="onCancel(file)"
              ></i>
              <nb-icon
                class="d_icon"
                icon="download"
                (click)="onImage(file)"
              ></nb-icon>
            </div>
          </div>
        </div>
        <ngx-doc-viewer
          *ngIf="
            !imageShowForUploadedFiles[file.toString().split('/').pop()] &&
            !audioShowForUploadedFiles[file.toString().split('/').pop()] &&
            !videoShowForUploadedFiles[file.toString().split('/').pop()]
          "
          queryParams="hl=Nl"
          [url]="selectedDocForUploadedFiles[file.toString().split('/').pop()]"
          [viewer]="
            selectedViewerForUploadedFiles[file.toString().split('/').pop()]
              .name
          "
          style="width: 100%; height: 80vh"
        ></ngx-doc-viewer>

        <div
          *ngIf="
            !imageShowForUploadedFiles[file.toString().split('/').pop()] &&
            audioShowForUploadedFiles[file.toString().split('/').pop()] &&
            !videoShowForUploadedFiles[file.toString().split('/').pop()]
          "
          class="inner-content-center"
        >
          <audio controls>
            <source [src]="Data" [type]="'audio/' + Ext" />
          </audio>
        </div>

        <div
          *ngIf="
            !imageShowForUploadedFiles[file.toString().split('/').pop()] &&
            !audioShowForUploadedFiles[file.toString().split('/').pop()] &&
            videoShowForUploadedFiles[file.toString().split('/').pop()]
          "
          class="inner-content-center"
        >
          <video width="320" height="240" controls>
            <source [src]="Data" [type]="'video/' + Ext" />
          </video>
        </div>

        <div
          class="upper_div"
          *ngIf="
            imageShowForUploadedFiles[file.toString().split('/').pop()] && false
          "
        >
          <i class="fa fa-plus" (click)="onZoomIn(file)" aria-hidden="true"></i>
          <i
            class="fa fa-minus ml-3"
            (click)="onZoomOut(file)"
            aria-hidden="true"
          ></i>
        </div>
      </nb-card>
    </ng-template>
  </ngb-carousel>
</ng-container>

<ng-container *ngIf="uploadedFiles && uploadedFiles.length == 0">
  <nb-card
    [ngClass]="{ main_layout: !imageShow }"
    class="file-preview-dialog"
    [nbSpinner]="eventService.loading"
    nbSpinnerSize="large"
    nbSpinnerStatus="primary"
  >
    <div class="upper_div_download">
      <div class="save_as_but">
        <i
          class="fa fa-search-plus"
          *ngIf="imageShow"
          (click)="onZoomIn()"
          aria-hidden="true"
        ></i>
        <i
          class="fa fa-search-minus ml-3 mr-3"
          *ngIf="imageShow"
          (click)="onZoomOut()"
          aria-hidden="true"
        ></i>
        <i
          class="fa fa-download"
          [matMenuTriggerFor]="menu"
          aria-hidden="true"
        ></i>
        <i
          class="fa fa-close"
          style="margin-left: 30px"
          (click)="this.ref.close()"
          aria-hidden="true"
        ></i>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="onImage()">Direct Download</button>
          <button mat-menu-item [matMenuTriggerFor]="menu1">Save to</button>
        </mat-menu>
        <mat-menu #menu1="matMenu">
          <button
            mat-menu-item
            *ngFor="let lookup of lookupList"
            (click)="addNewFile(lookup)"
          >
            {{ lookup }}
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="main" *ngIf="imageShow">
      <div [ngClass]="!isZoom ? 'all_image' : 'onZoom'">
        <img [src]="Data" alt="" id="image" />
        <div class="imglayout">
          <i class="fas fa-times close_icon mr-2" (click)="onCancel()"></i>
          <nb-icon class="d_icon" icon="download" (click)="onImage()"></nb-icon>
        </div>
      </div>
    </div>
    <ngx-doc-viewer
      *ngIf="!imageShow && !audioShow && !videoShow"
      queryParams="hl=Nl"
      [url]="selectedDoc"
      [viewer]="selectedViewer.name"
      style="width: 100%; height: 80vh"
    ></ngx-doc-viewer>

    <div
      *ngIf="!imageShow && audioShow && !videoShow"
      class="inner-content-center"
    >
      <audio controls>
        <source [src]="Data" [type]="'audio/' + Ext" />
      </audio>
    </div>

    <div
      *ngIf="!imageShow && !audioShow && videoShow"
      class="inner-content-center"
    >
      <video width="320" height="240" controls>
        <source [src]="Data" [type]="'video/' + Ext" />
      </video>
    </div>
  </nb-card>
  <div class="upper_div" *ngIf="imageShow && false">
    <i class="fa fa-plus" (click)="onZoomIn()" aria-hidden="true"></i>
    <i class="fa fa-minus ml-3" (click)="onZoomOut()" aria-hidden="true"></i>
  </div>
</ng-container>
