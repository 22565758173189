import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ngx-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.scss"],
})
export class PrivacyPolicyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    document
      .querySelector(".chat-button")
      .addEventListener("click", function () {
        (window as any)?.tidioChatApi?.show();
        (window as any)?.tidioChatApi?.open();
      });
  }
}
