import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from '@angular/common';
import * as moment from "moment-timezone";
@Pipe({
  name: "dateCustomPipe"
})

export class DateCustomPipe implements PipeTransform {
  transform(value: any): any {
    if (value) {
      let d = new Date(value);
      console.log("dddddd", d);
      let hh = d.getHours();
      let m: any = d.getMinutes();
      let s: any = d.getSeconds();
      let dd = "AM";
      let h = hh;
      if (h >= 12) {
        h = hh - 12;
        dd = "PM";
      }
      if (h == 0) {
        h = 12;
      }
      m = m < 10 ? "0" + m : m;

      s = s < 10 ? "0" + s : s;
      let startDate = moment().subtract(7, 'd').format('YYYY-MM-DD');
      let endDate = moment(value).format('YYYY-MM-DD');
      let currentDate = moment(value).format('YYYY-MM-DD');
      let date;
      let presentDate = moment().format('YYYY-MM-DD');

      if (presentDate == currentDate) {
        date = new DatePipe('en-US').transform(d, 'M/d/yyyy');
        let replacement = h + ":" + m;

        replacement += " " + dd;
        value = 'Today' + ' ' + replacement;
      }
      else if (currentDate >= startDate && currentDate <= endDate) {
        date = new DatePipe('en-US').transform(d, 'EEEE');
        console.log("date -----", date)
        let replacement = h + ":" + m;

        replacement += " " + dd;
        value = date + ' ' + replacement;
      }
      else {
        date = new DatePipe('en-US').transform(d, 'M/d/yyyy');
        let replacement = h + ":" + m;

        replacement += " " + dd;
        value = date + ' ' + replacement;
      }

    }
    return value;
  }
}
