<nb-card style="height: 800px;width:1500px;">
  <nb-card-header>
    <span class="material-icons pull-right cursor-pointer" (click)="close()"
    nbTooltip="Resize this window" nbTooltipPlacement="top"
    >
      close_fullscreen
    </span>
  </nb-card-header>

  <nb-card-body>
    <ngx-codemirror [(ngModel)]="code" [options]="codeMirrorConfig">
    </ngx-codemirror>
  </nb-card-body>
</nb-card>
