import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {

  transform(value: any): any {

    let string = "";

    if(value){
      string = value.split("|")[0];
    }

    return string;
  }

}
