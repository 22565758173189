import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { EventEmiterService } from '@app/shared/services/event-emiter.service';
import { TableService } from '@app/shared/services/table.service';
import { NbDialogRef } from '@nebular/theme';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { viewerType } from './modules/document-viewer.component';

@Component({
  selector: 'ngx-file-preview-dialog',
  templateUrl: './file-preview-dialog.component.html',
  styleUrls: ['./file-preview-dialog.component.scss'],
})
export class FilePreviewDialogComponent implements OnInit,AfterViewInit {

  loading = false;

  @Input() Data: any;
  @Input() Ext: any;
  @Input() filename: string;
  @Input() uploadedFiles = [];
  @Input() currentFile = null;
  @ViewChild('myCarousel') myCarousel: NgbCarousel;

  public saveTo = new Subject();

  imageShow;
  audioShow = false;
  videoShow = false;
  viewers: {
    name: viewerType,
    docs: string[],
    custom: boolean,
    acceptedUploadTypes: string,
    viewerUrl?: string
  }[] = [];
  selectedViewer;
  selectedDoc;
  isZoom = false;

  lookupList = [];


  DataForUploadedFiles = {};
  imageShowForUploadedFiles = {};
  audioShowForUploadedFiles = {};
  videoShowForUploadedFiles = {};
  ExtForUploadedFiles = {};
  selectedViewerForUploadedFiles = {};
  selectedDocForUploadedFiles = {};
  isZoomForUploadedFiles = {};

  constructor(public ref: NbDialogRef<FilePreviewDialogComponent>,
    private tableService: TableService,
    public eventService: EventEmiterService) { }

  ngOnInit(): void {

    if (this.uploadedFiles && this.uploadedFiles.length) {
      this.codeToDistinguishUploadFiels();
    } else {
      this.codeToDistinguish();
    }

    this.getTableByName();

  }


  ngAfterViewInit(): void {
    // this.myCarousel.activeId = this.currentFile;
  }

  codeToDistinguish() {

    this.eventService.loading = true;
    const myRegexForImage = /(https?:\/\/.*\.(?:png|jpg|jpeg|gif))/i;
    const myRegexForAudio = /(https?:\/\/.*\.(?:mp3|wav|aac))/i;
    const myRegexForVideo = /(https?:\/\/.*\.(?:mp4|m4a|webp))/i;

    if (myRegexForImage.test(this.Data)) {
      this.imageShow = true;
      this.eventService.loading = false;
    } else if (this.Ext == 'bloblUrl') {
      this.selectedViewer = {
        name: 'url'
      };
      this.eventService.loading = false;
      this.selectedDoc = this.Data;
    } else if (this.Ext == 'pdf') {
      this.imageShow = false;
      this.viewers = [
        {
          name: 'google',
          docs: [
            this.Data,
          ],
          custom: false,
          acceptedUploadTypes: 'application/pdf',
        },
      ];
      this.selectedViewer = this.viewers[0];
      this.selectedDoc = this.selectedViewer.docs[0];
    } else if(myRegexForAudio.test(this.Data)){
      this.imageShow = false;
      this.audioShow = true;
      this.videoShow = false;
      this.eventService.loading = false;
    } else if(myRegexForVideo.test(this.Data)){
      this.imageShow = false;
      this.audioShow = false;
      this.videoShow = true;
      this.eventService.loading = false;
    } else {
      this.imageShow = false;
      this.viewers = [
        {
          name: 'office',
          docs: [
            this.Data,
          ],
          custom: false,
          acceptedUploadTypes: '*',
        },
      ];
      this.selectedViewer = this.viewers[0];
      this.selectedDoc = this.selectedViewer.docs[0];
    }

  }

  codeToDistinguishUploadFiels() {

    this.eventService.loading = true;
    const myRegex = /(https?:\/\/.*\.(?:png|jpg|jpeg|gif))/i;
    const myRegexForAudio = /(https?:\/\/.*\.(?:mp3|wav|aac))/i;
    const myRegexForVideo = /(https?:\/\/.*\.(?:mp4|m4a|webp))/i;

    this.uploadedFiles.forEach(file => {
      this.DataForUploadedFiles[file.toString().split('/').pop()] = file;
      this.ExtForUploadedFiles[file.toString().split('/').pop()] = this.DataForUploadedFiles[file.toString().split('/').pop()].split('.').pop();

      if (myRegex.test(this.DataForUploadedFiles[file.toString().split('/').pop()])) {
        this.imageShowForUploadedFiles[file.toString().split('/').pop()] = true;
        this.audioShowForUploadedFiles[file.toString().split('/').pop()] = false;
        this.videoShowForUploadedFiles[file.toString().split('/').pop()] = false;
        this.eventService.loading = false;
      } else if (this.ExtForUploadedFiles[file.toString().split('/').pop()] == 'bloblUrl') {
        this.selectedViewerForUploadedFiles[file.toString().split('/').pop()] = {
          name: 'url'
        };
        this.eventService.loading = false;
        this.selectedDocForUploadedFiles[file.toString().split('/').pop()] = this.DataForUploadedFiles[file.toString().split('/').pop()];
      } else if (this.ExtForUploadedFiles[file.toString().split('/').pop()] == 'pdf') {
        this.imageShowForUploadedFiles[file.toString().split('/').pop()] = false;
        this.audioShowForUploadedFiles[file.toString().split('/').pop()] = false;
        this.videoShowForUploadedFiles[file.toString().split('/').pop()] = false;
        this.viewers = [
          {
            name: 'google',
            docs: [
              this.DataForUploadedFiles[file.toString().split('/').pop()],
            ],
            custom: false,
            acceptedUploadTypes: 'application/pdf',
          },
        ];
        this.selectedViewerForUploadedFiles[file.toString().split('/').pop()] = this.viewers[0];
        this.selectedDocForUploadedFiles[file.toString().split('/').pop()] = this.selectedViewerForUploadedFiles[file.toString().split('/').pop()].docs[0];
      } else if(myRegexForAudio.test(this.Data)){
        this.imageShowForUploadedFiles[file.toString().split('/').pop()] = false;
        this.audioShowForUploadedFiles[file.toString().split('/').pop()] = true;
        this.videoShowForUploadedFiles[file.toString().split('/').pop()] = false;
        this.eventService.loading = false;
      } else if(myRegexForVideo.test(this.Data)){
        this.imageShowForUploadedFiles[file.toString().split('/').pop()] = false;
        this.audioShowForUploadedFiles[file.toString().split('/').pop()] = false;
        this.videoShowForUploadedFiles[file.toString().split('/').pop()] = true;
        this.eventService.loading = false;
      } else {
        this.imageShowForUploadedFiles[file.toString().split('/').pop()] = false;
        this.viewers = [
          {
            name: 'office',
            docs: [
              this.DataForUploadedFiles[file.toString().split('/').pop()],
            ],
            custom: false,
            acceptedUploadTypes: '*',
          },
        ];
        this.selectedViewerForUploadedFiles[file.toString().split('/').pop()] = this.viewers[0];
        this.selectedDocForUploadedFiles[file.toString().split('/').pop()] = this.selectedViewerForUploadedFiles[file.toString().split('/').pop()].docs[0];
      }
    })

  }

  onCancel(file?) {
    if(file){
      this.isZoomForUploadedFiles[file.toString().split('/').pop()] = false;
    } else{
      this.isZoom = false;
    }

    this.myCarousel = null;
    this.ref.close();
  }

  addNewFile(lookup) {
    this.saveTo.next(lookup);
    this.myCarousel = null;
    this.ref.close();
  }
  
  getFileName(filename) {
    let fileName = decodeURI(filename);
    return this.filename || fileName.match(/.*\/(.*)$/)[1];
  }

  async getTableByName() {
    let res: any = await this.tableService.getTableByName('Files');
    if (res && res.data && res.data[0] && res.data[0].columns && res.data[0].columns.length) {
      const data = res.data[0].columns.find(x => x.type == 'dropdown' && x.name === "relatedTo");
      if (data && data.options) {
        this.lookupList = data.options
      }
    }
  }

  onImage(file?) {
    const link = document.createElement('a');
    link.href = file ? this.DataForUploadedFiles[file.toString().split('/').pop()] : this.Data;
    link.download = this.getFileName(file ? this.DataForUploadedFiles[file.toString().split('/').pop()] : this.Data);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  onZoomIn(file?) {

    if(file){
      this.isZoomForUploadedFiles[file.toString().split('/').pop()] = true;
    } else{
      this.isZoom = true;
    }

    const image = document.getElementById('image');
    const width: any = image.clientWidth;
    image.style.width = (width + 25) + 'px';
  }

  onZoomOut(file?) {

    if(file){
      this.isZoomForUploadedFiles[file.toString().split('/').pop()] = true;
    } else{
      this.isZoom = true;
    }

    const image = document.getElementById('image');
    const width: any = image.clientWidth;
    image.style.width = (width - 25) + 'px';
  }
}
