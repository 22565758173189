<nb-card style="height: 200px;width: 400px;">
  <nb-card-header style="display: flex;justify-content: space-between;">
    Dependent Field
    <img class="close-btn" src="/assets/images/ic_close_24px.svg" (click)="close()">
  </nb-card-header>

  <nb-card-body>
    <ngx-all-fields *ngIf="openFlag" [field]="field" [dataToPassInForm]="dataToPassInForm"
    [showEditFieldForInlineEdit]="showEditFieldForInlineEdit" [lookupDataObject]="lookupDataObject"
    [forkJoinLookupResponse]="forkJoinLookupResponse" [formColumns]="formColumns"
    [tableDataFromViewToForm]="tableDataFromViewToForm" [filteredOptions]="filteredOptions" [tableName]="this.tableName"
    [id]="id" [oneTime]="oneTime" (emitToOneField)="closeOneField()">
  </ngx-all-fields>
  </nb-card-body>
</nb-card>


