import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { DynamicFormCommonService } from '@app/shared/dynamic-form-common.service';
import { ChatSubscriptionService } from '@app/shared/services/chat-subscription.service';
import { EventEmiterService } from '@app/shared/services/event-emiter.service';
import { TableService } from '@app/shared/services/table.service';
import { NbToastrService } from '@nebular/theme';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ngx-activity-watchers',
  templateUrl: './activity-watchers.component.html',
  styleUrls: ['./activity-watchers.component.scss']
})
export class ActivityWatchersComponent implements OnInit, AfterViewInit {
  objectKeys = Object.keys;
  @Input() showChats;
  @Input() tableInfo;
  @Input() refreshChat = true;
  @Input() subscriptionText;
  @Input() subscribers;
  @Input() isSelfSubscribed;
  @Input() Data;
  @Input() tableName;
  @Input() formColumns;
  @Input() recordData;
  @Input() showWatchers = true;
  @Input() fromViewForm = false;
  @Input() isActivity = false;
  @Input() formHeight = null;
  @Input() getSystemConfigVariable;
  private destroy$ = new Subject();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  isWatcherOpened = false;
  activityLoading = false;
  currentUser;
  gradientColor: any;
  // isActivity = false;
  systemConfigColor: any;
  constructor(private changeDetector: ChangeDetectorRef,
    public datePipe: DatePipe,
    private chatSubscriptionService: ChatSubscriptionService,
    private dynamicFormService: DynamicFormCommonService,
    private toastrService: NbToastrService,
    private eventEmiterService: EventEmiterService,
    private elem: ElementRef,
    private tableService: TableService,) {
    this.tableService.gradientColor.subscribe((color: any) => {
      this.elem.nativeElement.style.setProperty('--gradient-color', color);
      this.gradientColor = color
    })
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("userData"));
    if (!this.showChats) {
      this.isActivity = true
    }
    if (!this.isActivity) {
      this.showChats = true;
    }
  }

  ngAfterViewInit(): void {

    if (this.getSystemConfigVariable) {
      this.systemConfigColor = this.getSystemConfigVariable.IconColors;
      this.elem.nativeElement.style.setProperty('--icon-color', this.systemConfigColor);
    } else {
      this.tableService.$systemConfig.subscribe((res:any) => {
        if (res) {
          this.systemConfigColor = res.IconColors;
          this.elem.nativeElement.style.setProperty('--icon-color', this.systemConfigColor);
        }
      })
    }
  }

  getShortName(fullName) {
    return fullName.split(' ').map(n => n[0]).join('').toUpperCase();
  }
  getSubscribers(val) {
    this.subscribers = val;
    if (val.length > 0) {
      val.map((data) => {
        if (data._id == this.currentUser._id) {
          this.subscriptionText = "Stop watching";
          this.isSelfSubscribed = true;
        }
      });
    }
    this.changeDetector.detectChanges();
  }

  selfSubsription() {
    this.isSelfSubscribed = !this.isSelfSubscribed;
    if (this.isSelfSubscribed) {
      this.subscribers.push({
        _id: this.currentUser._id,
        firstName: this.currentUser.firstName,
        lastName: this.currentUser.lastName,
      });
      this.subscriptionText = "Stop watching";
      this.isSelfSubscribed = true;
      this.activateSubscription();
    } else {
      this.cancelSubscription(this.currentUser._id);
      this.subscriptionText = "Start watching";
      this.isSelfSubscribed = false;

    }
  }

  setIsActivity(isActivity: boolean) {
    this.activityLoading = true
    this.isActivity = isActivity
    if (!isActivity) {
      this.showChats = true;
    }
    if (this.eventEmiterService.realData = {}) {
      this.activityLoading = false
    }
    setTimeout(() => this.activityLoading = false, 2000);
  }

  cancelSubscription(id) {
    const sub = this.subscribers.findIndex((s) => s._id == id);
    this.subscribers.splice(sub, 1);
    this.chatSubscriptionService.cancelSubscription(
      {
        resourceId: this.Data?._id,
        userId: id,
      }).pipe(takeUntil(this.destroy$)).subscribe(
        (data: any) => {
          if (data.statusCode == 200) {
            if (id == this.currentUser._id) {
              this.subscriptionText = "Start watching";
              this.isSelfSubscribed = false;
            }
            this.toastrService.success(data.message, 'Action was  completed!');
          }
        });
  }

  activateSubscription(idForCreate?, resourceId?, showToaster = true) {
    const data = {
      resourceId: resourceId ? resourceId : this.Data?._id,
      userId: idForCreate ? idForCreate : this.currentUser._id,
      tableName: this.tableName,
      invitedBy: this.currentUser._id
    };

    let res: any = this.dynamicFormService.activateSubscription(data);

  }

  updateSubscribers(subscriber) {
    const checkAlreadyAdded = this.subscribers.findIndex(
      (s) => s._id == subscriber._id
    );
    if (checkAlreadyAdded == -1) {
      this.subscribers.push(subscriber);
      this.activateSubscription(subscriber._id);
    }
    this.subscribers.forEach((data) => {
      if (data._id == this.currentUser._id) {
        this.subscriptionText = "Stop watching";
        this.isSelfSubscribed = true;
      }
    });

  }

  watcherMenuOpened() {
    this.isWatcherOpened = true;
  }

  watcherMenuClosed() {
    this.isWatcherOpened = false;
  }
}
