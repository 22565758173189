<nb-card [nbSpinner]="loading" nbSpinnerSize="large" nbSpinnerStatus="primary"
  [ngClass]="{ 'new-sub-form-con': viewFlagForSubForm }" [ngClass]="{'popout-form':isPopOut}"
  class="cdk-drag new-form-design modal-body project-pop-up add-form-con align-set-default new-sub-form-con">
  <nb-card-header>
    <div class="row">
      <div class="col-md-6 sub-form-col">
        <span class="sub-form-title text-clr">{{ subFormName }}</span>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body class="modal-body project-pop-up main_body customHeight"
    style="display: block;min-height: inherit !important;">
    <ng-container *ngIf="subFormArray && subFormArray.length">
      <div class="sub-form-con-main container-fluid" style="border: none">
        <div class="sub-form-table">

          <!-- form array -->
          <div class="table-content row new-design-row" *ngFor="let form of subFormArray; let idx = index"
            [formGroup]="subFormBuilderArray[idx]">
            <nb-icon *ngIf="!viewFlagForSubForm" icon="minus-outline" [style.color]="this.systemConfigColor"
              class="sub-form-row-close-btn" (click)="removeRowFromList(idx)"></nb-icon>
            <!-- <td class="s-no">{{idx + 1}}</td> -->
            <div [ngClass]="[viewFlagForSubForm ? 'col-md-3' : '',
            !viewFlagForSubForm && field.fieldSize == 'tiny' ? 'col-md-3' : '',
            !viewFlagForSubForm && field.fieldSize == 'small' ? 'col-md-4' : '',
            !viewFlagForSubForm && field.fieldSize == 'medium' ? 'col-md-6' : '',
            !viewFlagForSubForm && field.fieldSize == 'large' ? 'col-md-12' : '',
            field.isProvideButton ? 'col-md-12' : '',
            show[idx] && show[idx][field.name] ? '' : 'no-content',
            field.isProvideButton && field.provideButtonAlignment == 'left' ? 'provide-left-btn' : '',
            field.isProvideButton && field.provideButtonAlignment == 'right' ? 'provide-right-btn' : '']"
              style="width: 100%;" *ngFor="
                let field of form.fields ? form.fields : form;
                let fieldIndex = index
              ">

              <div *ngIf="!field.isProvideButton">
                <ng-container [ngTemplateOutlet]="allFields" [ngTemplateOutletContext]="{field: field}">
                </ng-container>
              </div>

              <div *ngIf="field.isProvideButton" class="center-div-only no-margin">
                <ng-container
                  *ngIf="(this.Data && this.Data.length && this.Data[idx] && this.Data[idx][field.name]) ||
                  (this.showProvideButtonAsField && this.showProvideButtonAsField.length && this.showProvideButtonAsField[idx] && this.showProvideButtonAsField[idx][field.name])">
                  <div style="width: 100%;">

                    <ng-container [ngTemplateOutlet]="allFields" [ngTemplateOutletContext]="{field: field}">
                    </ng-container>

                  </div>
                </ng-container>

                <ng-container
                  *ngIf="((!this.Data && !this.showProvideButtonAsField[idx][field.name]) || (this.Data && this.Data.length && this.Data[idx]
                   && !this.Data[idx][field.name] && !this.showProvideButtonAsField[idx][field.name])
                   || (this.Data && this.Data.length && !this.Data[idx] && !this.showProvideButtonAsField[idx][field.name])) && !viewFlag">

                  <div class="provide-btn txt-clr" style="cursor: pointer;"
                    (click)="showProvideButtonFieldClick(field,idx)">+
                    {{field.label}}</div>

                </ng-container>
              </div>

              <ng-template #allFields let-field="field">
                <div [ngStyle]="{'display': viewFlagForSubForm ? 'contents' : ''}">
                  <ng-container *ngIf="field.type == 'autoNumber' && field.startValue > 0">
                    <ng-container *ngIf="!viewFlagForSubForm">
                      <span *ngIf="idx == 0" class="new-label label sub-form-label"
                        [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>
                      <mat-form-field appearance="outline">
                        <mat-label>{{ field.label }} <br /><small class="ultra_small">(System generated)</small>
                        </mat-label>
                        <input matInput [type]="'text'" [placeholder]="field.label" [formControlName]="field.name"
                          [readonly]="true" />
                        <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
                          [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
                      </mat-form-field>
                    </ng-container>

                    <ng-container *ngIf="viewFlagForSubForm">
                      <!-- Label for field type autoNumber -->
                      <span *ngIf="idx == 0" class="new-label label sub-form-label"
                        [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>

                      <!-- View Field for type autoNumber -->
                      <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
                        <img class="mat-icon notranslate material-icons mat-icon-no-color top04"
                          src="{{ field.icon }}" />
                      </div>
                      <div class="edit-class-form sub-form-edit"
                        *ngIf="tempData[this.subFormLookupName][idx] && tempData[this.subFormLookupName][idx][field.name]"
                        [innerHTML]="
                          tempData[this.subFormLookupName][idx][field.name]
                        "></div>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="
                      field.type == 'formula' &&
                      field.options &&
                      field.options.length &&
                      show[idx] && show[idx][field.name]
                    ">
                    <ng-container *ngIf="!viewFlagForSubForm">

                      <span *ngIf="idx == 0" class="new-label label sub-form-label"
                        [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>
                      <!-- For type STRING -->
                      <ng-container *ngFor="let formula of field.options">
                        <ng-container *ngIf="formula.type == 'string'">
                          <mat-form-field appearance="outline" style="width: 100%">
                            <mat-label>{{ field.label }}</mat-label>
                            <div *ngIf="field.icon" matPrefix class="mat-matPrefix">
                              <img class="mat-icon notranslate material-icons mat-icon-no-color top11"
                                src="{{ field.icon }}" />
                            </div>
                            <input matInput [type]="'text'" [placeholder]="field.label" [formControlName]="field.name"
                              (change)="evalExpressionForFormulaField(idx)" [readonly]="field.isReadOnly" [ngClass]="{
                                'disable-formula-field': field.isReadOnly
                              }" />
                            <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
                              [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
                          </mat-form-field>
                        </ng-container>

                        <!-- For type NUMBER -->
                        <ng-container *ngIf="formula.type == 'number'">
                          <mat-form-field appearance="outline" style="width: 100%">
                            <mat-label>{{ field.label }}</mat-label>
                            <div *ngIf="field.icon" matPrefix class="mat-matPrefix">
                              <img class="mat-icon notranslate material-icons mat-icon-no-color top11"
                                src="{{ field.icon }}" />
                            </div>
                            <input matInput [type]="'number'" [placeholder]="field.label" [formControlName]="field.name"
                              [readonly]="field.isReadOnly" (change)="evalExpressionForFormulaField(idx)" [ngClass]="{
                                'disable-formula-field': field.isReadOnly
                              }" />
                            <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
                              [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
                          </mat-form-field>
                        </ng-container>

                        <!-- For type RADIO -->
                        <ng-container *ngIf="formula.type == 'radio'">
                          <div class="checkbox" style="width: 100%">
                            <span class="position-relative with-help">{{ field.label }} &nbsp; &nbsp;:
                              <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
                                [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
                            </span>
                            <nb-radio-group class="radio" name="my-radio-group-{{ field.name }}"
                              [formControlName]="field.name" [disabled]="field.isReadOnly"
                              (valueChange)="onDependentFieldChanged($event, field._id, field.type, idx)">
                              <nb-radio value="yes"> Yes </nb-radio>
                              <nb-radio value="no"> No </nb-radio>
                            </nb-radio-group>
                          </div>
                        </ng-container>

                        <!--  For type DATE -->
                        <ng-container *ngIf="formula.type == 'date'">
                          <mat-form-field class="mb-20 due-date-col" appearance="outline" style="width: 100%">
                            <mat-label>{{ field.label }}</mat-label>
                            <input matInput [matDatepicker]="formpicker1" [placeholder]="field.label"
                              [formControlName]="field.name" [readonly]="field.isReadOnly" [ngClass]="{
                                'disable-formula-field': field.isReadOnly
                              }" />
                            <mat-datepicker-toggle matSuffix [for]="formpicker1" [disabled]="field.isReadOnly">
                            </mat-datepicker-toggle>
                            <mat-datepicker #formpicker1></mat-datepicker>
                            <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
                              [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
                          </mat-form-field>
                        </ng-container>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="viewFlagForSubForm">
                      <!-- Label for field type autoNumber -->
                      <span *ngIf="idx == 0" class="new-label label sub-form-label"
                        [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>

                      <!-- View Field for type autoNumber -->
                      <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
                        <img class="mat-icon notranslate material-icons mat-icon-no-color top04"
                          src="{{ field.icon }}" />
                      </div>

                      <div class="edit-class-form sub-form-edit"
                        *ngIf="tempData[this.subFormLookupName][idx] && tempData[this.subFormLookupName][idx][field.name] && field.isCurrency"
                        [innerHTML]="'$' + tempData[this.subFormLookupName][idx][field.name]">
                      </div>
                      <div class="edit-class-form sub-form-edit"
                        *ngIf="tempData[this.subFormLookupName][idx] && tempData[this.subFormLookupName][idx][field.name]  && !field.isCurrency"
                        [innerHTML]="tempData[this.subFormLookupName][idx][field.name]">
                      </div>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="field.name == 'zip'">
                    <ng-container *ngIf="!viewFlagForSubForm">
                      <span *ngIf="idx == 0" class="new-label label sub-form-label"
                        [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>
                      <mat-form-field appearance="outline" *ngIf="field.name == 'zip'" style="width: 100%">
                        <mat-label>{{ field.label }}</mat-label>
                        <div *ngIf="field.icon" matPrefix class="mat-matPrefix">
                          <img class="mat-icon notranslate material-icons mat-icon-no-color top11"
                            src="{{ field.icon }}" />
                        </div>
                        <input matInput [type]="field.type" (keyup)="listCity(subFormBuilderArray[idx])"
                          [placeholder]="field.label" [id]="field.name" [formControlName]="field.name"
                          [readonly]="field.isReadOnly" />
                        <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
                          [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
                      </mat-form-field>
                    </ng-container>

                    <ng-container *ngIf="viewFlagForSubForm">
                      <!-- Label for field type zip -->
                      <span *ngIf="idx == 0" class="new-label label sub-form-label"
                        [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>

                      <!-- View Field for type zip -->
                      <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
                        <img class="mat-icon notranslate material-icons mat-icon-no-color top04"
                          src="{{ field.icon }}" />
                      </div>
                      <div class="edit-class-form sub-form-edit"
                        *ngIf="tempData[this.subFormLookupName][idx] && tempData[this.subFormLookupName][idx][field.name]"
                        [innerHTML]="
                          tempData[this.subFormLookupName][idx][field.name]
                        "></div>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="field.name != 'zip'">
                    <ng-container *ngIf="
                        inputFields.includes(field.type) && show[idx] && show[idx][field.name]
                      ">
                      <ng-container *ngIf="!viewFlagForSubForm">

                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>
                        <mat-form-field class="mb-20" appearance="outline" style="width: 100%" [ngClass]="{
                            'mat-form-field-invalid':
                              subFormBuilderArray[idx].get(field.name).invalid &&
                              formSubmitted
                          }">
                          <mat-label>{{ field.label }}</mat-label>
                          <div *ngIf="field.icon" matPrefix class="mat-matPrefix">
                            <img class="mat-icon notranslate material-icons mat-icon-no-color top11"
                              src="{{ field.icon }}" />
                          </div>
                          <input *ngIf="!field.isPhone" matInput placeholder="{{ field.label }}" [type]="field.type"
                            [id]="field.name + idx" [placeholder]="field.label" [formControlName]="field.name"
                            (change)="evalExpressionForFormulaField(idx)" [readonly]="field.isReadOnly"
                            (keyup)="evalExpressionForFormulaField(idx)"
                            (keydown)="onKeyDown($event, idx, field.name)" />
                          <input *ngIf="field.isPhone" #item [value]="
                              subFormBuilderArray[idx].get(field.name).value
                                | phone
                            " (keyup)="evalExpressionForFormulaField(idx)"
                            (change)="evalExpressionForFormulaField(idx)" matInput placeholder="{{ field.label }}"
                            [type]="field.type" [placeholder]="field.label" [formControlName]="field.name"
                            [readonly]="field.isReadOnly" />
                          <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
                            [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
                        </mat-form-field>
                      </ng-container>

                      <ng-container *ngIf="viewFlagForSubForm">
                        <!-- Label for field type inputFields -->
                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>

                        <!-- View Field for type inputFields -->
                        <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
                          <img class="mat-icon notranslate material-icons mat-icon-no-color top04"
                            src="{{ field.icon }}" />
                        </div>
                        <div class="edit-class-form sub-form-edit"
                          *ngIf="tempData[this.subFormLookupName][idx] && tempData[this.subFormLookupName][idx][field.name] && field.name !== 'phoneNumber'">
                          <a class="value_text" href="mailto:{{tempData[this.subFormLookupName][idx][field.name]}}"
                            *ngIf="matchPattern(tempData[this.subFormLookupName][idx] && tempData[this.subFormLookupName][idx][field.name]) && !field.isPhone">{{
                            tempData[this.subFormLookupName][idx][field.name]
                            }}</a>
                          <div
                            *ngIf="!matchPattern(tempData[this.subFormLookupName][idx] && tempData[this.subFormLookupName][idx][field.name]) && !field.isPhone && !field.isCurrency">
                            {{
                            tempData[this.subFormLookupName][idx][field.name]
                            }}
                          </div>
                          <div
                            *ngIf="tempData[this.subFormLookupName][idx] && tempData[this.subFormLookupName][idx][field.name] && field.isCurrency">
                            ${{tempData[this.subFormLookupName][idx][field.name]}}
                          </div>
                          <div *ngIf="
                              !matchPattern(
                                tempData[this.subFormLookupName][idx] && tempData[this.subFormLookupName][idx][field.name]
                              ) && field.isPhone && !field.isCurrency
                            ">
                            {{
                            tempData[this.subFormLookupName][idx][field.name]
                            | phone
                            }}
                          </div>
                        </div>
                        <div class="edit-class-form sub-form-edit" *ngIf="
                            tempData[this.subFormLookupName][idx] && tempData[this.subFormLookupName][idx][field.name] &&
                            field.name == 'phoneNumber'
                          ">
                          {{
                          tempData[this.subFormLookupName][idx][field.name]
                          | phone
                          }}
                        </div>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="field.type == 'area' && show[idx] && show[idx][field.name]">
                      <ng-container *ngIf="!viewFlagForSubForm">

                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>
                        <mat-form-field class="mb-20 text-area-field" appearance="outline" style="width: 100%"
                          [ngClass]="{
                            'mat-form-field-invalid':
                              subFormBuilderArray[idx].get(field.name).invalid &&
                              formSubmitted
                          }">
                          <mat-label>{{ field.label }}</mat-label>
                          <div *ngIf="field.icon" matPrefix class="mat-matPrefix">
                            <img class="mat-icon notranslate material-icons mat-icon-no-color top11"
                              src="{{ field.icon }}" />
                          </div>
                          <textarea [rows]="field.textAreaLines" matInput placeholder="{{ field.label }}"
                            [placeholder]="field.label" [id]="field.name + idx" [formControlName]="field.name"
                            [readonly]="field.isReadOnly" (keyup)="evalExpressionForFormulaField(idx)"
                            (keydown)="onKeyDown($event, idx, field.name)"></textarea>
                          <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
                            [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
                        </mat-form-field>
                      </ng-container>

                      <ng-container *ngIf="viewFlagForSubForm">
                        <!-- Label for field type area -->
                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>

                        <!-- View Field for type area -->
                        <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
                          <img class="mat-icon notranslate material-icons mat-icon-no-color top04"
                            src="{{ field.icon }}" />
                        </div>
                        <div class="edit-class-form sub-form-edit textarea-val" *ngIf="
                            field.type == 'area' &&
                            tempData[this.subFormLookupName][idx] && tempData[this.subFormLookupName][idx][field.name]
                          " [innerHTML]="
                            tempData[this.subFormLookupName][idx][field.name]
                          "></div>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="field.type === 'dropdown' && show[idx] && show[idx][field.name]">
                      <ng-container *ngIf="!viewFlagForSubForm">

                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>
                        <mat-form-field class="mb-20 drop-down-field" appearance="outline" style="width: 100%"
                          [ngClass]="{
                            'mat-form-field-invalid':
                              subFormBuilderArray[idx].get(field.name).invalid &&
                              formSubmitted
                          }">
                          <div class="select-new">
                            <mat-label>{{ field.label }}</mat-label>
                            <ng-container *ngIf="!field.allowMultipleValues">
                              <mat-select [formControlName]="field.name" [id]="field.name" [placeholder]="field.label"
                                [disabled]="field.isReadOnly" (selectionChange)="
                                  onDependentFieldChanged(
                                    $event,
                                    field._id,
                                    field.type,
                                    idx,
                                    field.name
                                  )
                                ">
                                <mat-option *ngFor="let option of field.options" [value]="option" matRipple>
                                  {{ option }}
                                </mat-option>
                              </mat-select>
                            </ng-container>
                          </div>
                          <ng-container *ngIf="field.allowMultipleValues">
                            <mat-select [formControlName]="field.name" [id]="field.name" multiple
                              [placeholder]="field.label" [disabled]="field.isReadOnly" (selectionChange)="
                                onDependentFieldChanged(
                                  $event,
                                  field._id,
                                  field.type,
                                  idx,
                                  field.name
                                )
                              ">
                              <mat-option *ngFor="let option of field.options" [value]="option" matRipple>
                                {{ option }}
                              </mat-option>
                            </mat-select>
                          </ng-container>
                        </mat-form-field>
                        <ngx-help class="help-icon help-right help-dropdown" *ngIf="tutorials | helpPipe: field.label"
                          [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
                      </ng-container>

                      <ng-container *ngIf="viewFlagForSubForm">
                        <!-- Label for field type dropdown -->
                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>

                        <!-- View Field for type dropdown -->
                        <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
                          <img class="mat-icon notranslate material-icons mat-icon-no-color top04"
                            src="{{ field.icon }}" />
                        </div>
                        <div class="edit-class-form sub-form-edit textarea-val" *ngIf="
                            tempData[this.subFormLookupName][idx] && tempData[this.subFormLookupName][idx][field.name]
                          " [innerHTML]="
                            tempData[this.subFormLookupName][idx][field.name]
                          "></div>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="
                        field.type === 'dropdownWithImage' &&
                        show[idx] && show[idx][field.name]
                      ">
                      <ng-container *ngIf="!viewFlagForSubForm">

                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>
                        <div class="select-new">
                          <mat-label>{{ field.label }}</mat-label>
                          <ng-container *ngIf="!field.allowMultipleValues">
                            <ng-select [ngClass]="{
                                'mat-form-field-invalid':
                                  subFormBuilderArray[idx].get(field.name)
                                    .invalid && formSubmitted
                              }" [formControlName]="field.name" class="ng-select-latest" [id]="field.name"
                              [placeholder]="field.label" [disabled]="field.isReadOnly" (change)="
                                onDependentFieldChanged(
                                  $event,
                                  field._id,
                                  field.type,
                                  idx,
                                  field.name
                                )
                              ">
                              <ng-option *ngFor="let option of field.options" [value]="option.title">
                                <div class="drop-down-img-col">
                                  <img [src]="option.image" alt="" height="18" width="18" />
                                  <span class="select-text">{{ option.title }}</span>
                                </div>
                                <!-- <div class="col-md-4">

                                </div>
                                <div class="col-md-4">

                                </div> -->
                              </ng-option>
                            </ng-select>
                          </ng-container>
                        </div>
                        <ng-container *ngIf="field.allowMultipleValues">
                          <ng-select [multiple]="true" [ngClass]="{
                              'mat-form-field-invalid':
                                subFormBuilderArray[idx].get(field.name)
                                  .invalid && formSubmitted
                            }" [formControlName]="field.name" [id]="field.name" [placeholder]="field.label"
                            [disabled]="field.isReadOnly" (change)="
                              onDependentFieldChanged(
                                $event,
                                field._id,
                                field.type,
                                idx,
                                field.name
                              )
                            ">
                            <ng-option *ngFor="let option of field.options" [value]="option.title">
                              <div class="col-md-4">
                                {{ option.title }}
                              </div>
                              <div class="col-md-4">
                                <img [src]="option.image" alt="" height="25" width="25" />
                              </div>
                            </ng-option>
                          </ng-select>
                        </ng-container>
                        <ngx-help class="help-icon help-right help-dropdown" *ngIf="tutorials | helpPipe: field.label"
                          [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
                      </ng-container>

                      <ng-container *ngIf="
                          viewFlagForSubForm &&
                          field.type === 'dropdownWithImage' &&
                          show[idx] && show[idx][field.name]
                        ">
                        <!-- Label for field type dropdownWithImage -->
                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>


                        <div class="edit-class-form sub-form-edit textarea-val" *ngIf="
                            tempData[this.subFormLookupName][idx] && tempData[this.subFormLookupName][idx][field.name]
                          " [innerHTML]="
                            tempData[this.subFormLookupName][idx][field.name]
                          "></div>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="field.type == 'date' && show[idx] && show[idx][field.name]">
                      <ng-container *ngIf="!viewFlagForSubForm">
                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>
                        <mat-form-field class="mb-20 due-date-col"
                          *ngIf="field.type == 'date' && show[idx] && show[idx][field.name]" appearance="outline"
                          style="width: 100%" [ngClass]="{
                            'mat-form-field-invalid':
                              subFormBuilderArray[idx].get(field.name).invalid &&
                              formSubmitted
                          }">
                          <mat-label>{{ field.label }}</mat-label>
                          <input matInput [matDatepicker]="formpicker" [id]="field.name" [placeholder]="field.label"
                            [formControlName]="field.name" [readonly]="field.isReadOnly"
                            (dateChange)="evalExpressionForFormulaField(idx)"
                            (dateInput)="evalExpressionForFormulaField(idx)" />
                          <mat-datepicker-toggle matSuffix [for]="formpicker"
                            (change)="evalExpressionForFormulaField(idx)">
                          </mat-datepicker-toggle>
                          <mat-datepicker #formpicker></mat-datepicker>
                          <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
                            [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
                        </mat-form-field>
                      </ng-container>

                      <ng-container *ngIf="viewFlagForSubForm">
                        <!-- Label for field type date -->
                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>

                        <!-- View Field for type date -->

                        <div class="edit-class-form sub-form-edit" *ngIf="
                            (field.type == 'date' || field.type == 'dateTime') &&
                            tempData[this.subFormLookupName][idx] && tempData[this.subFormLookupName][idx][field.name]
                          ">
                          <img class="mat-icon notranslate material-icons mat-icon-no-color top04"
                            src="{{ field.icon }}" />

                          {{
                          tempData[this.subFormLookupName][idx][field.name]
                          | dateCustomPipe
                          }}
                        </div>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="field.type == 'dateTime' && show[idx] && show[idx][field.name]">
                      <ng-container *ngIf="!viewFlagForSubForm">
                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>
                        <div class="date-time-pic-cus mb-20" [ngClass]="{
                            'is-valid':
                              subFormBuilderArray[idx].get(field.name).invalid &&
                              formSubmitted
                          }">
                          <span class="date-time-pic-cus-title" [ngClass]="{
                              'title-active': dateTimePickerToggeledOn
                            }">{{ field.label }} :</span>
                          <app-date-time-picker [field]="field" [formControlName]="field.name" [id]="field.name" pla
                            (ngModelChange)="evalExpressionForFormulaField(idx)"></app-date-time-picker>
                          <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
                            [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="viewFlagForSubForm">
                        <!-- Label for field type dateTime -->
                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>

                        <!-- View Field for type dateTime -->
                        <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
                          <img class="mat-icon notranslate material-icons mat-icon-no-color top04"
                            src="{{ field.icon }}" />
                        </div>
                        <div class="edit-class-form sub-form-edit" *ngIf="
                            (field.type == 'date' || field.type == 'dateTime') &&
                            tempData[this.subFormLookupName][idx] && tempData[this.subFormLookupName][idx][field.name]
                          ">
                          {{
                          tempData[this.subFormLookupName][idx][field.name]
                          | dateCustomPipe
                          }}
                        </div>
                      </ng-container>
                    </ng-container>

                    <div class="status-buttonss-form possition-relative" style="width: 100%"
                      *ngIf="field.type == 'status' && show[idx] && show[idx][field.name]" [ngClass]="{
                        'is-invalid':
                          subFormBuilderArray[idx].get(field.name).invalid &&
                          formSubmitted
                      }">
                      <!-- Label for field type status -->
                      <span class="new-label label sub-form-label" *ngIf="idx == 0"
                        [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{
                        field.label }}:
                        <span *ngIf="field.isRequired" style="color: #f44336">*</span>
                      </span>

                      <!-- Field input for type of status -->
                      <div class="status-buttonss-form possition-relative" [ngClass]="{
                          l: field.fieldSize == 'large',
                          s_s: field.fieldSize == 'tiny',
                          s: field.fieldSize == 'small',
                          m: field.fieldSize == 'medium'
                        }">
                        <mat-form-field appearance="outline">
                          <div class="status-btn-flex">
                            <mat-label>
                              <span class="status-color-bar txt-clr" [ngStyle]="{
                                  background: colorSetter[idx][field.name]
                                }"></span>

                              <i class="fas fa-circle mr-1" [ngStyle]="{ color: colorSetter[idx][field.name] }"></i>
                              <span class="status-label-text" [title]="statuses[field.name]">
                                {{
                                statuses[idx][field.name]
                                ? statuses[idx][field.name]
                                : field.label
                                }}
                              </span>
                            </mat-label>

                            <mat-select [disabled]="field.isReadOnly" [ngStyle]="{
                                display: field.isReadOnly ? 'none' : ''
                              }" [formControlName]="field.name">
                              <div class="px-3">
                                <mat-form-field>
                                  <input matInput #statusSearch placeholder="Search"
                                    (click)="$event.stopPropagation()" />
                                </mat-form-field>
                              </div>
                              <mat-option [class.mat-active]="
                              status.status === statuses[idx][field.name]
                            " *ngFor="
                              let status of field.statusOptions
                                | searchStatusOptions: statusSearch.value
                            " [value]="status" (click)="onDone(status, field, idx)">
                                <i class="fas fa-circle" [ngStyle]="{ color: status.color }"></i>
                                {{ status.status }}
                              </mat-option>
                            </mat-select>
                          </div>
                        </mat-form-field>
                        <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
                          [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
                      </div>

                      <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
                        [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
                    </div>

                    <ng-container *ngIf="field.type == 'checkbox' && show[idx] && show[idx][field.name]">
                      <ng-container *ngIf="!viewFlagForSubForm">

                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>
                        <div class="checkbox" style="width: 100%" [ngClass]="{
                            'is-invalid':
                              field.checkForValidationInSubForm && formSubmitted
                          }">
                          <span class="position-relative with-help">{{ field.label }} &nbsp; &nbsp;:
                            <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
                              [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
                          </span>
                          <ng-container [formArrayName]="field.name" *ngFor="let item of field.options; let i = index">
                            <nb-checkbox (change)="
                                onDependentFieldChanged(
                                  $event,
                                  field._id,
                                  field.type,
                                  idx,
                                  field.name,
                                  field
                                )
                              " class="inner_checkbox" [formControlName]="i" [id]="field.name"
                              [disabled]="field.isReadOnly" [checked]="field">{{ item }}
                            </nb-checkbox>
                          </ng-container>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="
                          viewFlagForSubForm &&
                          field.type == 'checkbox' &&
                          show[idx] && show[idx][field.name]
                        ">
                        <!-- Label for field type checkbox -->
                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>

                        <!-- View Field for type checkbox -->
                        <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
                          <img class="mat-icon notranslate material-icons mat-icon-no-color top04"
                            src="{{ field.icon }}" />
                        </div>
                        <div class="edit-class-form sub-form-edit" *ngIf="
                            tempData[this.subFormLookupName][idx] && tempData[this.subFormLookupName][idx][field.name]
                          " [innerHTML]="
                            tempData[this.subFormLookupName][idx][field.name]
                          "></div>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="field.type == 'radio' && show[idx] && show[idx][field.name]">
                      <ng-container *ngIf="!viewFlagForSubForm">
                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>
                        <div class="checkbox" style="width: 100%" [ngClass]="{
                            'is-invalid':
                              subFormBuilderArray[idx].get(field.name).invalid &&
                              formSubmitted
                          }">
                          <span class="position-relative with-help">{{ field.label }} &nbsp; &nbsp;:
                            <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
                              [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
                          </span>
                          <nb-radio-group class="radio" name="my-radio-group-{{ field.name }}{{ idx }}"
                            [formControlName]="field.name" [disabled]="field.isReadOnly" [id]="field.name">
                            <nb-radio (valueChange)="
                                onDependentFieldChanged(
                                  $event,
                                  field._id,
                                  field.type,
                                  idx,
                                  field.name
                                )
                              " *ngFor="let item of field.options" [value]="item">
                              {{ item }}
                            </nb-radio>
                          </nb-radio-group>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="viewFlagForSubForm">
                        <!-- Label for field type recordType -->
                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>

                        <!-- View Field for type recordType -->
                        <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
                          <img class="mat-icon notranslate material-icons mat-icon-no-color top04"
                            src="{{ field.icon }}" />
                        </div>
                        <div class="edit-class-form sub-form-edit" *ngIf="
                            tempData[this.subFormLookupName][idx] && tempData[this.subFormLookupName][idx][field.name]
                          " [innerHTML]="
                            tempData[this.subFormLookupName][idx][field.name]
                          "></div>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="
                        field.type == 'richTextArea' && show[idx] && show[idx][field.name]
                      ">
                      <ng-container *ngIf="!viewFlagForSubForm">
                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>
                          2
                        <div class="l text-area-con mb-20" style="width: 100%" [ngClass]="{
                            'is-invalid':
                              subFormBuilderArray[idx].get(field.name).invalid &&
                              formSubmitted
                          }">
                          <span class="position-relative with-help">{{ field.label }} &nbsp; &nbsp;:
                            <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
                              [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
                          </span>
                          <editor [init]="{
                              plugins: 'autoresize',
                              skin: false,
                              autoresize_bottom_margin: 10,
                              menubar: 'edit insert view format table tools help'
                            }" apiKey="lrae10dbb64q2td53f0wfxyccbptkf3pi1kaq2alw8ryqkpv"
                            (ngModelChange)="evalExpressionForFormulaField(idx)" [formControlName]="field.name">
                          </editor>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="viewFlagForSubForm">
                        <!-- Label for field type recordType -->
                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>

                        <!-- View Field for type recordType -->
                        <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
                          <img class="mat-icon notranslate material-icons mat-icon-no-color top04"
                            src="{{ field.icon }}" />
                        </div>
                        <div class="edit-class-form sub-form-edit" *ngIf="
                            tempData[this.subFormLookupName][idx] && tempData[this.subFormLookupName][idx][field.name]
                          " [innerHTML]="
                            tempData[this.subFormLookupName][idx][field.name]
                          "></div>
                      </ng-container>
                    </ng-container>

                    <ng-container *ngIf="
                        field.type == 'lookup' &&
                        show[idx] && show[idx][field.name] &&
                        !field.loadAsDropDown
                      ">
                      <ng-container *ngIf="!viewFlagForSubForm">
                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>
                        <div class="select-new" style="width: 100%" [ngClass]="{
                            error_color: lookupFieldRequired[field.name],
                            l: field.fieldSize == 'large',
                            s_s: field.fieldSize == 'tiny',
                            s: field.fieldSize == 'small',
                            m: field.fieldSize == 'medium',
                            'mat-form-field-invalid':
                              !lookupValue[idx][field.name].length &&
                              formSubmitted &&
                              field.isRequired
                          }">
                          <mat-label>{{ field.label }}</mat-label>
                          <ng-select [items]="filteredOptions[idx][field.name]" bindLabel="value" #in (keyup)="
                              dynamicSearch($event, field, idx, in.searchTerm)
                            " appendTo="body" [notFoundText]="dropDownText[idx][field.name]" [maxSelectedItems]="
                              !field.allowMultipleValues ? 1 : undefined
                            " (ngModelChange)="updatedVal($event, field.name, idx)"
                            [ngModelOptions]="{ standalone: true }" [id]="field.name" placeholder="{{ field.label }}"
                            [compareWith]="compareFn" (change)="
                              onSelectionChange(
                                lookupValue[idx][field.name],
                                field,
                                idx,
                                fieldIndex
                              )
                            " [multiple]="true" [(ngModel)]="lookupValue[idx][field.name]" clearable="true"
                            class="ng-select-latest">
                            <ng-template ng-footer-tmp>
                              <div *ngIf="loadingAPI" [nbSpinner]="true" nbSpinnerStatus="info">
                                Loading ...
                              </div>
                              <div (click)="
                                  in.close(); addSubForm(field, fieldIndex, idx)
                                " class="hover-addnew">
                                Add New {{ field.lookupTableName }}
                              </div>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                              <div
                                (click)="onShowLookupDetail($event,field,item,item.id,editNotLoadAsDropdownLookupPopover, 'editNotLoadAsDropdownLookupPopover')"
                                [ngbPopover]="templateRef5" placement="bottom" container="body"
                                #editNotLoadAsDropdownLookupPopover="ngbPopover" [autoClose]="'outside'">
                                <i class="fa fa-window-close" (click)="removeSelected(item, field)">x</i>
                                <b style="font-size: 16px">{{ item.value }}</b>
                              </div>
                            </ng-template>
                          </ng-select>
                        </div>

                        <ng-template #templateRef5>
                          <ngx-lookup-modal-detail *ngIf="lookupDetailClicked"
                            (viewModalEmit)="onEmitFromLookupPopoverForModal($event)" [data]="lookupDetailItem"
                            [reference]="ref" [tableIdFromView]="field.lookupTableId" [fromView]="true"
                            [name]="field.name" [tableData]="tableDataForForms"
                            [dataForLookupDetail]="dataForLookupDetail" [tableName]="tableForLookupDetail"
                            [formColumns]="subFormFields">
                          </ngx-lookup-modal-detail>
                        </ng-template>

                        <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
                          [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
                      </ng-container>

                      <ng-container *ngIf="viewFlagForSubForm">
                        <!-- Label for field type lookup -->
                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>

                        <!-- View Field for type lookup -->
                        <div matPrefix class="mat-matPrefix" *ngIf="field.icon">
                          <img class="mat-icon notranslate material-icons mat-icon-no-color top04"
                            src="{{ field.icon }}" />
                        </div>

                        <div *ngIf="lookupDataObject[idx]" class="chipset-custom-main">
                          <div style="cursor: pointer" (click)="
                              onShowLookupDetail(
                                $event,
                                field,
                                Data,
                                val.lookupId,
                                notLoadAsDropdownLookupPopover,
                                'notLoadAsDropdownLookupPopover',
                                idx
                              )
                            " [ngbPopover]="templateRef1" placement="bottom" container="body"
                            #notLoadAsDropdownLookupPopover="ngbPopover" [autoClose]="'outside'"
                            *ngFor="let val of lookupDataObject[idx][field.name]"
                            class="chipset-custom edit-class-form sub-form-edit dot_line">
                            <!-- <mat-chip-list aria-label="Fish selection">

                          <mat-chip *ngFor="let value of val.valueToDisplay; let ind = index">
                            {{val.isPhoneField[val.idFields[ind]] ? (value | phone) : value}}
                          </mat-chip>
                        </mat-chip-list> -->

                            <ng-container *ngFor="
                                let value of val.valueToDisplay;
                                let ind = index
                              ">
                              <span *ngIf="
                                  ind == 0 && ind == val.valueToDisplay.length - 1
                                ">
                                {{ value }}
                              </span>
                              <span *ngIf="
                                  ind != 0 && ind == val.valueToDisplay.length - 1
                                ">
                                {{ value }}</span>
                              <span *ngIf="
                                  ind != 0 && ind != val.valueToDisplay.length - 1
                                ">
                                {{ value }},
                              </span>
                              <span *ngIf="
                                  ind == 0 && ind != val.valueToDisplay.length - 1
                                ">
                                {{ value }},
                              </span>
                            </ng-container>
                          </div>
                        </div>

                        <ng-template #templateRef1>
                          <ngx-lookup-modal-detail id="pop-open1" (viewModalEmit)="
                              onEmitFromLookupPopoverForModal($event)
                            " [data]="lookupDetailItem" [reference]="ref" [tableIdFromView]="field.lookupTableId"
                            [fromView]="true" [name]="fieldNameForLookup" [tableData]="tableDataForForms"
                            [dataForLookupDetail]="dataForLookupDetail" [tableName]="tableForLookupDetail"
                            [formColumns]="tableFields">
                          </ngx-lookup-modal-detail>
                        </ng-template>
                      </ng-container>
                    </ng-container>

                    <ng-container class="fltt-left" *ngIf="
                        field.type == 'lookup' &&
                        show[idx] && show[idx][field.name] &&
                        field.loadAsDropDown
                      " appearance="outline">
                      <ng-container *ngIf="!viewFlagForSubForm">

                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>
                        <div class="select-new" style="width: 100%" [ngClass]="{
                            l: field.fieldSize == 'large',
                            s_s: field.fieldSize == 'tiny',
                            s: field.fieldSize == 'small',
                            m: field.fieldSize == 'medium',
                            'mat-form-field-invalid':
                              !lookupValue[idx][field.name].length &&
                              formSubmitted &&
                              field.isRequired
                          }">
                          <mat-label>{{ field.label }}</mat-label>
                          <ng-select [items]="
                              filteredOptions[idx][field.name]
                                | filter
                                  : lookupValue[idx][field.name]
                                  : in.searchTerm
                            " bindLabel="value" #in appendTo="body" [notFoundText]="'No record found'"
                            [maxSelectedItems]="
                              !field.allowMultipleValues ? 1 : undefined
                            " (ngModelChange)="updatedVal($event, field.name, idx)"
                            [ngModelOptions]="{ standalone: true }" [id]="field.name" placeholder="{{ field.label }}"
                            [compareWith]="compareFn" (change)="
                              onSelectionChange(
                                lookupValue[idx][field.name],
                                field,
                                idx,
                                fieldIndex
                              )
                            " [multiple]="true" [(ngModel)]="lookupValue[idx][field.name]" clearable="true"
                            class="ng-select-latest">
                            <ng-template ng-footer-tmp>
                              <div *ngIf="loadingAPI" [nbSpinner]="true" nbSpinnerStatus="info">
                                Loading ...
                              </div>
                              <div (click)="
                                  in.close(); addSubForm(field, fieldIndex, idx)
                                " class="hover-addnew">
                                Add New {{ field.lookupTableName }}
                              </div>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                              <div
                                (click)="onShowLookupDetail($event,field,item,item.id,editLoadAsDropdownLookupPopover, 'editLoadAsDropdownLookupPopover')"
                                [ngbPopover]="templateRef4" placement="bottom" container="body"
                                #editLoadAsDropdownLookupPopover="ngbPopover" [autoClose]="'outside'">
                                <i class="fa fa-window-close" (click)="removeSelected(item, field)">x</i>
                                <b style="font-size: 16px">{{ item.value }}</b>
                              </div>
                            </ng-template>
                          </ng-select>
                        </div>

                        <ng-template #templateRef4>
                          <ngx-lookup-modal-detail *ngIf="lookupDetailClicked"
                            (viewModalEmit)="onEmitFromLookupPopoverForModal($event)" [data]="lookupDetailItem"
                            [reference]="ref" [tableIdFromView]="field.lookupTableId" [fromView]="true"
                            [name]="field.name" [tableData]="tableDataForForms"
                            [dataForLookupDetail]="dataForLookupDetail" [tableName]="tableForLookupDetail"
                            [formColumns]="subFormFields">
                          </ngx-lookup-modal-detail>
                        </ng-template>
                      </ng-container>

                      <ng-container *ngIf="viewFlagForSubForm">
                        <!-- Label for field type lookup -->
                        <span *ngIf="idx == 0" class="new-label label sub-form-label"
                          [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>

                        <!-- View Field for type lookup -->
                        <div matPrefix class="mat-matPrefix" *ngIf="field.icon">
                          <img class="mat-icon notranslate material-icons mat-icon-no-color top04"
                            src="{{ field.icon }}" />
                        </div>
                        <div *ngIf="lookupDataObject[idx]" class="chipset-custom-main">
                          <div style="cursor: pointer" (click)="
                              onShowLookupDetail(
                                $event,
                                field,
                                Data,
                                val.lookupId,
                                loadAsDropdownLookupPopover,
                                'loadAsDropdownLookupPopover',
                                idx
                              )
                            " #loadAsDropdownLookupPopover="ngbPopover" [ngbPopover]="templateRef3" container="body"
                            placement="bottom" [autoClose]="'outside'"
                            *ngFor="let val of lookupDataObject[idx][field.name]"
                            class="chipset-custom edit-class-form sub-form-edit dot_line">
                            <!-- <mat-chip-list aria-label="Fish selection">
                          <mat-chip *ngFor="let value of val.valueToDisplay; let ind = index">{{val.isPhoneField[val.idFields[ind]] ? (value | phone) : value}}</mat-chip>
                        </mat-chip-list> -->
                            <ng-container *ngFor="
                                let value of val.valueToDisplay;
                                let ind = index
                              ">
                              <span *ngIf="
                                  ind == 0 && ind == val.valueToDisplay.length - 1
                                ">
                                {{ value }}
                              </span>
                              <span *ngIf="
                                  ind != 0 && ind == val.valueToDisplay.length - 1
                                ">
                                {{ value }}</span>
                              <span *ngIf="
                                  ind != 0 && ind != val.valueToDisplay.length - 1
                                ">
                                {{ value }},
                              </span>
                              <span *ngIf="
                                  ind == 0 && ind != val.valueToDisplay.length - 1
                                ">
                                {{ value }},
                              </span>
                            </ng-container>
                          </div>
                        </div>

                        <ng-template #templateRef3>
                          <ngx-lookup-modal-detail (viewModalEmit)="
                              onEmitFromLookupPopoverForModal($event)
                            " [data]="lookupDetailItem" [reference]="ref" [tableIdFromView]="field.lookupTableId"
                            [fromView]="true" [name]="fieldNameForLookup" [tableData]="tableDataForForms"
                            [dataForLookupDetail]="dataForLookupDetail" [tableName]="tableForLookupDetail"
                            [formColumns]="tableFields">
                          </ngx-lookup-modal-detail>
                        </ng-template>
                      </ng-container>
                    </ng-container>

                    <ng-container class="fltt-left" *ngIf="field.type == 'recordType'" appearance="outline">
                      <div class="select-new" style="width: 100%" [ngClass]="{
                          'is-invalid':
                            subFormBuilderArray[idx].get(field.name).invalid &&
                            formSubmitted
                        }">
                        <mat-label>{{ field.label }}</mat-label>
                        <div *ngIf="field.icon" matPrefix class="mat-matPrefix">
                          <img class="mat-icon notranslate material-icons mat-icon-no-color top11"
                            src="{{ field.icon }}" />
                        </div>
                        <ng-select [items]="field.options ? field.options : '[]'" #in appendTo="body"
                          [notFoundText]="'No record found'" [id]="field.name" placeholder="{{ field.label }}"
                          [multiple]="false" [formControlName]="field.name" clearable="true" class="ng-select-latest">
                        </ng-select>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="field.type == 'file' && show[idx] && show[idx][field.name]">
                      <div class="wrap_field" style="width: 100%" [ngClass]="{
                          'is-invalid':
                            subFormBuilderArray[idx].get(field.name).invalid &&
                            formSubmitted
                        }">
                        <!-- <span>{{field.label}} :</span> -->
                        <span class="position-relative with-help">{{ field.label }} &nbsp; &nbsp;:
                          <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
                            [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
                        </span>
                        <div class="file_upload">
                          <div class="files">
                            <div class="upload_file">
                              <div class="droparea" (dragover)="onDragOver($event)" (drop)="onDrop($event, field.name)"
                                (dragleave)="onDragLeave($event)" [class.active]="isActive">
                                <input type="file" name="{{ field.name }}" class="browse-file" [id]="field.name"
                                  (change)="onSelectedFile($event, field.name)" multiple
                                  [readonly]="field.isReadOnly" />
                                <div class="upload_img">
                                  <img src="/assets/images/file-upload.png" />
                                </div>
                                <span class="drop_text">Choose files or Drag and drop files here</span>
                              </div>
                            </div>
                            <div class="w_100" *ngIf="
                                field.type === 'file' &&
                                uploadedFiles &&
                                uploadedFiles[field.name]
                              ">
                              <div class="s_file">
                                <div class="inner_files edit_file">
                                  <span class="file_name edit_file_inner" *ngFor="
                                      let f of uploadedFiles[field.name];
                                      let index = index
                                    ">
                                    <span class="{{ getFileExtension(f) }}"></span>&nbsp;&nbsp;
                                    <span class="text_inner">
                                      {{ getFileName(f) }}
                                    </span>
                                    <span class="flex_space"></span>
                                    <div class="delete_icon" (click)="onDelete(index, field.name)">
                                      <img src="assets/images/close.png" />
                                    </div>
                                  </span>
                                </div>
                              </div>
                              <div>
                                <nb-progress-bar class="progress_bar" *ngIf="uploadProgress > 0" aria-valuenow="25"
                                  aria-valuemin="0" aria-valuemax="100" [value]="uploadProgress" status="primary">
                                  {{ uploadProgress }}%</nb-progress-bar>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="!viewFlagForSubForm">
                      <mat-form-field class="mb-20" appearance="outline" style="width: 100%"
                        *ngIf="field.type === 'time' && show[idx] && show[idx][field.name]">
                        <mat-label>{{ field.label }}</mat-label>
                        <input matInput placeholder="{{ field.label }}" [type]="field.type" [placeholder]="field.label"
                          [formControlName]="field.name" [id]="field.name + idx" [readonly]="field.isReadOnly"
                          (keyup)="evalExpressionForFormulaField(idx)" (keydown)="onKeyDown($event, idx, field.name)"
                          (ngModelChange)="evalExpressionForFormulaField(idx)" />
                        <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
                          [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
                      </mat-form-field>
                    </ng-container>

                    <ng-container *ngIf="
                        viewFlagForSubForm && field.type === 'time' && show[idx] && show[idx][field.name]
                      ">
                      <!-- Label for field type time -->
                      <span *ngIf="idx == 0" class="new-label label sub-form-label"
                        [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>

                      <!-- View Field for type time -->
                      <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
                        <img class="mat-icon notranslate material-icons mat-icon-no-color top04"
                          src="{{ field.icon }}" />
                      </div>
                      <div class="edit-class-form sub-form-edit"
                        *ngIf="tempData[this.subFormLookupName][idx] && tempData[this.subFormLookupName][idx][field.name]"
                        [innerHTML]="
                          tempData[this.subFormLookupName][idx][field.name]
                        "></div>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-template>
            </div>

            <!-- <div
                *ngIf="((this.editform && this.Data && !this.Data[idx])||(!this.editform) ) && ((idx == 0 && showDelete) || idx != 0)"
                class="col-md-1 edit_but" style="cursor: pointer;" (click)="deleteSubFormLookup(idx, false)"><i class="fa fa-times" aria-hidden="true"></i></div>
              <div
                *ngIf="(this.editform && this.Data && this.Data[idx]) || (editform && this.Data && this.Data.length && !idx)"
                class="col-md-1 edit_but" style="cursor: pointer;" (click)="deleteSubFormLookup(idx, true)"><i class="fa fa-times" aria-hidden="true"></i></div> -->

          </div>
          <div class="table-content row new-design-row" style="padding: 3px 35px !important">
            <button *ngIf="!viewFlagForSubForm" type="button"
              class="save add-line-btn filled-dynamic-color add-line-btn" nbButton matRipple
              (click)="addSubFormFields()">
              +
            </button>
          </div>
        </div>
      </div>

      <!-- Action buttons -->
      <!-- <div class="button_show">
        <div class="button-col">
          <button type="button" class="save" nbButton matRipple (click)="addSubFormFields()">Add Line</button>
        </div>
        <div class="button-col">
          <button type="button" class="save" nbButton matRipple (click)="clearRows()">Clear All</button>
        </div>
        <div class="button-col">
          <button type="button" class="save" nbButton matRipple>Add Subtotal</button>
        </div>
      </div> -->

      <!-- invoice div -->
      <ng-container *ngIf="subFormName == 'Invoices'">
        <div class="container">
          <div class="row">
            <div class="col-md-5">
              <label>Message on invoice:</label><br />
              <textarea placeholder="Text your message here" cols="3" style="height: 150px; width: 300px">
              </textarea>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-5">
              <div class="row">
                <div class="col-md-12">
                  <label>Taxable subtotal:</label><br />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-select id="taxRate" placeholder="Select a sales tax rate">
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field appearance="outline">
                    <input matInput type="number" placeholder="Rate" id="taxRateIn" />
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-select id="discountRate" placeholder="Select discount percentage"></mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field appearance="outline">
                    <input matInput type="number" id="taxRateIn" />
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-md-3">Total:</div>
                <div class="col-md-4">
                  <!-- total amount -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>


  </nb-card-body>

  <nb-card-footer *ngIf="isPopOut && !viewFlagForSubForm" class="button_show custom-footer">
    <button class="appearance-filled cancel outline-dynamic-color status-primary" nbButton matRipple status="primary"
      (click)="onCancel()">Cancel</button>
    <button class="appearance-filled filled-dynamic-color nb-transition status-primary" type="submit" nbButton matRipple
      status="primary" (click)="emitValuesToParentByPopup()">Save</button>
  </nb-card-footer>

</nb-card>
