import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ngxPlural' })
export class PluralPipe implements PipeTransform {

  transform(label: string,input: number, pluralLabel: string = ''): string {
    if(input == 1000){
      if(label.charAt(label.length - 1) == 's' || label.charAt(label.length - 1) == 'S' ){
        return label.slice(0,-1);
      } else{
        return label
      }

    } else{
      input = input || 0;
      return input === 1
        ? `${input} ${label}`
        : pluralLabel
          ? `${input} ${pluralLabel}`
          : `${input} ${label}s`;
    }

  }
}
