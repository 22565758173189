import { Component, OnInit, Input, AfterViewInit, ElementRef } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { TableService } from '../../services/table.service';
import { CreateNote } from '../reminder-modal/reminder-modal.component';

@Component({
  selector: 'ngx-new-reminder-modal',
  templateUrl: './new-reminder-modal.component.html',
  styleUrls: ['./new-reminder-modal.component.scss']
})
export class NewReminderModalComponent implements OnInit, AfterViewInit {
  @Input('tableId') tableId;
  @Input('tableName') tableName;
  @Input('resourceId') resourceId;
  @Input('IdFieldData') IdFieldData;
  @Input('fromViewPage') fromViewPage = false;
  @Input('tableIcon') tableIcon;
  @Input('updateReminder') updateReminder;
  @Input('dueDate') dueDate;

  reminder: CreateNote = new CreateNote();
  currentDate;
  isSaveDisable: boolean = false;
  systemConfigColor: '';
  allIcons = localStorage.getItem('allIcons');
  currentTableIcon = {}
  oneTime: boolean;
  isRecurring: boolean;
  showFrequency: boolean;
  isStartDate: boolean = false;
  isEndDate: boolean = false;
  repeatEveryValues = [];
  public newDateValue = new Date();
  public startDateValue = new Date();
  public endDateValue = new Date();
  constructor(protected ref: NbDialogRef<NewReminderModalComponent>,
    private toaster: NbToastrService,
    private elem: ElementRef,
    private tableService: TableService) {
    this.currentDate = new Date();
    this.tableService.iconColors.subscribe((color: any) => {
      this.systemConfigColor = color;
      this.elem.nativeElement.style.setProperty('--text-color', this.systemConfigColor);
    })
  }

  ngOnInit(): void {
    if (this.updateReminder) {
      if (!this.updateReminder.notificationId) {
        this.updateReminder.notificationId = this.updateReminder._id;
      }
      if (this.updateReminder.frequency == 'oneTime' || this.updateReminder.frequency == 'recurring') {
        this.showFrequency = true;
        if (this.updateReminder.frequency == 'oneTime') {
          this.oneTime = true;
        } else {
          this.isRecurring = true;
        }
      }
      this.reminder.notes = this.updateReminder.notes ? this.updateReminder.notes : this.updateReminder.reminderDetails.notes;
      this.reminder.details = this.updateReminder.details ? this.updateReminder.details : this.updateReminder.reminderDetails.details;
      this.reminder.dueDate = this.updateReminder.dateTime ? this.updateReminder.dateTime : this.updateReminder.dueDate ?
        this.updateReminder.dueDate : this.updateReminder.reminderDetails.dueDate;
      this.reminder.frequency = this.updateReminder.frequency;
      this.reminder.repeatEveryValue = this.updateReminder.repeatEveryValue;
      this.reminder.repeatEvery = this.updateReminder.repeatEvery;
      this.reminder.start = this.updateReminder.start;
      this.reminder.end = this.updateReminder.end;
      this.reminder.oneTimeDue = this.updateReminder.oneTimeDue;
    } else {
      this.reminder = new CreateNote();
      this.reminder = { ...this.reminder, dueDate: this.dueDate ?? null }
    }
    console.log('this.reminder ', this.reminder)
    this.currentTableIcon = JSON.parse(this.allIcons)[this.tableName]
    for (var value = 1; value < 60; value++) {
      this.repeatEveryValues.push({ id: value });
    }
  }

  ngAfterViewInit(): void {
    this.tableService.runCustomStyling();
  }
  close() {
    this.ref.close(false);
  }

  closeTableReference() {
    this.fromViewPage = false;
  }

  saveReminder() {
    if (this.reminder.notes && this.reminder.details && this.reminder.dueDate) {
      if (this.reminder.frequency != null) {
        if (this.reminder.frequency == 'recurring' && this.reminder.repeatEvery == null ||
          this.reminder.repeatEveryValue == null || this.reminder.start == null || this.reminder.end == null) {
          this.toaster.danger('please select require fields')
          return
        } else if (this.reminder.frequency == 'oneTime' && this.reminder.oneTimeDue == null) {
          this.toaster.danger('please select require fields')
          return
        }
      }
      this.isSaveDisable = true;
      if (this.fromViewPage) {
        this.reminder.resourceId = this.resourceId;
        this.reminder.tableId = this.tableId;
        this.reminder.tableName = this.tableName;
      } else {
        this.reminder.resourceId = null;
        this.reminder.tableId = null;
        this.reminder.tableName = "";
      }

      if (this.updateReminder) {
        this.reminder.dueDate = new Date(this.reminder.dueDate);
        let id = this.updateReminder.notificationId ? this.updateReminder.notificationId : this.updateReminder.reminderDetails._id;
        this.tableService.updateNotes(id, this.reminder).subscribe(
          (res: any) => {
            this.isSaveDisable = false;
            if (res.statusCode === 200) {
              this.toaster.success(res.message, "Success");
              this.ref.close('Done');
            } else {
              this.toaster.danger(res.message, "Error");
            }
          },
          (error) => {
          }
        );
      } else {
        this.tableService.createNotes(this.reminder).subscribe(
          (res: any) => {
            this.isSaveDisable = false;
            if (res.statusCode === 201) {
              this.toaster.success(res.message, "Success");
              this.ref.close('Done');
            } else {
              this.toaster.danger(res.message, "Error");
            }
          },
          (error) => {
          }
        );
      }


    } else {
      this.toaster.warning("Enter all fields", "Required Fields");
    }
  }

  checkRecurring(event: any) {
    // let oneTimeCheck = document.getElementById('oneTimeCheck');
    if (event?.currentTarget?.checked == true) {
      this.oneTime = false;
      this.reminder.oneTimeDue = null;
      this.isRecurring = true;
      this.reminder.frequency = 'recurring';
      // oneTimeCheck['checked'] = false;
    } else {
      this.isRecurring = false;
      this.reminder.frequency = null;
    }
  }

  // checkOneTime(event: any) {
  //   let recurringCheck = document.getElementById('recurringCheck');
  //   if (event?.currentTarget?.checked == true && recurringCheck) {
  //     this.isRecurring = false;
  //     this.reminder.start = null;
  //     this.reminder.end = null;
  //     this.reminder.repeatEvery = null;
  //     this.reminder.repeatEveryValue = null;
  //     this.oneTime = true;
  //     this.reminder.frequency = 'oneTime';
  //     recurringCheck['checked'] = false;
  //   } else {
  //     this.oneTime = false;
  //     this.reminder.frequency = null;
  //   }
  // }

  // selectFrequency(val:any){
  //   if(val == 'recurring'){
  //     this.oneTime=false;
  //     this.reminder.oneTimeDue = null;
  //     this.isRecurring=true;
  //   }
  //   if(val=='oneTime'){
  //     this.isRecurring=false;
  //     this.reminder.start = null;
  //     this.reminder.end = null;
  //     this.reminder.repeatEvery = null;
  //     this.reminder.repeatEveryValue = null;
  //     this.oneTime=true;
  //   }
  //   if(val=="0: null"){
  //     this.isRecurring=false;
  //     this.oneTime=false;
  //     this.reminder.start = null;
  //     this.reminder.end = null;
  //     this.reminder.repeatEvery = null;
  //     this.reminder.repeatEveryValue = null;
  //     this.reminder.oneTimeDue = null;
  //   }
  // }
  startDate(val: any) {
    if (val == 'Choose date') {
      this.isStartDate = true
    } else {
      this.isStartDate = false;
    }
  }
  endDate(val: any) {
    if (val == 'Choose date') {
      this.isEndDate = true
    } else {
      this.isEndDate = false;
    }
  }
  repeatEvery(val: any) {
    this.repeatEveryValues = [];
    let reapeatNumber = null;
    if (val == 'hour') {
      reapeatNumber = 24
    } else if (val == 'day') {
      reapeatNumber = 31
    } else if (val == 'week') {
      reapeatNumber = 5
    } else if (val == 'month') {
      reapeatNumber = 12
    } else if (val == 'year') {
      reapeatNumber = 10
    } else if (val == 'minute') {
      reapeatNumber = 60
    }
    for (var value = 1; value < reapeatNumber; value++) {
      this.repeatEveryValues.push({ id: Number(value) });
    }
  }
}
