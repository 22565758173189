import { Component, OnInit, AfterViewInit, Input, HostListener } from '@angular/core';
import { TableService } from '@app/shared/services/table.service';
import { NbDialogRef } from '@nebular/theme';
@Component({
  selector: 'ngx-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit, AfterViewInit {

  @Input() heading = '';
  @Input() body = '';
  @Input() btnText = '';

  constructor(private tableService: TableService,
      protected dialog: NbDialogRef<ConfirmDeleteComponent>
    ) { }

  ngOnInit(): void {

  }

  @HostListener("window:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode == 27) {
      this.dialog.close();

    }
  }

  ngAfterViewInit(): void {
      this.tableService.runCustomStyling();
  }

  action(val){
    if(val == 'yes'){
      this.dialog.close('yes');
    }{
      this.dialog.close();
    }
  }
}
