<nb-card class="csv-upload-sec">
  <span class="csv-close material-icons material-symbols-outlined cursor-pointer" (click)="cancel()">
    cancel
  </span>
  <nb-card-body class="add-date-time-form">

    <div>
      <span *ngIf="errorMsg" class="error-msg">{{errorMsg}}</span>
      <!-- <a [href]="S3+item.fileName" download>
        <button [ngStyle]="{'background-color':systemConfigColor}" style="color: white;margin-right: 10px;" nbButton
          matRipple class="csv-btn-style">Download sample CSV file</button></a> -->
    </div>


    <div class="csv-heading">{{tableName}} CSV Upload</div>

    <ng-container *ngIf="uploadResponseMsg">
      <div [innerHTML]="uploadResponseMsg" class="csv-card csv-record-added mb-2"></div>
    </ng-container>

    <div class="files-folder files-only">
      <div class="heading">
        <div class="file_upload full-width">
          <div class="files">
            <div class="upload_file">
              <div class="droparea" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
                [class.active]="isActive" (drop)="onDrop($event)">
                <input type="file" name="" class="browse-file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  multiple (change)="onSelectedFile($event)" />
                <div class="upload_img">
                  <span class="material-icons-outlined csv-file-upload full-width">cloud_upload</span>
                  <span class="drop_text full-width">Drag and Drop your CSV File Here</span>
                  <span class="csv-click-msg full-width">Click on the button or drag & drop files here</span>
                  <div class="csv-browse-file">
                    <input type="file" name="" class="browse-file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      multiple (change)="onSelectedFile($event)" />
                    <span class="csv-browse-btn">
                      <span class="material-symbols-outlined">
                        arrow_upward
                      </span>
                      Browse Files
                    </span>
                  </div>
                  <span class="max-size full-width">Max 1000 records allowed</span>

                </div>
              </div>
            </div>

            <!-- <div style="width:100%" class="w_100">
              <div>
                <ngx-custom-progress-bar *ngIf="uploadProgress > 0" aria-valuenow="25" aria-valuemin="0"
                  aria-valuemax="100" [value]="uploadProgress" [systemConfigColor]="systemConfigColor">
                  {{ uploadProgress }}%</ngx-custom-progress-bar>
              </div>
            </div> -->

          </div>
        </div>

        <ng-container *ngIf="droppedFiles && droppedFiles.length">
          <span *ngFor="let item of droppedFiles">{{item.name}}</span>
        </ng-container>
      </div>
    </div>
    <div class="progess-block" *ngIf="uploadProgress > 0">
      <span (click)="removeAllUploads()" class="material-symbols-outlined csv-prog-cancel">
        close
      </span>
      <div class="prog-block-left">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </div>
      <div class="prog-block-right">
        <span *ngFor="let item of droppedFiles">{{item.name}} is uploading...</span>
        <ngx-custom-progress-bar *ngIf="uploadProgress > 0" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"
          [value]="uploadProgress" [systemConfigColor]="systemConfigColor">
          {{ uploadProgress }}%
        </ngx-custom-progress-bar>
      </div>


    </div>

    <div class="csv-btns">
      <button [ngStyle]="{'background-color':systemConfigColor}" style="color: white;margin-right: 10px;" nbButton
        matRipple (click)="downloadSample()" class="csv-btn-style">Download sample CSV file</button>
      <button nbButton matRipple (click)="uploadFile()" class="csv-btn-style"
        style="background: #EBEBEB">Submit</button>

    </div>
    <div *ngIf="failedRecordsLink" class="csv-card csv-record-added mt-3 csv-failed-rec">
      <a [href]="failedRecordsLink" download class="txt-clr">
        <span class="material-symbols-outlined text-clr">
          cloud_download
        </span>
        Download invalid records, fix them and try again!</a>
    </div>

  </nb-card-body>

</nb-card>
