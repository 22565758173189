<nb-card [ngClass]="{'setWidth': !exportButton}" class="confirm-dialog text-center">
  <nb-card-header>
    <div class="icon-box">
      <!-- <img *ngIf="actionFlag" src="assets/images/alert-triangle-outline.png" alt="">  -->
      <div class="modal-title w-100 font-weight-bold">{{ title }}</div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <p *ngIf="actionFlag">{{warningMessage}}</p>
    <p>{{ text }}</p>
  </nb-card-body>
  <nb-card-footer style="border: none;" *ngIf="exportButton">
    <div *ngIf="exportButton">
      <button class="cancel rounded-pill" nbButton matRipple status="primary" (click)="onSelect('Yes & Next')">Yes &
        Next</button>
      <button type="submit rounded-pill" nbButton matRipple status="primary" class="export-buttons mr-2"
        (click)="onSelect('Ignore & Next')">Ignore & Next</button>
      <button type="submit rounded-pill" nbButton matRipple status="primary" class="export-buttons"
        (click)="onSelect('Choose manual')">Choose manual</button>
    </div>
  </nb-card-footer>
  <nb-card-footer *ngIf="!exportButton" class="d-flex justify-content-center">
    <button *ngIf="!actionFlag" class="cancel rounded-pill w-50" nbButton matRipple status="primary"
      [ngStyle]="{'border-color':systemConfigColor,'color':systemConfigColor}" (click)="onCancel()">No</button>
    <button *ngIf="!actionFlag" type="submit" class="rounded-pill w-50" nbButton matRipple status="primary"
      [ngStyle]="{'background-color':systemConfigColor,'color':'white','border-color':systemConfigColor}"
      (click)="onYes()">Yes</button>
    <button *ngIf="actionFlag" class="cancel float-right rounded-pill w-50" nbButton matRipple status="primary"
      [ngStyle]="{'border-color':systemConfigColor,'color':systemConfigColor}" (click)="onCancel()"> {{ cancel
      }}</button>
    <button *ngIf="actionFlag && !finalDialog" type="submit" nbButton matRipple status="primary"
      [ngStyle]="{'background-color':systemConfigColor,'color':'white','border-color':systemConfigColor}"
      class="pull-right rounded-pill w-50" (click)="onYes()">Proceed</button>
  </nb-card-footer>

</nb-card>