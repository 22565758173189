import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DynamicFormCommonService } from '@app/shared/dynamic-form-common.service';
import { HelperService } from '@app/shared/services/helper.service';
import { MessageService } from '@app/shared/services/message.service';
import { TableService } from '@app/shared/services/table.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { NgPluralizeService } from 'ng-pluralize';
import { FilePreviewDialogComponent } from '../file-preview-dialog/file-preview-dialog.component';
import { PhonePipe } from './../../pipes/phone.pipe';


@Component({
  selector: 'ngx-lookup-modal-detail',
  templateUrl: './lookup-modal-detail.component.html',
  styleUrls: ['./lookup-modal-detail.component.scss']
})
export class LookupModalDetailComponent implements OnInit {

  loading = true;
  @Input() viewInModal;
  @Output() viewModalEmit = new EventEmitter();
  @Output() viewInlineEmit = new EventEmitter();
  @Output() emitToTree = new EventEmitter();
  @Input() data: any;
  @Input() name: any;
  @Input() reference: any
  @Input() tableInfo = {
    tableName: '',
    tableId: '',
    resourceId: '',
    link: ''
  };
  title = '';
  url = '';
  @Input() subFormLookupIds;
  @Input() tempParentTableHeader;
  @Input() recordTypeFieldName;
  @Input() tableData = [];
  @Input() fromRecord;
  @Input() tableRecordTypes;
  @Input() tableName;
  @Input() tableColumns;
  @Input() dataForLookupDetail;
  @Input() fromFormFlag;
  @Input() dataFromFormPage;
  @Input() resourceIdForForm;
  @Input() tableIdFromForm;
  @Input() tableIdFromView;
  @Input() fromView;
  @Input() formColumns;

  @Input() fromRefRecord = false;
  viewIcon = true;
  clientData;
  profileData = [];
  orderedArray = [];
  profileImagePath = 'assets/images/cv-placeholder.png';
  showProfileImage = false;
  profileImageData;
  startPage: Number;
  paginationLimit = 3;
  showLess: boolean;
  showMore: boolean;
  singularTableName = '';
  currentUser;
  constructor(
    private tableService: TableService,
    private helperService: HelperService,
    private pluralizeService: NgPluralizeService,
    private messageService: MessageService,
    private router: Router,
    private dialogService: NbDialogService,
    private location: Location,
    private toaster: NbToastrService,
    private sanitizer: DomSanitizer,
    private formService: DynamicFormCommonService
  ) {
  }

  ngOnInit(): void {
    if (this.fromRecord) {
      if (this.tableInfo && this.tableInfo.tableName) {
        this.singularTableName = this.pluralizeService.singularize(this.tableInfo.tableName);
        this.getTableData();
        return;
      }
      var result = this.name.replace(/([A-Z])/g, " $1");
      var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      this.title = finalResult.replace(/ .*/, '') + " Details";

      this.tableInfo.tableId = this.data["tableId"];
      this.tableInfo.tableName = this.data["tableName"];
      this.tableInfo.resourceId = this.data["resourceId"];
      this.singularTableName = this.pluralizeService.singularize(this.tableInfo.tableName);
      this.getTableData();
    }
    else if (this.fromView) {
      let cols = this.tableData.filter(v => v.tableName == this.tableName);
      if (cols && cols.length) {
        this.tableInfo.tableId = cols[0]._id;
        this.tableInfo.tableName = cols[0].tableName;
        this.orderedArray = this.helperService.sortObject(cols[0].columns, 'displayPriority');
      }
      this.clientData = this.dataForLookupDetail;

      if (this.clientData) {
        this.tableInfo.resourceId = this.clientData._id;
        if (this.clientData.firstName) {
          this.clientData.firstName = this.clientData.firstName.charAt(0).toUpperCase() + this.clientData.firstName.slice(1);
        }
        if (this.clientData.lastName) {
          this.clientData.lastName = this.clientData.lastName.charAt(0).toUpperCase() + this.clientData.lastName.slice(1);
        }
      }

      this.setProfileData(this.dataForLookupDetail);
      this.loading = false;

    } else if (this.fromRefRecord) {

      let cols = this.tableData.filter(v => v.tableName == this.tableName);
      if (cols && cols.length) {
        this.tableInfo.tableId = cols[0]._id;
        this.tableInfo.tableName = cols[0].tableName;
        this.tableInfo.resourceId = this.data._id;
        this.formColumns = cols[0].columns;
      }
      this.getTableData()

    }
    else {
      if (this.tableInfo && this.tableInfo.tableName) {
        this.singularTableName = this.pluralizeService.singularize(this.tableInfo.tableName);
        this.getTableData();
        return;
      }
      var result = this.name.replace(/([A-Z])/g, " $1");
      var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      this.title = finalResult.replace(/ .*/, '') + " Details";

      this.tableInfo.tableId = this.data["tableId"];
      this.tableInfo.tableName = this.data["table"];
      this.tableInfo.resourceId = this.data["_id"];
      this.singularTableName = this.pluralizeService.singularize(this.tableInfo.tableName);
      this.getTableData();

    }

    this.viewIcon = this.tableData.map(ele => ele.tableName).includes(this.tableInfo.tableName);
  }

  getTableData() {
    this.tableService.getDynamicTreeDataById(this.tableInfo.tableName, this.tableInfo.resourceId).subscribe(
      (res: any) => {
        if (res.statusCode == 200 && res.data) {
          this.clientData = res.data;
          if (this.clientData.firstName) {
            this.clientData.firstName = this.clientData.firstName.charAt(0).toUpperCase() + this.clientData.firstName.slice(1);
          }
          if (this.clientData.lastName) {
            this.clientData.lastName = this.clientData.lastName.charAt(0).toUpperCase() + this.clientData.lastName.slice(1);
          }
          if (this.tableInfo.tableName) {
            let cols = this.tableData.filter(v => v.tableName == this.tableInfo.tableName);
            if (cols && cols.length) {
              this.orderedArray = this.helperService.sortObject(cols[0].columns, 'displayPriority');
            }
          } else {
            this.orderedArray = this.helperService.sortObject(res.data.tableColumns, 'displayPriority');
          }

          this.setProfileData(res.data);
          this.loading = false;
        }
      },
    );
  }

  onFilePreivew(filename) {
    this.dialogService.open(FilePreviewDialogComponent, {
      context: {
        Data: decodeURI(filename),
        Ext: decodeURI(filename).split(".").pop(),
      },
    });
  }

  setProfileData(data) {
    this.profileData = [];

    let field = this.formColumns.filter(v => v.name == this.name);
    if (field && field.length) {
      let displayFields = field[0].lookupTableFieldNames ? field[0].lookupTableFieldNames : [];
      let idFields = data['IDField'];
      this.orderedArray.forEach(ele => {
        let dataName = '';
        if (ele.type == 'lookup') {
          let r = data.lookup.find(lk => lk.lookupDataName === ele.name);
          dataName = r ? r.lookupName : '';
          if (typeof (dataName) == 'string') {
            dataName = dataName.charAt(0).toUpperCase() + dataName.slice(1);
          }
        } else {
          dataName = data[ele.name];
          if (typeof (dataName) == 'string') {
            dataName = dataName.charAt(0).toUpperCase() + dataName.slice(1);
          }
        }
        if (displayFields.includes(ele.name)) {
          if (ele.name !== "firstName" && ele.name !== "lastName") {
            const arr = {
              label: ele.label,
              name: ele.name,
              value: dataName,
              type: ele.type,
              icon: ele.icon,
              isPhone: ele.isPhone
            };
            if (this.matchPattern(arr.value)) {
              arr.type = "email";
            }
            this.profileData.push(arr);
          }
        }

        if (idFields.includes(ele.name)) {
          if (ele.isPhone) {
            const phonePipe = new PhonePipe();
            this.clientData[ele.name] = phonePipe.transform(this.clientData[ele.name]);
          }
        }
      });
    }

  }

  matchPattern(value): boolean {
    const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !!pattern.test(value);
  }

  isNotObject(val) {
    return typeof (val) !== 'object';
  }


  mentionRedirect() {
    if (this.clientData) {
      let user_id = this.clientData._id;
      let user_name = this.clientData.firstName + ' ' + this.clientData.lastName;
      const redirectFromMentionObj = {
        avatar: null,
        displayName: user_name,
        email: "",
        id: user_id,
        participantType: 0,
        status: 3,
        totalUnreadCount: 0,
      };

      this.messageService.redirectionFromMentionObj.next(
        redirectFromMentionObj
      );
    }
  }

  onShowLookupDetail() {
    this.loading = true;
    let table = this.tableData.filter(v => v.tableName == this.tableName)[0];
    if (table) {

      if (table.tableName === "Users") {
        let obj = {
          refer: this.reference,
          close: true
        }
        this.viewModalEmit.emit(obj);
        this.loading = false;
        this.router.navigate(["/pages/tables/dynamic/" + table._id + "/" + table.tableName + "/" + this.clientData._id]);
      } else {
        if (this.fromView || this.fromRefRecord) {

          if (table.viewInModal) {

            this.openViewModal(this.clientData, this.clientData._id, table.tableName, table._id);
            let obj = {
              refer: this.reference,
              close: false
            }
            this.viewModalEmit.emit(obj);
          } else {
            let obj = {
              refer: this.reference,
              close: true
            }
            this.viewModalEmit.emit(obj);
            this.loading = false;
            this.router.navigate(['pages/tables/dynamic/' + table._id + '/' + table.tableName + '/' + this.clientData._id]);
          }
        } else {
          if (table.viewInModal) {
            this.openViewModal(this.clientData, this.clientData._id, table.tableName, table._id);
            this.emitToTree.emit();
          } else {
            this.loading = false;
            this.router.navigate(['pages/tables/dynamic/' + table._id + '/' + table.tableName + '/' + this.tableInfo.resourceId]);
          }
        }
      }

    } else {
      this.loading = false;
      this.toaster.warning("You don't have permission to view this record", "Permission Denied");
    }

  }

  checkURL(url) {

    if (Array.isArray(url)) {
      url = url[0];
    }
    if (typeof url == 'string') {
      return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
    } else {
      return false;
    }
  }

  tableIcon;
  newViewFlag;
  actions = [];
  recordGadgets = [];
  customValidations = [];
  formHeight;
  formWidth;
  fieldAlignment;
  taskRecordTypes = [];
  recordTypes = [];
  recordType = '';
  showChats = false;

  previousLocation = '';
  async openViewModal(data, id, tableName, tableId) {

    this.previousLocation = this.location.path();
    let url = `/pages/tables/dynamic/${tableId}/${tableName}/${this.clientData._id}`;
    this.location.replaceState(url);

    let val: any = await this.formService.openAndCloseForm(data, tableName, this.tableData, true);

    const ref = this.dialogService.open(val.formComp, {
      closeOnEsc: false,
      context: val.formObj,
    }).onClose.subscribe((resp) => {

      this.location.replaceState(this.previousLocation);
      if (!this.fromView) {
        if (resp && resp.id) {
          this.emitToTree.emit();
        }
      }
    });



  }
}
