<nb-card style="height: 400px;width: 400px;">

  <nb-card-header>
    Area Default Value
  </nb-card-header>

  <nb-card-body>

    <div class="container">
      <div class="form-group with-plus-icon float-none">
        <label for="type" class="label">Default Value</label>

        <ngx-codemirror [(ngModel)]="item" [options]="{
            lineNumbers: true,
            theme: 'erlang-dark',
            mode: 'javascript',
            lineWrapping:true
          }"></ngx-codemirror>
      </div>
    </div>


  </nb-card-body>


  <nb-card-footer>
    <button class="cancel" nbButton matRipple status="danger" (click)="cancel()">Cancel</button>
    <button style="margin-left: 10px;" nbButton matRipple status="success" [disabled]="!item" (click)="submit()">Submit</button>
  </nb-card-footer>
</nb-card>
