import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { TableService } from '../../services/table.service';

@Component({
  selector: 'ngx-snooze-time',
  templateUrl: './snooze-time.component.html',
  styleUrls: ['./snooze-time.component.scss']
})
export class SnoozeTimeComponent implements OnInit,AfterViewInit {


  @Input("times") times = [];
  snoozeTime;
  constructor( public ref: NbDialogRef<SnoozeTimeComponent>,
    private tableservice:TableService
    ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
      this.tableservice.runCustomStyling();
  }

  selectedTime(value){
    this.snoozeTime = value;
    this.ref.close(this.snoozeTime);
  }

  close(){
    this.ref.close();
  }

}
