import { Component, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-open-code-mirror',
  templateUrl: './open-code-mirror.component.html',
  styleUrls: ['./open-code-mirror.component.scss']
})
export class OpenCodeMirrorComponent implements OnInit,AfterViewInit {

  @Input() code;
  codeMirrorConfig = {
    lineNumbers: true,
    mode: "javascript",
    lineWrapping: true,
    theme: 'erlang-dark'
  };

  @ViewChild(CodemirrorComponent) codemirrorComponent: CodemirrorComponent;
  constructor(
    public dialogRef: NbDialogRef<OpenCodeMirrorComponent>
  ) { }

  ngOnInit(): void {


  }

  ngAfterViewInit(): void {
    this.codemirrorComponent.codeMirror.setSize(null, 700);
  }

  close(){
    this.dialogRef.close(this.code);
  }

}
