import { DatePipe, formatDate, Location } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TableService } from '@app/shared/services/table.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { NgxLinkifyjsService } from 'ngx-linkifyjs';
import { MessageService } from './../../services/message.service';

import { DynamicFormCommonService } from '@app/shared/dynamic-form-common.service';
import { NewReminderModalComponent } from '../new-reminder-modal/new-reminder-modal.component';
import { SnoozeTimeComponent } from '../snooze-time/snooze-time.component';

@Component({
  selector: 'ngx-reminder-notification',
  templateUrl: './reminder-notification.component.html',
  styleUrls: ['./reminder-notification.component.scss']
})
export class ReminderNotificationComponent implements OnInit, OnChanges {

  @Input() reminderList = [];
  @Input() notificationList = [];
  @Input() loader = false;
  @Input() getTablesForMenuData = [];
  @Input() unreadReminderCount = 0;
  @Input() unreadNotificationCount = 0;
  @Output() emitToOpen = new EventEmitter();
  @Output() emitToEdit = new EventEmitter();
  @Output() emitToLoadReminder = new EventEmitter();
  @Output() emitToLoadNotification = new EventEmitter();
  @Output() emitToRead = new EventEmitter();
  @Output() emitReminderRead = new EventEmitter();
  users = [];
  systemConfigColor: any;

  constructor(private tableService: TableService, private datePipe: DatePipe, private cdr: ChangeDetectorRef,
    public route: ActivatedRoute,
    private router: Router,
    private dialogService: NbDialogService,
    private location: Location,
    public linkifyService: NgxLinkifyjsService,
    public toasterService: NbToastrService,
    public messageService: MessageService,
    public sanitizer: DomSanitizer,
    public formService: DynamicFormCommonService
  ) {
    this.tableService.$users.subscribe((res: any) => {
      this.users = res;
    })
    this.tableService.$systemConfig.subscribe((res: any) => {
      if (res) {
        this.systemConfigColor = res.IconColors;
      }
    })
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['notificationList']) {
      this.notificationList = changes['notificationList'].currentValue;
    }
    this.addSenderInfo();
    this.cdr.detectChanges();
  }

  addSenderInfo() {

    this.notificationList.forEach(notification => {
      let user = this.users.find(user => user._id == notification.sender);

      if (user) {
        notification['sender'] = user;
      }
    })
  }

  openReminder(reminder) {
    this.emitToOpen.emit(reminder);
  }

  editReminder(reminder) {
    this.emitToEdit.emit(reminder);
  }

  changedTab($event) {
    setTimeout(() => {
      this.tableService.runCustomStyling();
    }, 100);
  }

  formatDate(value) {
    if (!value) {
      return "";
    }

    let currentDate = new Date();
    let data = this.datePipe.transform(value, "dd/MM/YYYY");
    let current = this.datePipe.transform(currentDate, "dd/MM/YYYY");

    if (data == current) {
      return formatDate(value, "h:mm a", "en");
    }

    return formatDate(value, "M/d/yy, h:mm a", "en");
  }

  scrollHandler(type, e) {

    if (type == 'reminder') {
      let diff = e.srcElement.scrollTop - (e.srcElement.scrollHeight - 352);

      if (diff == 0) {

        if (!this.loader)
          this.emitToLoadReminder.emit(true);

        e.preventDefault();
      }
    } else {

      let diff = e.srcElement.scrollTop - (e.srcElement.scrollHeight - 352);

      if (diff == 0) {

        if (!this.loader)
          this.emitToLoadNotification.emit(true);

        e.preventDefault();
      }
    }
  }

  previousLocation;

  tdClick(notification, tableId, tableName, _id) {

    this.emitToRead.emit(notification);

    let table = this.getTablesForMenuData.find(v => v.tableName == tableName);
    this.previousLocation = this.location.path();

    if (table) {
      if (table.viewInModal) {
        this.tableService.getDynamicTreeDataById(tableName, _id).subscribe((res: any) => {
          if (res.statusCode == 200) {
            this.location.replaceState("pages/tables/dynamic/" + tableId + "/" + tableName + "/" + _id);
            if (table.formWidth) {

              let width = (Number)(table.formWidth);
              if (width < 410) {
                width = 410
              }

              let wid: any = this.sanitizer.bypassSecurityTrustStyle((String)(width + 350));
              table.formWidth = wid.changingThisBreaksApplicationSecurity;
            }
            this.openViewModal(res.data, _id, table);
          }
        });
      } else {
        this.router.navigate(["/pages/tables/dynamic/" + tableId + "/" + tableName + "/" + _id,]);
      }
    }
  }

  async openViewModal(data, id, table) {

    let val: any = await this.formService.openAndCloseForm(data, table.tableName, this.getTablesForMenuData, true);

    const ref = this.dialogService.open(val.formComp, {
      closeOnEsc: false,
      context: val.formObj,
    }).onClose.subscribe((resp) => {
      this.location.replaceState(this.previousLocation);
    });

  }

  times = [
    { label: "5 min", val: 5 },
    { label: "10 min", val: 10 },
    { label: "30 min", val: 30 },
    { label: "1 hour", val: 60 },
    { label: "reschedule", val: 'reschedule' },
  ];

  snoozeTime;

  openSnoozeModal(reminder) {

    const ref = this.dialogService.open(SnoozeTimeComponent, {
      context: {
        times: this.times,
      },
    })
      .onClose.subscribe(val => {

        if (val) {
          if (val == "reschedule") {
            this.openReminderModal(reminder);
          } else {
            this.snoozeTime = val;
            this.snooze(reminder);
          }

        }
      });
  }

  openReminderModal(reminder) {

    if ((reminder.tableId && reminder.resourceId) ||
      (reminder.reminderDetails?.dateTime && reminder.resourceId)) {
      const ref = this.dialogService.open(NewReminderModalComponent, {
        context: {
          tableId: reminder.tableId ? reminder.tableId : '',
          tableName: reminder.tableId ? reminder.tableName : '',
          resourceId: reminder.resourceId,
          IdFieldData: reminder.tableId ? reminder.toDisplay : '',
          fromViewPage: reminder.tableId ? true : false,
          tableIcon: reminder.tableId && reminder.tableIcon ? reminder.tableIcon[reminder.tableName] : '',
          updateReminder: reminder.tableId ? reminder : reminder.reminderDetails
        },
      })
        .onClose.subscribe(val => {

          if (val == 'Done') {
            // this.ref.close();
          }
        });
    } else {
      const ref = this.dialogService.open(NewReminderModalComponent, {
        context: {
          fromViewPage: false,
          updateReminder: reminder
        },
      })
        .onClose.subscribe(val => {

          if (val == 'Done') {
            // this.ref.close();
          }
        });
    }

  }

  markComplete(reminder, i) {

    let resource = '';

    if (reminder.reminderDetails?._id) {
      resource = reminder.reminderDetails._id;
    }

    if (reminder.reminderDetails?.reminderId) {
      resource = reminder.reminderDetails.notificationId;
    }

    if (reminder.notificationId) {
      resource = reminder.notificationId;
    }

    this.tableService.markComplete(resource).subscribe(
      (res: any) => {
        if (res.statusCode === 200) {
          this.toasterService.success("", "Reminder marked as completed sucessfully!");
        } else {
          this.toasterService.danger(res.message, "Error");
        }
        this.reminderList.splice(i, 1);
        this.reminderCompleted('markDone');
      },
      (error) => {
        this.reminderCompleted(false);
      }
    );

  }

  reminderCompleted(value) {
    this.emitReminderRead.emit('read');
  }


  snooze(notification) {

    if (this.snoozeTime) {
      // this.loading = true;
      let dateVal = new Date();

      let snooze = new Date(dateVal.getTime() + this.snoozeTime * 60000)

      let resource = '';

      if (notification?.reminderDetails?._id) {
        resource = notification.reminderDetails?._id;
      }

      if (notification?.reminderDetails?.notificationId) {
        resource = notification.reminderDetails?.notificationId;
      }

      if (notification?.notificationId) {
        resource = notification?.notificationId;
      }

      this.tableService.updateDueTimeForReminder(resource, snooze).subscribe(
        (res: any) => {
          if (res.statusCode === 200) {
            this.toasterService.success(res.message, "Success");
          } else {
            this.toasterService.danger(res.message, "Error");
          }
          // this.closeIt();
        },
        (error) => {
          // this.closeIt();
        }
      );

    } else {
      // if (this.dialogType == 'reminder') {
      //   this.router.navigate(['/pages/tables/notifications']);
      // }

      // this.ref.close();
    }

  }

  deleteReminder(reminder, i) {

    const id = reminder?._id || reminder.notificationId

    this.tableService.deleteReminder(id).subscribe(
      (res: any) => {
        if (res.statusCode === 200) {
          this.toasterService.success(res.message, "Success");
          this.reminderList.splice(i, 1);
          this.unreadReminderCount--;
        } else {
          this.toasterService.danger(res.message, "Error");
        }
      },
      (error) => {
      }
    );
  }

  deleteNotification(notification, i) {
    this.messageService.deleteMessage(notification._id).subscribe((res: any) => {
      if (res["statusCode"] == 200) {
        this.notificationList.splice(i, 1);
        this.unreadNotificationCount--;
        this.toasterService.success(res.message, "Success");
      }
    });
  }
}
