import { TableService } from '@app/shared/services/table.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { head } from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'ngx-upload-csv',
  templateUrl: './upload-csv.component.html',
  styleUrls: ['./upload-csv.component.scss']
})
export class UploadCsvComponent implements OnInit {

  uploadedFiles: {};
  isActive: boolean;
  droppedFiles = [];
  uploadProgress = 0;
  systemConfigColor;
  @Input() tableName = '';
  failedRecordsLink = '';
  uploadResponseMsg = '';
  @Input() parentTableData = [];
  @Input() columns = [];
  @Input() parentTableNameFromRecord = null;
  @Input() parentTableDataFromRecord = null;
  @Input() lookUpNameIdFromRecord = null;
  @Input() lookUpNameFromRecord = null;

  constructor(
    protected ref: NbDialogRef<UploadCsvComponent>,
    private toastrService: NbToastrService,
    private tableService: TableService
  ) { }

  ngOnInit(): void {

    this.errorMsg = '';
    this.tableService.$systemConfig.subscribe((res: any) => {
      if (res) {
        this.systemConfigColor = res.IconColors;
      }
    })
  }

  cancel() {
    this.ref.close();
  }

  submit() {

  }

  downloadSample() {

    let unwantedFieldTypes = ['rollUp', 'formula', 'injectSubForm', 'section', 'gadget', 'refButton', 'lookup'];
    let inputFields: string[] = ["password", "text", "email", "currency", "area", "richTextArea"];
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const tempHeader = this.columns.filter(v => !unwantedFieldTypes.includes(v.type)).map(ele => ele.name);
    tempHeader.unshift('_id');
    let header = this.columns.filter(v => !unwantedFieldTypes.includes(v.type)).map(ele => ele.isRequired ? ele.label + ' *' : ele.label);
    header.unshift('_id');
    let finalData = [];

    if (this.parentTableData && this.parentTableData.length) {

      this.parentTableData.forEach(ele => {

        let obj = {};
        tempHeader.forEach(val => {
          if (tempHeader.includes(val)) {
            if (ele[val] && typeof ele[val] == "object" && ele[val].length) {
              let value = [];
              ele[val].forEach((val) => {
                value.push(val._id);
              });
              obj[val] = value.join('|');
            } else {
              let field = this.columns.find(v => v.name == val);
              if (field) {
                if (inputFields.includes(field.type)) {
                  obj[val] = ele[val] ? ele[val] : 'dummy';
                } else if (field.type == 'lookup') {
                  obj[val] = obj[val] ? obj[val] : "5f5fa253c1657a41f959b574";
                } else if (field.type == 'status') {
                  obj[val] = ele[val] ? ele[val] : field.statusOptions[0].status;
                } else if (field.type == 'dropdown') {
                  obj[val] = ele[val] ? ele[val] : field.options[0];
                } else if (field.type == 'dropdownWithImage') {
                  obj[val] = ele[val] ? ele[val] : field.options[0].title;
                } else if (field.type == 'checkbox') {
                  obj[val] = ele[val] ? ele[val] : field.options[0];
                } else if (field.type == 'date') {
                  obj[val] = ele[val] ? moment(ele[val]).format('YYYY-MM-DD').toString() : moment().format('YYYY-MM-DD').toString();
                } else if (field.type == 'dateTime') {
                  obj[val] = ele[val] ? moment(ele[val]).format('YYYY-MM-DD').toString() : moment().format('YYYY-MM-DD').toString();
                } else if (field.isPhone) {
                  obj[val] = ele[val] ? ele[val] : 9876543210;
                }
              } else {
                obj[val] = ele[val];
              }


            }
          }
        });

        finalData.push(obj);
      })

    } else {

      let obj = {};
      tempHeader.forEach(val => {
        if (tempHeader.includes(val)) {

          let field = this.columns.find(v => v.name == val);
          if (field) {
            if (inputFields.includes(field.type)) {
              obj[val] = 'dummy';
            } else if (field.type == 'lookup') {
              obj[val] = "5f5fa253c1657a41f959b574";
            } else if (field.type == 'status') {
              obj[val] = field.statusOptions[0].status;
            } else if (field.type == 'dropdown') {
              obj[val] = field.options[0];
            } else if (field.type == 'dropdownWithImage') {
              obj[val] = field.options[0].title;
            } else if (field.type == 'checkbox') {
              obj[val] = field.options[0];
            } else if (field.type == 'date') {
              obj[val] = moment().format('YYYY-MM-DD').toString();
            } else if (field.type == 'dateTime') {
              obj[val] = moment().format('YYYY-MM-DD').toString();
            } else if (field.isPhone) {
              obj[val] = 9876543210;
            }
          } else {
            obj[val] = 'dummy';
          }

        }
      });

      finalData.push(obj);

    }


    let csv = finalData.slice(0, 2).map(row => tempHeader.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], { type: 'text/csv' })

    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", this.tableName + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  getFileName(filename) {
    filename = decodeURI(filename);
    return filename.match(/.*\/(.*)$/)[1];
  }
  onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.isActive = true;
  }

  onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.isActive = false;
  }

  onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.droppedFiles = [];
    for (let i = 0; i < event.dataTransfer.files.length; i++) {
      this.droppedFiles.push(event.dataTransfer.files[i]);
    }
    // this.uploadFile();
    this.isActive = false;
  }

  fileFormData: any = [];
  fileUploadS3Data = [];

  onSelectedFile(event) {


    this.droppedFiles = [];
    for (let i = 0; i < event.target.files.length; i++) {
      this.droppedFiles.push(event.target.files[i]);
    }
    // this.uploadFile();
  }

  errorMsg = '';
  uploadFile() {

    this.errorMsg = '';
    const formData = new FormData();
    console.log(this.droppedFiles);

    let valid = true;

    if (this.droppedFiles && this.droppedFiles.length) {

      for (let i = 0; i < this.droppedFiles.length; i++) {
        let mb = (this.droppedFiles[i].size / 1024) / 1024;
        // if (mb > 20) {
        //   valid = false;
        //   break;
        // }


        let fileName = this.droppedFiles[i].name;
        let nameExt = fileName.split('.')[1];

        if (nameExt !== 'csv') {
          valid = false;
          break;
        }

      }

      if (valid) {

        for (let i = 0; i < this.droppedFiles.length; i++) {
          formData.append("csvFile", this.droppedFiles[i]);
          formData.append("tableName", this.tableName);

          this.fileFormData = formData;
          this.uploadProgress = 0;

          if (this.fileFormData) {
            this.tableService
              .uploadCSV(this.fileFormData, this.parentTableNameFromRecord, this.parentTableDataFromRecord, this.lookUpNameIdFromRecord, this.lookUpNameFromRecord).subscribe(
                async (event: any) => {
                  // this.isUpload = true;
                  switch (event.type) {
                    case HttpEventType.Sent:
                      break;
                    case HttpEventType.ResponseHeader:
                      break;
                    case HttpEventType.UploadProgress:

                      this.uploadProgress = Math.round(
                        (event.loaded / event.total) * 100
                      );

                      break;
                    case HttpEventType.Response:
                      setTimeout(() => {
                        this.uploadProgress = 0;
                      }, 2000);
                  }
                  if (event instanceof HttpResponse) {
                    if (event.status >= 200) {
                      let s: any = event.body;
                      this.fileUploadS3Data = s.data;

                      this.uploadResponseMsg = s.data.message;

                      if (s.data.failedRecordsFile) {
                        this.failedRecordsLink = s.data.failedRecordsFile;
                      }

                      this.toastrService.success("Done uploading", event.body.data.message);

                      this.ref.close(true);

                    } else {
                      this.errorMsg = event.body.message;
                      this.toastrService.danger(event.body.error, event.body.message);
                    }

                  }
                  // this.buttonDisable = true;
                },
                (err) => {
                  this.uploadProgress = 0;
                }
              );
          } else {
            this.uploadProgress = 0;
          }
        }
      } else {
        this.toastrService.warning("Please upload a CSV File", "Not CSV File");
        this.droppedFiles = [];
      }

    }

  }

  removeAllUploads() {

    this.droppedFiles = [];
    this.uploadProgress = 0;

  }

}
