import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'currency' })
export class CurrencyPipe implements PipeTransform {

  transform(input: any) {

    if (input)
      return '$ ' + input.toFixed(2);

    return input;
  }
}
