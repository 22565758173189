import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, Output } from "@angular/core";
import { Router } from "@angular/router";
import { DynamicTableViewComponent } from "@app/pages/tables/dynamic-table-view/dynamic-table-view.component";
import { environment } from "@env/environment";
import { NbDialogService, NbToastrService } from "@nebular/theme";
import { cloneDeep } from "lodash";
import * as moment from "moment";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { tableRequest } from "../interfaces/table";
@Injectable({
  providedIn: "root",
})
export class TableService {
  filterObject = new FilterForService();
  storedTreeData = {};
  clientFormData = [];
  announcementListData: BehaviorSubject<[]> = new BehaviorSubject<[]>([]);
  $users: BehaviorSubject<[]> = new BehaviorSubject<[]>([]);
  $systemConfig: BehaviorSubject<[]> = new BehaviorSubject<[]>([]);
  // $notificationList: BehaviorSubject<[]> = new BehaviorSubject<[]>([]);
  $specificRecordMessageList: BehaviorSubject<[]> = new BehaviorSubject<[]>([]);
  dateTimePickerFocused = new BehaviorSubject<boolean>(false);
  taskCount$ = new Subject();
  ejsPackage = new BehaviorSubject<any>("");
  toggleSidebar = new Subject<any>();
  layoutData = new Subject<any>();
  redirect = new BehaviorSubject<string>("");
  welcome = new BehaviorSubject<string>("");
  logo = new BehaviorSubject<string>("");
  login = new BehaviorSubject<string>("");
  title = new BehaviorSubject<string>("");
  loginLogo = new BehaviorSubject<string>("");
  iconColors = new BehaviorSubject<string>("");
  systemConfigColor;
  gradientColor = new BehaviorSubject<string>("");
  appName = new BehaviorSubject<string>("");
  lastSearchedURL = new BehaviorSubject<string>("");
  lastAccessedPage = new BehaviorSubject(0);
  reminderUpdated$ = new Subject<any>();
  refreshHeader = new Subject<any>();
  refreshOverviewCount = new Subject<any>();
  refreshListingCount = new Subject<any>();
  refreshMessageCount = new Subject<any>();
  refreshSumCount = new Subject<any>();
  refreshViewTree = new Subject<any>();
  $tablesMenu = new Subject<any>();
  openChat = new Subject<any>();
  private cacheForGetTablesForMenu: any;
  private cacheForUserDetail: any;
  allAPIobject: any;
  ipAddress;
  recentTablePageCount: any = 1;
  currentIdField = "";
  constructor(
    private http: HttpClient,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private router: Router
  ) {
    this.callEjs();
  }

  getIpInfo() {
    return this.http
      .get(`${environment.apiUrl}/ipInfo`)
      .pipe(tap())
      .toPromise();
  }

  getRecordSpecificMessages(data) {
    this.$specificRecordMessageList.next(data);
  }

  // getNotificationsList(data) {
  //   this.$notificationList.next(data);
  // }

  getSystemConfigDataFromSubject(data) {
    if (data) {
      this.$systemConfig.next(data);
    }
  }

  setTablesMenu(data) {
    this.$tablesMenu.next(data);
  }


  callEjs() {
    System.import("../../../../node_modules/ejs/ejs.js").then(async (res) => {
      this.ejsPackage.next(res);
    });
  }

  storeLayoutData(obj) {
    this.layoutData.next(obj);
  }

  getStoreDataFromTree(tableName?, data?, type?) {
    if (data || type || tableName) {
      if (tableName) this.storedTreeData["tableName"] = tableName;
      if (data == "empty" && type == "empty" && tableName == "empty") {
        this.storedTreeData = {};
      } else this.storedTreeData[type] = data;
    }
    return this.storedTreeData;
  }

  getFilterObject(filterObj?, tableName?) {
    if (tableName !== "empty" && filterObj !== "empty") {
      if (tableName) this.filterObject["tableName"] = tableName;

      if (filterObj) this.filterObject = filterObj;
    } else {
      this.filterObject = new FilterForService();
    }
    return this.filterObject;
  }

  compLogingetDynamicTreeDataByIdForPromise(
    tableName,
    id,
    compName,
    action = ""
  ) {
    try {
      let URL = `${environment.apiUrl}/getById?tableName=${tableName}&id=${id}`;

      const headers = new HttpHeaders({
        "Content-Type": "application/json",
        manualorigin: `${compName}.synccos.com`,
      });

      if (action) URL = URL + "&emailAction=" + action;
      return this.http
        .get(URL, {
          headers: headers,
        })
        .toPromise();
    } catch (e) {
      console.log("Error firing request", e);
    }
  }

  getSystemConfig() {
    return this.http
      .get(`${"https://api-new.synccos.com/api"}/getSystemConfig`)
      .pipe(
        map((resp: any) =>
          resp.data.reduce(
            (configs: any, { configName, configValue }) => ({
              ...configs,
              [configName]: configValue,
            }),
            {}
          )
        )
      );
  }

  getSearchDataForRef(tableName, searchString, fromRelated) {
    return this.http.get(
      `${environment.apiUrl}/search?baseTableName=${tableName}&q=${searchString}&fromRelatedTab=${fromRelated}`
    );
  }

  refreshHeaderComponent() {
    this.refreshHeader.next(true);
  }

  refreshOverview() {
    this.refreshOverviewCount.next(true);
  }

  refreshListingLayout() {
    this.refreshListingCount.next(true);
  }

  refreshMessageLayout() {
    this.refreshMessageCount.next(true);
  }

  refreshSumCountLayout() {
    this.refreshSumCount.next(true);
  }

  refreshViewFromTree() {
    this.refreshViewTree.next(true);
  }

  openChatComp() {
    this.openChat.next(true);
  }

  /*Manage & Tables List Start*/
  getTable(): Observable<tableRequest> {
    return this.http.get(`${environment.apiUrl}/table/all`);
  }

  validateImport(data) {
    return this.http.post(`${environment.apiUrl}/table/validateImport`, data);
  }

  // massDeleteRecords(ids,tableName){
  //   return this.http.post(`${environment.apiUrl}/mass-delete?tableName=${tableName}`,ids);
  // }

  getTablesForMenu(): Observable<tableRequest> {
    if (this.cacheForGetTablesForMenu) {
      return of(this.cacheForGetTablesForMenu);
    }
    return this.http
      .get(`${environment.apiUrl}/table/getTablesForMenu`)
      .pipe(tap((res) => (this.cacheForGetTablesForMenu = res)));
  }

  getTablesForMenuForPromise() {
    // if (this.cacheForGetTablesForMenu) {
    //   return of(this.cacheForGetTablesForMenu);
    // }
    return this.http
      .get(`${environment.apiUrl}/table/getTablesForMenu`)
      .toPromise();
  }

  getTaskCountByTable() {
    return this.http.get(`${environment.apiUrl}/getAssignedTasksCountByTable`);
  }

  getTableDetails(id) {
    return this.http.get(
      `${environment.apiUrl}/table/getTableById?tableId=${id}`
    );
  }

  saveData(data) {
    return this.http.post(`${environment.apiUrl}/table/create`, data);
  }

  addDefaultFilter(data) {
    return this.http.post(`${environment.apiUrl}/table-filter/create`, data);
  }

  updateDefaultFilter(data) {
    return this.http.post(`${environment.apiUrl}/table-filter/update`, data);
  }

  defaultFilterList(id) {
    return this.http.get(`${environment.apiUrl}/table-filter/all/${id}`);
  }

  defaultFilterSelected(id) {
    return this.http.get(
      `${environment.apiUrl}/table-filter/defaultSelected/${id}`
    );
  }

  defaultFilterSelectedWithPromise(id) {
    return this.http
      .get(`${environment.apiUrl}/table-filter/defaultSelected/${id}`)
      .toPromise();
  }

  deleteFilter(id) {
    return this.http.get(
      `${environment.apiUrl}/table-filter/deleteFilter/${id}`
    );
  }

  deletePush(id) {
    return this.http.get(
      `${environment.apiUrl}/table-filter/deleteFilter/${id}`
    );
  }

  pushAllPush(obj: any) {
    return this.http.post(
      `${environment.apiUrl}/push-to-apps/pushTableColumns`,
      obj
    );
  }

  getAllPush(id: any) {
    return this.http.get(`${environment.apiUrl}/push-table-columns/all/${id}`);
  }

  savePush(data: any) {
    return this.http.post(
      `${environment.apiUrl}/push-table-columns/create`,
      data
    );
  }

  updatePush(data: any, id: any) {
    return this.http.put(
      `${environment.apiUrl}/push-table-columns/update/${id}`,
      data
    );
  }

  uploadMedia(data) {
    return this.http.post(`${environment.apiUrl}/upload`, data);
  }

  updateTable(data) {
    return this.http.post(`${environment.apiUrl}/table/update`, data);
  }

  updateStatusRecord(data) {
    return this.http.post(`${environment.apiUrl}/mass-update-status`, data);
  }

  updateMassRecords(data) {
    return this.http.post(`${environment.apiUrl}/mass-update-records`, data);
  }

  getAllEmailEvents(tableId) {
    return this.http.get(`${environment.apiUrl}/events/${tableId}`);
  }

  getAllDocumentsEvents(tableId, data = null) {
    return this.http.post(
      `${environment.apiUrl}/events/getDocuments/${tableId}`,
      data
    );
  }

  createEmailTemplate(data) {
    return this.http.post(`${environment.apiUrl}/events/create`, data);
  }

  updateEmailTemplate(data) {
    const id = data.id ? data.id : data._id;

    return this.http.put(`${environment.apiUrl}/events/update/${id}`, data);
  }

  deleteEmailEvent(id) {
    return this.http.delete(`${environment.apiUrl}/events/${id}`);
  }

  fireTestEmail(templateId, tableName) {
    return this.http.get(
      `${environment.apiUrl}/events/fireTest/${tableName}/${templateId}`
    );
  }

  uploadImageEmailEvent(data) {
    const formData: FormData = new FormData();
    formData.append("file", data, data.name);
    return this.http.post(`${environment.apiUrl}/upload`, formData);
  }

  updateMainTable(data) {
    return this.http.post(`${environment.apiUrl}/table/updateTable`, data);
  }

  updateTableColumn(data) {
    return this.http.post(`${environment.apiUrl}/table-columns/update`, data);
  }

  saveTableColumn(data) {
    return this.http.post(`${environment.apiUrl}/table-columns/create`, data);
  }

  getLookupsSubFormRelation(name) {
    return this.http.get(
      `${environment.apiUrl}/table-columns/getParentLookups/${name}`
    );
  }

  tableSchema(id) {
    return this.http.get(`${environment.apiUrl}/table/tableSchema/${id}`);
  }

  //Action to perform...
  actionPerform(url) {
    let res: any = "";
    try {
      res = this.http.get(`${url}`);
    } catch (e) {
      res = "";
    }
    return res;
  }

  /*Manage & Tables List End*/
  // getClientData() {
  //   return this.http.get(`${environment.apiUrl}/client/all`);
  // }

  saveClientData(data) {
    return this.http.post(`${environment.apiUrl}/client`, data);
  }

  getSubFormFieldsFromTableName(name) {
    return this.http.get(
      `${environment.apiUrl}/table-columns/getSubFormFields/${name}`
    );
  }

  deleteClientData(id) {
    return this.http.delete(`${environment.apiUrl}/client/${id}`);
  }

  getUserData() {
    return this.http.get(`${environment.apiUrl}/user/all`);
  }

  deleteUserData(id) {
    return this.http.delete(`${environment.apiUrl}/user/${id}`);
  }

  /*Dynamic Table Start*/
  getTableByName(name: string) {
    return this.http
      .get(`${environment.apiUrl}/table/getTableByName?tableName=${name}`)
      .pipe(tap())
      .toPromise();
  }

  getSumAndCount(
    tableName,
    filterKey?,
    operation?,
    days?,
    operandField?,
    mustHaveLookup?
  ) {
    let query = `tableName=${tableName}`;
    if (filterKey && filterKey.length > 0) {
      filterKey = JSON.stringify(filterKey);
      query += `&filterKey=${filterKey}`;
    }
    if (days) {
      query += `&days=${days}`;
    }
    if (operation) {
      query += `&operation=${operation}`;
    }
    if (operandField) {
      query += `&operandField=${operandField}`;
    }
    if (mustHaveLookup) {
      query += `&mustHaveLookup=${mustHaveLookup}`;
    }
    let url = `${environment.apiUrl}/getSUMnCount?${query}`;

    return this.http.get(url).toPromise();
  }

  getDynamicTreeData(
    tableName,
    pageNo?,
    search?,
    sort?,
    filterKey?,
    recordType?,
    taskRecordsOnly?,
    watchedIssue?,
    baseTable?,
    fieldName?,
    formData?,
    lookupVal?,
    searchMode?,
    viewCompletedRecords?,
    statusFilter?,
    lastSearchedURL?,
    mustHaveLookup?,
    limit?,
    FEview?,
    groupBy?,
    displayAllFields?,
    pagination?
  ) {
    // if (lastSearchedURL) {
    //   return this.http.get(lastSearchedURL);
    // }

    let query = `tableName=${tableName}&page=${pageNo}`;
    if (filterKey && filterKey.length > 0) {
      filterKey = JSON.stringify(filterKey);
      query += `&filterKey=${filterKey}`;
    }
    if (search) {
      query += `&search=${search}`;
    }
    if (statusFilter && statusFilter.length) {
      statusFilter = JSON.stringify(statusFilter);
      query += `&statusFilter=${statusFilter}`;
    }
    if (sort) {
      query += `&sortBy=${sort.column}&orderBy=${sort.direction}`;
    }
    if (recordType && Object.keys(recordType).length > 0) {
      recordType = JSON.stringify(recordType);
      query += `&recordType=${recordType}`;
    }
    if (taskRecordsOnly && taskRecordsOnly == true) {
      query += "&taskRecordsOnly=yes";
    }

    if (searchMode && searchMode == "yes") {
      query += "&isMasterSearch=yes";
    }

    if (viewCompletedRecords && viewCompletedRecords == true) {
      query += "&viewCompletedRecords=true";
    }

    if (watchedIssue && watchedIssue.noFilter && watchedIssue.watchedIssue) {
      query += "&watchedIssue=true&noWatchedFilter=true";
    } else if (watchedIssue && watchedIssue.noFilter === undefined) {
      query += "&watchedIssue=true";
    }
    if (baseTable && fieldName) {
      query += `&baseTable=${baseTable}&fieldName=${fieldName}&formData=${JSON.stringify(
        formData
      )}&lookUp=${encodeURIComponent(JSON.stringify(lookupVal))}`;
    }
    if (mustHaveLookup) {
      query += `&mustHaveLookup=${mustHaveLookup}`;
    }
    if (limit) {
      query += `&limit=${limit}`;
    }
    if (FEview) {
      query += `&FEview=${FEview}`;
    }

    if (groupBy) {
      query += `&groupBy=${groupBy}`;
    }

    if (displayAllFields) {
      query += `&displayAllFields=${displayAllFields}`;
    }

    if (pagination) {
      query += `&pagination=${pagination}`;
    }

    let url = `${environment.apiUrl}/all?${query}`;

    this.lastSearchedURL.next(url);
    this.lastAccessedPage.next(pageNo);

    return this.http
      .get(url)
      .pipe(
        tap((res: any) => {
          this.allAPIobject = res;
        })
      )
      .toPromise();
  }

  getDynamicTreeDataOld(
    tableName,
    pageNo?,
    search?,
    sort?,
    filterKey?,
    recordType?,
    taskRecordsOnly?,
    watchedIssue?,
    baseTable?,
    fieldName?,
    formData?,
    lookupVal?,
    searchMode?,
    viewCompletedRecords?,
    statusFilter?,
    lastSearchedURL?,
    mustHaveLookup?
  ) {
    if (lastSearchedURL) {
      return this.http.get(lastSearchedURL);
    }

    let query = `tableName=${tableName}&page=${pageNo}`;
    if (filterKey && filterKey.length > 0) {
      filterKey = JSON.stringify(filterKey);
      query += `&filterKey=${filterKey}`;
    }
    if (search) {
      query += `&search=${search}`;
    }
    if (statusFilter && statusFilter.length) {
      statusFilter = JSON.stringify(statusFilter);
      query += `&statusFilter=${statusFilter}`;
    }
    if (sort) {
      query += `&sortBy=${sort.column}&orderBy=${sort.direction}`;
    }
    if (recordType && Object.keys(recordType).length > 0) {
      recordType = JSON.stringify(recordType);
      query += `&recordType=${recordType}`;
    }
    if (taskRecordsOnly && taskRecordsOnly == true) {
      query += "&taskRecordsOnly=yes";
    }

    if (searchMode && searchMode == "yes") {
      query += "&isMasterSearch=yes";
    }

    if (viewCompletedRecords && viewCompletedRecords == true) {
      query += "&viewCompletedRecords=true";
    }

    if (watchedIssue && watchedIssue.noFilter && watchedIssue.watchedIssue) {
      query += "&watchedIssue=true&noWatchedFilter=true";
    } else if (watchedIssue && watchedIssue.noFilter === undefined) {
      query += "&watchedIssue=true";
    }
    if (baseTable && fieldName) {
      query += `&baseTable=${baseTable}&fieldName=${fieldName}&formData=${JSON.stringify(
        formData
      )}&lookUp=${encodeURIComponent(JSON.stringify(lookupVal))}`;
    }
    if (mustHaveLookup) {
      query += `&mustHaveLookup=${mustHaveLookup}`;
    }
    let url = `${environment.apiUrl}/all?${query}`;

    this.lastSearchedURL.next(url);
    this.lastAccessedPage.next(pageNo);

    return this.http.get(url).pipe(tap((res) => (this.allAPIobject = res)));
  }

  GetDynamicTableWithoutPagination(
    tableName,
    pagination,
    displayAllFields?,
    viewDate?,
    search?,
    sort?,
    filterKey?,
    recordType?,
    taskRecordsOnly?,
    watchedIssue?,
    baseTable?,
    fieldName?,
    formData?,
    lookupVal?,
    searchMode?,
    viewCompletedRecords?,
    statusFilter?,
    FEview?,
    groupBy?
  ) {
    let query = `tableName=${tableName}&pagination=${pagination}`;
    if (filterKey && filterKey.length > 0) {
      filterKey = JSON.stringify(filterKey);
      query += `&filterKey=${filterKey}`;
    }
    if (search) {
      query += `&search=${search}`;
    }
    if (statusFilter && statusFilter.length) {
      statusFilter = JSON.stringify(statusFilter);
      query += `&statusFilter=${statusFilter}`;
    }
    if (sort) {
      query += `&sortBy=${sort.column}&orderBy=${sort.direction}`;
    }
    if (recordType && Object.keys(recordType).length > 0) {
      recordType = JSON.stringify(recordType);
      query += `&recordType=${recordType}`;
    }
    if (taskRecordsOnly && taskRecordsOnly == true) {
      query += "&taskRecordsOnly=yes";
    }

    if (displayAllFields && displayAllFields == true) {
      query += "&displayAllFields=true";
    }

    if (searchMode && searchMode == "yes") {
      query += "&isMasterSearch=yes";
    }

    if (viewCompletedRecords && viewCompletedRecords == true) {
      query += "&viewCompletedRecords=true";
    }

    if (watchedIssue && watchedIssue.noFilter && watchedIssue.watchedIssue) {
      query += "&watchedIssue=true&noWatchedFilter=true";
    } else if (watchedIssue && watchedIssue.noFilter === undefined) {
      query += "&watchedIssue=true";
    }
    if (baseTable && fieldName) {
      query += `&baseTable=${baseTable}&fieldName=${fieldName}&formData=${JSON.stringify(
        formData
      )}&lookUp=${encodeURIComponent(JSON.stringify(lookupVal))}`;
    }

    if (FEview) {
      query += `&FEview=${FEview}`;
    }

    if (groupBy) {
      query += `&groupBy=${groupBy}`;
    }

    if (viewDate) {
      query += `&viewDate=${new Date(viewDate).toISOString()}`;
    }

    let url = `${environment.apiUrl}/all?${query}`;

    return this.http
      .get(url)
      .pipe(tap((res) => (this.allAPIobject = res)))
      .toPromise();
  }

  getTasksCount(tableName, ids) {
    return this.http.get(
      `${environment.apiUrl}/getTasksCountForIDs?tableName=${tableName}&IDs=${ids}`
    );
  }

  getDynamicTreeDataById(tableName, id, action = "") {
    try {
      let URL = `${environment.apiUrl}/getById?tableName=${tableName}&id=${id}`;
      if (action) URL = URL + "&emailAction=" + action;
      return this.http.get(URL);
    } catch (e) {
      console.log("Error firing request", e);
    }
  }

  //view invoice
  viewDocumentPDF(tableName, data, document) {
    try {
      let URL = `${environment.apiUrl}/viewDocumentPDF?tableName=${tableName}&document=${document}`;
      return this.http.post(URL, data);
    } catch (e) {
      console.log("Error firing request", e);
    }
  }

  getDynamicTreeDataByIdForPromise(tableName, id, action = "") {
    try {
      let URL = `${environment.apiUrl}/getById?tableName=${tableName}&id=${id}`;
      if (action) URL = URL + "&emailAction=" + action;
      return this.http.get(URL).toPromise();
    } catch (e) {
      console.log("Error firing request", e);
    }
  }


  getRelatedDataById(tableName, tableId, id) {
    return this.http.get(
      `${environment.apiUrl}/getByIdWithRelatedData?tableId=${tableId}&tableName=${tableName}&resourceId=${id}`
    );
  }

  saveDynamicFormData(data, tableName, tableId?) {
    return this.http.post(
      `${environment.apiUrl}/create?tableName=${tableName}&tableId=${tableId}`,
      data
    );
  }

  saveDynamicFormDataForPromise(data, tableName, tableId?) {
    let url = `${environment.apiUrl}/create?tableName=${tableName}`;
    if (tableId) {
      url = url + `&tableId=${tableId}`;
    }
    return this.http.post(url, data).toPromise();
  }

  download(url) {
    return this.http.get(url, {
      responseType: "blob",
    });
  }

  updateDynamicFormData(id, tableName, data, tableId?) {
    return this.http.put(
      `${environment.apiUrl}/update?id=${id}&tableName=${tableName}&tableId=${tableId}`,
      cloneDeep(data)
    );
  }

  updateDynamicFormDataWithPromise(id, tableName, data, tableId?) {
    return this.http
      .put(
        `${environment.apiUrl}/update?id=${id}&tableName=${tableName}&tableId=${tableId}`,
        cloneDeep(data)
      )
      .toPromise();
  }

  deleteS3File(fileName) {
    return this.http.delete(
      `${environment.apiUrl}/delete-file-from-s3?fileName=${fileName}`
    );
  }

  deleteRecordForPromise(id, tableName) {
    return this.http
      .delete(`${environment.apiUrl}/delete?id=${id}&tableName=${tableName}`)
      .toPromise();
  }

  deleteDynamicFormData(id, tableName, deleteAll?) {
    if (deleteAll) {
      const options = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
        body: {
          records: id,
        },
      };
      return this.http.delete(
        `${environment.apiUrl}/mass-delete?tableName=${tableName}`,
        options
      );
    } else {
      return this.http.delete(
        `${environment.apiUrl}/delete?id=${id}&tableName=${tableName}`
      );
    }
  }

  /*Dynamic Table End*/

  // Pending users
  getPendingUsersData() {
    return this.http.get(`${environment.apiUrl}/user/getAllPendingUser`);
  }

  /// end

  /// Multiple and single File upload in S3 API
  formFileUpload(data): Observable<HttpEvent<any>> {
    const req = new HttpRequest("POST", `${environment.apiUrl}/upload`, data, {
      reportProgress: true,
      responseType: "json",
    });
    console.log("req !!!!!!", req);
    return this.http.request(req);
    // return this.http.post(`${environment.apiUrl}/upload`, data);
  }

  uploadCSV(
    data,
    parentTableNameFromRecord?,
    parentTableDataFromRecord?,
    lookUpNameIdFromRecord?,
    lookUpNameFromRecord?
  ) {
    let query = ``;
    if (parentTableNameFromRecord) {
      query += `&parentTableNameFromRecord=${parentTableNameFromRecord}`;
    }

    if (parentTableDataFromRecord) {
      query += `&parentTableDataFromRecord=${JSON.stringify(
        parentTableDataFromRecord
      )}`;
    }

    if (lookUpNameIdFromRecord) {
      query += `&lookUpNameIdFromRecord=${lookUpNameIdFromRecord}`;
    }

    if (lookUpNameFromRecord) {
      query += `&lookUpNameFromRecord=${lookUpNameFromRecord}`;
    }

    const req = new HttpRequest(
      "POST",
      `${environment.apiUrl}/uploadCSV?${query}`,
      data,
      {
        reportProgress: true,
        responseType: "json",
      }
    );
    return this.http.request(req);
  }

  formFileUploadForPromise(data) {
    const req = new HttpRequest("POST", `${environment.apiUrl}/upload`, data, {
      reportProgress: true,
      responseType: "json",
    });
    return this.http.request(req).toPromise();
    // return this.http.post(`${environment.apiUrl}/upload`, data);
  }

  ///

  uploadProfileImage(data) {
    return this.http.post(`${environment.apiUrl}/upload`, data, {
      responseType: "json",
    });
  }

  getAllTasksData(selectedTaskValue) {
    return this.http.get(
      `${environment.apiUrl}/getTasks?selectedTaskValue=${selectedTaskValue}`
    );
  }

  getChartData() {
    return this.http.get(`${environment.apiUrl}/getChartTables`);
  }

  userDetail() {
    const apiUrl = `${environment.apiUrl}/user/me?id=${this.userId}`;
    if (this.cacheForUserDetail) {
      return of(this.cacheForUserDetail);
    }

    return this.http
      .get(apiUrl)
      .pipe(tap((res) => (this.cacheForUserDetail = res)));
  }

  userDetailWithoutCache() {
    const apiUrl = `${environment.apiUrl}/user/me?id=${this.userId}`;
    return this.http
      .get(apiUrl)
      .pipe(tap((res) => (this.cacheForUserDetail = res)));
  }

  userDetailWithPromise() {
    const apiUrl = `${environment.apiUrl}/user/me?id=${this.userId}`;
    if (this.cacheForUserDetail) {
      return of(this.cacheForUserDetail).toPromise();
    }
    return this.http
      .get(apiUrl)
      .pipe(tap((res) => (this.cacheForUserDetail = res)))
      .toPromise();
  }

  getAllUsers() {
    return this.http.get(`${environment.apiUrl}/user/getAllUsers`);
  }

  getAllUsersForPromise() {
    return this.http.get(`${environment.apiUrl}/user/getAllUsers`).toPromise();
  }

  getAllClients() {
    return this.http.get(`${environment.apiUrl}/client-management/getClients`);
  }

  fetchNotificationsHistory(limit, skip = 0) {
    return this.http.get(
      `${environment.apiUrl}/user/fetchAllNotifications?limit=${limit}&skip=${skip}`
    );
  }

  myNotifications(notificationType = "log", limit, skip = 0) {
    return this.http.get(
      `${environment.apiUrl}/user/fetchAllNotifications?notificationType=${notificationType}&limit=${limit}&skip=${skip}`
    );
  }

  myReminders(limit, skip = 0) {
    return this.http.get(
      `${environment.apiUrl}/reminders/myReminders?limit=${limit}&skip=${skip}`
    );
  }

  announcementSeen(announcementId?) {
    let obj = {
      id: this.userId,
    };

    if (announcementId) {
      obj["aid"] = announcementId;
    }
    return this.http.post(`${environment.apiUrl}/user/announcementSeen`, obj);
  }

  getDateRangeData(startDate, endDate) {
    if (startDate && endDate) {
      startDate = new Date(startDate).toUTCString();
      endDate = new Date(endDate).toUTCString();
    } else {
      startDate = new Date(startDate).toUTCString();
      endDate = new Date(startDate).toUTCString();
    }
    return this.http.get(
      `${environment.apiUrl}/getTasks?startDate=${startDate}&endDate=${endDate}`
    );
  }

  getFilterSaveData(tableName) {
    return this.http.get(
      `${environment.apiUrl}/getFilterList?tableName=${tableName}`
    );
  }

  getRelatedLookupData(tableName, resourceId, page = 1) {
    return this.http.get(
      `${environment.apiUrl}/getRelatedLookupData?tableName=${tableName}&resourceId=${resourceId}&page=${page}`
    );
  }

  getRelatedLookupDataWithPromise(tableName, resourceId, page = 1) {
    return this.http
      .get(
        `${environment.apiUrl}/getRelatedLookupData?tableName=${tableName}&resourceId=${resourceId}&page=${page}`
      )
      .toPromise();
  }

  getRelatedLookupDataByTableName(tableName) {
    return this.http.get(
      `${environment.apiUrl}/getRelatedLookupTables?tableName=${tableName}`
    );
  }

  getTasksGroupByStatus(
    skip,
    taskType,
    task,
    assignee?,
    lookupName?,
    lookupTableId?,
    lookupTableName?,
    openStatus?,
    showCompleted?,
    groupBy?,
    groupByDates?,
    createdBy?,
    watchedIssue?,
    search?,
    showFutureTasks?
  ) {
    let query = `skip=${skip}`;
    query += taskType ? `&taskType=${taskType}` : `&taskType=${null}`;
    if (task) {
      query += `&search=${task}`;
    }

    if (openStatus && openStatus.length > 0) {
      openStatus = JSON.stringify(openStatus);
      query += `&openStatus=${openStatus}`;
    }

    if (showCompleted) {
      query += `&showCompleted=${showCompleted}`;
    }

    if (lookupTableId && lookupTableName) {
      query += `&lookupTableId=${lookupTableId}&lookupTableName=${lookupTableName}`;
      if (lookupName) {
        query += `&lookupName=${lookupName}`;
      }
    } else if (assignee && assignee.length > 0) {
      assignee = JSON.stringify(assignee);
      query += `&assignee=${assignee}`;
    }

    if (groupBy === "date") {
      if (groupByDates) {
        groupByDates = JSON.stringify(groupByDates);
        query += `&groupByDates=${groupByDates}`;
      }
    }
    if (showFutureTasks) {
      query += `&showFutureTasks=true`;
    }

    if (
      !lookupTableId &&
      !lookupTableName &&
      createdBy &&
      createdBy.length > 0
    ) {
      createdBy = JSON.stringify(createdBy);
      query += `&createdBy=${createdBy}`;
    }

    if (watchedIssue === true) {
      query += `&watchedIssue=${watchedIssue}`;
    }

    if (search) {
      query += `&searchValue=${search}`;
    }

    return this.http.get(
      `${environment.apiUrl}/getTasksGroupByStatus?${query}`
    );
  }

  setWelcomeMessageSeen(userId) {
    return this.http.put<any>(
      `${environment.apiUrl}/update?id=${userId}&tableName=Users`,
      { welcomeNoteSeen: true }
    );
  }

  getTotalOpenTasks(
    assignee,
    lookupTableId,
    lookupTableName,
    openStatus,
    groupBy,
    createdBy,
    watchedIssue,
    searchValue,
    showCompleted,
    showFutureTasks?
  ) {
    let query;
    if (assignee && assignee.length > 0) {
      assignee = JSON.stringify(assignee);
      query = `&assignee=${assignee}`;
    } else if (lookupTableId && lookupTableName) {
      query = `lookupTableId=${lookupTableId}&lookupTableName=${lookupTableName}`;
    }
    if (openStatus && openStatus.length > 0) {
      openStatus = JSON.stringify(openStatus);
      query += `&openStatus=${openStatus}`;
    }
    if (groupBy) {
      query += `&groupBy=${groupBy}`;
    }

    if (showFutureTasks) {
      query += `&showFutureTasks=true`;
    }

    if (
      !lookupTableId &&
      !lookupTableName &&
      createdBy &&
      createdBy.length > 0
    ) {
      createdBy = JSON.stringify(createdBy);
      query += `&createdBy=${createdBy}`;
    }

    if (watchedIssue === true) {
      query += `&watchedIssue=${watchedIssue}`;
    }

    if (searchValue) {
      query += `&searchValue=${searchValue}`;
    }

    if (showCompleted) {
      query += `&showCompleted=${showCompleted}`;
    }

    let apiCall;

    if (query) {
      apiCall = this.http.get(
        `${environment.apiUrl}/getTotalOpenTasks?${query}`
      );
    } else {
      apiCall = this.http.get(`${environment.apiUrl}/getTotalOpenTasks`);
    }

    return apiCall.pipe(tap((res) => this.updateTaskCount(res)));
  }

  updateTaskCount(resp) {
    if (resp && resp.data) {
      const count = resp.data.reduce(
        (total, cur) => (total += cur.totalInCompleteTasks),
        0
      );
      this.taskCount$.next(count);
    }
  }

  getRedirectVariable(resp) {
    if (resp) {
      this.redirect.next(resp);
    }
  }

  redirectToHomePage() {
    this.redirect.subscribe((redirectUrl: any) => {
      if (redirectUrl) {
        this.router.navigate([redirectUrl]);
      } else {
        this.router.navigate(["pages/**"]);
      }
    });
  }

  getWelcomeVariable(resp) {
    if (resp) {
      this.welcome.next(resp);
    }
  }

  getLogoVariable(resp) {
    if (resp) {
      this.logo.next(resp);
    }
  }

  getLoginVariable(resp) {
    if (resp) {
      this.login.next(resp);
    }
  }

  getTitleVariable(resp) {
    if (resp) {
      this.title.next(resp);
    }
  }

  getLoginLogoVariable(resp) {
    if (resp) {
      this.loginLogo.next(resp);
    }
  }

  getGradientVariable(resp) {
    if (resp) {
      this.gradientColor.next(resp);
    }
  }

  getIconColors(resp) {
    if (resp) {
      this.systemConfigColor = resp;
      this.iconColors.next(resp);
    }
  }

  getAppNameVariable(resp) {
    if (resp) {
      this.appName.next(resp);
    }
  }

  get userId() {
    if (JSON.parse(localStorage.getItem("userData"))) {
      return JSON.parse(localStorage.getItem("userData"))._id;
    } else {
      return;
    }
  }

  getTaskTotal() {
    let query = {};
    if (this.userId) {
      let assignee = JSON.stringify([this.userId]);
      query = `&assignee=${assignee}`;
    }

    query += `&groupBy=date`;

    return this.http
      .get(`${environment.apiUrl}/getTotalOpenTasks?${query}`)
      .pipe(tap((res) => this.updateTaskCount(res)))
      .subscribe();
  }

  getSubTasks(taskId) {
    return this.http.get(`${environment.apiUrl}/getSubTasks?id=${taskId}`);
  }

  getTutorials(query) {
    return this.http.get(
      `${environment.apiUrl}/tutorials/getHelpByName?${query}`
    );

    // localhost:8081/api/tutorials/getHelpByName?tutorialFor=Table&tableName=Clients
  }

  getCalendarTasks() {
    return this.http.get(`${environment.apiUrl}/getCalendarTasks`);
  }

  createNotes(notesObj) {
    return this.http.post(`${environment.apiUrl}/reminders/create`, notesObj);
  }

  updateNotes(id, notesObj) {
    return this.http.put(
      `${environment.apiUrl}/reminders/update/${id}`,
      notesObj
    );
  }

  getEvents(id) {
    return this.http.get(
      `${environment.apiUrl}/reminders/resourceReminders/${id}`
    );
  }

  updateReminder(id) {
    this.reminderUpdated$.next(true);
    return this.http.put(`${environment.apiUrl}/reminders/update/${id}`, {
      visibilityStatus: "read",
    });
  }

  updateDueTimeForReminder(id, dueDate) {
    this.reminderUpdated$.next(true);
    return this.http.put(`${environment.apiUrl}/reminders/update/${id}`, {
      dueDate: dueDate,
    });
  }

  markComplete(id) {
    this.reminderUpdated$.next(true);
    return this.http.put(`${environment.apiUrl}/reminders/update/${id}`, {
      isCompleted: true,
    });
  }

  deleteReminder(id) {
    return this.http.put(`${environment.apiUrl}/reminders/update/${id}`, {
      isActive: false,
    });
  }

  announcementList(data) {
    if (data) {
      this.announcementListData.next(data);
    }
  }

  callViewForAction(
    chatSubscriptionService,
    route,
    dialogService,
    tableService,
    service,
    helperService,
    toastrService,
    nbMenuService,
    datePipe,
    router,
    linkifyService,
    changeDetector,
    location,
    sidebarService,
    messageService,
    oldData,
    newData,
    element
  ) {
    let obj = new DynamicTableViewComponent(
      chatSubscriptionService,
      route,
      dialogService,
      tableService,
      service,
      helperService,
      toastrService,
      nbMenuService,
      null,
      router,
      null,
      null,
      null,
      sidebarService,
      null,
      null,
      null,
      null,
      null,
      null
    );
    obj.oldData = oldData;
    obj.newData = newData;
    obj.onActionsClick("", element);
  }

  runCustomStyling() {
    this.setCustomStyle(".border-clr", "borderColor");
    this.setCustomStyle("mat-form-field-outline", "borderColor", '#C5C5CC');
    this.setCustomStyle(".mat-form-field-outline", "border-color", '#C5C5CC');
    this.setCustomStyle(
      "nb-stepper.updated-design .header .step.selected.noninteractive .label",
      "color"
    );
    this.setCustomStyle(".left-search-box span.left-search-icon", "color");
    this.setCustomStyle(
      ".left-search-box mat-form-field.mat-form-field-should-float .mat-form-field-outline-start, .left-search-box mat-form-field.mat-form-field-should-float .mat-form-field-outline-gap, .left-search-box mat-form-field.mat-form-field-should-float .mat-form-field-outline-end",
      "border-color"
    );

    this.setCustomStyle(".filled-dynamic-color", "background");

    this.setCustomStyle("button.filled-dynamic-color", "background");
    this.setCustomStyle("button.filled-dynamic-color", "color", "white");
    this.setCustomStyle("button.filled-dynamic-color", "border-color");

    this.setCustomStyle(
      ".header-activeCall.filled-dynamic-color",
      "color",
      "white"
    );
    // this.setCustomStyle('.filled-dynamic-color span.text-part', 'color', 'white');
    this.setCustomStyle(
      ".header-activeCall.filled-dynamic-color",
      "border-color"
    );
    this.setCustomStyle("svg.filled-dynamic-color path", "fill");

    this.setCustomStyle(".header-inner.filled-dynamic-color", "color", "white");

    this.setCustomStyle(
      "button.outline-dynamic-color",
      "color",
      this.systemConfigColor
    );
    this.setCustomStyle("button.outline-dynamic-color", "border-color");
    this.setCustomStyle(
      "button.outline-dynamic-color",
      "background",
      "transparent"
    );

    this.setCustomStyle(".txt-clr", "color");
    this.setCustomStyle(".txt-clr", "fill");
    this.setCustomStyle(".txt-clr path", "fill");
    this.setCustomStyle(".txt-bg", "background");
    this.setCustomStyle(".outlined-svg path", "stroke");
    this.setCustomStyle(".right-curved-breadcrum-outlined path", "stroke");
    this.setCustomStyle(".left-curved-breadcrum-outlined path", "stroke");
    // this.setCustomStyle('ul.brad-btn-liist li span.material-symbols-outlined', 'background');

    // this.setCustomStyle('.status-text.not-active .status-bred-txt', 'color');

    this.setCustomStyle(
      "nb-tabset.reminder-notifications-popup ul.tabset li.tab a.tab-link",
      "background",
      "transparent"
    );
    this.setCustomStyle(
      "nb-tabset.reminder-notifications-popup ul.tabset li.tab.active a.tab-link",
      "background"
    );

    this.setCustomStyle("svg.mat-datepicker-toggle-default-icon", "color");
    this.setCustomStyle(".fa-calendar", "color");
    this.setCustomStyle(".ng-select .ng-select-container", "border-color");
    this.setCustomStyle(".center-div-only .ui-label", "border-color");
    this.setCustomStyle(".date-time-inp-cus", "border-color");
    this.setCustomStyle(".system-color", "color", this.systemConfigColor);
    this.setCustomStyle(".fa-question-circle", "color", this.systemConfigColor);
    this.setCustomStyle(".custom-checkbox.checked", "border-color");
    this.setCustomStyle(".custom-checkbox.checked", "background");
    this.setCustomStyle(".ref-options", "background");

    this.setCustomStyle(".notification_dropdown svg", "fill");
  }

  setCustomStyle(selector, prop, color = "") {
    let el: any;
    let colr = color ? color : this.systemConfigColor;

    el = document.querySelectorAll(selector);

    for (var i = 0; i < el.length; i++) {
      el[i].style.setProperty(prop, colr, "important");
    }
  }

  // push to all apps
  setPageLayout(data: any) {
    return this.http.post(`${environment.apiUrl}/page-layout`, data);
  }
  setEventTemplate(data: any) {
    return this.http.post(
      `${environment.apiUrl}/page-layout/emailTemplate`,
      data
    );
  }
  setActions(data: any) {
    return this.http.post(`${environment.apiUrl}/page-layout/action`, data);
  }
  setFilter(data: any) {
    return this.http.post(`${environment.apiUrl}/page-layout/filter`, data);
  }

  setFormLayout(data: any) {
    return this.http.post(
      `${environment.apiUrl}/push-to-apps/pushFormLayout`,
      data
    );
  }

  //Activate - Deactivate form all apps

  updateFormLayout(data: any) {
    return this.http.post(
      `${environment.apiUrl}/push-to-apps/changeFormStatus`,
      data
    );
  }

  updateFilter(data: any) {
    return this.http.post(
      `${environment.apiUrl}/activedeactive-from-all/updateFilter`,
      data
    );
  }

  updateAction(data: any) {
    return this.http.post(
      `${environment.apiUrl}/activedeactive-from-all/updateAction`,
      data
    );
  }

  updatePageLayout(data: any) {
    return this.http.post(
      `${environment.apiUrl}/activedeactive-from-all/updatePageLayout`,
      data
    );
  }

  updateEvent(data: any) {
    return this.http.post(
      `${environment.apiUrl}/activedeactive-from-all/updateEmailTemplate`,
      data
    );
  }

  //push Table Configs

  pushTableConfigs(data: any) {
    return this.http.post(
      `${environment.apiUrl}/push-table-configs/pushTable`,
      data
    );
  }

  queryParamGeneration(field, item, objKeys) {
    if (field.type == "date" || field.type == "dateTime") {
      if (item[objKeys[0]] && item[objKeys[0]].value) {
        switch (item[objKeys[0]].operation) {
          case "$eq": {
            if (item[objKeys[0]].value == "Today") {
              let obj = {
                fromDate: moment().format("YYYY-MM-DD"),
                toDate: moment().format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            } else if (item[objKeys[0]].value == "Yesterday") {
              let obj = {
                fromDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
                toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            } else if (item[objKeys[0]].value == "Week") {
              let obj = {
                fromDate: moment().startOf("week").format("YYYY-MM-DD"),
                toDate: moment().endOf("week").format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            } else if (item[objKeys[0]].value == "Month") {
              let obj = {
                fromDate: moment().startOf("month").format("YYYY-MM-DD"),
                toDate: moment().endOf("month").format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            }
            break;
          }

          case "$lt": {
            if (item[objKeys[0]].value == "Today") {
              let obj = {
                fromDate: moment().subtract(1, "years").format("YYYY-MM-DD"),
                toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            } else if (item[objKeys[0]].value == "Yesterday") {
              let obj = {
                fromDate: moment().subtract(1, "years").format("YYYY-MM-DD"),
                toDate: moment().subtract(2, "days").format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            } else if (item[objKeys[0]].value == "Week") {
              let obj = {
                fromDate: moment().subtract(1, "years").format("YYYY-MM-DD"),
                toDate: moment().subtract(1, "weeks").format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            } else if (item[objKeys[0]].value == "Month") {
              let obj = {
                fromDate: moment().subtract(1, "years").format("YYYY-MM-DD"),
                toDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            }
            break;
          }

          case "$gt": {
            if (item[objKeys[0]].value == "Today") {
              let obj = {
                fromDate: moment().add(1, "days").format("YYYY-MM-DD"),
                toDate: moment().add(1, "years").format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            } else if (item[objKeys[0]].value == "Yesterday") {
              let obj = {
                fromDate: moment().format("YYYY-MM-DD"),
                toDate: moment().add(1, "years").format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            } else if (item[objKeys[0]].value == "Week") {
              let obj = {
                fromDate: moment().add(1, "weeks").format("YYYY-MM-DD"),
                toDate: moment().add(1, "years").format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            } else if (item[objKeys[0]].value == "Month") {
              let obj = {
                fromDate: moment().add(1, "months").format("YYYY-MM-DD"),
                toDate: moment().add(1, "years").format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            }
            break;
          }

          case "$lte": {
            if (item[objKeys[0]].value == "Today") {
              let obj = {
                fromDate: moment().subtract(1, "years").format("YYYY-MM-DD"),
                toDate: moment().format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            } else if (item[objKeys[0]].value == "Yesterday") {
              let obj = {
                fromDate: moment().subtract(1, "years").format("YYYY-MM-DD"),
                toDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            } else if (item[objKeys[0]].value == "Week") {
              let obj = {
                fromDate: moment().subtract(1, "years").format("YYYY-MM-DD"),
                toDate: moment().subtract(1, "weeks").format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            } else if (item[objKeys[0]].value == "Month") {
              let obj = {
                fromDate: moment().subtract(1, "years").format("YYYY-MM-DD"),
                toDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            }
            break;
          }

          case "$gte": {
            if (item[objKeys[0]].value == "Today") {
              let obj = {
                fromDate: moment().format("YYYY-MM-DD"),
                toDate: moment().add(1, "years").format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            } else if (item[objKeys[0]].value == "Yesterday") {
              let obj = {
                fromDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
                toDate: moment().add(1, "years").format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            } else if (item[objKeys[0]].value == "Week") {
              let obj = {
                fromDate: moment().format("YYYY-MM-DD"),
                toDate: moment().add(1, "years").format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            } else if (item[objKeys[0]].value == "Month") {
              let obj = {
                fromDate: moment().format("YYYY-MM-DD"),
                toDate: moment().add(1, "years").format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            }
            break;
          }

          default: {
            if (item[objKeys[0]].value == "Today") {
              let obj = {
                fromDate: moment().format("YYYY-MM-DD"),
                toDate: moment().format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            } else if (item[objKeys[0]].value == "Yesterday") {
              let obj = {
                fromDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
                toDate: moment().format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            } else if (item[objKeys[0]].value == "Week") {
              let obj = {
                fromDate: moment().subtract(1, "weeks").format("YYYY-MM-DD"),
                toDate: moment().format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            } else if (item[objKeys[0]].value == "Month") {
              let obj = {
                fromDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
                toDate: moment().format("YYYY-MM-DD"),
              };

              item[objKeys[0]] = obj;
            }
            break;
          }
        }
      }
    }

    return item;
  }

  searchInput = new Subject();

  setSearchInput(event) {
    this.searchInput.next(event);
  }
}

export class FilterForService {
  tableName: string;
  filterKey: any[];
  lookupValue: {};
  lastFilterSelected: string;

  constructor() {
    this.tableName = "";
    this.filterKey = [];
    this.lookupValue = {};
    this.lastFilterSelected = null;
  }
}
