import { Component, Input, OnInit } from '@angular/core';
import { TableService } from '@app/shared/services/table.service';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-archive-dialog',
  templateUrl: './archive-dialog.component.html',
  styleUrls: ['./archive-dialog.component.scss']
})
export class ArchiveDialogComponent implements OnInit {

  @Input() items = [];
  @Input() records = [];
  selectedOption = '';
  systemConfigColor = '';
  constructor(
    protected ref: NbDialogRef<ArchiveDialogComponent>,
    private tableService:TableService
  ) { }

  ngOnInit(): void {

    this.tableService.$systemConfig.subscribe((res: any) => {
      if (res) {
        this.systemConfigColor = res.IconColors;
        this.tableService.runCustomStyling();
      }
    })
  }

  cancel() {
    this.ref.close();
  }

  submit(selectedOption=''){

    if(!selectedOption){
      alert('Please select some status');
      return;
    }

    this.ref.close(selectedOption);
  }

}
