import { Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LayoutService } from '@app/@core/utils';
import { TableService } from '@app/shared/services/table.service';
import { NbSidebarService } from '@nebular/theme';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header subheader>
        <ngx-header></ngx-header>
      </nb-layout-header>


      <nb-sidebar [state]="this.state" class="menu-sidebar" tag="menu-sidebar" responsive >
      <a href="#" class="sidebar-toggle" matRipple [matRippleUnbounded]="true" [matRippleCentered]="true"
        (click)="toggleSidebar()" [ngStyle]="{'color':systemConfigColor}">
        <nb-icon id="menu-id" [icon]="'menu-2-outline'"></nb-icon>
      </a>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer style="display: none;" fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  url: string = '';
  state: string = 'compacted';
  currentUser;
  condtion = false;
  systemConfigColor: any;

  constructor(private sidebarService: NbSidebarService, private router: Router,
    private layoutService: LayoutService, private tableService: TableService,
  ) {

    this.currentUser = JSON.parse(localStorage.getItem('userData'));

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.url = '';
        this.url = this.router.url;
        this.url = this.url.slice(14, 21);
        if (this.url.includes('dynamic')) {
          this.state = 'compacted';
        }
      }
    });

    this.tableService.$systemConfig.subscribe((res: any) => {
      if (res) {
        this.systemConfigColor = res.IconColors;
      }
    })

  }


  // onOver() {
  //   if (this.url.includes('dynamic')) {
  //     this.sidebarService.toggle(true, 'menu-sidebar');
  //   }
  // }

  // onLeave() {

  //   //-- Check for Hemant
  //   //   if(this.currentUser.firstName != 'hemant'){
  //   if (this.url.includes('dynamic')) {
  //     this.sidebarService.toggle(true, 'menu-sidebar');
  //   }
  //   //   }
  // }

  toggleSidebar(): boolean {
    console.log(55)
    this.sidebarService.toggle(true, "menu-sidebar");
    setTimeout(() => {
      document.getElementById("search_tab_input").focus();
    }, 200);
    this.layoutService.changeLayoutSize();
    this.tableService.toggleSidebar.next((this.condtion = !this.condtion));
    return false;
  }

}
