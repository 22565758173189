import { TableService } from '@app/shared/services/table.service';
import { DynamicFormDialogNewDesignComponent } from '@app/shared/components/dynamic-form-dialog-new-design/dynamic-form-dialog-new-design.component';
import { Injectable, Optional } from '@angular/core';
import { ChatSubscriptionService } from './services/chat-subscription.service';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
// import { NgPluralizeService } from 'ng-pluralize';
import { NbDialogService } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormCommonService {

  constructor(private chatSubscriptionService: ChatSubscriptionService,
    private location: Location, private tableService: TableService,
    private sanitizer: DomSanitizer,
    @Optional() private dialogService: NbDialogService) { }

  activateSubscription(data) {
    this.chatSubscriptionService.watch(data).subscribe(
      (res: any) => {
        if (res.statusCode === 201) {
          return res;
        }
        else {
          return res;
        }
      },
      (error) => {
        return '';
      },
    );
    return '';
  }

  userLookupChangedWatchers(temp: Object) {

    if (temp["lookup"]) {
      let inval = false;
      let id = [];
      temp["lookup"].forEach(element => {
        if (element.lookupTableName == "Users") {
          inval = true;
          id.push(element.lookupId);
        }
      });
      if (inval) {
        return id;
      }

      return id;
    }
  }


  async openAndCloseForm(data, tableName, tableData, viewFlag, recordType?,
    fromRecord?, parentObj?, viewDate?, status?, mappedFields?, mappedKeysValues?,
    mapDataArray?,stepperIndex?,parentStatus?,fromBreadCrump?, folderList?): Promise<any> {

    let res: any = await this.tableService.getTableByName(tableName);
    if (res) {

      if (res?.data && res.data[0].columns) {
        let actions = res.data[0].actions ? res.data[0].actions : [];
        let formLayouts = res.data[0].formLayouts ? res.data[0].formLayouts : [];
        let tutorials = res.data[0].tutorials ? res.data[0].tutorials : [];
        let recordGadgets = res.data[0].recordGadgets ? res.data[0].recordGadgets : [];
        let showChats = res.data[0].showChats ? res.data[0].showChats : false;

        let customValidations = res.data[0].customValidations ? res.data[0].customValidations : [];
        let tableIcons = res.data[0].iconLocation ? res.data[0].iconLocation : '';
        let formHeight = res.data[0].formHeight ? res.data[0].formHeight : null;
        let formWidth = res.data[0].formWidth ? res.data[0].formWidth : null;

        if (formWidth) {

          let width = (Number)(formWidth);
          if (width < 410) {
            width = 410
          }
          let wid: any = this.sanitizer.bypassSecurityTrustStyle((String)(width + 350));
          formWidth = wid.changingThisBreaksApplicationSecurity;
        }

        let fieldAlignment = res.data[0].fieldAlignment ? res.data[0].fieldAlignment : null;
        let recordTypes = [];
        let recordTypeFieldName = '';
        res.data[0].columns.map((column) => {
          if (column.type == "recordType") {
            recordTypeFieldName = column.name;
            column.options.forEach((element) => {
              // let plural = this.service.fromCount(element, 2, false);

              const obj = {
                title: element,
                pluralize: element,
              };
              recordTypes.push(obj);
            });
          }

        });

        let customLabelForAddForm = '';
        if (res.data[0].customLabelForAddForm) {
          customLabelForAddForm = res.data[0].customLabelForAddForm;
        }



        let subFormLookupIds = res.data[0].subFormLookups ? res.data[0].subFormLookups : [];

        let title: string;
        if (recordTypeFieldName && recordType) {
          title = "Add New " + recordType;
        } else {
          if (customLabelForAddForm) {
            title = customLabelForAddForm;
          }
          else {
            title = 'Add New ' + tableName;
          }

        }
        console.log("res.data[0].columns",res.data[0].columns)
        let formComp: any = DynamicFormDialogNewDesignComponent;
        let formObj = {
          title: title,
          subFormLookupIds: subFormLookupIds,
          form: res.data[0].columns,
          tableName: tableName,
          recordType: recordType,
          recordTypeFieldName: recordTypeFieldName,
          // tableRecordTypes: tableRecordTypes,
          tableDataForForms: tableData,
          actions: actions,
          formLayouts: formLayouts,
          tutorials: tutorials,
          customValidations: customValidations,
          tableId: res.data[0]._id,
          tableIcons: tableIcons,
          statusColorFromTree: status,
          viewFlag: viewFlag,
          folderList: folderList,
          dueDate: viewDate
        }

        if (mappedFields && mappedKeysValues) {
          formObj['mapField'] = mappedFields;
          formObj['values'] = mappedKeysValues;
        }

        if (mapDataArray) {
          formObj['mapDataArray'] = mapDataArray;
        }

        if(parentStatus){
          formObj['parentStatus'] = parentStatus;
        }

        if(stepperIndex){
          formObj['stepperIndex'] = stepperIndex;
        }

        if(fromBreadCrump){
          formObj['fromBreadCrump'] = fromBreadCrump;
        }

        if (!data) {
          formObj['button'] = { text: "Save" };
          formObj['Data'] = null;
          formObj['action'] = "Add";

          // if (this.subFormLookupIds && this.subFormLookupIds.length == 0) {

          formComp = DynamicFormDialogNewDesignComponent;
          formObj['recordGadgets'] = recordGadgets;
          formObj['showChats'] = viewFlag ? true : showChats;
          formObj['formHeight'] = formHeight;
          formObj['formWidth'] = formWidth;
          formObj['fieldAlignment'] = fieldAlignment;
          // formObj['optionRecordType'] = this.tableRecordTypes["Tasks"];

          if (fromRecord) {
            formObj['parentTableName'] = parentObj.parentTableName;
            formObj['parentTableData'] = parentObj.parentTableData;
            formObj['lookUpNameId'] = parentObj.lookUpNameId;
            formObj['lookUpName'] = parentObj.lookUpName;
          }
          // }
          // this.openAgainOnAddUpdate(formComp, formObj);
        } else {

          if (recordTypeFieldName && data.hasOwnProperty([recordTypeFieldName])) {
            title = "Edit " + data[recordTypeFieldName];
          } else {
            title = "Edit " + tableName;
          }

          formObj['title'] = title;
          formObj['button'] = { text: "Update" };
          formObj['Data'] = data;
          formObj['id'] = data._id;
          formObj['action'] = "Edit";
          // if (this.subFormLookupIds.length == 0) {

          formComp = DynamicFormDialogNewDesignComponent;
          formObj['recordGadgets'] = recordGadgets;
          formObj['showChats'] = showChats;
          formObj['formHeight'] = formHeight;
          formObj['formWidth'] = formWidth;
          formObj['fieldAlignment'] = fieldAlignment;
          // formObj['optionRecordType'] = this.tableRecordTypes["Tasks"];

          if (fromRecord) {
            formObj['parentTableName'] = parentObj.parentTableName;
            formObj['parentTableData'] = parentObj.parentTableData;
            formObj['lookUpNameId'] = parentObj.lookUpNameId;
            formObj['lookUpName'] = parentObj.lookUpName;
          }


        }

        return { 'formComp': formComp, 'formObj': formObj };
      }
    }

  }
}
