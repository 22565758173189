import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventEmiterService {
  isDynamicTableView:boolean=false;
  isDraggable:boolean=false;
  externalWindow:any=null
  private reminderSub =new Subject<any>();

  realData = {}
  creatorData={date:'',firstName:'',lastName:'', recordId:[] ,text:'',type:''}
  allIcons={};
  idField = [];
  dynamicID: any;
  loading: boolean;
  Announcments: boolean = false ;
  sendReminderClick(){
    this.reminderSub.next();
  }
  getReminderClick(){
    return this.reminderSub.asObservable();
  }
}