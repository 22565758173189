<ng-container *ngIf="!externalViewer">
    <div *ngIf="configuredViewer !== 'pdf'" [innerHtml]="docHtml"></div>
    <object *ngIf="fullUrl && configuredViewer === 'pdf'" [data]="fullUrl"
        type="application/pdf" width="100%" height="100%">
        <p>Your browser does not support PDFs.
            <a [href]="fullUrl">Download the PDF</a>.</p>
    </object>
</ng-container>
<ng-container *ngIf="externalViewer">
    <iframe (load)="iframeLoaded()" *ngIf="fullUrl && disableContent === 'none'" #iframe id="iframe" frameBorder="0" [src]="fullUrl"></iframe>
    <div class="container" *ngIf="disableContent !== 'none'">
        <div [class.overlay-full]="disableContent === 'all'"
            [class.overlay-popout-google]="configuredViewer ==='google' && (disableContent === 'popout' || disableContent === 'popout-hide')"
            [class.overlay-popout-office]="configuredViewer ==='office' && (disableContent === 'popout' || disableContent === 'popout-hide')"
            [style.background-color]="disableContent === 'popout-hide' ? '#fff': 'transparent'">
        </div>
        <iframe (load)="iframeLoaded()" *ngIf="fullUrl" #iframe id="iframe" frameBorder="0" [src]="fullUrl"></iframe>
    </div>
</ng-container>