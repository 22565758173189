<nb-card class="appear" [nbSpinner]="loading" nbSpinnerSize="large" nbSpinnerStatus="primary">
  <nb-card-header>
    <span class="material-symbols-outlined search-icon-apper">search</span>
    Add Reference
    <!-- <img class="close-btn" src="/assets/images/ic_close_24px.svg" (click)="close()"> -->
    <span class="material-symbols-outlined close-btn-apper" (click)="close()">close</span>
    <span class="colored-divider filled-dynamic-color"></span>
  </nb-card-header>
  <nb-card-body>

    <div class="container">
      <div class="row appear-main-row">
        <!-- <span class="new-label">Search by {{placehold}}....</span> -->
        <span class="new-label">Search by General Reference, Client, Task...</span>
        <mat-form-field appearance="outline" style="width: 100%;">
          <input matInput type="text" [(ngModel)]="searchString" (keyup)="getData($event.target.value,$event)">
        </mat-form-field>
      </div>
      <div *ngIf="!fromRelated" class="row refer-lookup">
        <div class="col-md-6">
          <div *ngIf="refFlag" class="select-lookup-refeer">
            <!-- <span class="new-label">Select Lookup</span> -->

            <button [nbPopover]="popUpRef" nbPopoverPlacement="right" class="filled-dynamic-color refrence-add-btn">Add
              New <span class="expand-arrow">></span></button>
            <ng-template #popUpRef>
              <div class="ref-options">
                <mat-option *ngFor="let lookup of lookupArray" (click)="setValue(lookup)">
                  {{lookup.label}}</mat-option>
              </div>
            </ng-template>

          </div>
        </div>
      </div>
      <ng-container *ngIf="showDataFlag">
        <div class="row result">
          <span>{{count}} Results</span>
        </div>

        <div *ngFor="let ele of finalArray" class="ui-row-content">
          <div class="row heading">
            <span>{{ele.name}}</span>
          </div>
          <div class="row label-css" *ngFor="let item of ele.value" (click)="setData(item,ele)">
            <!-- Add static row and design as per mockup -->
            <span *ngFor="let val of item.idField" class="ui-content1" [innerHtml]='val'> </span>

            <span *ngFor="let val of item.others" class="ui-content2" [innerHtml]='val'></span>
            <!-- <span class="ui-content2">703 Addison Dr. Bronx, NY 10468</span> -->
            <!-- <span class="col-md-12">{{val}}</span> -->


          </div>
        </div>
      </ng-container>
    </div>

    <!-- <div class="white-space"></div> -->
  </nb-card-body>
</nb-card>
