<nb-card class="email_modal">
    <nb-card-header>
              Add Notes
          <div class="col-md-1 close-btn" (click)="close()" style="cursor: pointer;">
              <strong>X</strong>
          </div>
    </nb-card-header>

    <nb-card-body>
        ​<div class="row">
            <div class="col-md-4">
                <span class='date-time-pic-cus-title col-md-4'>Due Date:<span style="color: red;">*</span></span>
            </div>
            <div class="col-md-7">
                <app-date-time-picker name="dueDate" [(ngModel)]="createNoteObj.dueDate" pla></app-date-time-picker>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12">

                <mat-form-field class="mb-20 width-100 mr-top-15">
                    <mat-label class="col-md-4">Notes:<span style="color: red;">*</span></mat-label>
                    <textarea class="col-md-8" matInput placeholder="Enter Notes" name="notes" [(ngModel)]="createNoteObj.notes"></textarea>
                </mat-form-field>
            </div>

        </div>

        <button class="btn btn-primary pull-right" (click)="saveNotes()">
            Add
        </button>
    </nb-card-body>
</nb-card>
