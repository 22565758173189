import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { TableService } from './../shared/services/table.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  userData: any;
  configs: any;
  roleData: any;
  constructor(private tableService: TableService,
    private router: Router) {

    this.tableService.$systemConfig.subscribe((configs) => {
      this.configs = configs;
    });

    this.roleData = JSON.parse(localStorage.getItem('roleData'));
    this.userData = JSON.parse(localStorage.getItem('userData'));
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

      this.roleData = JSON.parse(localStorage.getItem('roleData'));
      this.userData = JSON.parse(localStorage.getItem('userData'));
  
      this.tableService.$systemConfig.subscribe((configs) => {
        this.configs = configs;
        let redirectUrl = 'pages/tables/Dashboard';
        if (this.userData?.lookup && this.userData?.lookup.length) {
  
          let roleObject = this.userData.lookup.find(v => v.lookupTableName == 'Roles');
          if (roleObject) {
  
            if (this.roleData['loginRedirect']) {
              redirectUrl = this.roleData['loginRedirect'];
            } else if (this.configs['Login Redirect']) {
              redirectUrl = this.configs['Login Redirect'];
            } else {
              redirectUrl = 'pages/tables/Dashboard';
            }
          } else if (this.configs['Login Redirect']) {
            redirectUrl = this.configs['Login Redirect'];
          } else {
            redirectUrl = 'pages/tables/Dashboard';
          }
  
        }
  
        this.router.navigate([redirectUrl]);
      });
  
      return true;
    
  }

}
