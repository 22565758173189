import { DatePipe, DOCUMENT, Location } from "@angular/common";
import { HttpClient, HttpEventType, HttpResponse } from "@angular/common/http";
import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { MatMenuTrigger } from "@angular/material/menu";
import { DomSanitizer, Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmDeleteComponent } from "@app/pages/tables/confirm-delete/confirm-delete.component";
import { ConfirmDialogComponent } from "@app/shared/components/confirm-dialog/confirm-dialog.component";
import { InfoDialogComponent } from "@app/shared/components/dialog/info-dialog/info-dialog.component";
import { DynamicFormCommonService } from "@app/shared/dynamic-form-common.service";
import { DateCustomPipe } from "@app/shared/pipes/date.pipe";
import { FilterPipe } from "@app/shared/pipes/filter.pipe";
import { PhonePipe } from "@app/shared/pipes/phone.pipe";
import { ChatSubscriptionService } from "@app/shared/services/chat-subscription.service";
import { EventEmiterService } from "@app/shared/services/event-emiter.service";
import { HelperService } from "@app/shared/services/helper.service";
import { MessageService } from "@app/shared/services/message.service";
import { SocketService } from "@app/shared/services/socket.service";
import { TableService } from "@app/shared/services/table.service";
import { environment } from "@env/environment";
import {
  NbContextMenuDirective,
  NbDialogRef,
  NbDialogService,
  NbMenuService,
  NbPopoverDirective,
  NbSidebarService,
  NbStepperComponent,
  NbToastrService
} from "@nebular/theme";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { iconList } from "@shared/iconData/iconList";
import { jsPDF } from 'jspdf';
import { cloneDeep } from "lodash";
import * as moment from "moment-timezone";
import { NgPluralizeService } from "ng-pluralize";
import { NgxLinkifyjsService } from "ngx-linkifyjs";
import { forkJoin, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DynamicFormDependenciesPipe } from "../../pipes/dynamic-form-dependency-filter.pipe";
import { MapService } from "../../services/map.service";
import { AddRefComponent } from "../add-ref/add-ref.component";
import { LookupDetailDialogComponent } from "../dialog/lookup-detail-dialog/lookup-detail-dialog.component";
import { FilePreviewDialogComponent } from "../file-preview-dialog/file-preview-dialog.component";
import { NewReminderModalComponent } from "../new-reminder-modal/new-reminder-modal.component";
import { FilterForService } from './../../../shared/services/table.service';
import { DynamicSubformComponent } from "./../dynamic-form-dialog/dynamic-subform/dynamic-subform.component";

@Component({
  selector: "ngx-dynamic-form-dialog-new-design",
  templateUrl: "./dynamic-form-dialog-new-design.component.html",
  styleUrls: ["./dynamic-form-dialog-new-design.component.scss"],
  providers: [DynamicFormDependenciesPipe],
})
export class DynamicFormDialogNewDesignComponent
  implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy, OnChanges {
  @Input() inlineView: boolean = false;
  @Input() stepperIndex;
  @Input() formLayouts = [];
  @Input() fromBreadCrump = false;
  private destroy$ = new Subject();
  dynamicForm: FormGroup;
  newDynamicForm: FormGroup;
  formColumns: any;
  refDynamicForm: FormGroup;
  mappedKeysValues = {};
  @Input() tableDataForForms;
  refDynamicData: any;
  inputFields: string[] = ["password", "text", "email"];
  userFields: any = {};
  clientFields = ["zip", "city", "state"];
  loading = false;
  loadingAPI = false;
  formSubmitted = false;
  validFlag = false;
  options: string[];
  lookupValue = {};
  lookupObj = {};
  filteredOptions = {};
  selectedDoc = '';
  search: any;
  lookTable: any;
  lookupName: any;
  tableData: any = [];
  lookupData: any = [];
  isActive: boolean;
  currentGadgetValue = "";
  finalRecordTypes = [];
  fileUploadS3Data: [];
  demoData: [];
  fileFormData: any = [];
  showArrayData: {};
  @Input() droppedFiles: {};
  uploadProgress = 0;
  buttonDisable = true;
  checkData = false;
  isUpload = false;
  dateTimeErrorFlag = false;
  dateErrorFlag = false;
  removeLookup = {};
  uploadedFiles: {};
  editData = {};
  lookupFieldRequired = {};
  valueToMapInField;
  showEditIconForStatus = true;
  S3 = "https://cavalry.s3.amazonaws.com/";

  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  @ViewChild("scrollable") scrollable: ElementRef;
  @ViewChild("notLoadAsDropdownLookupPopover", { static: false })
  notLoadAsDropdownLookupPopover;
  @ViewChild("referenceLookupPopover", { static: false })
  referenceLookupPopover;
  @ViewChild("referenceRecordLookupPopover", { static: false })
  referenceRecordLookupPopover;
  @ViewChild("loadAsDropdownLookupPopover", { static: false })
  loadAsDropdownLookupPopover;
  @ViewChild("editNotLoadAsDropdownLookupPopover", { static: false })
  editNotLoadAsDropdownLookupPopover;
  @ViewChild("editLoadAsDropdownLookupPopover", { static: false })
  editLoadAsDropdownLookupPopover;
  @ViewChild("stepper") stepper: NbStepperComponent;

  @Input() tableName: string;
  @Input() optionRecordType;
  @Input() title: string;
  @Input() subFormLookupIds: any;
  @Input() form: any[];
  @Input() button: { text: string };
  @Input() Data: any;
  @Input() isForceSave: boolean = false;
  @Input() recordType: string;
  @Input() parentStatus = '';
  @Input() taskStatus: string;
  @Input() recordTypeFieldName: string;
  @Input() recordTypeFlagFromAddNew = false;
  @Input() action: string;
  @ViewChild("autoInput") input;
  @Input() parentTableName: string;
  @Input() parentTableData: any;
  @Input() recordTypeName: string;
  @ViewChild("formpicker") formpicker;
  @Input() isDraggedFromParent: boolean;
  @Input() showChats = false;
  @Input() lookUpNameId = "";
  @Input() lookUpName = "";
  @Input() parentLookupName = "";
  @Input() dueDate: Date;
  @Input() tableIcon = "";
  @Input() viewFlag = false;
  @Input() editViewFlag = false;
  @Input() tableId;
  @Input() id;
  @Input() actions;
  @Input() recordGadgets;
  @Input() fromOldView = false;
  @Input() formHeight;
  @Input() formWidth;
  @Input() fieldAlignment;
  @Input() customValidations;
  @Input() fromRecordPage = false;
  @Input() getFolderList: any;
  groupChatTitle = "";
  lookUpOptions = [];
  timeout;
  dependsFields = [];
  unwantedFieldsInOptions = ["displayInTree", "lookups", "_id", "createdAt"];
  public subFormData = [];
  public subFormFields = [];
  public subFormTableName = [];
  public showSubForm = {};
  public cssFlagAfterVisibility: boolean = false;
  public subFormFieldCount = 0;
  public subFormDataValues = [];
  public subFormDataDataFields = [];
  public editDataSubForm = [];
  tempEditFormData = [];
  public subFormLookupData: any = [];
  subFormActions = [];

  subFormLookupFields = {};
  subFormLookupFieldRequired = [];
  subFormEditData = [];
  subFormRemoveLookup = [];
  show = {};
  list;
  visibilityData;
  reftableId: any;
  tempParentTableHeader: any = [];
  refRecordTypes: any = [];
  reflookupFieldRequired = {};
  refshowAutocomplete = {};
  reffilteredOptions = {};
  refeditData = {};
  reflookupName: any;
  reflookTable: any;
  refremoveLookup = {};
  refIsInputDisable: boolean;
  refUserFields: any = {};
  refDemoData: [];
  refLookupData: any = [];
  refLookupValue = {};
  refEditData: any;
  refShow = {};
  refVisibilityData: any;
  refDependsFields = [];
  refRecordType: any;
  refparentTableName: any;
  refLookUpNameId: any;
  refRecordTypeFieldName: any;
  refDynamicFilterData: any;
  reftitle = "";
  refLookUpName: any = "";
  reftableData: any = [];
  refTableName: string;
  @Input() tableRecordTypes = [];
  @ViewChild(NbContextMenuDirective) contextMenu: NbContextMenuDirective;
  tableRecordType: any;
  setFieldIndex: any;
  isSubForm: boolean = false;
  subField: any;
  @Input() tutorials = [];
  statuses = {};
  chatOpened = true;
  continueFlag = false;

  // -- Watch properties
  isWatcherOpened = false;
  subscriptionText = "Follow this task";
  subscribers = [];
  tableInfo = {};
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  isSelfSubscribed = false;
  currentUser = null;
  public dependenciesList: any = [];
  public count: number = 0;
  colorSetter = {};
  dateTimePickerToggeledOn = false;
  editform: boolean = false;
  tutorial = "Hello this is tutorial";
  sections = [];
  arrayForLookup = [];
  withoutSectionData = [];
  public refreshFilterVar = "";
  public dropDownText = {};
  public rollupFields = [];
  getTableByNameObjectForData = {};
  colorForFont = "";
  sizeObj = {
    large: 12,
    medium: 6,
    small: 4,
    tiny: 3,
  };
  addEditFlag = true;
  finalArrayForWithoutSection = [];
  finalArrayForSection = [];
  singularTableName;
  hour;
  minute;
  second;
  day;
  year;
  recordData;
  imageForDropdownWithImageView = "";
  lookupArray = [];
  lookupTableName = [];
  otherDataKeys = [];
  @Input() mapField: any;
  @Input() values: any;
  showFooter = true;
  finalArrayForViewMode = [];
  loadTinyMce = false;
  lookupDataObject = {};
  forkJoinLookupResponse;
  lookupDetailClicked = false;
  lookupDetailItem;
  tableForLookupDetail;
  dataForLookupDetail;
  tableIdFromView;
  fieldNameForLookup;
  actionsMenu = [];
  actionSubscription: Subscription;
  forkJoinTableResponse: any;
  taskRecordTypes = [];
  recordGadgetValue;
  gadgetFieldValue;
  optionsData = [];
  subFormVariables = {
    formHeight: null,
    formWidth: null,
    actions: [],
    customValidations: [],
    fieldAlign: null,
    tableIcon: "",
    recordgadgets: [],
    showChats: null,
    form: [],
    tableId: null,
  };
  useHostListener = false;
  mapData = {};
  @Input() mapDataArray = [];
  showAddTask = false;
  taskFormHeight;
  taskFormWidth;
  taskFormCustomValidations = [];
  taskFormActions = [];
  taskFormRecordGadgets = [];
  taskFormFieldAllignment;
  taskFormForm = [];
  taskTableIcon;
  taskTableId;
  recordTypes = [];
  taskRecordType = "";
  taskRecordTypeFieldName;
  timeZone;
  @Input() statusColorFromTree;

  gradientColor = "";
  systemConfigColor = "";
  idValues = "";
  searchMode = "no";
  statusFilter = [];
  pageOfItems = [];
  filterObject: FilterForService;
  selectedGroupBy = '';
  sortObject: any = null;
  kanbanSort = {};
  userPreferences = [];
  userTablePreferencesForSorting = [];
  hasUserPreference = false;

  checkListArray = [];

  selectedCar: number;
  progressColor;
  cars = [
    { id: 1, value: "Volvo" },
    { id: 2, value: "Saab" },
    { id: 3, value: "Opel" },
    { id: 4, value: "Audi" },
  ];

  formulaDataObject = {};
  viewFlagForSubForm = {};
  currentTimeStamp;
  constructor(
    private elRef: ElementRef,
    @Optional() public ref: NbDialogRef<DynamicFormDialogNewDesignComponent>,
    private modalDialg: NgbModal,
    @Inject(DOCUMENT) private document: Document,
    private formBuilder: FormBuilder,
    private toastrService: NbToastrService,
    private tableService: TableService,
    private router: Router,
    // private service: NgPluralizeService,
    private socketService: SocketService,
    private mapService: MapService,
    private dialogService: NbDialogService,
    private nbMenuService: NbMenuService,
    private changeDetector: ChangeDetectorRef,
    private chatSubscriptionService: ChatSubscriptionService,
    private route: ActivatedRoute,
    private helperService: HelperService,
    private sidebarService: NbSidebarService,
    private dynamicFormService: DynamicFormCommonService,
    private messageService: MessageService,
    private filterPipe: FilterPipe,
    private datePipe: DatePipe,
    private titleS: Title,
    private eventService: EventEmiterService,
    public sanitizer: DomSanitizer,
    private linkifyService: NgxLinkifyjsService,
    private http: HttpClient,
    private formService: DynamicFormCommonService,
    private location: Location
  ) {
    this.currentUser = JSON.parse(localStorage.getItem("userData"));
    if (this.actionSubscription) this.actionSubscription = null;
    this.actionSubscription = this.nbMenuService
      .onItemClick()
      .subscribe((event) => {
        this.onActionsClick(event, this.Data, this.Data);
      });

    this.tableService.$systemConfig.subscribe((res: any) => {
      if (res) {
        this.progressColor = res.IconColors;
      }
    });

  }

  // getTableDataByID() {
  //   if (this.tableName) {
  //     this.tableService.getDynamicTreeDataById(this.tableName, this.id)
  //       .pipe(takeUntil(this.destroy$))
  //       .subscribe((res: any) => {
  //         if (res.data) {
  //           this.Data = res.data;
  //           console.log("this.Data %%%%%%%% ===> ", this.Data)
  //         }
  //       });
  //   }
  // }

  async getTimeZone() {
    this.timeZone = await this.tableService.getIpInfo();
    // console.log("🚀 ~ file: dynamic-form-dialog-new-design.component.ts:421 ~ getTImeZone ~ this.timeZone", this.timeZone)
    // this.getTableDataByID();
  }

  resetVars() {
    this.list = [];
    this.finalArrayForWithoutSection = [];
    this.finalArrayForViewMode = [];
    this.finalArrayForSection = [];
    this.formColumns = [];
    this.lookupValue = [];
    this.lookupDataObject = {};
    this.filteredOptions = [];
    this.tableData = [];
  }

  consoleOptions(val, val2) {
    console.log("options:", JSON.stringify(val));
    console.log("options2:", JSON.stringify(val2));
  }

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (this.editLoadAsDropdownLookupPopover) {
      if (this.useHostListener) {
        const ele = this.document.getElementsByClassName("popover-body")[0];
        if (ele && ele.contains(event.target)) {
        } else {
          this.editLoadAsDropdownLookupPopover.close();
        }
      }
    }

    if (this.editNotLoadAsDropdownLookupPopover) {
      if (this.useHostListener) {
        const ele = this.document.getElementsByClassName("popover-body")[0];
        if (ele && ele.contains(event.target)) {
        } else {
          this.editNotLoadAsDropdownLookupPopover.close();
        }
      }
    }
  }

  hideNameInput(i) {
    this.showNameInput[i] = false;
  }

  changeButtonTxt = (e: MouseEvent) => {
    console.log('event', e)
  }

  ngOnChanges(simple: SimpleChanges) {
    if (simple["Data"] && !simple["Data"].firstChange) {
      this.Data = simple["Data"].currentValue;

      if (simple["form"]) {
        this.formColumns = simple["form"].currentValue
          ? simple["form"].currentValue
          : [];
      }

      if (this.formColumns && this.formColumns.length) {
        this.formColumns.forEach((data, index) => {
          if (data.type == "checkList") {
            if (this.Data && this.Data[data.name]) {
              this.checkListArray = cloneDeep(this.Data[data.name]);
              this.checkListArray.forEach((ele, i) => {
                let key = Object.keys(ele);

                ele["name"] = key[0];
                ele["value"] = ele[key[0]];
                delete ele[key[0]];

                this.showList[i] = true;
                this.showNameInput[i] = false;
                for (var x = 0; x < this.checkListArray[i].value.length; x++) {
                  this.showItemInput[i] = new Array(
                    this.checkListArray[i].value.length
                  );
                  this.showDeleteIcon[i] = new Array(
                    this.checkListArray[i].value.length
                  );
                }

                // Loop to initialize 2D array elements.
                for (var y = 0; y < this.checkListArray[i].value.length; y++) {
                  for (
                    var j = 0;
                    j < this.checkListArray[i].value.length;
                    j++
                  ) {
                    this.showItemInput[i][j] = false;
                    this.showDeleteIcon[i][j] = false;
                  }
                }
              });
            }
          }
        });
      }
    }
  }

  activeAction = [];
  actionsWithSchedulars = [];
  activeFormLayout = null;
  async ngOnInit() {
    // this.loadTutorial();
    await this.getTimeZone();
    this.formColumns = this.form;

    if (this.formLayouts && this.formLayouts.length) {
      let temp = [];
      this.activeFormLayout = this.formLayouts.find((a) => a.isActive);
      if (this.activeFormLayout) {
        this.activeFormLayout.list.forEach((ele) => {
          let field = this.formColumns.find((v) => v._id == (ele.field._id ? ele.field._id : ele.field));
          if (field) {
            field.fieldSize = ele.size ? ele.size : field.fieldSize;
            temp.push(field);
            ele.field = field;
          }
        });
        this.formColumns = [...temp];
      }
    }

    this.currentTimeStamp = Math.floor(Date.now() / 1000);

    this.loading = true;

    if (this.id) {
      this.getTableData();
      this.getRelatedLookupData(this.tableName, this.id);
      this.editform = true;

    }

    if (this.tableName.endsWith("s" || "S")) {
      const service = new NgPluralizeService();
      this.singularTableName = service.singularize(this.tableName);
      //  this.tableNameWithoutS = this.tableName.slice(0, -1);
    } else {
      this.singularTableName = this.tableName;
    }

    this.list = [];
    this.finalArrayForWithoutSection = [];
    this.finalArrayForViewMode = [];
    this.finalArrayForSection = [];

    if (this.actions && this.actions.length) {
      this.activeAction = this.actions.filter((v) => v.isActive);
    }

    // if (this.activeAction && this.activeAction.length) {
    //   this.actionsWithSchedulars = this.activeAction.filter(v => v.setScheduler == 'yes');
    // }

    // if (this.actionsWithSchedulars && this.actionsWithSchedulars.length) {

    //   for (let ele of this.actionsWithSchedulars) {

    //     setInterval(() => {
    //       this.markComplete(ele);
    //     }, ele.scheduleTime == 'monthly' ? 43800 * 60 * 1000 : ele.scheduleTime == 'weekly' ? 10080 * 60 * 1000 :
    //       ele.scheduleTime == 'daily' ? 1440 * 60 * 1000 : ele.scheduleTime == 'hourly' ? 60 * 60 * 1000 : 60 * 1000)
    //   }
    // }

    if (this.viewFlag) {
      if (this.activeAction && this.activeAction.length) {
        this.activeAction.forEach((ele) => {
          ele["hover"] = false;
        });
      }
      this.viewFlag = true;
      this.editViewFlag = false;
      this.addEditFlag = false;
      let currentDate = new Date();
      let finalcurrentDate = currentDate.getTime();
      let createdAt = new Date(this.Data["createdAt"]);
      let finalcreatedAt = createdAt.getTime();
      const difference = Math.abs(finalcurrentDate - finalcreatedAt);
      this.day = Math.floor((difference / (1000 * 60 * 60 * 24)) % 365);
      this.hour = Math.round((difference / (1000 * 60 * 60)) % 24);
      this.minute = Math.round((difference / (1000 * 60)) % 60);
      this.second = Math.round((difference / 1000) % 60);

      let image = this.formColumns.filter((v) => v.type == "dropdownWithImage");
      if (image && image.length) {
        image.forEach((element) => {
          if (element.options && element.options.length) {
            element.options.forEach((ele) => {
              if (this.Data[element.name] == ele.title) {
                this.imageForDropdownWithImageView = ele.image;
              }
            });
          }
        });
      }
    } else {
      this.addEditFlag = true;
      this.viewFlag = false;
      this.editViewFlag = false;
    }
    const element = document.getElementById("main_body");
    element.classList.add("add-edit-client-form");

    this.setDependenciesList();



    if (this.formColumns && this.formColumns.length) {

      this.formColumns.forEach((data, index) => {
        data["linkify"] = false;
        if (this.inputFields.includes(data.type)) {
          if (this.Data && this.Data[data.name]) {
            data["linkify"] = this.matchPattern(this.Data[data.name]);
          }
        }

        data["showProvideButtonField"] = false;
        if (data.isProvideButton) {
          if (this.Data && this.Data[data.name]) {
            data["showProvideButtonField"] = true;
          }
        }

        if (data.type == "refRecords") {
          this.userFields[data.name] = [];
        }
        // if (data.type === "date") {
        //   if (this.editform) {
        //     data["dueDate"] = moment(this.Data.dueDate).tz(this.timeZone?.data?.timezone)?.format();
        //     data["startDate"] = moment(this.Data.startDate).tz(this.timeZone?.data?.timezone)?.format();
        //   }
        // }

        let defaultEmpty: any = '';

        if (data.type == 'formula') {
          if (data.options[0].type == 'number') {
            defaultEmpty = 0;
          }
          this.formulaDataObject[data.name] = (this.Data && this.Data[data.name]) ? this.Data[data.name] : defaultEmpty;
        }

        if (data.type == "checkList") {
          if (this.Data && this.Data[data.name]) {
            this.checkListArray = cloneDeep(this.Data[data.name]);
            this.checkListArray.forEach((ele, i) => {
              let key = Object.keys(ele);

              ele["name"] = key[0];
              ele["value"] = ele[key[0]];
              delete ele[key[0]];

              this.showList[i] = true;
              this.showNameInput[i] = false;
              for (var x = 0; x < this.checkListArray[i].value.length; x++) {
                this.showItemInput[i] = new Array(
                  this.checkListArray[i].value.length
                );
                this.showDeleteIcon[i] = new Array(
                  this.checkListArray[i].value.length
                );
              }

              // Loop to initialize 2D array elements.
              for (var y = 0; y < this.checkListArray[i].value.length; y++) {
                for (var j = 0; j < this.checkListArray[i].value.length; j++) {
                  this.showItemInput[i][j] = false;
                  this.showDeleteIcon[i][j] = false;
                }
              }
            });
          }
        }

        if (this.Data && data.type === "file") {
          this.uploadedFiles = {};
          this.uploadedFiles[data.name] = [];
          this.uploadedFiles[data.name] = this.Data[data.name];
          this.Data["filesList"] = Object.assign({}, this.uploadedFiles);
        }

        if (data.type === "checkbox") {
          data["this.checkBoxValidateForDynamicForm"] = false;
          const check = [];
          let checkedData = [];
          if (this.Data) {
            checkedData = this.Data[data.name]?.split(",");
          } else if (data.defaultOptionValue) {
            checkedData = [data.defaultOptionValue];
          }
          if (data.options) {
            data.options.forEach((option) =>
              check.push(
                new FormControl(
                  checkedData && checkedData.length > 0
                    ? checkedData?.includes(option)
                    : false
                )
              )
            );
          }
          this.userFields[data.name] = new FormArray(check);
        } else if (data.type === "lookup") {
          this.dropDownText[data.name] = "Type to search";
          this.removeLookup[data.name] = true;
          this.lookTable = data.lookupTableName;
          this.lookupName = data.name;
          this.lookupValue[data.name] = [];
          this.editData[data.name] = [];
          this.filteredOptions[data.name] = [];
          this.lookupFieldRequired[data.name] = false;

          const lookUpArray = [];
          data.options.forEach((el) => {
            const temp: [] = Object.assign([], el);
            temp.shift();
            if (data.loadAsDropDown) {
              if (el.length > 1) {
                this.filteredOptions[data.name].push({
                  id: el[0],
                  value: temp.toString().replace(/,/g, " "),
                });
              }
            }

            if (data.name == this.lookUpName) {
              lookUpArray.push({
                id: el[0],
                value: temp.toString().replace(/,/g, " "),
              });
            }
          });

          if (this.editform && !data.loadAsDropDown) {
            this.filteredOptions[data.name] = [];

            if (this.Data && this.Data.lookup && this.Data.lookup.length) {
              const lookups = this.Data.lookup.filter(
                (f) => f.lookupTableName === data.lookupTableName
              );
              if (lookups.length) {
                for (let lookUP of lookups) {
                  let value = "";
                  if (
                    lookUP &&
                    lookUP[data.name] &&
                    lookUP[data.name].length > 2
                  ) {
                    for (let i = 1; i < lookUP[data.name].length; i++) {
                      value += lookUP[data.name][i] + " ";
                    }
                    value = value.trim();
                  } else if (
                    lookUP &&
                    lookUP[data.name] &&
                    lookUP[data.name].length == 2
                  ) {
                    value = lookUP[data.name][1];
                  }

                  if (lookUP[data.name] && lookUP[data.name].length) {
                    this.filteredOptions[data.name].push({
                      id: lookUP.lookupId,
                      value: value,
                    });
                    this.lookupValue[data.name].push({
                      id: lookUP.lookupId,
                      value: value,
                    });
                  } else if (value) {
                    this.filteredOptions[data.name].push({
                      id: lookUP.lookupId,
                      value: value,
                    });
                    this.lookupValue[data.name].push({
                      id: lookUP.lookupId,
                      value: value,
                    });

                  }

                  this.editData[data.name] = this.lookupValue[data.name];
                  this.editData[data.name].forEach((element) => {
                    if (element.name) element.name = element.value;
                  });
                }
              }
            }
          }

          //set lookupvalue, options and filteredOptions according to parent table, if there
          if (
            !this.editform &&
            this.parentTableName &&
            this.parentTableName == data.lookupTableName &&
            this.parentTableData
          ) {
            let tempOptionForOtherFields = [];
            let tempFilteredOptionForOtherFields = {};
            let tempOptionForIdFields = [];
            let tempFilteredOptionForIdFields = {};
            tempOptionForOtherFields.push(this.lookUpNameId);
            tempOptionForIdFields.push(this.lookUpNameId);
            tempFilteredOptionForOtherFields["id"] = this.lookUpNameId;
            tempFilteredOptionForOtherFields["value"] = "";
            data.lookupTableFieldNames.forEach((element, i) => {
              if (this.parentTableData[element]) {
                tempOptionForOtherFields.push(
                  "   " + this.parentTableData[element]
                );
                tempFilteredOptionForOtherFields["value"] +=
                  this.parentTableData[element] + " ";
              }
            });
            tempFilteredOptionForOtherFields["value"] =
              tempFilteredOptionForOtherFields["value"].trim();
            this.filteredOptions[data.name].push({
              ...tempFilteredOptionForOtherFields,
            });
            if (data.options) {
              data.options.push(tempOptionForOtherFields);
            } else {
              data.options = [];
              data.options.push(tempOptionForOtherFields);
            }
            let copyTemp = [];
            let copyOpt = [];
            copyOpt = cloneDeep(tempOptionForOtherFields);
            copyOpt.shift();
            if (copyOpt && copyOpt.length) {
              copyTemp.push(copyOpt);
            }

            let refLookupIdFields = [];
            let refLookTable = data.lookupTableName;
            let response = this.tableDataForForms.filter(
              (ele) => ele.tableName == refLookTable
            );
            if (response && response.length) {
              refLookupIdFields = response[0].columns.map((v) =>
                v.idField ? v.name : ""
              );
            }

            tempFilteredOptionForIdFields["id"] = this.lookUpNameId;
            tempFilteredOptionForIdFields["value"] = "";
            refLookupIdFields.forEach((element, i) => {
              if (this.parentTableData[element]) {
                tempOptionForIdFields.push(
                  "   " + this.parentTableData[element]
                );
                tempFilteredOptionForIdFields["value"] +=
                  this.parentTableData[element] + " ";
              }
            });
            tempFilteredOptionForIdFields["value"] =
              tempFilteredOptionForIdFields["value"].trim();
            this.filteredOptions[data.name].push({
              ...tempFilteredOptionForIdFields,
            });
            if (data.options) {
              data.options.push(tempOptionForIdFields);
            } else {
              data.options = [];
              data.options.push(tempOptionForIdFields);
            }
            let copyTemp1 = [];
            let copyOpt1 = [];
            copyOpt1 = cloneDeep(tempOptionForIdFields);
            copyOpt1.shift();
            if (copyOpt1 && copyOpt1.length) {
              copyTemp1.push(copyOpt1);
            }

            if (data.isReference) {
              this.list.push({
                name: data.name,
                lookupTableId: data.lookupTableId,
                value: [{ idField: copyTemp1, others: copyTemp, id: this.lookUpNameId }],
                lookupTableName: data.lookupTableName,
              });
            }

          }

          this.lookUpOptions = lookUpArray;
          data.options?.map((res) => {
            res.splice(3, 1);
          });
          this.demoData = data;
          this.lookupData.push(this.demoData);

          if (
            this.Data &&
            data.loadAsDropDown &&
            this.Data.lookup &&
            this.Data.lookup.length
          ) {
            this.Data.lookup.forEach((el1) => {
              if (!data.isReference) {
                data.options.forEach((element) => {
                  if (
                    this.Data.lookup &&
                    data.name === el1.lookupName &&
                    element[0] === el1.lookupId
                  ) {
                    const displayValue = data.options.filter(
                      (f) => f[0] === el1.lookupId
                    );
                    if (displayValue.length > 0) {
                      const temp: [] = Object.assign([], displayValue[0]);
                      temp.shift();
                      this.lookupValue[data.name].push({
                        id: el1.lookupId,
                        value: temp.toString().replace(/,/g, " "),
                      });
                    } else {
                      if (element[0] === el1.lookupId) {
                        this.lookupValue[data.name].push({
                          id: el1.lookupId,
                          value: element[1],
                        });
                      }
                    }

                    this.editData[data.name] = this.lookupValue[data.name];
                    this.editData[data.name].forEach((element) => {
                      if (element.name) element.name = element.value;
                    });
                  }
                });
              }
            });
          }
          // else if (this.editform) {
          //   if (!data.isReference) {
          //     if (this.filteredOptions[data.name].length && this.fromRecordPage) {
          //       this.lookupValue[data.name] = this.filteredOptions[data.name];
          //       this.editData[data.name] = this.lookupValue[data.name];
          //       this.editData[data.name].forEach((element) => {
          //         if (element.name) element.name = element.value;
          //       });
          //     }
          //   }
          // }
        } else if (data.type === "status") {
          this.setUserFields(data);

          if (this.Data) {
            if (data.statusOptions && Array.isArray(data.statusOptions)) {
              const i = data.statusOptions.find(
                (j) => j.status == this.Data[data.name]
              );
              if (i) {
                this.colorSetter[data.name] = i.color;
                this.colorForFont = i.labelColor ? i.labelColor : "";
              }
            }
            this.statuses[data.name] = this.Data[data.name];
            this.Data["statusMain"] = Object.assign({}, this.statuses);
            this.Data["colorSetter"] = Object.assign({}, this.colorSetter);
          }
          if (!this.Data && data.defaultOptionValue) {
            if (data.statusOptions && Array.isArray(data.statusOptions)) {
              const i = data.statusOptions.find(
                (j) => j.status == data.defaultOptionValue
              );
              if (i) {
                this.colorSetter[data.name] = i.color;
                this.colorForFont = i.labelColor ? i.labelColor : "";
              }
            }
            this.statuses[data.name] = data.defaultOptionValue;
          }
          if (!this.Data && this.statusColorFromTree) {
            if (data.statusOptions && Array.isArray(data.statusOptions)) {
              const i = data.statusOptions.find(
                (j) => j.status == this.statusColorFromTree.status
              );
              if (i) {
                this.colorSetter[data.name] = i.color;
                this.colorForFont = i.labelColor ? i.labelColor : "";
              }
            }
            this.statuses[data.name] = this.statusColorFromTree.status;
          }
        } else if (
          ((!this.editform || !this.Data[data.name]) &&
            data.type === "dateTime") ||
          data.type == "date"
        ) {
          // console.log("is nor editable")
          let days: any = 0;
          let isRecordTypeMatchFlag = false;
          let setInitialDateFlag = false;
          if (data.dateTimeOptions && data.dateTimeOptions.length) {
            setInitialDateFlag = true;
            const recordelement = this.recordType
              ? this.recordType
              : this.tableName;
            data.dateTimeOptions.forEach((element) => {
              if (
                element.recordType.toLowerCase() == recordelement.toLowerCase()
              ) {
                days = element.numberOfDays;
                isRecordTypeMatchFlag = true;
              }
            });
            if (!isRecordTypeMatchFlag) {
              const index = data.dateTimeOptions.findIndex((v) =>
                v.recordType
                  ? v.recordType.toLowerCase()
                  : v.recordType == "all"
              );
              if (index > -1) {
                days = data.dateTimeOptions[index].numberOfDays;
              }
            }
          }
          const date = new Date();
          days = parseInt(days);

          date.setDate(date.getDate() + days);

          if (this.Data && this.Data[data.name]) {
            let d = new Date(this.Data[data.name]);
            d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
            this.Data[data.name] = d;
          }
          this.userFields[data.name] = [
            {
              value: this.Data
                ? this.Data[data.name]
                : setInitialDateFlag
                  ? date
                  : "",
              disabled: data.isReadOnly ? true : false,
            },
            data.isRequired ? [Validators.required] : [],
          ];
          if (
            this.parentLookupName === "dependentTask" &&
            data.name === "isSubtask"
          ) {
            this.userFields[data.name] = "Yes";
          }
          if (this.dueDate) {
            this.userFields[data.name] = this.dueDate;
          }
        } else if (data.type === "dropdown") {
          if (
            this.Data &&
            this.Data[data.name] &&
            data.allowMultipleValues &&
            !Array.isArray(this.Data[data.name])
          ) {
            this.Data[data.name] = this.Data[data.name].split();
          }
          this.userFields[data.name] = [
            {
              value: this.Data
                ? this.Data[data.name]
                : data.defaultOptionValue
                  ? data.defaultOptionValue
                  : "",
              disabled: data.isReadOnly ? true : false,
            },
            data.isRequired ? [Validators.required] : [],
          ];
        } else if (data.type === "checkList") {
          this.userFields[data.name] = [
            this.Data ? this.Data[data.name] : "",
            [],
          ];
        } else if (data.type === "number") {
          this.setUserFields(data);
        } else if (data.type === "area") {
          this.userFields[data.name] = [
            {
              value: this.Data
                ? this.Data[data.name]
                : data.defaultOptionValue
                  ? data.defaultOptionValue
                  : "",
              disabled: data.isReadOnly ? true : false,
            },
            [],
          ];
        } else if (data.type == "rollUp") {
          this.userFields[data.name] = [
            this.Data ? this.Data[data.name] : 0,
            data.isRequired ? [Validators.required] : [],
          ];
        } else if (data.type === "gadget") {
          this.userFields[data.name] = [
            this.Data ? this.Data[data.name] : data.gadget ? data.gadget : "",
            data.isRequired ? [Validators.required] : [],
          ];
          this.currentGadgetValue = data.gadget ? data.gadget : "";
        } else if (data.type === "dropdownWithImage") {
          if (
            this.Data &&
            this.Data[data.name] &&
            data.allowMultipleValues &&
            !Array.isArray(this.Data[data.name])
          ) {
            this.Data[data.name] = this.Data[data.name].split();
          }
          this.userFields[data.name] = [
            {
              value: this.Data
                ? this.Data[data.name]
                : data.defaultOptionValue
                  ? data.defaultOptionValue
                  : null,
              disabled: data.isReadOnly ? true : false,
            },
            data.isRequired ? [Validators.required] : [],
          ];
        } else if (data.type === "recordType") {
          if (
            this.Data &&
            this.Data[data.name] &&
            data.allowMultipleValues &&
            !Array.isArray(this.Data[data.name])
          ) {
            this.Data[data.name] = this.Data[data.name].split();
          }
          this.setUserFields(data);
        } else if (data.type === "radio") {
          this.setUserFields(data);
        } else if (data.type == "autoNumber") {
          this.userFields[data.name] = [
            {
              value: this.Data ? this.Data[data.name] : "",
              disabled: data.isReadOnly ? true : false,
            },
            data.isRequired ? [Validators.required] : [],
          ];
        } else {
          this.userFields[data.name] = [
            {
              value: this.Data ? this.Data[data.name] : "",
              disabled: data.isReadOnly ? true : false,
            },
            data.isRequired ? [Validators.required] : [],
          ];
          if (
            this.parentLookupName === "dependentTask" &&
            data.name === "isSubtask"
          ) {
            this.userFields[data.name] = "Yes";
          }
        }

        this.show[data.name] = true;
        if (
          data.isVisibilityOn &&
          data.fieldValuesData &&
          data.fieldValuesData.length > 0
        ) {
          this.visibilityData = this.formColumns
            .filter((col) => col._id === data.visibilityData)
            .map((col) => col.name);
          this.visibilityData = this.visibilityData[0];
          this.dependsFields.push(data);
          let arr = [];
          arr = this.Data
            ? this.Data[this.visibilityData]
              ? this.Data[this.visibilityData].split(",")
              : []
            : [];
          if (arr && arr.length) {
            for (const item of arr) {
              this.show[data.name] = this.Data
                ? !!data.fieldValuesData.includes(item)
                : this.recordType
                  ? !!data.fieldValuesData.includes(this.recordType)
                  : false;
              if (this.show[data.name]) {
                break;
              }
            }
          } else {
            this.show[data.name] = this.Data
              ? !!data.fieldValuesData.includes(this.Data[this.visibilityData])
              : this.recordType
                ? !!data.fieldValuesData.includes(this.recordType)
                : false;
          }
          if (
            data.name === "dependentTask" &&
            this.parentLookupName === "dependentTask"
          ) {
            this.show[data.name] = true;
          }
        }

        if (data.type == "lookup") {
          if (data.isReference == true) {
            this.show[data.name] = false;
          }
        }

        // if (data.isHidden) {
        //   this.show[data.name] = false;
        // }

        this.setAddEditFields(data);
      });

      this.dynamicForm = this.formBuilder.group(this.userFields);

      await this.getFieldRestrictions();

      if (!this.Data) {
        this.visibilityFieldsFn();
      }

      if (this.values && this.mapField) {
        let mapFieldKeys = Object.keys(this.mapField);
        mapFieldKeys.forEach((ele) => {
          let typeCheck = this.formColumns.filter((item) => item.name == ele);
          if (
            typeCheck &&
            typeCheck.length > 0 &&
            typeCheck[0].type == "lookup"
          ) {
            if (this.lookupValue[ele]) {
              this.lookupValue[ele] = this.values[this.mapField[ele]];
            }
          } else {
            if (Object.keys(this.userFields).findIndex((v) => v == ele) > -1) {
              this.dynamicForm.patchValue({
                [ele]: this.values[this.mapField[ele]],
              });
            }
          }
        });
      }

      this.subFormFieldCount = 0;

      let subFormArrays = this.formColumns.filter(
        (v) => v.type == "injectSubForm"
      );
      if (subFormArrays && subFormArrays.length) {
        for (let i in subFormArrays) {
          let element = subFormArrays[i];
          if (element.options && element.options.length) {
            let subFormObjectForOption = element.options[0];

            if (
              this.getTableByNameObjectForData[subFormObjectForOption.tableName]
            ) {
              let resData =
                this.getTableByNameObjectForData[
                subFormObjectForOption.tableName
                ];
              this.subFormFormationCode(subFormObjectForOption, resData);
            } else {
              let res: any = await this.tableService.getTableByName(
                subFormObjectForOption.tableName
              );
              if (res && res.data) {
                let resData = res.data;
                this.subFormFormationCode(subFormObjectForOption, resData);
              }
            }

            this.showSubForm[
              subFormObjectForOption.lookupName +
              "|" +
              subFormObjectForOption.tableName
            ] = false;
          } else {
            this.toastrService.warning(
              "Missing Sub Form Table Name or Lookup Name",
              "Inject Sub Form Config"
            );
          }
        }
      }

      if (this.Data && this.Data["subForm"]) {
        this.Data["subForm"].forEach((element) => {
          this.editDataSubForm.push(element);
          let keys = Object.keys(element);
          if (keys && keys.length) {
            keys.forEach((ele) => {

              let subF = subFormArrays.find(v => (v.options[0].lookupName + "|" + v.options[0].tableName) == ele);
              if (subF) {
                this.showSubForm[ele] = true;
                if (this.viewFlag || subF.isPopOut) {
                  this.viewFlagForSubForm[ele] = true;
                }
              }

            });
          }
        });
        this.tempEditFormData = cloneDeep(this.editDataSubForm);
        this.Data["refSubForm"] = cloneDeep(this.tempEditFormData);
      } else {
        this.editDataSubForm = [];
        this.tempEditFormData = cloneDeep(this.editDataSubForm);
      }

      this.changeDetector.detectChanges();
    }

    if (this.Data) {
      this.lookupDetailDataLoad();
    }

    if (this.Data && this.Data["lookup"] && this.Data["lookup"].length) {
      let lookupfields = this.form.filter((f) => f.type == "lookup");
      if (lookupfields && lookupfields.length) {
        lookupfields.forEach((element, i) => {
          if (element.isReference) {
            this.lookupArray.push(element);
          }
        });
      }

      if (this.lookupArray && this.lookupArray.length) {
        this.lookupArray.forEach((ele) => {
          this.lookupTableName.push(ele.lookupTableName);
          this.otherDataKeys.push({
            name: ele.name,
            value: ele.lookupTableFieldNames,
          });
        });
      }
      this.Data["lookup"].forEach((element) => {
        let field = this.formColumns.filter(
          (v) => v.name == element.lookupName
        );
        if (field && field.length && field[0].isReference) {
          if (element.lookupId) {
            this.tableService
              .getDynamicTreeDataById(element.lookupTableName, element.lookupId)
              .pipe(takeUntil(this.destroy$))
              .subscribe((res: any) => {
                if (res.data) {
                  this.getReferList(res, element, element);
                }
              });
          }
        }
      });

      this.Data["refList"] = [...this.list];
      this.changeDetector.detectChanges();
    }

    let lookupfields = this.form.filter((f) => f.type == "lookup");
    if (lookupfields && lookupfields.length) {
      lookupfields.forEach((element, i) => {
        let temp = [];
        if (element.mappedFields) {
          let mapKeys = Object.keys(element.mappedFields);
          if (mapKeys && mapKeys.length) {
            mapKeys.forEach((ele) => {
              let obj = {
                fieldName: "",
                mapFieldName: "",
                type: "",
                value: null,
              };
              obj.fieldName = element.mappedFields[ele];
              obj.mapFieldName = ele;
              let mapField = this.form.find((f) => f.name == obj.fieldName);
              if (mapField) {
                obj.type = mapField.type;
              }
              obj.value = this.Data
                ? this.Data[obj.fieldName]
                  ? this.Data[obj.fieldName]
                  : ""
                : "";
              temp.push(obj);
            });
          }
        }
        this.mapData[element.name] = temp;

        if (element.mappedFields && element.mappedFields !== null && typeof element.mappedFields != 'undefined' && this.parentTableData) {
          let mappedKeys = Object.keys(element.mappedFields);
          if (mappedKeys && mappedKeys.length) {

            mappedKeys.forEach(ele => {

              let valueToMap = element.mappedFields[ele];
              let valueField = this.formColumns.find(v => v.name == valueToMap);
              if (valueField) {

                if (
                  valueField.type == "lookup"
                ) {
                  if (this.parentTableData && this.parentTableData["lookup"] && this.parentTableData["lookup"].length) {
                    let lookupList = this.parentTableData["lookup"].filter(
                      (element) => (element.lookupDataName ? element.lookupDataName : element.lookupName) == valueToMap
                    );
                    if (lookupList && lookupList.length) {
                      this.userFields[valueToMap] = lookupList[0].lookupId;
                      let obj = {
                        id: lookupList[0].lookupId,
                        value: lookupList[0].valueToDisplay.join(' '),
                      };
                      if (
                        this.filteredOptions[valueToMap] &&
                        this.filteredOptions[valueToMap]
                          .length
                      ) {
                        this.filteredOptions[
                          valueToMap
                        ].push(obj);
                      } else {
                        this.filteredOptions[
                          valueToMap
                        ] = [];
                      }
                      this.lookupValue[valueToMap] = [];
                      this.editData[valueToMap] = [];
                      this.lookupValue[valueToMap] = [
                        ...this.lookupValue[valueToMap],
                        {
                          ...obj,
                        },
                      ];
                      this.editData[valueToMap] = [
                        ...this.editData[valueToMap],
                        {
                          ...obj,
                        }

                      ];
                      // this.editData[valueToMap][
                      //   index
                      // ].name = obj.value;
                      this.dynamicForm.patchValue({
                        [valueToMap]:
                          lookupList[0].lookupId,
                      });
                    }
                  }
                } else {
                  if (this.parentTableData[ele] && this.parentTableData[ele] != "") {
                    this.userFields[valueToMap] =
                      this.parentTableData[ele];
                    this.dynamicForm.patchValue({
                      [valueToMap]: this.parentTableData[ele],
                    });
                  }
                }
              }
            })
          }
        }

      });
    }

    const indices = this.formColumns
      .map((e, k) => (e.type === "section" ? k : ""))
      .filter(String);
    let i = 0;
    this.formColumns.forEach((data) => {
      if (data.type == "section") {
        this.sections[i] = this.formColumns.slice(indices[i], indices[++i]);
      }
    });
    this.withoutSectionData = this.formColumns.slice(0, indices[0]);

    let limit = 12;
    let temp = [];
    let count = 0;
    if (this.withoutSectionData && this.withoutSectionData.length) {
      this.withoutSectionData.forEach((element, i) => {
        if (
          !element.isHidden &&
          element.type != "section" &&
          element.type !== "autoNumber" &&
          (element.type !== "recordType" ||
            (element.type == "recordType" && this.recordType == ""))
        ) {
          if (limit >= this.sizeObj[element.fieldSize]) {
            element["sequence"] = i;
            temp.push(element);
            limit = limit - this.sizeObj[element.fieldSize];
            if (i == this.withoutSectionData.length - 1) {
              this.hideAndShowField(
                temp,
                element,
                this.finalArrayForWithoutSection,
                count
              );
            }
          } else {
            this.hideAndShowField(
              temp,
              element,
              this.finalArrayForWithoutSection,
              count
            );
            temp = [];
            limit = 12;
            element["sequence"] = i;
            temp.push(element);
            limit = limit - this.sizeObj[element.fieldSize];
            if (i == this.withoutSectionData.length - 1) {
              this.hideAndShowField(
                temp,
                element,
                this.finalArrayForWithoutSection,
                count
              );
            }
          }
        } else {
          if (i == this.withoutSectionData.length - 1) {
            this.hideAndShowField(
              temp,
              element,
              this.finalArrayForWithoutSection,
              count
            );
          }
        }
      });

      this.loadTinyMce = true;
    }

    if (this.sections && this.sections.length) {
      this.showFooter = false;
      this.sections.forEach((ele) => {
        limit = 12;
        temp = [];
        count = 0;
        this.finalArrayForSection = [];
        if (ele && ele.length) {
          ele.forEach((element, i) => {
            if (
              !element.isHidden &&
              element.type != "section" &&
              element.type !== "autoNumber" &&
              (element.type !== "recordType" ||
                (element.type == "recordType" && this.recordType == ""))
            ) {
              if (limit >= this.sizeObj[element.fieldSize]) {
                element["sequence"] = i;
                temp.push(element);
                limit = limit - this.sizeObj[element.fieldSize];
                if (i == ele.length - 1) {
                  this.hideAndShowField(
                    temp,
                    element,
                    this.finalArrayForSection,
                    count
                  );
                }
              } else {
                this.hideAndShowField(
                  temp,
                  element,
                  this.finalArrayForSection,
                  count
                );
                temp = [];
                limit = 12;
                element["sequence"] = i;
                temp.push(element);
                limit = limit - this.sizeObj[element.fieldSize];
                if (i == ele.length - 1) {
                  this.hideAndShowField(
                    temp,
                    element,
                    this.finalArrayForSection,
                    count
                  );
                }
              }
            } else {
              if (i == ele.length - 1) {
                this.hideAndShowField(
                  temp,
                  element,
                  this.finalArrayForSection,
                  count
                );
              }
            }
          });
        }
        ele["array"] = this.finalArrayForSection;
      });
      this.sections = this.sections.filter((ele) => ele.array.length > 0);
    }
    if (this.viewFlag) {
      let limit = 12;
      let temp = [];
      let count = 0;
      if (this.formColumns && this.formColumns.length) {
        this.formColumns.forEach((element, i) => {
          if (
            !element.isHidden &&
            element.type != "section" &&
            element.type !== "autoNumber" &&
            (element.isProvideButton
              ? element.isProvideButton && this.Data[element.name]
              : true)
          ) {
            if (
              limit >= (this.inlineView ? 6 : this.sizeObj[element.fieldSize])
            ) {
              element["sequence"] = i;
              temp.push(element);
              limit =
                limit - (this.inlineView ? 6 : this.sizeObj[element.fieldSize]);
              if (i == this.formColumns.length - 1) {
                this.finalArrayForViewMode.push({
                  data: temp,
                });
                this.statusColor(this.finalArrayForViewMode, count);
                count++;
              }
            } else {
              this.finalArrayForViewMode.push({
                data: temp,
              });
              this.statusColor(this.finalArrayForViewMode, count);
              count++;
              temp = [];
              limit = 12;
              element["sequence"] = i;
              temp.push(element);
              limit =
                limit - (this.inlineView ? 6 : this.sizeObj[element.fieldSize]);
              if (i == this.formColumns.length - 1) {
                this.finalArrayForViewMode.push({
                  data: temp,
                });
                this.statusColor(this.finalArrayForViewMode, count);
                count++;
              }
            }
          } else {
            if (i == this.formColumns.length - 1) {
              this.finalArrayForViewMode.push({
                data: temp,
              });
              this.statusColor(this.finalArrayForViewMode, count);
              count++;
            }
          }
        });
      }
    }

    if (this.isDraggedFromParent) {
      this.onDroppedFile(this.droppedFiles["file"], "file");
    }
    if (this.lookUpNameId && this.parentTableName) {
      let lookupDetail;
      if (this.parentLookupName) {
        lookupDetail = this.formColumns.find(
          (x) =>
            x.lookupTableName == this.parentTableName &&
            x.name === this.parentLookupName
        );
      } else {
        lookupDetail = this.formColumns.find(
          (x) => x.lookupTableName == this.parentTableName
        );
      }

      if (lookupDetail) {
        let lookupDetailToDisplay = [];
        lookupDetail.options.forEach((e) => {
          e.forEach((e1) => {
            if (e1 == this.lookUpNameId) {
              lookupDetailToDisplay = e;
            }
          });
        });
        const lookup = [];
        lookup.push({
          id: lookupDetailToDisplay[0],
          value: lookupDetailToDisplay[1],
          name: lookupDetailToDisplay[2],
        });
        this.editData[lookupDetail.name].push(lookup[0]);
        this.lookupValue[lookupDetail.name].push(lookup[0]);
        this.editData[lookupDetail.name][
          this.editData[lookupDetail.name].length - 1
        ].name = this.parentTableName;
        this.onSelectionChange(lookup, lookupDetail);
      }

    }

    this.dynamicForm.valueChanges.subscribe((x) => {
      this.count++;
      if (this.count > 999) this.count = 0;
      this.dependenciesCheck();
      if (this.dynamicForm.valid && !this.validFlag) {
        this.formSubmitted = false;
        this.validFlag = false;
      }
    });
    this.dependenciesCheck();
    if (!this.Data) {
      this.evalExpressionForFormulaField();
    }

    try {
      this.getRecordGadgets();
    } catch (e) {
      console.log("Error proccessing gadget ", e);
    }

    if (this.tableDataForForms && this.tableDataForForms.length) {
      this.tableDataForForms.forEach((element) => {
        if (element.tableName == "Tasks") {
          let col = element.columns;
          if (col && col.length) {
            col.forEach((item) => {
              if (item.type == "lookup" && item.lookupTableId == this.tableId) {
                this.showAddTask = true;
                this.taskFormHeight = element.formHeight
                  ? element.formHeight
                  : null;
                this.taskFormWidth = element.formWidth
                  ? element.formWidth
                  : null;
                this.taskFormFieldAllignment = element.fieldAlignment
                  ? element.fieldAlignment
                  : null;
                this.taskFormActions = element.actions ? element.actions : [];
                this.taskFormCustomValidations = element.customValidations
                  ? element.customValidations
                  : [];
                this.taskFormRecordGadgets = element.recordGadgets
                  ? element.recordGadgets
                  : [];
                this.taskFormForm = element.columns;
                this.taskTableIcon = element.iconLocation
                  ? element.iconLocation
                  : "";
                this.taskTableId = element._id;
                if (this.tableDataForForms && this.tableDataForForms.length) {
                  this.tableDataForForms.forEach((ele) => {
                    if (ele && ele.columns && ele.columns.length) {
                      this.getRecordType(
                        Object.assign([], ele.columns),
                        ele.tableName
                      );
                    } else {
                      this.tableRecordTypes[ele.tableName] = [];
                    }
                  });
                }

                this.tempParentTableHeader = Object.assign([], col);
                this.tempParentTableHeader.map((column) => {
                  if (column.type == "recordType") {
                    this.recordTypeFieldName = column.name;
                    column.options.forEach((element) => {
                      const obj = {
                        title: element,
                      };
                      this.recordTypes.push(obj);
                    });
                  }
                });
              }
            });
          }
        }
      });
    }

    if (this.mapDataArray && this.mapDataArray.length) {
      this.mapDataArray.forEach((ele) => {
        if (ele.type !== "lookup") {
          this.dynamicForm.patchValue({
            [ele.mapFieldName]: ele.value,
          });
        } else {
          if (ele.value && ele.value.length) {
            ele.value.forEach((val) => {
              this.lookupValue[ele.mapFieldName] = [
                ...this.lookupValue[ele.mapFieldName],
                {
                  id: val.id,
                  value: val.value,
                },
              ];
            });
          }
        }
      });
      this.changeDetector.detectChanges();
    }

    this.tableService.gradientColor.subscribe((color: any) => {
      this.gradientColor = color;
    });

    this.tableService.iconColors.subscribe((color: any) => {
      this.systemConfigColor = color;
    });

    if (this.sections.length > 0) {
      element.classList.add("stepper-layout");
    }

    setTimeout(() => {
      this.tableService.runCustomStyling();
    }, 10);

    // this.loading = false;
    const res: any = this.tableService.userDetailWithPromise();
    if (res.statusCode == 200) {
      if (res.message.me.userPreferences && res.message.me.userPreferences.length) {
        this.userPreferences = res.message.me.userPreferences;
        let filterObjectFromUser = res.message.me.userPreferences.find(ele => ele.tableName == this.tableName);
        if (filterObjectFromUser) {
          this.filterObject = filterObjectFromUser;
          this.hasUserPreference = true;
        }
      }

      if (res.message.me.userTablePreferences?.sortings && res.message.me.userTablePreferences?.sortings?.length) {
        this.userTablePreferencesForSorting = res.message.me.userTablePreferences.sortings;
        let sortObjectFromUser = res.message.me.userTablePreferences.sortings.find(ele => ele.tableName == this.tableName);
        if (sortObjectFromUser) {
          if (sortObjectFromUser.kanbanValues) {

            sortObjectFromUser.kanbanValues.forEach(element => {
              this.kanbanSort[element['status']] = {
                column: element['column'],
                direction: element['direction']
              };
            });
          } else {
            this.sortObject = sortObjectFromUser;
          }

        }
      }
    }

    this.evalExpressionForFormulaField('init');

    if(this.parentTableName === "Clients" && this.parentTableData) {

      if (this.viewFlag) {
        this.viewFlag = false;
        this.editViewFlag = true;
      }

      this.mapDataFn();
      const clientData = {
        lookupTableName: "Clients",
        name: "client",
        value: [
          {
            id: this.parentTableData._id,
            idField: [this.parentTableData.name],
            others: [this.parentTableData.companyName,this.parentTableData.firstName,this.parentTableData.lastName],
            val: this.parentTableData
          }
        ]
      }
      this.addRefAfterClose([clientData]);
    }
    this.loading = false;
  }

  //-- set null values to 0, for formula processing
  setNumberFieldsToZero($Table) {
    this.formColumns.find((f) => {
      if (f.type == 'number') {
        if ($Table[f.name] == null) {
          $Table[f.name] = 0;
        }
      }
    })
    return $Table;
  }


  setFieldsRowLogic() {

    this.finalArrayForWithoutSection = [];
    this.finalArrayForViewMode = [];
    this.finalArrayForSection = [];

    let limit = 12;
    let temp = [];
    let count = 0;
    if (this.withoutSectionData && this.withoutSectionData.length) {
      this.withoutSectionData.forEach((element, i) => {
        if (
          // !element.isHidden &&
          this.show[element.name] &&
          element.type != "section" &&
          element.type !== "autoNumber" &&
          (element.type !== "recordType" ||
            (element.type == "recordType" && this.recordType == ""))
        ) {
          if (limit >= this.sizeObj[element.fieldSize]) {
            element["sequence"] = i;
            temp.push(element);
            limit = limit - this.sizeObj[element.fieldSize];
            if (i == this.withoutSectionData.length - 1) {
              this.hideAndShowField(
                temp,
                element,
                this.finalArrayForWithoutSection,
                count
              );
            }
          } else {
            this.hideAndShowField(
              temp,
              element,
              this.finalArrayForWithoutSection,
              count
            );
            temp = [];
            limit = 12;
            element["sequence"] = i;
            temp.push(element);
            limit = limit - this.sizeObj[element.fieldSize];
            if (i == this.withoutSectionData.length - 1) {
              this.hideAndShowField(
                temp,
                element,
                this.finalArrayForWithoutSection,
                count
              );
            }
          }
        } else {
          if (i == this.withoutSectionData.length - 1) {
            this.hideAndShowField(
              temp,
              element,
              this.finalArrayForWithoutSection,
              count
            );
          }
        }
      });

      this.loadTinyMce = true;
    }

    if (this.sections && this.sections.length) {
      this.showFooter = false;
      this.sections.forEach((ele) => {
        limit = 12;
        temp = [];
        count = 0;
        this.finalArrayForSection = [];
        if (ele && ele.length) {
          ele.forEach((element, i) => {
            if (
              // !element.isHidden &&
              this.show[element.name] &&
              element.type != "section" &&
              element.type !== "autoNumber" &&
              (element.type !== "recordType" ||
                (element.type == "recordType" && this.recordType == ""))
            ) {
              if (limit >= this.sizeObj[element.fieldSize]) {
                element["sequence"] = i;
                temp.push(element);
                limit = limit - this.sizeObj[element.fieldSize];
                if (i == ele.length - 1) {
                  this.hideAndShowField(
                    temp,
                    element,
                    this.finalArrayForSection,
                    count
                  );
                }
              } else {
                this.hideAndShowField(
                  temp,
                  element,
                  this.finalArrayForSection,
                  count
                );
                temp = [];
                limit = 12;
                element["sequence"] = i;
                temp.push(element);
                limit = limit - this.sizeObj[element.fieldSize];
                if (i == ele.length - 1) {
                  this.hideAndShowField(
                    temp,
                    element,
                    this.finalArrayForSection,
                    count
                  );
                }
              }
            } else {
              if (i == ele.length - 1) {
                this.hideAndShowField(
                  temp,
                  element,
                  this.finalArrayForSection,
                  count
                );
              }
            }
          });
        }
        ele["array"] = this.finalArrayForSection;
      });
      this.sections = this.sections.filter((ele) => ele.array.length > 0);
    }
    if (this.viewFlag) {

      let limit = 12;
      let temp = [];
      let count = 0;
      if (this.formColumns && this.formColumns.length) {
        this.formColumns.forEach((element, i) => {
          if (
            // !element.isHidden &&
            this.show[element.name] &&
            element.type != "section" &&
            element.type !== "autoNumber" &&
            (element.isProvideButton
              ? element.isProvideButton && this.Data[element.name]
              : true)
          ) {
            if (
              limit >= (this.inlineView ? 6 : this.sizeObj[element.fieldSize])
            ) {
              element["sequence"] = i;

              temp.push(element);
              limit =
                limit - (this.inlineView ? 6 : this.sizeObj[element.fieldSize]);
              if (i == this.formColumns.length - 1) {
                this.finalArrayForViewMode.push({
                  data: temp,
                });
                this.statusColor(this.finalArrayForViewMode, count);
                count++;
              }
            } else {
              this.finalArrayForViewMode.push({
                data: temp,
              });
              this.statusColor(this.finalArrayForViewMode, count);
              count++;
              temp = [];
              limit = 12;
              element["sequence"] = i;
              temp.push(element);
              limit =
                limit - (this.inlineView ? 6 : this.sizeObj[element.fieldSize]);
              if (i == this.formColumns.length - 1) {
                this.finalArrayForViewMode.push({
                  data: temp,
                });
                this.statusColor(this.finalArrayForViewMode, count);
                count++;
              }
            }
          } else {
            if (i == this.formColumns.length - 1) {
              this.finalArrayForViewMode.push({
                data: temp,
              });
              this.statusColor(this.finalArrayForViewMode, count);
              count++;
            }
          }
        });
      }
      console.log("this.finalArrayForViewMode === >", this.finalArrayForViewMode)
    }

    this.changeDetector.detectChanges();
    this.tableService.runCustomStyling();
  }

  // returnHtml (html:any) {
  //   console.log("html",html);
  //   const htmlValye = this.document.getElementById("message")
  //   return htmlValye.appendChild(html)
  // }

  async getFieldRestrictions() {
    let response: any = await this.tableService.getDynamicTreeData(
      "Field Restrictions"
    );
    if (
      response &&
      response.data &&
      response.data.pageOfItems &&
      response.data.pageOfItems.length
    ) {
      let allData = [...response.data.pageOfItems];
      let currentUserRoleRestrictions = [];
      let currentRoleId =
        this.currentUser.lookup && this.currentUser.lookup.length
          ? this.currentUser.lookup.find((a) => a && a.lookupTableName == "Roles")
            .lookupId
          : null;

      allData.forEach((ele) => {
        if (ele.lookups && ele.lookups.length) {
          let rolesToCheck = [];
          rolesToCheck = ele.lookups.filter((val) => val.table == "Roles");
          if (rolesToCheck && rolesToCheck.length) {
            let isRoleOfCurrent = rolesToCheck.find(
              (alpha) => alpha._id == currentRoleId
            );
            if (isRoleOfCurrent) {
            } else {
              let fieldToBind = ele.lookups.find(
                (val) => val.table == "tablefields"
              );
              if (fieldToBind) {
                let field = this.formColumns.find(
                  (el) => el._id == fieldToBind._id
                );
                if (field) {
                  if (ele.restriction == "Read Only") {
                    field.isReadOnly = true;
                    this.setAddEditFields(field);
                  }

                  if (ele.restriction == "Hidden") {
                    // this.show[field.name] = false;
                    field.isHidden = true;
                  }
                }
              }
            }
          }
        }
      });

      this.changeDetector.detectChanges();
    }
  }

  setAddEditFields(field) {
    field["showAsInput"] = true;
    if (field.isReadOnly) {
      field["showAsInput"] = false;
    }

    if (field.onlyEditInAdd && this.id) {
      field["showAsInput"] = false;
    }
  }

  async markComplete(action) {
    if (action.actionsList && action.actionsList.length) {
      let actionType = null
      if (action.actionsList !== undefined) {
        action.actionsList.filter((item) => {
          if (item.actionType === 'Preview Doc') {
            actionType = item
            return false
          }
        })
      }
      if (actionType && action.document != "") {
        await this.viewDocumentPDF(actionType)
      } else {
        let obj = {
          item: action,
        };
        this.onActionsClick(obj, this.Data, this.Data);
      }
    } else {
      if (action.actionType == "Preview Doc" && action.document != "") {
        await this.viewDocumentPDF(action)
      } else {
        let obj = {
          item: action,
        };
        this.onActionsClick(obj, this.Data, this.Data);
      }
    }
  }

  async viewDocumentPDF(action) {
    await this.tableService.viewDocumentPDF(this.tableName, this.Data, action.document).subscribe(
      (resd: any) => {
        if (resd.data) {
          this.tableService.getAllDocumentsEvents(this.tableId).subscribe(
            (res: any) => {
              if (res.statusCode === 200) {
                let docList = res["data"].find((x) => x._id === action.document);
                if (docList) {
                  this.viewDocument(docList, resd.data.data)
                }
              } else {
              }
            },
            (error) => { }
          );
        } else {
          this.toastrService.danger(`${this.tableName} is not assigned!`);
        }
      }
    )
  }

  viewDocument(col, html_body) {
    const doc: any = new jsPDF('p', 'pt', 'letter');

    doc.html(html_body, {
      filename: col.event.name,
      callback: (doc) => {
        var blobPDF = new Blob([doc.output()], { type: 'application/pdf' });
        var blobUrl = URL.createObjectURL(blobPDF);
        this.selectedDoc = blobUrl

        const fileDialog = this.dialogService.open(FilePreviewDialogComponent, {
          context: {
            Data: blobUrl,
            Ext: 'bloblUrl'
          },
        });

        fileDialog.componentRef.instance.saveTo.subscribe(async (data: any) => {
          let tableres: any = await this.tableService.getTableByName('Files');

          if (tableres && tableres.data && tableres.data[0].columns.length) {
            const tempParentTableHeader = Object.assign([], tableres.data[0].columns);
            const fileType = tableres.data[0].columns.find(({ type }) => type === "file");
            let type = 'file';
            if (fileType && fileType.name) {
              type = fileType.name
            }
            const res = {
              data: {
                relatedTo: data,
                lookup: [],
                [type]: []
              }
            };
            res.data[type].push(blobUrl);
            const ref = this.dialogService.open(DynamicFormDialogNewDesignComponent, {
              closeOnEsc: false,
              context: {
                title: 'Add New File',
                //headerDetail: this.headerObj,
                isForceSave: true,
                subFormLookupIds: "",
                form: tempParentTableHeader,
                button: { text: 'Save' },
                tableName: 'Files',
                Data: res.data,
                recordType: null,
                recordTypeFieldName: null,
                action: 'Add',
                // mainTableData: [],
                tableRecordTypes: []
              },
            })
              .onClose.subscribe(name => {
              });
          }
        })
      }
    });
  }

  setStepperIndex(step) {
    for (let i = 1; i <= step; i++) {
      this.stepper.next();
    }
  }

  subFormFormationCode(subFormObjectForOption, resData) {
    if (resData[0].actions && resData[0].actions.length) {
      this.subFormActions = resData[0].actions;
    }
    this.getTableByNameObjectForData[subFormObjectForOption.tableName] =
      resData;
    this.subFormFieldCount += 1;
    // If columns available in list

    if (resData.length && resData[0].columns && resData[0].columns.length) {
      let obj = [];

      if (this.activeFormLayout) {

        let isSubFormPresent = this.activeFormLayout.list.find(v => v.field.type == 'injectSubForm' && v.field.options[0].lookupName == subFormObjectForOption.lookupName);
        if (isSubFormPresent) {
          isSubFormPresent.subFormList.forEach((element) => {
            let field = resData[0].columns.find(v => v._id == (element.field._id ? element.field._id : element.field));
            if (field) {
              field.fieldSize = element.size ? element.size : field.fieldSize;
              element.field = field;
              obj.push(field);
            }

          });
        }
      } else {
        // Filter subForm fields only and ignore subform lookup which is available in parent form
        resData[0].columns.forEach((element) => {
          if (
            element.isASubFormField &&
            element.name != subFormObjectForOption.lookupName
          ) {
            obj.push(element);
          }
        });

      }

      // Creating list of all subForms with name and columns.
      if (obj.length) {
        this.subFormTableName[
          subFormObjectForOption.lookupName +
          "|" +
          subFormObjectForOption.tableName
        ] = subFormObjectForOption.tableName;
        this.subFormFields[subFormObjectForOption.tableName] = [...obj];
        this.viewFlagForSubForm[
          subFormObjectForOption.lookupName +
          "|" +
          subFormObjectForOption.tableName
        ] = false;
      }

      //checking for rollUp field in subForm --> remove from dynamic form if not in subForm
      this.formColumns.forEach((element, i) => {
        if (element.type == "rollUp") {
          if (
            element.options &&
            element.options[0] &&
            subFormObjectForOption.tableName == element.options[0].rollUptable
          ) {
            if (
              this.subFormFields[subFormObjectForOption.tableName] &&
              this.subFormFields[subFormObjectForOption.tableName].length
            ) {
              element["subFormLookupName"] = subFormObjectForOption.lookupName;
            }
          }
        }
      });

      this.rollupFields = this.formColumns.filter((f) => f.type == "rollUp");
      this.formColumns = [...this.formColumns];
    }
  }

  setFinalArray(tempArray, temp, hide, element) {
    tempArray.push({
      data: temp,
      hide: hide,
    });
  }

  hideAndShowField(temp, element, array, count) {
    let hide = true;
    for (const item of temp) {
      if (this.show[item.name]) {
        hide = false;
        break;
      }
    }
    if (hide) {
      this.setFinalArray(array, temp, true, element);
    } else {
      this.setFinalArray(array, temp, false, element);
    }

    if (array && array.length) {
      this.statusColor(array, count);
      count++;
    }
  }

  statusColor(tempArray, count) {
    let checkStatus = tempArray[count].data.filter((v) => v.type == "status");
  }

  setUserFields(data) {
    this.userFields[data.name] = [
      {
        value: this.Data
          ? this.Data[data.name]
          : this.statusColorFromTree
            ? this.statusColorFromTree.status
            : data.defaultOptionValue
              ? data.defaultOptionValue
              : data.type == 'number' ? data.defaultValue ? data.defaultValue : 0 : '',
        disabled: data.isReadOnly ? true : false,
      },
      data.isRequired ? [Validators.required] : [],
    ];
  }

  visibilityFieldsFn() {
    let visibilityFields = this.formColumns.filter(
      (item) => item.isVisibilityOn == true
    );
    visibilityFields.forEach((ele) => {
      if (
        ele.isVisibilityOn &&
        ele.fieldValuesData &&
        ele.fieldValuesData.length > 0
      ) {
        this.show[ele.name] = false;
        this.visibilityData = this.formColumns
          .filter((col) => col._id === ele.visibilityData)
          .map((col) => col.name);
        if (this.visibilityData && this.visibilityData.length) {
          this.visibilityData = this.visibilityData[0];
          this.dependsFields.push(ele);
          if (this.visibilityData == "taskType") {
            ele.fieldValuesData.forEach((item) => {
              if (this.recordType == item) {
                this.show[ele.name] = true;
              }
            });
          } else {
            ele.fieldValuesData.forEach((item) => {
              let value = this.formColumns.filter((val) => {
                if (this.visibilityData == val.name) return true;
              });
              if (value && value.length) {
                if (value[0].type == "checkbox") {
                  let index = value[0].options.findIndex((v) => v == item);
                  if (index > -1) {
                    let arr = this.dynamicForm.get(this.visibilityData).value;
                    arr.forEach((element, i) => {
                      if (i == index) {
                        if (element == true) {
                          this.show[ele.name] = true;
                        }
                      }
                    });
                  }
                } else {
                  if (
                    this.visibilityData &&
                    this.dynamicForm.get(this.visibilityData).value == item
                  ) {
                    this.show[ele.name] = true;
                  }
                }
              }
            });
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.actionSubscription) {
      this.actionSubscription.unsubscribe();
    }

    const element = document.getElementById("main_body");
    element.classList.remove("stepper-layout");
    this.eventService.realData = {};
  }

  matchPattern(value): boolean {
    // const matchPattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
    if (value && isNaN(value)) return this.linkifyService.test(value);
  }

  changesPhoneDashes(phone) {
    const phoneVal = phone.replace(/\D[^\.]/g, "");
    return (
      phoneVal.slice(0, 3) +
      "-" +
      phoneVal.slice(3, 6) +
      "-" +
      phoneVal.slice(6)
    );
  }

  getReferList(res, element, data) {
    let response = [];
    let refLookupIdFields = [];
    let refLookupField = [];
    if (element.lookupName) {
      refLookupField = this.lookupArray.filter(
        (f) => f.name == element.lookupName
      );
    } else {
      refLookupField = this.lookupArray.filter((f) => f.name == element);
    }

    if (refLookupField && refLookupField.length) {
      let refLookTable = refLookupField[0].lookupTableName;
      if (this.tableDataForForms && this.tableDataForForms.length) {
        response = this.tableDataForForms.filter(
          (ele) => ele.tableName == refLookTable
        );
        if (response && response.length) {
          refLookupIdFields = response[0].columns.filter((v) => v.idField);
        }
      } else {
        this.tableService.getTablesForMenu().subscribe((res: any) => {
          if (res.statusCode == 200) {
            this.tableDataForForms = res.data;
            response = this.tableDataForForms.filter(
              (ele) => ele.tableName == refLookTable
            );
            if (response && response.length) {
              refLookupIdFields = response[0].columns.filter((v) => v.idField);
            }
          }
        });
      }
    } else {
      let refLookTable = element.lookupTableName;
      if (this.tableDataForForms && this.tableDataForForms.length) {
        response = this.tableDataForForms.filter(
          (ele) => ele.tableName == refLookTable
        );
        if (response && response.length) {
          refLookupIdFields = response[0].columns.filter((v) => v.idField);
        }
      } else {
        this.tableService.getTablesForMenu().subscribe((res: any) => {
          if (res.statusCode == 200) {
            this.tableDataForForms = res.data;
            response = this.tableDataForForms.filter(
              (ele) => ele.tableName == refLookTable
            );
            if (response && response.length) {
              refLookupIdFields = response[0].columns.filter((v) => v.idField);
            }
          }
        });
      }
    }
    let obj = {
      name: "",
      value: null,
      lookupTableName: "",
      lookupTableId: "",
    };
    if (element.lookupName) {
      obj.name = element.lookupName;
    } else {
      obj.name = element;
    }

    obj.value = [];
    obj.lookupTableName =
      refLookupField.length > 0 ? refLookupField[0].lookupTableName : "";
    obj.lookupTableId =
      refLookupField.length > 0 ? refLookupField[0].lookupTableId : "";
    let valueObj = {
      idField: [],
      others: [],
      id: null,
      val: null,
    };
    let arrayForId = [];
    let arrForOther = [];
    let keys = Object.keys(res.data);
    let valueofOther = [];

    if (element.lookupName) {
      valueofOther = this.otherDataKeys.filter((valueELe) => {
        if (valueELe.name == element.lookupName) {
          return true;
        }
      });
    } else {
      valueofOther = this.otherDataKeys.filter((valueELe) => {
        if (valueELe.name == element) {
          return true;
        }
      });
    }

    if (keys) {
      keys.forEach((element) => {
        let field = refLookupIdFields.find((v) => v.name == element);
        if (field) {
          if (field.isPhone) {
            if (typeof res.data[element] !== "object") {
              const phonePipe = new PhonePipe();
              const finalPhone = phonePipe.transform(res.data[element]);
              arrayForId.push(" " + finalPhone);
            }
          } else {
            if (typeof res.data[element] !== "object") {
              arrayForId.push(" " + res.data[element]);
            }
          }
        }
        if (valueofOther && valueofOther.length) {
          if (valueofOther[0].value.includes(element)) {
            let fieldOther = response[0].columns.find((v) => v.name == element);
            if (fieldOther) {
              if (fieldOther.isPhone) {
                if (typeof res.data[element] !== "object") {
                  const phonePipe = new PhonePipe();
                  const finalPhone = phonePipe.transform(res.data[element]);
                  arrForOther.push(" " + finalPhone);
                }
              } else {
                if (typeof res.data[element] !== "object") {
                  arrForOther.push(" " + res.data[element]);
                }
              }
            }
          }
        }
      });
    }
    if (data.lookupId) {
      valueObj.id = data.lookupId;
    } else {
      valueObj.id = data;
    }
    valueObj.idField.push(arrayForId);
    valueObj.others.push(arrForOther);
    valueObj.val = res.data;
    obj.value.push(valueObj);
    let temp = [];
    temp.push(obj);
    let invalid = false;
    if (this.list && this.list.length) {
      this.list.forEach((ele) => {
        if (ele.name == obj.name) {
          if (!ele.value.includes(obj.value)) {
            ele.value.push(valueObj);
            invalid = true;
            return;
          }
        }
      });
      if (!invalid) {
        this.list.push(obj);
      }
    } else {
      this.list = temp;
    }

    if (this.Data) {
      this.Data['refList'] = [...this.list];
    }
  }

  getTableData() {
    if (!this.tableName || !this.tableId || !this.id) {
      return;
    }

    if (this.tableName.endsWith("s" || "S")) {
      const service = new NgPluralizeService();
      this.singularTableName = service.singularize(this.tableName);
      //  this.tableNameWithoutS = this.tableName.slice(0, -1);
    } else {
      this.singularTableName = this.tableName;
    }

    this.lookupData = [];
    this.recordData = this.Data;
    // -- Set Group chat title
    this.setGroupChatTitle();
    this.setTableInfo(this.id);
    this.setIdFields();
  }

  setGroupChatTitle() {
    if (this.Data.IDField && this.Data.IDField.length > 0) {
      let tempName = "";
      this.Data.IDField.map((data) => {
        if (tempName == "") {
          let field = this.formColumns.find((ele) => ele.name == data);
          if (field && field.type == "lookup") {
            if (this.Data.lookup && this.Data.lookup.length) {
              this.Data.lookup.forEach((element) => {
                if (element.lookupDataName == data || element.lookupName == data) {

                  let temp = [];
                  temp = element[data];
                  if (temp && temp.length) {
                    temp.shift();
                  }
                  let value = temp.toString().replace(/,/g, " ");
                  tempName = value;
                }
              });
            }
          } else if (field && field.isPhone) {
            const phonePipe = new PhonePipe();
            const finalPhone = phonePipe.transform(this.Data[data]);
            if (finalPhone && typeof finalPhone !== "object") {
              tempName = finalPhone;
            }
          } else {
            tempName = this.Data[data];
          }

          this.idValues = tempName;
        } else {
          // if (this.Data[data]) {
          let field = this.formColumns.find((ele) => ele.name == data);
          if (field && field.type == "lookup") {
            if (this.Data.lookup && this.Data.lookup.length) {
              this.Data.lookup.forEach((element) => {
                if (element.lookupDataName == data || element.lookupName == data) {
                  let temp = [];
                  temp = element[data];
                  if (temp && temp.length) {
                    temp.shift();
                  }
                  let value = temp.toString().replace(/,/g, " ");
                  this.Data[data] = value;

                }
              });
            }
          } else if (field && field.isPhone) {
            const phonePipe = new PhonePipe();
            const finalPhone = phonePipe.transform(this.Data[data]);
            if (finalPhone && typeof finalPhone !== "object") {
              this.Data[data] = finalPhone;
            }
          } else {
            this.Data[data] = this.Data[data];
          }

          if (this.Data[data]) {
            this.idValues = `${tempName} ${this.Data[data]}`;
          }

          if (this.Data[data] && this.Data[data] != this.recordType) {
            tempName = `${tempName} - ${this.Data[data]}`;
          }
          // }
        }
      });

      this.groupChatTitle = this.singularTableName + " - " + tempName;

      if (this.recordType) {
        this.groupChatTitle = this.recordType + " - " + tempName;
      } else {
        this.groupChatTitle = this.singularTableName + " - " + tempName;
      }
    }
  }

  setTableInfo(id) {
    this.tableInfo = {
      resourceId: id,
      tableId: this.tableId,
      tableName: this.tableName,
      resourceName: this.groupChatTitle,
    };
  }

  dependenciesCheck() {
    //check if dependency is there for current field..
    this.formColumns.forEach((item, index) => {
      item.isDependencyHit = false;
      item.oldValue = this.dynamicForm.value[item.name];
      this.dependenciesList.forEach((element, i) => {
        let keys = Object.keys(element.query);
        if (keys.indexOf(item.name) > -1) {
          if (!item.isDependencyHit) {
            this.filtereAllOptions(item, element);
          }
        }
      });
    });
  }

  setOptionForDependencyHit(field) {
    if (!field.isDependencyHit) {
      if (field.type == "status") {
        field.statusOptions = [];
      } else {
        field.options = [];
      }
      this.setFormValueNull(field);
    }
  }

  filtereAllOptions(field, dependencyObj) {
    //check for the value in srcField..
    let srcFieldName = dependencyObj.srcFieldName;
    let srcFieldType = dependencyObj.srcFieldType;
    let srcFieldOptions = dependencyObj.srcFieldOptions;

    let dependencyField = [...dependencyObj.fields];

    for (
      let dFieldIndex = 0;
      dFieldIndex < dependencyField.length;
      dFieldIndex++
    ) {
      //find index of option
      let idxOfOption = -1;
      let isAllCheckboxesNull = true;

      //if src type  is checkbox then we have to check value for respected option
      if (srcFieldType == "checkbox") {
        srcFieldOptions.forEach((op, i) => {
          if (this.dynamicForm.value[srcFieldName][i] == true)
            isAllCheckboxesNull = false;
        });

        idxOfOption = srcFieldOptions.findIndex(
          (e) => e == dependencyField[dFieldIndex]
        );

        //if checkbox is true for srcField || dependency is null and all checkboxes are false
        if (
          this.dynamicForm.value[srcFieldName][idxOfOption] == true ||
          (dependencyField[dFieldIndex] == "Null" && isAllCheckboxesNull)
        ) {
          this.updateOptionsList(field, dependencyObj);
          field.isDependencyHit = true;
          return;
        } else if (dependencyObj.showUndependent && !field.isDependencyHit) {
          this.updateOptionsListOnDependencyCheckFail(field, dependencyObj);
        } else {
          this.setOptionForDependencyHit(field);
        }
      }
      //for recordType only
      else if (srcFieldType == "recordType") {
        //if current recordType is equal to dependency's field value || for record type null condition
        if (
          this.recordType == dependencyField[dFieldIndex] ||
          (dependencyField[dFieldIndex] == "Null" &&
            !this.dynamicForm.value[srcFieldName])
        ) {
          this.updateOptionsList(field, dependencyObj);
          field.isDependencyHit = true;
          return;
        } else if (dependencyObj.showUndependent && !field.isDependencyHit) {
          this.updateOptionsListOnDependencyCheckFail(field, dependencyObj);
        } else {
          this.setOptionForDependencyHit(field);
        }
      } else if (srcFieldType == "lookup") {
        if (
          dependencyField[dFieldIndex] == "Null" &&
          ((field.type == "checkbox" &&
            field.type == "checkbox" &&
            isAllCheckboxesNull) ||
            !this.lookupValue[srcFieldName].length)
        ) {
          this.updateOptionsList(field, dependencyObj);
          field.isDependencyHit = true;
          return;
        } else if (
          dependencyField[dFieldIndex] == "Not Null" &&
          ((field.type == "checkbox" &&
            field.type == "checkbox" &&
            !isAllCheckboxesNull) ||
            this.lookupValue[srcFieldName].length)
        ) {
          this.updateOptionsList(field, dependencyObj);
          field.isDependencyHit = true;
          return;
        } else if (dependencyObj.showUndependent && !field.isDependencyHit) {
          this.updateOptionsListOnDependencyCheckFail(field, dependencyObj);
        } else {
          this.setOptionForDependencyHit(field);
        }
      }

      //if src type isnot checkbox then we have to check options directly
      else if (srcFieldType != "checkbox") {
        //if value is equal to srcField-value || dependency is null and src-field-value is null
        if (
          this.dynamicForm.value[srcFieldName] ==
          dependencyField[dFieldIndex] ||
          (dependencyField[dFieldIndex] == "Null" &&
            !this.dynamicForm.value[srcFieldName])
        ) {
          this.updateOptionsList(field, dependencyObj);
          field.isDependencyHit = true;
          return;
        } else if (dependencyObj.showUndependent && !field.isDependencyHit) {
          this.updateOptionsListOnDependencyCheckFail(field, dependencyObj);
        } else {
          this.setOptionForDependencyHit(field);
        }
      }
    }
  }

  updateOptionsList(field, dependencyObj) {
    if (field.type == "status") {
      field.statusOptions = [...field.temOptions];
      let temp = [];
      dependencyObj.query[field.name].forEach((element) => {
        if (field.temOptions && field.temOptions.length) {
          let index = field.temOptions.findIndex((f) => f.status == element);
          if (index > -1) {
            temp.push(field.temOptions[index]);
          }
        }
      });
      field.statusOptions = [...temp];
    } else {
      field.options = [...dependencyObj.query[field.name]];
    }

    //if old value of field is available in option..
    let isOldValueAvailable = false;
    if (field.type == "status") {
      let oldValueOption = field.statusOptions.filter(
        (f) => f.status == field.oldValue
      );
      if (oldValueOption.length) {
        isOldValueAvailable = true;
        this.colorSetter[field.name] = oldValueOption[0].color;
        this.statuses[field.name] = oldValueOption[0].status;
        this.dynamicForm.value[field.name] = oldValueOption[0].status;
      }
    } else {
      console.log("field.oldValue =======>", field.oldValue);

      let oldValueOption = field.options.filter((f) => f == field.oldValue);
      if (oldValueOption.length) {
        isOldValueAvailable = true;
        this.dynamicForm.value[field.name] = field.oldValue;
      }
    }

    //make value of field null if not in the options.
    if (!isOldValueAvailable) {
      if (field.type == "status" && this.dynamicForm.value[field.name]) {
        let index = field.statusOptions.findIndex(
          (f) => f.status == this.dynamicForm.value[field.name]
        );
        if (index == -1) {
          this.colorSetter[field.name] = "#fff";
          this.statuses[field.name] = "STATUS";
          this.dynamicForm.value[field.name] = "";
        }
      } else if (field.type == "checkbox") {
        if (field.temOptions && field.temOptions.length) {
          field.temOptions.forEach((element, idx) => {
            let idx1 = dependencyObj.query[field.name].findIndex(
              (f) => f == element
            );
            if (idx1 == -1) {
              if (this.dynamicForm.value[field.name][idx]) {
                this.dynamicForm.value[field.name][idx] = false;
              }
            }
          });
        }
      }
    }
  }

  updateOptionsListOnDependencyCheckFail(field, dependencyObj) {
    if (field.type == "status") {
      field.statusOptions = [...field.tempOptions];
      field.statusOptions.forEach((opt) => {
        let optionInQuery = dependencyObj.query[field.name].indexOf((itm) => {
          opt === itm;
        });
        if (optionInQuery > -1) {
          field.statusOptions.splice(optionInQuery, 1);
        }
      });
    } else {
      field.options = [...field.tempOptions];
      if (field.options) {
        field.options.forEach((opt) => {
          let optionInQuery = dependencyObj.query[field.name].indexOf((itm) => {
            opt === itm;
          });
          if (optionInQuery > -1) {
            field.options.splice(optionInQuery, 1);
          }
        });
      }
    }

    //make value of field null if not in the options.
    if ((field.type = "status" && this.dynamicForm.value[field.name])) {
      let index = field.statusOptions.findIndex(
        (f) => f.status == this.dynamicForm.value[field.name]
      );
      if (index == -1) {
        this.dynamicForm.value[field.name] = "";
        this.colorSetter[field.name] = "#fff";
        this.statuses[field.name] = "";
      }
    } else if (field.type == "checkbox") {
      if (field.temOptions && field.temOptions.length) {
        field.temoptions.forEach((element, idx) => {
          let idx1 = dependencyObj.query[field.name].findIndex(
            (f) => f == element
          );
          if (idx1 == -1) {
            if (this.dynamicForm.value[field.name][idx])
              this.dynamicForm.value[field.name][idx] = false;
          }
        });
      }
    }
  }

  setFormValueNull(field) {
    if (field && field.name) {
      if (field.type == "status") {
        this.dynamicForm.value[field.name] = "";
        this.dynamicForm.value[field.name] = "";
        this.colorSetter[field.name] = "#fff";
        this.statuses[field.name] = "";
      } else if (field.type != "checkbox") {
        this.dynamicForm.value[field.name] = null;
      } else {
        if (this.dynamicForm.value[field.name].length) {
          this.dynamicForm.value[field.name].forEach((element) => {
            element = false;
          });
        }
      }
    }
  }

  evalExpressionForFormulaField(source = '') {

    for (let field of this.formColumns) {
      if (field.type == "formula") {
        if (
          field.options &&
          field.options[0] &&
          field.options[0].formula &&
          field.options[0].alsoAFrontEndFormula
        ) {

          //-- Need to uncomment once we have method to set default val for number field
          // if(source == 'init' && field.options[0].backEndFormula){
          //   //$Table[field.name] =
          //   return;
          // }

          let $Table = this.dynamicForm.value;
          let formulaKeys = Object.keys(this.formulaDataObject);
          if (formulaKeys && formulaKeys.length) {
            formulaKeys.forEach(ele => {
              $Table[ele] = this.formulaDataObject[ele];
              if (field.options[0].type == 'number' && ($Table[ele] == null || typeof $Table[ele] == 'undefined')) {
                $Table[ele] = 0;
              }
            });
          }

          $Table = this.setNumberFieldsToZero($Table);

          try {
            if (
              $Table &&
              field &&
              field.name &&
              field.options &&
              field.options[0].formula
            ) {
              this.dynamicForm
                .get(field.name)
                .setValue(eval(field.options[0].formula));

              this.formulaDataObject[field.name] = Number(eval(field.options[0].formula));

            } else {
              this.dynamicForm.get(field.name).setValue("");
            }
          } catch (e) {
            console.log("Error processing formula ", e);
          }
        } else {
          this.dynamicForm.get(field.name).setValue("");
        }
      }
    }
  }

  ngAfterViewInit() {
    if (this.formWidth) {
      let ele = this.document.getElementById(
        "nb-card-" + this.tableName + "-" + this.currentTimeStamp
      );
      if (this.viewFlag || this.editViewFlag) {
        if (ele) {
          ele.style.setProperty("width", this.formWidth + "px", "important");
        }
      } else if (this.addEditFlag) {
        let width = Number(this.formWidth);
        if (width < 410) {
          width = 410;
        }

        let wid: any = this.sanitizer.bypassSecurityTrustStyle(
          String(width - 350)
        );

        this.formWidth = wid.changingThisBreaksApplicationSecurity;
        if (ele) {
          ele.style.setProperty("width", this.formWidth + "px", "important");
        }
      }
    }

    if (this.formHeight) {
      if (this.viewFlag) {
        let ele = this.document.getElementById(
          "nb-card-body-" + this.tableName + "-" + this.currentTimeStamp
        );
        if (ele) {
          ele.style.setProperty("height", this.formHeight + "px", "important");
          ele.style.setProperty(
            "min-height",
            this.formHeight + "px",
            "important"
          );
          ele.style.setProperty(
            "max-height",
            this.formHeight + "px",
            "important"
          );
        }
      } else {
        let el: any = this.document.getElementsByClassName(
          "mat-expansion-panel-body"
        );
        if (el.length) {
          for (var i = 0; i < el.length; i++) {
            el[i].style.setProperty(
              "height",
              this.formHeight + "px",
              "important"
            );
          }
        }
      }
    }

    this.tableService.dateTimePickerFocused.subscribe((res) => {
      setTimeout((_) => (this.dateTimePickerToggeledOn = res));
    });
    if (this.Data) {
      this.loadSubscribers();
    }
    if (this.notLoadAsDropdownLookupPopover) {
      this.notLoadAsDropdownLookupPopover.close();
    }
    if (this.referenceLookupPopover) {
      this.referenceLookupPopover.close();
    }
    if (this.referenceRecordLookupPopover) {
      this.referenceRecordLookupPopover.close();
    }
    if (this.loadAsDropdownLookupPopover) {
      this.loadAsDropdownLookupPopover.close();
    }
    if (this.editLoadAsDropdownLookupPopover) {
      this.editLoadAsDropdownLookupPopover.close();
    }
    if (this.editNotLoadAsDropdownLookupPopover) {
      this.editNotLoadAsDropdownLookupPopover.close();
    }

    //-- set custom step
    if (this.stepperIndex && this.stepperIndex > 0) {
      this.setStepperIndex(this.stepperIndex);
      this.step = this.stepperIndex - 1;
    }

    //-- Add theme specific color
    this.tableService.runCustomStyling();
  }

  ngAfterContentChecked() {
    this.changeDetector.detectChanges();
  }

  loadSubscribers() {
    this.chatSubscriptionService
      .getSubscribers(this.Data._id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (res.data) {
          this.subscribers = res.data;
          if (res.data.length > 0) {
            res.data.map((data) => {
              if (data._id == this.currentUser._id) {
                this.subscriptionText = "Stop watching";
                this.isSelfSubscribed = true;
              }
            });
            this.giveMarginToSubscriber();
          }
          this.changeDetector.detectChanges();
        }
      });
  }

  giveMarginToSubscriber() {
    this.subscribers.forEach((element, i) => {
      const ele = document.getElementById("watcher-span-" + i);
      if (ele) {
        if (this.subscribers.length > 4) {
          let currentMarginLeft = this.subscribers.length * 2 + 3;
          currentMarginLeft = 0 - currentMarginLeft;
          ele.style.marginLeft = currentMarginLeft + "px";
        } else {
          ele.style.marginLeft = -8 + "px";
        }
      }
    });
  }

  setDependenciesList() {
    if (this.formColumns.length > 0) {
      const dependencies = this.formColumns.filter(
        (f) => f.dependencies != undefined
      );
      dependencies.forEach((element) => {
        element.dependencies.forEach((e) => {
          this.dependenciesList.push({
            fields: e.fields,
            query: e.query,
            srcFieldName: element.name,
            showUndependent: element.showUndependentOptions,
            srcFieldType: element.type,
            srcFieldOptions: element.statusOptions
              ? element.statusOptions
              : element.options,
          });
        });
      });
      this.formColumns.forEach((element) => {
        if (
          element.type == "radio" ||
          element.type == "checkbox" ||
          element.type == "recordType" ||
          element.type == "dropdown" ||
          element.type == "dropdownWithImage" ||
          element.type == "lookup"
        ) {
          if (element.options) element.temOptions = [...element.options];
        } else if (element.type == "status") {
          if (element.options) element.temOptions = [...element.statusOptions];
        }
      });
    }
  }

  // async loadTutorial() {
  // window.location.reload();
  //  await this.tableDataByName(1, this.search, this.selectedGroupBy ? '' : this.sortObject, this.filterObject.filterKey = [{ "status": ["Draft", "Sent", "Paid", "Overdue", "Recurring"] }],
  //     this.searchMode, this.statusFilter, "");
  // }

  scrollToBottom(delayTime) {
    setTimeout(() => {
      const natEle = this.scrollable["scrollable"].nativeElement;
      natEle.scrollTop = natEle.scrollHeight;
    }, delayTime);
  }

  setDynamicData(formColumns) {
    if (formColumns) {
      formColumns.forEach((data, index) => {
        if (data.type === "checkbox") {
          const check = [];
          data.options.forEach((option) => check.push(new FormControl(false)));
          this.refUserFields[data.name] = new FormArray(check);
        } else if (data.type === "lookup") {
          this.refIsInputDisable = false;
          this.refremoveLookup[data.name] = true;
          this.reflookTable = data.lookupTableName;
          this.reflookupName = data.name;
          this.refLookupValue[data.name] = "";
          this.refeditData[data.name] = "";
          this.reffilteredOptions[data.name] = [];
          this.refshowAutocomplete[data.name] = false;
          this.reflookupFieldRequired[data.name] = false;

          const lookUpArray = [];
          data.options.forEach((el) => {
            const temp: [] = Object.assign([], el);
            temp.shift();
            this.reffilteredOptions[data.name].push({
              id: el[0],
              value: temp.toString().replace(/,/g, " "),
            });

            if (data.name == this.refLookUpName) {
              lookUpArray.push({
                id: el[0],
                value: temp.toString().replace(/,/g, " "),
              });
            }
          });
          this.lookUpOptions = lookUpArray;

          this.refDemoData = data;
          this.refLookupData.push(this.refDemoData);
        } else if (data.type === "status") {
          data.isRequired = false;
        } else {
          // tslint:disable-next-line: max-line-length
          this.refUserFields[data.name] = [
            "",
            data.isRequired ? [Validators.required] : [],
          ];
        }

        this.refShow[data.name] = true;
        if (
          data.isVisibilityOn &&
          data.fieldValuesData &&
          data.fieldValuesData.length > 0
        ) {
          this.refVisibilityData = this.refDynamicData
            .filter((col) => col._id === data.visibilityData)
            .map((col) => col.name);
          this.refVisibilityData = this.refVisibilityData[0];
          this.refDependsFields.push(data);
          this.refShow[data.name] = this.refRecordType
            ? !!data.fieldValuesData.includes(this.refRecordType)
            : false;
          if (data.isRequired) {
            data.isRequired = this.refRecordType
              ? !!data.fieldValuesData.includes(this.refRecordType)
              : false;
          }
        }
      });
      this.refDynamicForm = this.formBuilder.group(this.refUserFields);
    }
    if (this.refLookUpNameId && this.refparentTableName) {
      const lookupDetail = this.refDynamicData.find(
        (x) => x.lookupTableName == this.refparentTableName
      );
      let lookupDetailToDisplay = [];
      lookupDetail.options.forEach((e) => {
        e.forEach((e1) => {
          if (e1 == this.refLookUpNameId) {
            lookupDetailToDisplay = e;
          }
        });
      });
      const lookup = {
        id: lookupDetailToDisplay[0],
        value: lookupDetailToDisplay[1],
        name: lookupDetailToDisplay[2],
      };
      this.refEditData[lookupDetail.name] = lookup;
      this.refLookupValue[lookupDetail.name] = lookup;
      this.refEditData[lookupDetail.name].name = this.refparentTableName;
    }
  }

  refDynamicDataCrreate(resData) {
    if (this.refDynamicFilterData) {
      for (const data of this.refDynamicFilterData) {
        if (data.type === "lookup") {
          this.reflookTable = data.lookupTableName;
          this.reflookupName = data.name;
          this.refLookupValue[data.name] = "";
          this.reffilteredOptions[data.name] = [];
          this.refshowAutocomplete[data.name] = false;

          data.options.forEach((el) => {
            const temp: [] = Object.assign([], el);
            temp.shift();
            this.reffilteredOptions[data.name].push({
              id: el[0],
              value: temp.toString().replace(/,/g, " "),
            });
          });
          this.refDemoData = data;
          this.refLookupData.push(this.refDemoData);
        }
      }
    }

    if (
      resData &&
      resData.data &&
      resData.data.length &&
      resData.data[0].columns &&
      resData.data[0].columns.length
    ) {
      let cols = resData.data[0].columns;
      this.reftableId = resData.data[0]._id;
      this.tempParentTableHeader = Object.assign([], cols);
      this.tempParentTableHeader.map((column) => {
        if (column.type == "recordType") {
          this.refRecordTypeFieldName = column.name;
          column.options.forEach((element) => {
            const obj = {
              title: element,
            };
            this.refRecordTypes.push(obj);
          });
        }
      });
    }
  }
  async getTableByName() {
    this.refTableName = this.subField.lookupTableName;

    let res: any = await this.tableService.getTableByName(this.refTableName);
    if (res && res.data && res.data[0].columns) {
      this.getTableByNameObjectForData[this.reftableData] = res.data;
      let table = res.data[0];
      this.subFormVariables.tableId = table._id;
      this.subFormVariables.form = res.data[0].columns;
      this.subFormVariables.actions = table.actions ? table.actions : [];
      this.subFormVariables.recordgadgets = table.recordGadgets
        ? table.recordGadgets
        : [];
      if (table.hasOwnProperty("showChats")) {
        this.subFormVariables.showChats = table.showChats;
      }
      this.subFormVariables.customValidations = table.customValidations
        ? table.customValidations
        : [];
      this.subFormVariables.tableIcon = table.iconLocation
        ? table.iconLocation
        : "";
      this.subFormVariables.formHeight = table.formHeight
        ? table.formHeight
        : "";
      this.subFormVariables.formWidth = table.formWidth ? table.formWidth : "";
      if (this.subFormVariables.formWidth) {
        let width = Number(this.subFormVariables.formWidth);
        if (width < 410) {
          width = 410;
        }

        let wid: any = this.sanitizer.bypassSecurityTrustStyle(
          String(width + 350)
        );

        this.subFormVariables.formWidth =
          wid.changingThisBreaksApplicationSecurity;
      }
      if (table.fieldAlignment) {
        this.subFormVariables.fieldAlign = table.fieldAlignment;
      }

      this.refDynamicFilterData = res.data[0].columns;
      this.refDynamicDataCrreate(res);
      this.refDynamicData = this.tempParentTableHeader;
      this.openSubForm(this.subField, this.setFieldIndex);
    }

    this.loading = false;
  }

  async openSubForm(field, fieldIndex) {
    this.mapDataFn();
    let mapDataArray = this.mapData
      ? this.mapData[field.name]
        ? this.mapData[field.name]
        : []
      : [];
    this.loading = false;
    let val: any = await this.formService.openAndCloseForm(null, this.refTableName, this.tableData, false,
      this.refRecordType, false, null, null, field.mappedFields, this.mappedKeysValues, mapDataArray);

    const ref = this.dialogService.open(val.formComp, {
      closeOnEsc: false,
      context: val.formObj,
    }).onClose.subscribe((res) => {
      this.isSubForm = false;
      let optionValue: string = "";
      if (res && res.close == "yes") {
        const name = res.data;
        if (name._id) {
          const option = [];
          option.push(name._id);
          if (
            field.lookupTableFieldNames &&
            field.lookupTableFieldNames.length
          ) {
            field.lookupTableFieldNames.forEach((lookupele) => {
              if (!!name[lookupele]) {
                optionValue = optionValue + " " + name[lookupele];
              }
            });
            option.push(name[field.lookupTableFieldNames[0]]);
            option.push(name[field.lookupTableFieldNames[1]]);
          }

          if (field.loadAsDropDown) {
            this.refreshFilterVar = "";
            if (
              this.formColumns[fieldIndex] &&
              this.formColumns[fieldIndex].options &&
              this.formColumns[fieldIndex].options.length
            ) {
              this.formColumns[fieldIndex].options.push(option);
            } else {
              this.formColumns[fieldIndex].options = [];
              this.formColumns[fieldIndex].options.push(option);
            }
            this.filteredOptions[this.subField.name] = [
              ...this.filteredOptions[this.subField.name],
              { id: name._id, value: optionValue },
            ];
          } else {
            this.filteredOptions[this.subField.name] = [
              ...this.filteredOptions[this.subField.name],
              { id: name._id, value: optionValue },
            ];
          }
          this.lookupValue[this.subField.name] = [
            ...this.lookupValue[this.subField.name],
            {
              id: name._id,
              value: optionValue,
            },
          ];
          this.editData[this.subField.name] = [
            ...this.editData[this.subField.name],
            {
              id: name._id,
              value: optionValue,
            },
          ];
          //this.filteredOptions[this.subField.name] = [...this.lookupValue[this.subField.name]];
          this.onSelectionChange(
            this.lookupValue[this.subField.name],
            this.subField
          );
        }
        // Call a Pipe to filter options.
        this.filteredOptions[this.subField.name] = this.filterPipe.transform(
          this.filteredOptions[this.subField.name],
          this.lookupValue[this.subField.name],
          ""
        );
      } else {
        this.lookupValue[this.subField.name] = [
          ...this.lookupValue[this.subField.name],
        ];
        this.editData[this.subField.name] = [
          ...this.editData[this.subField.name],
        ];
      }
    });
  }

  getFileName(filename) {
    filename = decodeURI(filename);
    return filename || filename.match(/.*\/(.*)$/)[1];
  }

  getFileExtension(filename) {
    const ext = filename.split(".").pop();
    const obj = iconList.filter((row) => {
      if (row.type === ext) {
        return true;
      }
    });
    if (obj.length > 0) {
      return obj[0].icon;
    } else {
      return "fiv-cla fiv-icon-blank fiv-size-md";
    }
  }

  getOptionText(option) {
    if (option && option.value) return option.value;
  }

  listCity(dynamicForm) {
    const zip = dynamicForm.value.zip;
    const stateForm = dynamicForm.get("state");
    const cityForm = dynamicForm.get("city");
    if (stateForm) {
      stateForm.setValue("");
    }
    if (cityForm) {
      cityForm.setValue("");
    }
    if (zip && zip.length == 5) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.mapService
          .getLocations(zip)
          .pipe(takeUntil(this.destroy$))
          .subscribe((res: any) => {
            if (res && res.results) {
              const data = res.results[zip][0];
              if (stateForm) {
                stateForm.setValue(data.state);
              }
              if (cityForm) {
                cityForm.setValue(data.city);
              }
            }
          });
      }, 800);
    }
  }

  cancel() {
    console.log("close Action");
    this.ref.close({ close: "no" });
    this.ref.close();
  }

  audioToBase64(audioFile) {

  }

  downloadFiles(link, file) {

    fetch(link).then((res: any) => {
      let url = window.URL.createObjectURL(new Blob(res));
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = file;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });

  }

  closeModal() {
    this.ref.close({ isStatusUpdatedFromView: this.isStatusUpdatedFromView });
  }

  //-- Sub form code needs to be removed
  // lookuptableName,name,index,field
  addSubForm(field, fieldIndex) {
    this.loading = true;
    this.isSubForm = true;
    this.subField = field;
    this.setFieldIndex = fieldIndex;
    this.tableRecordType = this.subField.lookupTableName;
    const service = new NgPluralizeService();
    this.refRecordType = service.singularize(this.subField.lookupTableName);
    let values = [];
    if (field.mappedFields) {
      values = Object.values(field.mappedFields);
      values.forEach((ele) => {
        let typeCheck = this.formColumns.filter((v) => v.name == ele);
        if (typeCheck && typeCheck.length && typeCheck[0].type == "lookup") {
          this.mappedKeysValues[ele] = this.lookupValue[ele];
        } else {
          this.mappedKeysValues[ele] = this.dynamicForm.get(ele).value;
        }
      });
    }

    if (
      this.tableRecordTypes[this.subField.lookupTableName] &&
      this.tableRecordTypes[this.subField.lookupTableName].length
    ) {
      this.finalRecordTypes =
        this.tableRecordTypes[this.subField.lookupTableName];
    }
    this.setSubForm(this.subField.lookupTableName);
  }

  setSubForm(formName) {
    this.loading = true;
    this.reftitle = "Add " + formName;
    this.getTableByName();
  }

  onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.isActive = true;
  }

  onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.isActive = false;
  }

  onDrop(event: any, fieldName) {
    event.preventDefault();
    event.stopPropagation();
    this.droppedFiles = {};
    this.droppedFiles[fieldName] = [];
    for (let i = 0; i < event.dataTransfer.files.length; i++) {
      this.droppedFiles[fieldName].push(event.dataTransfer.files[i]);
    }
    this.onDroppedFile(this.droppedFiles[fieldName], fieldName);
    this.isActive = false;
  }

  onDroppedFile(droppedFiles, fieldName) {
    const formData = new FormData();
    !this.showArrayData && (this.showArrayData = {});
    this.showArrayData[fieldName] = droppedFiles;
    this.buttonDisable = false;
    if (this.droppedFiles && this.droppedFiles[fieldName]) {
      for (let i = 0; i < this.droppedFiles[fieldName].length; i++) {
        formData.append("file", this.droppedFiles[fieldName][i]);

        this.fileFormData = formData;
        this.uploadProgress = 0;
        if (
          this.showArrayData &&
          this.showArrayData[fieldName] &&
          this.showArrayData[fieldName].length > 0
        ) {
          // File upload in S3 API
          if (this.fileFormData) {
            this.tableService
              .formFileUpload(this.fileFormData)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                async (event) => {
                  this.isUpload = true;
                  switch (event.type) {
                    case HttpEventType.Sent:
                      break;
                    case HttpEventType.ResponseHeader:
                      break;
                    case HttpEventType.UploadProgress:
                      this.uploadProgress = Math.round(
                        (event.loaded / event.total) * 100
                      );
                      break;
                    case HttpEventType.Response:
                      setTimeout(() => {
                        this.uploadProgress = 0;
                      }, 2000);
                  }
                  if (event instanceof HttpResponse) {
                    if (event.body.statusCode === 201) {
                      this.fileUploadS3Data = event.body.data;

                      for (const d of this.formColumns) {
                        if (this.Data) {
                          if (d.type === "file" && d.name === fieldName) {
                            if (!this.uploadedFiles) this.uploadedFiles = {};

                            if (!this.uploadedFiles[d.name])
                              this.uploadedFiles[d.name] = [];

                            this.fileUploadS3Data.map((files) => {
                              this.uploadedFiles[d.name].push(files);
                            });
                          }
                        } else {
                          if (d.type === "file" && d.name === fieldName) {
                            if (!this.uploadedFiles) this.uploadedFiles = {};

                            if (!this.uploadedFiles[d.name])
                              this.uploadedFiles[d.name] = [];

                            this.fileUploadS3Data.map((files) => {
                              this.uploadedFiles[d.name].push(files);
                            });
                          }
                        }

                        if (this.viewFlag) {
                          if (d.type === "file" && d.name === fieldName) {
                            let temp = {};
                            temp[d.name] = this.uploadedFiles[d.name];

                            this.tableService
                              .updateDynamicFormData(
                                this.Data._id,
                                this.tableName,
                                temp,
                                this.tableId
                              )
                              .subscribe((res: any) => {
                                if (res.statusCode == 200) {
                                  this.toastrService.success(
                                    "Done",
                                    "File Added Successfully!"
                                  );
                                  this.loading = false;

                                }
                              });
                          }
                        }
                      }
                    }
                  }
                  this.buttonDisable = true;
                },
                (err) => {
                  this.uploadProgress = 0;
                }
              );
          } else {
            this.uploadProgress = 0;
          }
        }
      }
    }
  }

  onSelectedFile(event: any, fieldName) {
    this.droppedFiles = {};
    this.buttonDisable = false;
    this.droppedFiles[fieldName] = [];
    for (let i = 0; i < event.target.files.length; i++) {
      this.droppedFiles[fieldName].push(event.target.files[i]);
    }
    this.onDroppedFile(this.droppedFiles[fieldName], fieldName);
  }

  updatedVal(e, value) {
    if (e && e.length) {
      this.editData[value] = [];
      e.forEach((element) => {
        if (element && !element.$ngOptionLabel) {
          this.editData[value].push(element);
        }
      });
    }
    this.count++;
    this.checkData = false;
    if (e && e.length >= 0) {
      this.checkData = true;
    }
  }

  compareFn(c11: any, c22: any): boolean {
    return c11 && c22 ? c11.id === c22.id : c11 === c22;
  }

  onSelectionChange(data1, field, index?) {
    let lookupfields = this.form.filter((f) => f.type == "lookup");
    if (lookupfields && lookupfields.length) {
      lookupfields.forEach((element, i) => {
        if (element.isReference) {
          this.lookupArray.push(element);
        }
      });
    }

    if (this.lookupArray && this.lookupArray.length) {
      this.lookupArray.forEach((ele) => {
        this.lookupTableName.push(ele.lookupTableName);
        this.otherDataKeys.push({
          name: ele.name,
          value: ele.lookupTableFieldNames,
        });
      });
    }

    if (field.mappedFields) {
      let keys = Object.keys(field.mappedFields);
      if (keys && keys.length) {
        keys.forEach((ele) => {
          this.valueToMapInField = field.mappedFields[ele];
          let mappedFieldIndex = this.formColumns.findIndex(
            (v) => v.name == this.valueToMapInField
          );
          if (mappedFieldIndex > -1) {
            if (
              this.formColumns[mappedFieldIndex].type == "lookup" &&
              this.formColumns[mappedFieldIndex].isReference
            ) {
              if (data1[0].id) {
                this.tableService
                  .getDynamicTreeDataById(field.lookupTableName, data1[0].id)
                  .pipe(takeUntil(this.destroy$))
                  .subscribe((res: any) => {
                    if (res.data) {
                      let mainField = res.data.lookup.filter((all) => all[ele]);

                      if (mainField && mainField.length) {
                        if (mainField[0].lookupId) {
                          this.tableService
                            .getDynamicTreeDataById(
                              mainField[0].lookupTableName,
                              mainField[0].lookupId
                            )
                            .pipe(takeUntil(this.destroy$))
                            .subscribe((response: any) => {
                              if (response.data) {
                                this.getReferList(
                                  response,
                                  this.formColumns[mappedFieldIndex].name,
                                  response.data._id
                                );
                              }
                            });
                        }
                      }
                    }
                  });
              }
            } else {
              // this.codeBeforeForEachInOnSelectionChange(field, data1, index);
            }
          }
        });
      }
    } else {
      // this.codeBeforeForEachInOnSelectionChange(field, data1, index);
    }

    this.codeBeforeForEachInOnSelectionChange(field, data1, index);
    this.dependenciesCheck();

    //Check if filter => call api for that field and set filtered opetions.
    this.formColumns.forEach((element) => {
      if (element.filters && element.filters.length) {
        let filter = [...element.filters];
        filter.forEach((f) => {
          if (f.baseTableField === field.name) {
            this.dynamicSearchForLoadAsDropDown(element);
          }
        });
      }
    });
  }

  codeBeforeForEachInOnSelectionChange(field, data1, index) {
    field.showOption = false;
    this.lookupValue[field.name] = [];
    this.arrayForLookup = [];
    if (index && !data1.length) {
      // Cancel clicked.
      this.removeText(field.name);
    } else {
      let name = field.name;
      if (data1 && !data1[0]) {
        let obj = data1;
        data1 = [];
        data1.push(obj);
      }
      this.onSelectionChangeForEachCode(data1, name, field);
      this.arrayForLookup = this.lookupValue[field.name];
    }
  }

  onSelectionChangeForEachCode(data1, name, field) {
    data1.forEach((data, index) => {
      if (data && data.id) {
        this.tableService
          .getDynamicTreeDataById(field.lookupTableName, data.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe((res: any) => {
            if (res.statusCode === 200) {
              let listData = this.formColumns.filter((item) => {
                if (item.name == name) {
                  return true;
                } else {
                  return false;
                }
              });

              //show Error Message for Lookup
              let process = this.showErrorMessage(listData, res.data);

              if (process) {
                if (listData && listData.length && listData[0].mappedFields) {
                  let keys = Object.keys(listData[0].mappedFields);
                  if (keys && keys.length) {
                    keys.forEach((ele) => {
                      this.valueToMapInField = listData[0].mappedFields[ele];
                      let mappedFieldIndex = this.formColumns.findIndex(
                        (v) => v.name == this.valueToMapInField
                      );
                      if (mappedFieldIndex > -1) {
                        if (this.formColumns[mappedFieldIndex].type == "lookup") {
                          if (res.data["lookup"] && res.data["lookup"].length) {
                            let lookupList = res.data["lookup"].filter(
                              (element) => (element.lookupDataName == ele || element.lookupName == ele)
                            );
                            if (lookupList && lookupList.length) {
                              this.userFields[this.valueToMapInField] =
                                lookupList[0].lookupId;
                              let temp = [];
                              temp = lookupList[0][ele];
                              if (temp && temp.length) {
                                temp.shift();
                              }
                              let obj = {
                                id: lookupList[0].lookupId,
                                value: temp.toString().replace(/,/g, " "),
                              };
                              if (
                                this.filteredOptions[this.valueToMapInField] &&
                                this.filteredOptions[this.valueToMapInField]
                                  .length
                              ) {
                                this.filteredOptions[this.valueToMapInField].push(
                                  obj
                                );
                              } else {
                                this.filteredOptions[this.valueToMapInField] = [];
                              }
                              this.lookupDataObject[this.valueToMapInField] = [];
                              this.lookupValue[this.valueToMapInField] = [];
                              this.editData[this.valueToMapInField][index] = {};
                              this.lookupValue[this.valueToMapInField] = [
                                ...this.lookupValue[this.valueToMapInField],
                                {
                                  ...obj,
                                },
                              ];
                              this.editData[this.valueToMapInField][index] = {
                                ...obj,
                              };
                              this.editData[this.valueToMapInField][index].name =
                                obj.value;
                              this.dynamicForm.patchValue({
                                [this.valueToMapInField]: lookupList[0].lookupId,
                              });
                            }
                          }
                        } else {
                          if (res.data[ele] && res.data[ele] != "") {
                            this.userFields[this.valueToMapInField] =
                              res.data[ele];
                            this.dynamicForm.patchValue({
                              [this.valueToMapInField]: res.data[ele],
                            });

                            this.dynamicForm.value[this.valueToMapInField] = res.data[ele];
                          }
                        }
                      }

                      this.visibilityFieldsFn();
                    });
                  }
                }

                if (data && data.id && data.value) {
                  if (!this.lookupObj[name]) {
                    this.lookupObj[name] = [];
                  }
                  this.lookupObj[name].push(data);
                  this.removeLookup[name] = true;
                  for (const d of this.formColumns) {
                    if (d.type === "lookup" && name === d.name) {
                      if (d.loadAsDropDown) {
                        const displayValue = d.options.filter((f) => f[0] === data.id);
                        if (displayValue.length > 0) {
                          const temp: [] = Object.assign([], displayValue[0]);
                          temp.shift();
                          this.lookupValue[name] = [
                            ...this.lookupValue[name],
                            {
                              id: data.id,
                              value: temp.toString().replace(/,/g, " "),
                            },
                          ];
                        } else {
                          d.options.forEach((el) => {
                            if (data.id === el[0]) {
                              this.lookupValue[name] = [
                                ...this.lookupValue[name],
                                {
                                  id: data.id,
                                  value: el[1],
                                },
                              ];
                            }
                          });
                        }
                      } else {
                        if (
                          this.lookupValue[name].findIndex((f) => f.id == data.id) == -1
                        ) {
                          this.lookupValue[name] = [
                            ...this.lookupValue[name],
                            { id: data.id, value: data.value },
                          ];
                        }
                      }
                      this.editData[name] = this.editData[name].concat(
                        this.lookupValue[name]
                      );

                      // Filterout only unique values
                      this.editData[name] = this.editData[name].filter(
                        (ob, i, arr) => arr.findIndex((t) => t.id === ob.id) === i
                      );

                      // Filterout only unique values
                      this.lookupValue[name] = this.lookupValue[name].filter(
                        (ob, i, arr) => arr.findIndex((t) => t.id === ob.id) === i
                      );
                      this.lookupValue[name] = [...this.lookupValue[name]];

                      this.editData[name].forEach((element) => {
                        if (element.name) {
                          element.name = element.value;
                        }
                      });
                    }
                  }
                  this.lookupFieldRequired[name] = false;
                }
              }

              this.evalExpressionForFormulaField();

            }
          });
      }


    });


  }

  onDelete(event, fieldName) {
    // delete file from FileList
    if (this.uploadedFiles && this.uploadedFiles[fieldName]) {
      this.uploadedFiles[fieldName].splice(event, 1);
    }
  }

  removeText(value, isLoadAsDropDown?) {
    this.count++;
    this.lookupValue[value] = [];
    this.lookupObj[value] = [];
    this.removeLookup[value] = false;
    if (isLoadAsDropDown) {
      this.filteredOptions[value] = [];
    }
    this.dependenciesCheck();
  }

  submit() {
    this.formSubmitted = true;

    try {
      let invalid = false;
      let tempCheck = this.formColumns.filter((ele) => {
        if (ele.type == "checkbox") return true;
      });

      let textFields = this.formColumns.filter((ele) => {
        if (this.inputFields.includes(ele.type) || ele.type == 'area') return true;
      })

      if (tempCheck && tempCheck.length) {
        for (const item of tempCheck) {
          if (
            !this.dynamicForm.value[item.name].includes(true) &&
            item.isRequired &&
            this.show[item.name]
          ) {
            this.toastrService.danger(
              "Required Field is Missing",
              `${item.label} is Missing`
            );
            invalid = true;
            break;
          }
        }
        if (invalid) return;
      }

      if (textFields && textFields.length) {
        for (const item of textFields) {
          if (
            this.dynamicForm.value[item.name] == "" &&
            item.isRequired &&
            this.show[item.name]
          ) {
            this.toastrService.danger(
              "Required Field is Missing",
              `${item.label} is Missing`
            );
            invalid = true;
            break;
          }
        }
        if (invalid) return;
      }

      let tempCheckForDataTime;
      let tempCheckForDate;

      this.formColumns.forEach((ele) => {
        if (ele.type == "dateTime") tempCheckForDataTime = ele;
        if (ele.type == "date") tempCheckForDate = ele;
      });

      if (tempCheckForDataTime && tempCheckForDataTime.length) {
        for (const item of tempCheckForDataTime) {
          if (
            this.dynamicForm.value[item.name] == "" &&
            item.isRequired &&
            this.show[item.name]
          ) {
            this.dateTimeErrorFlag = true;
            this.toastrService.danger(
              "Required Field is Missing",
              `${item.label} is Missing`
            );
            invalid = true;
            break;
          }
        }
        if (invalid) return;
      }

      if (tempCheckForDate && tempCheckForDate.length) {
        for (const item of tempCheckForDate) {
          if (
            (this.dynamicForm.value[item.name] == "" ||
              this.dynamicForm.value[item.name] == null) &&
            item.isRequired &&
            this.show[item.name]
          ) {
            this.dateErrorFlag = true;
            this.toastrService.danger(
              "Required Field is Missing",
              `${item.label} is Missing`
            );
            invalid = true;
            break;
          }
        }
        if (invalid) return;
      }

      if (this.dynamicForm.invalid) {
        for (const [key, value] of Object.entries(this.dynamicForm.controls)) {
          let dateVal = false;
          if (tempCheckForDate && tempCheckForDate.length) {
            tempCheckForDate.forEach((element) => {
              if (element.name == key) {
                dateVal = true;
                return;
              }
            });
          }
          if (!dateVal) {
            if (value.invalid && this.show[key]) {
              this.toastrService.danger(
                "Required Field is Missing",
                `${key} is Missing`
              );
              invalid = true;
              break;
            }
          }
        }
        if (invalid) return;
      }

      this.loading = true;
      if (
        this.recordType != null &&
        this.recordType != "" &&
        this.recordTypeFieldName
      ) {
        this.dynamicForm.value[this.recordTypeFieldName] = this.recordType;
      }
      if (this.recordType && this.recordTypeName) {
        this.dynamicForm.value[this.recordTypeName] = this.recordType;
      }
      if (this.taskStatus && !this.dynamicForm.value.status) {
        this.dynamicForm.value.status = this.taskStatus;
      }

      let total_files = 0,
        temp = {};
      for (const data of this.formColumns) {
        if (data.isPhone) {
          let phoneVar = this.dynamicForm.value[data.name];
          if (String(phoneVar).includes("-")) {
            phoneVar = String(phoneVar).split("-").join("");
          }
          if (String(phoneVar).includes(" ")) {
            phoneVar = String(phoneVar).split(" ").join("");
          }
          if (String(phoneVar).includes("(")) {
            phoneVar = String(phoneVar).split("(").join("");
          }
          if (String(phoneVar).includes(")")) {
            phoneVar = String(phoneVar).split(")").join("");
          }
          this.dynamicForm.value[data.name] = phoneVar;
        }

        if (
          data.type === "lookup" &&
          data.isRequired === true &&
          !this.lookupValue[data.name].length &&
          this.show[data.name]
        ) {
          this.lookupFieldRequired[data.name] = true;
          this.loading = false;
          this.tableData = [];
          this.toastrService.danger("Please select all fields", `${data.label} is Missing`);
          this.loading = false;
          return;
        } else if (
          data.type === "lookup" &&
          data.isRequired === true &&
          this.lookupValue[data.name].length
        ) {
          this.lookupFieldRequired[data.name] = false;
        }

        if (data.type === "lookup") {
          this.lookTable = data.lookupTableName;
          let temp = {};
          temp = this.dynamicForm.value;
          this.editData[data.name].forEach((element) => {
            if (element["id"]) {
              if (this.tableData && this.tableData.length) {
                let ind = this.tableData.findIndex(
                  (v) => v.lookupId == element["id"]
                );
                if (ind < 0) {

                  let obj = {
                    lookupTableName: this.lookTable,
                    lookupId: element["id"],
                    lookupName: data.name,
                  }
                  if (this.fromBreadCrump && this.lookTable == this.parentTableName) {
                    obj['parentStatus'] = this.parentStatus
                  }
                  this.tableData.push(obj);
                }
              } else {

                let obj = {
                  lookupTableName: this.lookTable,
                  lookupId: element["id"],
                  lookupName: data.name,
                }

                if (this.fromBreadCrump && this.lookTable == this.parentTableName) {
                  obj['parentStatus'] = this.parentStatus
                }
                this.tableData.push(obj);
              }
            }
            if (!this.removeLookup[data.name] && element["id"]) {

              let obj = {
                lookupTableName: this.lookTable,
                lookupId: element["id"],
                lookupName: data.name,
              }
              if (this.fromBreadCrump && this.lookTable == this.parentTableName) {
                obj['parentStatus'] = this.parentStatus
              }
              this.tableData.pop(obj);
            }
          });
          temp["lookup"] = this.tableData;
        }
        if (
          data.type === "file" &&
          this.showArrayData &&
          this.showArrayData[data.name] &&
          this.showArrayData[data.name].length > 0 &&
          this.isUpload === true
        ) {
          //  check file upload exists or not
          total_files += this.showArrayData[data.name].length;
        }

        temp = this.dynamicForm.value;
        if (data.type === "file" && this.uploadedFiles) {
          temp[data.name] = this.uploadedFiles[data.name];
        }

        if (data.type === "time" && this.dynamicForm.value[data.name]) {
          const relatedDateField = this.formColumns
            .filter((field) => field._id === data.relatedDate)
            .map((field) => field.name);
          if (relatedDateField && relatedDateField.length > 0) {
            const time = this.dynamicForm.value[data.name];
            const relatedDate = this.dynamicForm.value[relatedDateField[0]];
            const hours = time.split(":");
            const datetime = new Date(relatedDate);
            this.dynamicForm.value[relatedDateField[0]] = new Date(
              datetime.setHours(hours[0], hours[1], 0)
            );
          }
        }

        if (data.type == "checkList") {
          let checkArray = cloneDeep(this.checkListArray);

          checkArray.forEach((ele) => {
            ele[ele.name] = ele.value;
            delete ele["name"];
            delete ele["value"];
          });

          temp[data.name] = checkArray;
        }

        if (data.type === "number") {
          let fractionPoint = data.fraction;
          if (fractionPoint > 0) {
            temp[data.name] = Number(temp[data.name]).toFixed(fractionPoint);
          } else {
            if (!(Number(temp[data.name]) % 1 != 0)) {
              fractionPoint = 2;
              temp[data.name] = Number(temp[data.name]).toFixed(fractionPoint);
            }
          }
        }

        if (data.name === "activateDate" && !temp[data.name]) {
          temp[data.name] = moment(new Date()).format('YYYY-MM-DD');
        }
        if (data.type === "date" && temp[data.name]) {
          temp[data.name] = moment(temp[data.name]).format('YYYY-MM-DD');
        }
        if (data.name === "relatedToRecord")
          temp[data.name] = this.tableId
        if (data.name === "relatedToTable")
          temp[data.name] = this.tableName
      }

      if (this.list && this.list.length) {
        this.list.forEach((element) => {
          element.value.forEach((item) => {
            if (this.tableData && this.tableData.length) {
              let ind = this.tableData.findIndex(
                (v) => v.lookupId == element["id"]
              );
              if (ind < 0 && item.id) {

                let obj = {
                  lookupTableName: element.lookupTableName,
                  lookupId: item.id,
                  lookupName: element.name,
                }

                if (this.fromBreadCrump && element.lookupTableName == this.parentTableName) {
                  obj['parentStatus'] = this.parentStatus;
                }
                this.tableData.push(obj);
              }
            } else {

              if (item.id) {
                let obj = {
                  lookupTableName: element.lookupTableName,
                  lookupId: item.id,
                  lookupName: element.name,
                }

                if (this.fromBreadCrump && element.lookupTableName == this.parentTableName) {
                  obj['parentStatus'] = this.parentStatus;
                }
                this.tableData.push(obj);
              }

            }
          });
        });
      }

      temp["lookup"] = this.tableData;
      temp["subscribers"] = this.subscribers;
      this.loading = true;

      let validation = {}; // To check if validation will be applied or not on specific sub-form row
      let validationLookup = {}; // Validation for lookup array

      if (this.subFormDataValues) {
        for (const form of Object.keys(this.subFormDataValues)) {
          validation[form] = [];
          validationLookup[form] = [];
          let i = 0;

          for (const field of this.subFormDataValues[form]) {
            validation[form].push(false);
            validationLookup[form].push(false);
            for (const [key, value] of Object.entries(field.controls)) {
              // Check for validation will apply or not..
              for (let [k, val] of Object.entries(field.value)) {
                if (val) {
                  let fields1 = this.subFormDataDataFields[form][0].fields
                    ? this.subFormDataDataFields[form][0].fields
                    : this.subFormDataDataFields[form][0];
                  let getField = fields1.filter(
                    (f) => f.name == k && f.type == "checkbox"
                  );
                  if (getField.length) {
                    let data = getField[0];
                    let value = val as any;
                    if (!value.includes(true)) {
                    } else {
                      validation[form][i] = true;
                      validationLookup[form][i] = true;
                    }
                  } else {
                    validation[form][i] = true;
                    validationLookup[form][i] = true;
                  }
                } else {
                  validation[form][i] = true;
                  validationLookup[form][i] = true;
                }
              }

              // Check validation for lookup.
              if (
                this.subFormLookupFields[form] &&
                this.subFormLookupFields[form][i] &&
                !validationLookup[form][i] &&
                this.subFormLookupData
              ) {
                for (let [lookupKey, itm] of Object.entries(
                  this.subFormLookupData[form][i]
                )) {
                  let itm1 = itm as any;
                  if (itm1.length) {
                    validationLookup[form][i] = true;
                  }
                }
              }

              // If validation is applied.
              if (validation[form][i] || validationLookup[form][i]) {
                let val = value as any;
                if (val.invalid) {
                  this.toastrService.danger(
                    "Required Fields Are Missing",
                    "Sub-Form Error"
                  );
                  this.loading = false;
                  break;
                }
              }
            }
            i++;
          }
          if (!this.loading) return;
        }
      }

      //append values of subForm to dynamicForm (in temp variable)
      temp["subForm"] = [];
      for (const form of Object.keys(this.subFormDataValues)) {
        if (this.showSubForm[form]) {
          let tempSubForm = [];
          let i = 0;

          for (const field of this.subFormDataValues[form]) {
            let appendObj = {};
            for (const [key, value] of Object.entries(field.controls)) {
              let val = value as any;
              if (val.value && val.value["dateTime"] == "") {
                this.toastrService.danger(
                  "Required Fields Are Missing",
                  "Sub-Form Error"
                );
                this.loading = false;
                return;
              }

              if (
                this.subFormDataDataFields[form] &&
                this.subFormDataDataFields[form].length &&
                this.subFormDataDataFields[form][0]
              ) {
                let fields1 = this.subFormDataDataFields[form][0].fields
                  ? this.subFormDataDataFields[form][0].fields
                  : this.subFormDataDataFields[form][0];

                let getField = fields1.filter((f) => f.name == key);
                if (getField.length) {
                  let data = getField[0];

                  // For checkbox
                  if (data.type === "checkbox") {
                    // copy = [...val.value];
                    if (typeof val.value == "string") {
                    } else {
                      val.value = val.value
                        .map((checked, i) => (checked ? data.options[i] : null))
                        .filter((v) => v !== null)
                        .join(",");
                    }

                    if (!val.value && data.isRequired) {
                      this.toastrService.danger(
                        `${data.label} is Missing`,
                        "Sub-Form Error"
                      );
                      // field.value[data.name] = copy;
                      this.loading = false;
                    }
                  }
                  if (!this.loading) {
                    return;
                  }

                  // For file
                  if (
                    data.type === "file" &&
                    this.showArrayData &&
                    this.showArrayData[data.name] &&
                    this.showArrayData[data.name].length > 0 &&
                    this.isUpload === true
                  ) {
                    // Check file upload exists or not
                    total_files += this.showArrayData[data.name].length;
                  }

                  if (data.type == "status") {
                  }

                  if (data.type === "file" && this.uploadedFiles) {
                    appendObj[key] = this.uploadedFiles[data.name];
                  }

                  if (data.type === "time" && val.value) {
                    const relatedDateField = this.subFormDataDataFields[
                      form
                    ][0].fields
                      .filter((field) => field._id === data.relatedDate)
                      .map((field) => field.name);
                    if (relatedDateField && relatedDateField.length > 0) {
                      const time = val.value;
                      const relatedDate = val.value[relatedDateField[0]];
                      const hours = time.split(":");
                      const datetime = new Date(relatedDate);
                      val.value[relatedDateField[0]] = new Date(
                        datetime.setHours(hours[0], hours[1], 0)
                      );
                    }
                  }
                }
              }

              if (!appendObj[key] && val.value) {
                appendObj[key] = val.value;
              }
            }

            //remove rollup, formula, injectSubForm fields.
            for (let col of Object.keys(appendObj)) {
              let fields1 = this.subFormDataDataFields[form][0].fields
                ? this.subFormDataDataFields[form][0].fields
                : this.subFormDataDataFields[form][0];
              let list = fields1.filter(
                (f) =>
                  f.name == col &&
                  (f.type == "rollUp" ||
                    f.type == "formula" ||
                    f.type == "injectSubForm")
              );
              if (list && list.length) {
                delete appendObj[col];
              }
            }
            if (validation[form][i]) tempSubForm.push({ ...appendObj });
            i++;
          }
          temp["subForm"].push({ [form]: [...tempSubForm] });
        }
      }

      // For sub-form lookups
      for (let [key, lookup] of Object.entries(this.subFormLookupFields)) {
        let idxOfLookup = 0;
        for (let [k, form] of Object.entries(lookup)) {
          let f = form as any;
          this.tableData = [];

          // Setting validation array for lookups
          for (let [lookupKey, itm] of Object.entries(
            this.subFormLookupData[key][k]
          )) {
            let itm1 = itm as any;
            if (itm1.length) {
              validationLookup[key][k] = true;
            }
          }

          for (let data of f) {
            // If validation is not applied
            if (validationLookup[key][k] || validation[key][k]) {
              if (
                data.type === "lookup" &&
                data.isRequired === true &&
                !this.subFormLookupData[key][k][data.name].length
              ) {
                this.subFormLookupFieldRequired[key][data.name] = true;
                this.loading = false;
                this.tableData = [];
                this.toastrService.danger(
                  `${data.label} is Missing`,
                  "Sub-Form Error"
                );
                this.loading = false;
                return;
              } else if (
                data.type === "lookup" &&
                data.isRequired === true &&
                this.subFormLookupData[key][k][data.name].length
              ) {
                this.subFormLookupFieldRequired[key][data.name] = false;
              }

              if (data.type === "lookup") {
                this.lookTable = data.lookupTableName;
                this.subFormEditData[key][k][data.name].forEach((element) => {
                  if (element["id"]) {
                    if (this.tableData && this.tableData.length) {
                      let ind = this.tableData.findIndex(
                        (v) => v.lookupId == element["id"]
                      );
                      if (ind < 0) {
                        this.tableData.push({
                          lookupTableName: this.lookTable,
                          lookupId: element["id"],
                          lookupName: data.name,
                        });
                      }
                    } else {
                      this.tableData.push({
                        lookupTableName: this.lookTable,
                        lookupId: element["id"],
                        lookupName: data.name,
                      });
                    }
                  }
                  if (!this.subFormRemoveLookup[key][k][data.name]) {
                    this.tableData.pop({
                      lookupTableName: this.lookTable,
                      lookupId: element["id"],
                      lookupName: data.name,
                    });
                  }
                });
              }
              let index = 0;
              for (let f of temp["subForm"]) {
                let a = Object.keys(f);
                let id = a.indexOf(key);
                if (id > -1) break;
                index++;
              }

              // If index is not available, setting empty value for that index
              if (!temp["subForm"][index][key][idxOfLookup]) {
                temp["subForm"][index][key][idxOfLookup] = {};
              }
              if (!temp["subForm"][index][key][idxOfLookup]["lookup"]) {
                temp["subForm"][index][key][idxOfLookup]["lookup"] = [];
              }

              // Set value in temp subForm lookup array
              temp["subForm"][index][key][idxOfLookup]["lookup"] =
                this.tableData;
            }
          }
          idxOfLookup++;
        }
      }

      //Set sub-form values in temp variable for create/edit.
      this.formColumns.forEach((ele) => {
        if (ele.type == "injectSubForm") {
          if (!this.show[ele.name]) {
            this.subFormLookupIds.forEach((element) => {
              if (ele.subFormTableName == element.tableName) {
                // delete temp["subForm"][element.lookupName];
                temp["subForm"].forEach((item, i) => {
                  let key = Object.keys(item);
                  if (key[0] == element.lookupName) {
                    temp["subForm"].splice(i, 1);
                  }
                });
              }
            });
          }
        }
      });

      for (const data of this.formColumns) {
        if (data.type === "checkbox") {
          this.dynamicForm.value[data.name] = this.dynamicForm.value[data.name]
            .map((checked, i) => (checked ? data.options[i] : null))
            .filter((v) => v !== null)
            .join(",");
        }
      }

      //Remove rollup and formula fields..
      for (let key of Object.keys(temp)) {
        let isExists = this.formColumns.filter(
          (f) =>
            f.name == key &&
            (f.type == "rollUp" ||
              f.type == "formula" ||
              f.type == "injectSubForm")
        );
        if (isExists && isExists.length) {
          delete temp[key];
        }
      }

      if (this.Data == null || this.isForceSave) {
        if (this.isUpload === false) {
          this.dynamicForm.value.file = [];
          this.showArrayData = {};
        }
        if (this.isForceSave) {
          temp["file"] = this.Data.file;
        }
        let $Table = this.dynamicForm.value;
        let $Form = this.dynamicForm.value;
        let customValidateLogic = false;
        if (this.customValidations && this.customValidations.length) {
          this.customValidations.forEach((element) => {
            if (!eval(element[0].jstextArea)) {
              customValidateLogic = true;
              this.toastrService.danger(
                element[0].errorMessage,
                "Custom Validate Error"
              );
              temp = {};
              this.tableData = [];
              return;
            }
          });
        }

        if (!customValidateLogic) {
          this.tableService
            .saveDynamicFormData(temp, this.tableName, this.tableId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res: any) => {
                if (res.statusCode === 201) {
                  //-- Add user lookup to watcher
                  let id = this.dynamicFormService.userLookupChangedWatchers(
                    res.data
                  );
                  if (id && id.length) {
                    id.forEach((ele) => {
                      this.activateSubscription(ele, res.data._id, false);
                    });
                  }
                  if (this.continueFlag) {
                    this.toastrService.success(res.message, "Success");
                    this.dynamicForm.reset();
                    const element = document.getElementById("main_body");
                    element.classList.remove("add-edit-client-form");
                    this.loading = false;
                    this.ref.close({
                      close: "yes",
                      data: res.data,
                      continue: this.continueFlag,
                      fromRecordPage: this.fromRecordPage,
                    });
                  } else {
                    if (this.activeAction && this.activeAction.length) {
                      this.activeAction.forEach((element) => {
                        if (element.onSave == "yes") {
                          this.onActionsClick("", temp, temp, element);
                        }
                      });
                    }
                    this.toastrService.success(res.message, "Success");
                    if (this.tableName === "TableFolder") {
                      this.getFolderList()
                    }
                    this.dynamicForm.reset();
                    const element = document.getElementById("main_body");
                    element.classList.remove("add-edit-client-form");
                    this.loading = false;
                    this.ref.close({ close: "yes", data: res.data });
                  }
                } else {
                  this.loading = false;
                  this.toastrService.danger(res.message, "Error");
                }
              },
              (error) => {
                this.toastrService.danger(
                  `${error.error && error.error.message}`,
                  "Error"
                );
                this.loading = false;
              }
            );
        } else this.loading = false;
      } else {
        let $Form = this.dynamicForm.value;
        let $Table = this.Data;
        let customValidateLogic = false;
        if (this.customValidations && this.customValidations.length) {
          this.customValidations.forEach((element) => {
            if (!eval(element[0].jstextArea)) {
              customValidateLogic = true;
              this.toastrService.danger(
                element[0].errorMessage,
                "Custom Validate Error"
              );
              temp = {};
              this.tableData = [];
              return;
            }
          });
        } else {
          this.tableData = [];
        }

        if (!customValidateLogic) {
          if (temp["phoneNumber"]) {
            temp["phoneNumber"] = temp["phoneNumber"]
              .replace(/-/gi, "")
              .replace("(", "")
              .replace(")", "");
          }

          this.tableService
            .updateDynamicFormData(
              this.Data._id,
              this.tableName,
              temp,
              this.tableId
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res: any) => {
                if (res.statusCode === 200) {
                  // if (this.tableName == "Call Log") {
                  //   if (temp['callType'] != "Active Call") {
                  //     this.socketService.emit("delete_record", temp)
                  //   } else {
                  //     temp['activeData'] = 1
                  //     this.socketService.emit("delete_record", temp)
                  //   }
                  // }

                  let subObjRem = [];
                  if (this.Data["refSubForm"] && this.Data["refSubForm"].length) {
                    this.Data["refSubForm"].forEach(element => {
                      let key = Object.keys(element);
                      if (key && key.length) {
                        key.forEach(val => {
                          if (element[val] && element[val].length) {

                            element[val].forEach(item => {
                              if (temp["subForm"] && temp["subForm"].length) {

                                temp["subForm"].forEach(element1 => {
                                  let keyInt = Object.keys(element1);

                                  if (val == keyInt[0]) {
                                    if (keyInt && keyInt.length) {
                                      keyInt.forEach(val1 => {
                                        if (element1[val1] && element1[val1].length) {
                                          let ind = element1[val1].find(v => v._id == item._id);
                                          if (ind) {

                                          } else {
                                            subObjRem.push({ id: item._id, tableName: val.split('|')[1] });
                                          }
                                        }
                                      })
                                    }
                                  }

                                });
                              }
                            });



                          }
                        })
                      }
                    });
                  }

                  console.log(subObjRem);

                  if (subObjRem && subObjRem.length) {
                    for (let i = 0; i < subObjRem.length; i++) {
                      this.deleteSubFormIndividual(subObjRem[i].id, subObjRem[i].tableName);
                    }
                  }

                  let id =
                    this.dynamicFormService.userLookupChangedWatchers(temp);
                  if (id && id.length) {
                    id.forEach((ele) => {
                      this.activateSubscription(ele, this.Data._id, false);
                    });
                  }

                  if (this.continueFlag) {
                    this.toastrService.success(res.message, "Success");
                    this.dynamicForm.reset();
                    const element = document.getElementById("main_body");
                    element.classList.remove("add-edit-client-form");
                    // this.refreshData(this.Data);
                    this.loading = false;
                    this.ref.close({
                      close: "yes",
                      data: temp,
                      id: this.id,
                      continue: this.continueFlag,
                      fromRecordPage: this.fromRecordPage,
                    });
                  } else {
                    if (this.actions && this.actions.length) {
                      this.actions.forEach((element) => {
                        if (element.onSave == "yes") {
                          this.onActionsClick("", this.Data, temp, element);
                        }
                      });
                    }
                    this.toastrService.success(res.message, "Success");
                    this.dynamicForm.reset();
                    const element = document.getElementById("main_body");
                    element.classList.remove("add-edit-client-form");
                    // if (!this.inlineView) {
                    //   this.backToView();
                    // }

                    // this.refreshData(this.Data);
                    if (this.tableName == 'Users') {
                      let userData = JSON.parse(localStorage.getItem('userData'));
                      localStorage.removeItem('userData');
                      userData['lookup'][0] = temp['lookup'].find(v => v.lookupTableName == 'Roles');
                      localStorage.setItem('userData', JSON.stringify(userData));
                    }
                    this.loading = false;
                    this.ref.close({ close: "yes", data: temp, id: this.id });
                  }
                } else {
                  this.loading = false;
                  this.toastrService.danger(res.message, "Error");
                }
              },
              (error) => {
                this.toastrService.danger(
                  `${error.error && error.error.message}`,
                  "Error"
                );
                this.loading = false;
              }
            );
        } else this.loading = false;
      }
    } catch (error) {
      this.loading = false;
    }
  }

  async deleteSubFormIndividual(id, tableName) {
    let res: any = await this.tableService.deleteRecordForPromise(id, tableName);
    if (res.statusCode === 200) {

    }

  }

  updateCheckList() {
    let temp = {};
    let data = this.formColumns.find((v) => v.type == "checkList");
    if (data) {
      let checkArray = cloneDeep(this.checkListArray);

      checkArray.forEach((ele) => {
        ele[ele.name] = ele.value;
        delete ele["name"];
        delete ele["value"];
      });

      temp[data.name] = checkArray;

      this.tableService
        .updateDynamicFormData(
          this.Data._id,
          this.tableName,
          temp,
          this.tableId
        )
        .subscribe((res: any) => {
          if (res.statusCode === 200) {
            this.toastrService.success("CheckList updated", "Success");
          }
        });
    }
  }

  onDependentFieldChanged(
    event,
    fieldId,
    type,
    fieldName,
    section,
    index,
    field?
  ) {
    let value,
      isChecked = true;
    if (type === "checkbox") {
      this.formColumns.filter((ele) => {
        if (ele.name == fieldName) ele.checkBoxValidateForDynamicForm = false;
        value = event.currentTarget.textContent.trim();
        isChecked = event.target.checked;
        if (!this.dynamicForm.value[fieldName].includes(true)) {
          ele.checkBoxValidateForDynamicForm = true;
        }
      });
    } else if (type === "dropdown") {
      value = event.value;
    } else if (type === "gadget") {
      value = event.name;
    } else if (type === "dropdownWithImage") {
      value = event;
    } else if (type === "recordType") {
      value = event.value;
    } else if (type === "radio") {
      value = event;
    }
    console.log("value", value)
    if (field) {
      this.dynamicForm.value[fieldName] = field.oldValue;
    } else {
      this.dynamicForm.patchValue({ [fieldName]: value });
    }
    this.dependsFields.forEach((element) => {
      if (element.visibilityData === fieldId) {
        this.show[element.name] =
          isChecked &&
          element.fieldValuesData.length > 0 &&
          !!element.fieldValuesData.includes(value);

        let arr =
          this.finalArrayForWithoutSection &&
            this.finalArrayForWithoutSection.length
            ? this.finalArrayForWithoutSection
            : this.finalArrayForSection;
        arr.forEach((item) => {
          item.hide = true;
          for (const temp of item.data) {
            if (temp.type == "injectSubForm") {
              item.hide = false;
            } else {
              if (this.show[temp.name]) {
                item.hide = false;
                break;
              }
            }
          }
        });
        this.cssFlagAfterVisibility = true;
      }
    });
    this.evalExpressionForFormulaField();
    this.setFieldsRowLogic();
    setTimeout(() => {
      this.tableService.runCustomStyling();
    }, 10);
  }

  incrCountForCallingPipe() {
    this.count++;
  }

  isStatusUpdatedFromView = false;
  onDone(status, field, index, section) {
    this.colorSetter[field.name] = status.color;

    if (status.labelColor) {
      this.colorForFont = status.labelColor;
    } else {
      this.colorForFont = "";
    }
    this.statuses[field.name] = status.status;
    if (this.dynamicForm.get(field.name))
      this.dynamicForm.patchValue({ [field.name]: status.status });
    else if (this.newDynamicForm.get(field.name))
      this.newDynamicForm.patchValue({ [field.name]: status.status });

    this.dependsFields.forEach((element) => {
      if (element.visibilityData === field._id) {
        this.show[element.name] =
          element.fieldValuesData.length > 0 &&
          !!element.fieldValuesData.includes(status.status);
      }
    });
    this.evalExpressionForFormulaField();

    if (this.viewFlag) {
      let temp = {};
      temp[field.name] = status.status;

      this.Data["statusMain"] = Object.assign({}, temp);
      this.Data["colorSetter"] = Object.assign({}, this.colorSetter);
      this.tableService
        .updateDynamicFormData(this.id, this.tableName, temp, this.tableId)
        .subscribe((res: any) => {
          if (res.statusCode === 200) {
            //  this.refreshData(this.Data);
            this.toastrService.success(
              `${field.label} updated successfully`,
              "Done!"
            );
            this.isStatusUpdatedFromView = true;
          } else {
            this.toastrService.danger(res.message, "Some Error Occured");
          }
        });
    }

    this.setFieldsRowLogic();
  }

  async dynamicSearchCommonFn(field, filterKey, formDataObj, lookupVal, e?) {
    let response = await this.tableService.getDynamicTreeData(
      field.lookupTableName,
      1,
      e ? e.target.value : "",
      0,
      filterKey,
      "",
      "",
      "",
      this.tableName,
      field.name,
      formDataObj,
      lookupVal
    );

    if (response) {
      let res = response as any;
      this.filteredOptions[field.name] = [];
      if (res && res.data && res.data.pageOfItems) {
        res.data.pageOfItems.forEach((data) => {
          let val = "";
          Object.keys(data).sort().forEach((lookupele) => {
            if (
              data[lookupele] &&
              !this.unwantedFieldsInOptions.includes(lookupele)
            ) {
              val = val + " " + data[lookupele];
            }
          });
          const obj = {
            id: data._id,
            value: val,
          };
          this.filteredOptions[field.name] = [
            ...this.filteredOptions[field.name],
            obj,
          ];
        });
        this.loadingAPI = false;
      } else {
        this.loadingAPI = false;
      }
      if (this.filteredOptions[field.name].length) {
        this.dropDownText[field.name] = "Type to search";
      } else {
        this.dropDownText[field.name] = "No record found";
      }
    }
  }

  dynamicSearch(e, field) {
    this.dropDownText[field.name] = "";
    this.loadingAPI = true;
    let filterKey;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      if (field.name === "dependentTask" && field.lookupTableName === "Tasks") {
        filterKey = [{ isSubtask: "No" }];
      } else {
        filterKey = 0;
      }
      if (!e.target.value) {
        this.filteredOptions[field.name] = [];
        this.loadingAPI = false;
        this.dropDownText[field.name] = "Type to search";
      } else {
        let lookupVal = this.lookupValue;
        let formDataObj = {};
        this.formColumns.forEach((e) => {
          if (e.type != "injectSubForm" && e.type != "lookup") {
            formDataObj[e.name] = this.dynamicForm.value[e.name];
          }
        });
        this.dynamicSearchCommonFn(field, filterKey, formDataObj, lookupVal, e);
      }
    }, 800);
  }

  dynamicSearchForLoadAsDropDown(field) {
    this.loadingAPI = true;
    let filterKey;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      if (field.name === "dependentTask" && field.lookupTableName === "Tasks") {
        filterKey = [{ isSubtask: "No" }];
      } else {
        filterKey = 0;
      }
      let lookupVal = this.lookupValue;
      let formDataObj = {};
      this.formColumns.forEach((e) => {
        if (e.type != "injectSubForm" && e.type != "lookup") {
          formDataObj[e.name] = this.dynamicForm.value[e.name];
        }
      });
      this.dynamicSearchCommonFn(field, filterKey, formDataObj, lookupVal);
    }, 800);
  }

  watcherMenuOpened() {
    this.isWatcherOpened = true;
  }

  watcherMenuClosed() {
    this.isWatcherOpened = false;
  }

  selfSubsription() {
    this.isSelfSubscribed = !this.isSelfSubscribed;

    let user;
    this.tableService.$users.subscribe((d: any) => {
      user = d.find(($u) => $u._id == this.currentUser._id);
    });

    if (this.isSelfSubscribed) {
      this.subscribers.push(user);
      this.subscriptionText = "Stop watching";
      this.isSelfSubscribed = true;
      this.activateSubscription();
    } else {
      this.cancelSubscription(this.currentUser._id);
      this.subscriptionText = "Start watching";
      this.isSelfSubscribed = false;
    }

    this.giveMarginToSubscriber();
  }

  cancelSubscription(id) {
    const sub = this.subscribers.findIndex((s) => s._id == id);
    this.subscribers.splice(sub, 1);
    this.chatSubscriptionService
      .cancelSubscription({
        resourceId: this.Data?._id,
        userId: id,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data.statusCode == 200) {
          if (id == this.currentUser._id) {
            this.subscriptionText = "Start watching";
            this.isSelfSubscribed = false;
          }
          this.toastrService.success(data.message, "Action was  completed!");
        }
      });

    this.giveMarginToSubscriber();
  }

  updateSubscribers(subscriber) {
    const checkAlreadyAdded = this.subscribers.findIndex(
      (s) => s._id == subscriber._id
    );
    if (checkAlreadyAdded == -1) {
      this.subscribers.push(subscriber);
      if (this.viewFlag) {
        this.activateSubscription(subscriber._id);
      }
    }
    this.subscribers.forEach((data) => {
      if (data._id == this.currentUser._id) {
        this.subscriptionText = "Stop watching";
        this.isSelfSubscribed = true;
      }
    });

    this.giveMarginToSubscriber();
  }

  @HostListener("window:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode == 27) {
      // if (this.editViewFlag) {
      this.dialogService
        .open(ConfirmDeleteComponent, {
          closeOnEsc: false,
          context: {
            heading: "Are you Sure",
            body: "Are you sure you want to close?",
            btnText: "Close",
          },
        })
        .onClose.subscribe((res) => {
          if (res) {
            console.log("close 2 Action");
            this.ref.close();
          }
          this.modalDialg.dismissAll();
        });
    }
  }

  openLookupModalFnCode(
    resData,
    field,
    val,
    tableColumns,
    subFormLookupIdsForLookupView
  ) {
    if (
      resData &&
      resData[0] &&
      resData[0].columns &&
      resData[0].columns.length
    ) {
      let tempParentTableHeaderForLookup = Object.assign(
        [],
        resData[0].columns
      );
      let recordTypeFieldNameforlookup;
      let recordTypesForlookup = [];
      tempParentTableHeaderForLookup.map((column) => {
        if (column.type == "recordType") {
          recordTypeFieldNameforlookup = column.name;
          column.options.forEach((element) => {
            const obj = {
              title: element,
            };
            recordTypesForlookup.push(obj);
          });
        }
      });
      let dataForDisplay;
      this.tableService
        .getDynamicTreeDataById(field.lookupTableName, val.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: any) => {
          if (res.statusCode === 200) {
            if (res.data) {
              dataForDisplay = res.data;
              if (dataForDisplay) {
                this.loading = false;
                const ref = this.dialogService
                  .open(LookupDetailDialogComponent, {
                    context: {
                      fromFormFlag: true,
                      name: field.name,
                      subFormLookupIds: subFormLookupIdsForLookupView,
                      tempParentTableHeader: tempParentTableHeaderForLookup,
                      recordTypeFieldName: recordTypeFieldNameforlookup,
                      tableData: this.tableDataForForms,
                      tableRecordTypes: this.tableRecordTypes,
                      dataForLookupDetail: dataForDisplay,
                      tableName: field.lookupTableName,
                      tableIdFromForm: field.lookupTableId,
                      resourceIdForForm: val.id,
                      tableColumns: tableColumns,
                    },
                  })
                  .onClose.subscribe((name) => { });
              }
            }
          }
        });
    }
  }

  async openLookupModalForDetail(val, field) {
    if (!field.allowMultipleValues) {
      this.loading = true;

      let index = this.lookupValue[field.name].findIndex((f) => f.id == val.id);
      let data = this.tableDataForForms;
      data = data.filter((item) => item.tableName == field.lookupTableName);
      let tableColumns = data[0]?.columns;
      let subFormLookupIdsForLookupView = data[0]?.subFormLookups;

      if (this.getTableByNameObjectForData[field.lookuptableName]) {
        let resData = this.getTableByNameObjectForData[field.lookuptableName];
        this.openLookupModalFnCode(
          resData,
          field,
          val,
          tableColumns,
          subFormLookupIdsForLookupView
        );
      } else {
        let res: any = await this.tableService.getTableByName(
          field.lookupTableName
        );

        this.openLookupModalFnCode(
          res,
          field,
          val,
          tableColumns,
          subFormLookupIdsForLookupView
        );
      }
    }
  }

  removeSelected(val, field) {
    field.showOption = false;
    let index = this.lookupValue[field.name].findIndex((f) => f.id == val.id);
    if (index > -1) {
      if (index == 0 && this.lookupValue[field.name].length == 1) {
        this.lookupValue[field.name] = [];
        this.editData[field.name] = [];
      } else {
        this.lookupValue[field.name].splice(index, 1);
        this.editData[field.name].splice(index, 1);
      }
      this.editData[field.name] = [...this.editData[field.name]];
      this.lookupValue[field.name] = [...this.lookupValue[field.name]];
    }

    if (field.mappedFields) {
      let keys = Object.keys(field.mappedFields);
      if (keys && keys.length) {
        keys.forEach((ele) => {
          this.valueToMapInField = field.mappedFields[ele];
          let mappedFieldIndex = this.formColumns.findIndex(
            (v) => v.name == this.valueToMapInField
          );
          if (mappedFieldIndex > -1) {
            if (this.formColumns[mappedFieldIndex].type == "lookup") {

              this.lookupValue[this.valueToMapInField] = [];
              this.editData[this.valueToMapInField][index] = {};

              this.dynamicForm.patchValue({
                [this.valueToMapInField]: [],
              });

            } else {
              // if (res.data[ele] && res.data[ele] != "") {
              this.userFields[this.valueToMapInField] =
                null;
              this.dynamicForm.patchValue({
                [this.valueToMapInField]: null,
              });

              this.dynamicForm.value[this.valueToMapInField] = null;
              // }
            }
          }

          this.visibilityFieldsFn();
        });
      }
    }

    field.showOption = true;
  }

  showColor(row, index) {
    let color = "";
    for (let element of row) {
      if (element.type == "status") {
        color = this.colorSetter[element.name];
        return color;
      }
    }
  }

  setRowColorAt1stIndex(rowIndex) {
    let color = "";
    if (rowIndex == 0) {
      color = "white";
      return color;
    }
  }

  showFontColor(row, index) {
    let color = "";
    if (this.sections && this.sections.length) {
      return color;
    } else {
      for (let element of row) {
        if (element.type == "status") {
          color = this.colorForFont;
          return color;
        }
      }
    }
  }

  mapDataFn() {
    if (this.mapData) {
      let keys = Object.keys(this.mapData);
      if (keys && keys.length) {
        keys.forEach((ele) => {
          if (this.mapData[ele] && this.mapData[ele].length) {
            this.mapData[ele].forEach((obj) => {
              if (obj.type) {

                if (obj.type !== "lookup") {
                  obj.value = this.dynamicForm.get(obj.fieldName).value
                    ? this.dynamicForm.get(obj.fieldName).value
                    : "";
                } else {
                  let temp = [];

                  if (this.Data) {
                    this.Data.lookup.forEach((element) => {
                      if (obj.fieldName == element.lookupName) {
                        let obj = {
                          id: element.lookupId,
                          value: element.valueToDisplay ? element.valueToDisplay.join(" ") : '',
                        };

                        temp.push(obj);
                      }
                    });
                  }

                  // if (this.lookupValue[obj.fieldName] && this.lookupValue[obj.fieldName].length) {
                  //   this.lookupValue[obj.fieldName].forEach(element => {
                  //     temp.push(element);
                  //   });
                  // }
                  obj.value = temp;
                }
              }

            });
          }
        });
      }
    }
  }

  addRef(fieldName) {
    if (this.viewFlag) {
      this.viewFlag = false;
      this.editViewFlag = true;
    }

    this.mapDataFn();

    const ref = this.dialogService
      .open(AddRefComponent, {
        context: {
          fieldsData: this.form,
          tableName: this.tableName,
          list: this.list,
          tableDataForForms: this.tableDataForForms,
          mapData: this.mapData,
        },
      })
      .onClose.subscribe((listval) => this.addRefAfterClose(listval));
  }

  addRefAfterClose(listval) {
    if (listval && listval.length) {
      this.list = listval;

      this.list.forEach((loop) => {
        let listData = this.formColumns.filter((item) => {
          if (item.name == loop.name) {
            return true;
          } else {
            return false;
          }
        });
        if (listData && listData.length && listData[0].mappedFields) {
          let keys = Object.keys(listData[0].mappedFields);
          if (keys) {
            keys.forEach((ele) => {
              this.valueToMapInField = listData[0].mappedFields[ele];
              let mappedFieldIndex = this.formColumns.findIndex(
                (v) => v.name == this.valueToMapInField
              );
              if (mappedFieldIndex > -1) {
                if (this.formColumns[mappedFieldIndex].type == "lookup") {
                  if (
                    loop.value[0] &&
                    loop.value[0].val &&
                    loop.value[0].val["lookup"] &&
                    loop.value[0].val["lookup"].length
                  ) {
                    let lookupList = loop.value[loop.value.length - 1].val[
                      "lookup"
                    ].filter((element) => element.lookupName == ele);
                    if (lookupList && lookupList.length) {
                      if (lookupList[0].lookupId) {
                        this.tableService
                          .getDynamicTreeDataById(
                            lookupList[0].lookupTableName,
                            lookupList[0].lookupId
                          )
                          .pipe(takeUntil(this.destroy$))
                          .subscribe((res: any) => {
                            if (res.data) {
                              if (
                                this.formColumns[mappedFieldIndex]
                                  .isReference
                              ) {
                                let lookupfields = this.form.filter(
                                  (f) => f.type == "lookup"
                                );
                                if (lookupfields && lookupfields.length) {
                                  lookupfields.forEach((element, i) => {
                                    if (element.isReference) {
                                      this.lookupArray.push(element);
                                    }
                                  });
                                }

                                if (
                                  this.lookupArray &&
                                  this.lookupArray.length
                                ) {
                                  this.lookupArray.forEach((ele) => {
                                    this.lookupTableName.push(
                                      ele.lookupTableName
                                    );
                                    this.otherDataKeys.push({
                                      name: ele.name,
                                      value: ele.lookupTableFieldNames,
                                    });
                                  });
                                }

                                this.getReferList(
                                  res,
                                  lookupList[0],
                                  lookupList[0]
                                );
                                this.userFields[this.valueToMapInField] =
                                  lookupList[0].lookupId;
                                let obj = {
                                  id: lookupList[0].lookupId,
                                  value: res.data.name,
                                };
                                if (
                                  this.filteredOptions[
                                  this.valueToMapInField
                                  ] &&
                                  this.filteredOptions[
                                    this.valueToMapInField
                                  ].length
                                ) {
                                  this.filteredOptions[
                                    this.valueToMapInField
                                  ].push(obj);
                                } else {
                                  this.filteredOptions[
                                    this.valueToMapInField
                                  ] = [];
                                }
                                this.lookupValue[this.valueToMapInField] =
                                  [];
                                this.lookupValue[this.valueToMapInField] = [
                                  ...this.lookupValue[
                                  this.valueToMapInField
                                  ],
                                  {
                                    ...obj,
                                  },
                                ];
                                if (this.editData[this.valueToMapInField]) {
                                  this.editData[this.valueToMapInField] = [
                                    ...this.editData[
                                    this.valueToMapInField
                                    ],
                                    {
                                      ...obj,
                                    },
                                  ];
                                } else {
                                  this.editData[this.valueToMapInField] = [
                                    {
                                      ...obj,
                                    },
                                  ];
                                }

                                this.dynamicForm.patchValue({
                                  [this.valueToMapInField]:
                                    lookupList[0].lookupId,
                                });
                              } else {
                                this.userFields[this.valueToMapInField] =
                                  lookupList[0].lookupId;
                                let obj = {
                                  id: lookupList[0].lookupId,
                                  value: res.data.name,
                                };
                                if (
                                  this.filteredOptions[
                                  this.valueToMapInField
                                  ] &&
                                  this.filteredOptions[
                                    this.valueToMapInField
                                  ].length
                                ) {
                                  this.filteredOptions[
                                    this.valueToMapInField
                                  ].push(obj);
                                } else {
                                  this.filteredOptions[
                                    this.valueToMapInField
                                  ] = [];
                                }
                                this.lookupValue[this.valueToMapInField] =
                                  [];
                                this.lookupValue[this.valueToMapInField] = [
                                  ...this.lookupValue[
                                  this.valueToMapInField
                                  ],
                                  {
                                    ...obj,
                                  },
                                ];
                                this.dynamicForm.patchValue({
                                  [this.valueToMapInField]:
                                    lookupList[0].lookupId,
                                });
                              }
                            }
                          });
                      }
                    }
                  }
                } else {
                  if (
                    loop.value[loop.value.length - 1].val[ele] &&
                    loop.value[loop.value.length - 1].val[ele] != ""
                  ) {
                    this.userFields[this.valueToMapInField] =
                      loop.value[loop.value.length - 1].val[ele];
                    this.dynamicForm.patchValue({
                      [this.valueToMapInField]:
                        loop.value[loop.value.length - 1].val[ele],
                    });
                  }
                }
              }
              this.visibilityFieldsFn();
            });
          }
        }
        //show Error Message for Lookup
        this.showErrorMessage(listData);
      });
      this.changeDetector.detectChanges();
    }
  };

  showErrorMessage(listData, Data?) {

    if (Data) {
      if (listData && listData[0] && listData[0].filters) {
        let valid = true;
        for (let element of listData[0].filters) {

          if (element.filterBy) {
            if (!element.baseTableField) {
              if (element.text !== Data[element.filterBy] && element.showErrorMessage)
                this.toastrService.warning(element.errorMessageForCheckBox, "LookUp");

              if (element.text !== Data[element.filterBy] && !element.showErrorMessage) {
                this.toastrService.warning(element.errorMessageForCheckBox, "LookUp");
                valid = false;
                break;
              }
            } else {
              if (element.showErrorMessage)
                this.toastrService.warning(element.errorMessageForCheckBox, "LookUp");
            }
          }

        }

        if (valid) {
          return true;
        } else {
          return false;
        }

      } else {
        return true;
      }
    } else {
      if (listData && listData[0] && listData[0].filters) {
        listData[0].filters.forEach((element) => {
          if (element.filterBy) {
            if (element.showErrorMessage)
              this.toastrService.warning(element.errorMessageForCheckBox, "LookUp");
          }

        });
      }
    }


  }

  closeList(main, index, row) {
    if (row == 0 && main.length == 1) {
      main = [];
      if (index == 0 && this.list.length == 1) {
        this.list = [];
      } else {
        this.list.splice(index, 1);
      }
    } else {
      main.splice(row, 1);
    }
    this.changeDetector.detectChanges();
  }

  changeToEditViewMode() {
    this.editViewFlag = true;
    this.viewFlag = false;
    let keys = Object.keys(this.viewFlagForSubForm);
    if (keys && keys.length) {
      keys.forEach((ele) => {
        this.viewFlagForSubForm[ele] = false;
        this.showEditForSubForm = true;
      });
    }
    this.addEditFlag = false;
    this.changeDetector.detectChanges();
    setTimeout(() => {
      this.tableService.runCustomStyling();
    }, 10);
  }

  backToView(onlyViewChange?) {
    this.editViewFlag = false;
    this.viewFlag = true;
    this.addEditFlag = false;

    if (!onlyViewChange) {
      this.tempEditFormData = [];
      this.showSubForm = {};
      // this.viewFlagForSubForm = {};
      if (this.Data && this.Data["subForm"]) {
        this.Data["subForm"].forEach((element) => {
          this.editDataSubForm.push(element);
          let keys = Object.keys(element);
          if (keys && keys.length) {
            keys.forEach((ele) => {
              this.showSubForm[ele] = true;
              this.viewFlagForSubForm[ele] = true;
              // this.rollUpCalculationFunction(ele,true);
            });
          }
        });
        this.tempEditFormData = cloneDeep(this.editDataSubForm);
        this.Data["refSubForm"] = cloneDeep(this.tempEditFormData);
      } else {
        this.editDataSubForm = [];
        this.tempEditFormData = cloneDeep(this.editDataSubForm);
      }

      if (this.Data && this.Data["subForm"]) {
        this.Data["subForm"].forEach((element) => {
          let keys = Object.keys(element);
          if (keys && keys.length) {
            keys.forEach((ele) => {
              this.rollUpCalculationFunction(ele, true);
              this.evalExpressionForFormulaField();
            });
          }
        });
      }

      this.statuses = Object.assign({}, this.Data["statusMain"]);
      this.colorSetter = Object.assign({}, this.Data["colorSetter"]);
      this.list = this.Data["refList"];
      this.uploadedFiles = Object.assign({}, this.Data["filesList"]);
    }

    // this.ngOnInit();
    this.evalExpressionForFormulaField();
    this.changeDetector.detectChanges();
    setTimeout(() => {
      this.tableService.runCustomStyling();
    }, 10);
  }

  openReminderModal() {
    let temp = [];
    this.recordData["IDField"].forEach((element, i) => {
      let IDElement = this.formColumns.filter((v) => v.name == element)[0];
      if (IDElement.type == "date" || IDElement.type == "dateTime") {
        if (
          this.recordData[element] &&
          typeof this.recordData[element] !== "object"
        ) {
          let val = this.datePipe.transform(this.recordData[element], "M/d/yy");
          temp.push(" " + val);
        }
      } else {
        if (
          this.recordData[element] &&
          typeof this.recordData[element] !== "object"
        ) {
          temp.push(" " + this.recordData[element]);
        }
      }
    });

    this.dialogService
      .open(NewReminderModalComponent, {
        context: {
          tableId: this.tableId,
          tableName: this.tableName,
          resourceId: this.Data._id,
          IdFieldData: temp,
          fromViewPage: true,
          tableIcon: this.tableIcon,
        },
        autoFocus: true,
      })
      .onClose.subscribe((res) => { });
  }

  continueView() {
    this.continueFlag = true;
    this.submit();
  }

  userLookupChangedWatchers(temp: Object) {
    if (temp["lookup"]) {
      temp["lookup"].forEach((element) => {
        if (element.lookupTableName == "Users") {
          this.activateSubscription(element.lookupId, temp["_id"], false);
        }
      });
    }
  }

  getSubscribers(val) {
    this.subscribers = val;
    if (val.length > 0) {
      val.map((data) => {
        if (data._id == this.currentUser._id) {
          this.subscriptionText = "Stop watching";
          this.isSelfSubscribed = true;
        }
      });
    }
    this.giveMarginToSubscriber();
    this.changeDetector.detectChanges();
  }
  activateSubscription(idForCreate?, resourceId?, showToaster = true) {
    const data = {
      resourceId: resourceId ? resourceId : this.Data?._id,
      userId: idForCreate ? idForCreate : this.currentUser._id,
      tableName: this.tableName,
      invitedBy: this.currentUser._id,
    };
    this.loading = true;
    let res: any = this.dynamicFormService.activateSubscription(data);
    this.loading = false;
  }

  ejsPackage;
  async getRecordGadgets() {
    await System.import("../../../../../node_modules/ejs/ejs.js").then(
      async (res) => {
        this.ejsPackage = res;
        let $Table = this.Data;
        if (this.recordGadgets && this.recordGadgets.length) {
          let activeGadget = this.recordGadgets.filter(
            (v) => v.active == true
          )[0];
          if (activeGadget)
            this.recordGadgetValue = this.ejsPackage.render(
              activeGadget.logic,
              { $Table: $Table }
            );

          let currentGadget = this.recordGadgets.filter(
            (v) => v.name == this.currentGadgetValue
          )[0];
          if (currentGadget)
            this.gadgetFieldValue = this.ejsPackage.render(
              currentGadget.logic,
              { $Table: $Table }
            );

          this.changeDetector.detectChanges();
        }
      }
    );
  }

  getStep(index, move = "") {
    if (index == 1 && move == "Back") {
      return this.ref.close();
    }
    if (index == this.sections.length - 1) {
      this.showFooter = true;
    } else {
      this.showFooter = false;
    }

    this.loadTinyMce = false;
    if (index < this.sections.length) {
      this.sections[index].array.forEach((element) => {
        element.data.forEach((item) => {
          if (item.type == "richTextArea") {
            setTimeout(() => {
              this.loadTinyMce = true;
            }, 1000);
          }
        });
      });
    }
    setTimeout(() => {
      this.tableService.runCustomStyling();
    });
  }

  goBackToMode() {
    if (this.editViewFlag) {
      this.editViewFlag = false;
      this.viewFlag = true;
    } else if (this.addEditFlag && !this.viewFlag && !this.editViewFlag) {
      this.ref.close();
    }
  }


  getRecordType(cols, tableName) {
    this.tableRecordTypes = [];
    let findRecordType = cols.find((x) => x.type == "recordType");
    if (
      typeof findRecordType != "undefined" &&
      findRecordType != "undefined" &&
      findRecordType !== null
    ) {
      const name = findRecordType.name;
      findRecordType = findRecordType.options;
      const recordArray = [];
      findRecordType &&
        findRecordType.forEach((element) => {
          const obj = {
            title: element,
            data: {
              menu: tableName,
              name: name,
            },
          };
          recordArray.push(obj);
          if (tableName == "Tasks") {
            this.taskRecordTypes.push(obj);
          }
        });
      this.tableRecordTypes[tableName] = recordArray;
    }
  }

  onShowLookupDetail(event, field, item, index, p1, pop: string, isRefRecord?) {
    this.lookupDetailClicked = false;
    this.useHostListener = false;
    this.lookupDetailItem = {};
    this.tableForLookupDetail = "";
    this.tableIdFromView = "";
    this.fieldNameForLookup = "";
    this.dataForLookupDetail = {};

    if (isRefRecord) {
      this.lookupDetailClicked = true;
      this.lookupDetailItem = field.record;
      this.tableForLookupDetail = field.name;
    } else {
      let data;
      if (this.lookupDataObject[field.name]) {
        data = this.lookupDataObject[field.name].filter(
          (v) => v.lookupId == index
        )[0];
      }

      if (data) {
        this.dataForLookupDetail =
          this.forkJoinLookupResponse[data.lookupId].data;
        this.lookupDetailItem = data;
        this.tableForLookupDetail = data.lookupTableName;
        this.tableIdFromView = field.lookupTableId;
        this.fieldNameForLookup = field.name;
        this.lookupDetailClicked = true;
        if (this.addEditFlag) {
          this.useHostListener = true;
        }
      } else {
        let lookupData = this.formColumns.filter((v) => v.name == field.name);
        if (lookupData && lookupData.length) {
          let id = item._id ? item._id : item.id;
          this.tableService
            .getDynamicTreeDataById(lookupData[0].lookupTableName, id)
            .subscribe(
              (res: any) => {
                if (res.statusCode == 200) {
                  this.dataForLookupDetail = res.data;
                  this.lookupDetailItem = lookupData[0];
                  this.tableForLookupDetail = lookupData[0].lookupTableName;
                  this.lookupDetailClicked = true;
                  this.useHostListener = true;
                }
              },
              (err) => { }
            );
        }
      }
    }

    switch (pop) {
      case "notLoadAsDropdownLookupPopover":
        this.notLoadAsDropdownLookupPopover = p1;
        break;
      case "referenceLookupPopover":
        this.referenceLookupPopover = p1;
        break;
      case "referenceRecordLookupPopover":
        this.referenceRecordLookupPopover = p1;
        break;
      case "editLoadAsDropdownLookupPopover":
        this.editLoadAsDropdownLookupPopover = p1;
        break;
      case "editNotLoadAsDropdownLookupPopover":
        this.editNotLoadAsDropdownLookupPopover = p1;
        break;
      case "loadAsDropdownLookupPopover":
        this.loadAsDropdownLookupPopover = p1;
        break;
    }
  }

  lookupDetailDataLoad() {
    this.loading = true;
    let lookupList = [];
    let forkJoinLookupObject = {};
    let forkJoinTableDataObject = {};
    lookupList = this.formColumns.filter((v) => v.type == "lookup");

    if (lookupList && lookupList.length) {
      lookupList.forEach((ele) => {
        if (this.Data && this.Data.lookup && this.Data.lookup.length) {
          this.lookupDataObject[ele.name] = this.Data.lookup.filter((v) =>
            v.lookupDataName
              ? v.lookupDataName == ele.name
              : v.lookupName == ele.name
          );
          if (
            this.lookupDataObject[ele.name] &&
            this.lookupDataObject[ele.name].length
          ) {
            this.lookupDataObject[ele.name].forEach(async (element) => {
              if (element.lookupId) {
                forkJoinLookupObject[element.lookupId] =
                  this.tableService.getDynamicTreeDataById(
                    element.lookupTableName,
                    element.lookupId
                  );
                if (this.tableDataForForms && this.tableDataForForms.length) {
                  let fieldIndex = this.tableDataForForms.findIndex(
                    (v) => v.tableName == element.lookupTableName
                  );
                  if (fieldIndex < 0) {
                    if (!forkJoinTableDataObject[element.lookupTableName])
                      forkJoinTableDataObject[element.lookupTableName] =
                        this.tableService.getTableByName(
                          element.lookupTableName
                        );
                  }
                } else {
                  this.tableService.getTablesForMenu().subscribe((res: any) => {
                    if (res.statusCode == 200) {
                      this.tableDataForForms = res.data;
                      let fieldIndex = this.tableDataForForms.findIndex(
                        (v) => v.tableName == element.lookupTableName
                      );
                      if (fieldIndex < 0) {
                        if (!forkJoinTableDataObject[element.lookupTableName])
                          forkJoinTableDataObject[element.lookupTableName] =
                            this.tableService.getTableByName(
                              element.lookupTableName
                            );
                      }
                    }
                  });
                }
              }
            });
            this.loading = true;
          }
        }
      });
    }

    let keys1 = Object.keys(forkJoinTableDataObject);
    if (keys1 && keys1.length) {
      forkJoin(forkJoinTableDataObject).subscribe((res) => {
        this.forkJoinTableResponse = res;

        this.getIdFields(lookupList);
        this.callForkJoinFn(forkJoinLookupObject, lookupList);
      });
    } else {
      this.getIdFields(lookupList);
      this.callForkJoinFn(forkJoinLookupObject, lookupList);
    }
  }

  getIdFields(lookupList) {
    if (lookupList && lookupList.length) {
      lookupList.forEach((ele) => {
        if (
          this.lookupDataObject[ele.name] &&
          this.lookupDataObject[ele.name].length
        ) {
          this.lookupDataObject[ele.name].forEach(async (element) => {
            let idFields = [];
            element["idFields"] = [];

            if (this.tableDataForForms && this.tableDataForForms.length) {
              let table = this.tableDataForForms.filter(
                (val) => val.tableName == element.lookupTableName
              );

              if (table && table.length) {
                idFields = table[0].columns
                  .filter((v) => v.idField)
                  .map((a) => a.name);
              } else {
                idFields = this.forkJoinTableResponse[
                  element.lookupTableName
                ].data[0].columns
                  .filter((v) => v.idField)
                  .map((a) => a.name);
              }

              if (idFields && idFields.length) {
                element["idFields"] = idFields;
              }
            } else {
              this.tableService.getTablesForMenu().subscribe((res: any) => {
                if (res.statusCode == 200) {
                  this.tableDataForForms = res.data;
                  let table = this.tableDataForForms.filter(
                    (val) => val.tableName == element.lookupTableName
                  );

                  if (table && table.length) {
                    idFields = table[0].columns
                      .filter((v) => v.idField)
                      .map((a) => a.name);
                  } else {
                    idFields = this.forkJoinTableResponse[
                      element.lookupTableName
                    ].data[0].columns
                      .filter((v) => v.idField)
                      .map((a) => a.name);
                  }

                  if (idFields && idFields.length) {
                    element["idFields"] = idFields;
                  }
                }
              });
            }
          });
        }
      });
    }
  }

  callForkJoinFn(forkJoinLookupObject, lookupList) {
    forkJoin(forkJoinLookupObject).subscribe((response) => {
      this.forkJoinLookupResponse = response;

      if (lookupList && lookupList.length) {
        lookupList.forEach((ele) => {
          if (
            this.lookupDataObject[ele.name] &&
            this.lookupDataObject[ele.name].length
          ) {
            this.lookupDataObject[ele.name].forEach((element) => {
              this.createDisplayForLookup(element);
            });
          }
        });
      }
      this.changeDetector.detectChanges();
      this.loading = false;
    });
  }

  createDisplayForLookup(element) {
    if (element.lookupId) {
      let temp = [];
      let data = this.forkJoinLookupResponse[element.lookupId].data;

      if (!data) return;

      if (element.idFields && element.idFields.length) {
        element.idFields.forEach((item) => {
          let table = this.tableDataForForms.filter(
            (val) => val.tableName == element.lookupTableName
          );
          let find: any;
          if (table && table.length) {
            find = table[0].columns.find((v) => v.name == item);
          } else {
            find = this.forkJoinTableResponse[
              element.lookupTableName
            ].data[0].columns.find((v) => v.name == item);
          }
          if (find) {
            if (find.type == "date" || find.type == "dateTime") {
              if (data[item]) {
                let dataToStore = this.datePipe.transform(
                  data[item],
                  "shortDate"
                );
                temp.push(dataToStore);
              }
            } else if (find.type == "file") {

              if (data[item] && data[item].length) {
                // let string = '';
                temp.push(data[item].join(' '));
              }

            } else {
              if (find.isPhone) {
                const phonePipe = new PhonePipe();
                const finalPhone = phonePipe.transform(data[item]);
                if (finalPhone) {
                  temp.push(finalPhone);
                }
              } else {
                if (data[item]) {
                  temp.push(data[item]);
                }
              }
            }
          }
        });
      }
      element["valueToDisplay"] = temp;
    }
  }

  onEmitFromLookupPopoverForModal(event) {
    if (event.close) {
      if (this.ref) {
        this.ref.close();
      }
      if (event.refer) {
        event.refer.close();
      }
    }
    if (this.notLoadAsDropdownLookupPopover) {
      this.notLoadAsDropdownLookupPopover.close();
    }
    if (this.referenceLookupPopover) {
      this.referenceLookupPopover.close();
    }
    if (this.referenceRecordLookupPopover) {
      this.referenceRecordLookupPopover.close();
    }
    if (this.editNotLoadAsDropdownLookupPopover) {
      this.editNotLoadAsDropdownLookupPopover.close();
    }
    if (this.editLoadAsDropdownLookupPopover) {
      this.editLoadAsDropdownLookupPopover.close();
    }
    if (this.loadAsDropdownLookupPopover) {
      this.loadAsDropdownLookupPopover.close();
    }
    this.changeDetector.detectChanges();
  }

  onActionsClick(event, oldVlaue?, newVlaue?, actionObj?, callback?) {
    let action1;
    if (actionObj) {
      action1 = actionObj;
    } else {
      if (event.item.actionName) {
        action1 = this.actions.find(
          (f) => f.actionName == event.item.actionName.toString()
        );
      }
    }


    if (!action1) return;

    let $Table = oldVlaue ? oldVlaue : null;
    let $Form = newVlaue ? newVlaue : null;
    let $Token = localStorage.getItem("userToken");
    let $api = `${environment.apiUrl}`;

    try {

      if (!eval(action1.displayCondition)) {
        if (action1.onSave == "yes") {
          if (callback) {
            callback();
          }
          return;
        }

        let notAvilableMessage = "This action is not available on this record";
        let notAvilableMessageColr = "";

        if (Array.isArray(action1.actionNotAvailable)) {
          action1.actionNotAvailable.forEach((action) => {
            if (eval(action.condition)) {
              notAvilableMessage = eval(action.message);
              notAvilableMessageColr = action.color;
            }
          });
        } else {
          notAvilableMessage = action1.actionNotAvailable;
        }

        this.dialogService.open(InfoDialogComponent, {
          context: {
            text: notAvilableMessage,
            title: "Action not available",
          },
        });
        if (callback) {
          callback();
        }
        return;
      }

      if (action1.warningMessage) {
        this.dialogService
          .open(ConfirmDialogComponent, {
            context: {
              actionObj: action1,
              title: action1.actionName,
              warningMessage: eval(action1.warningMessage),
              text: "",
              actionFlag: true,
              warningColor: action1.warningMessageColor,
            },
          })
          .onClose.subscribe((column) => {
            if (column) {
              this.triggerAction(action1, oldVlaue, newVlaue);
            }
          });
      } else {
        this.triggerAction(action1, oldVlaue, newVlaue);
      }

    } catch (e) {

    }

    if (callback) {
      callback();
    }
  }

  refreshData(newValue?) {
    if (!newValue) {
      newValue = this.Data;
    }

    this.tableService
      .getDynamicTreeDataById(this.tableName, newValue._id)
      .pipe()
      .subscribe(
        (res: any) => {
          if (res.statusCode == 200) {
            this.Data = res.data;
            this.ngOnInit();
            this.emitToView.emit();
          }
        },
        (err) => {
          // Error
        }
      );
  }

  async openForm(status, Data?, actionListObj?) {

    let url = this.location.path();

    if (!Data) {
      this.location.replaceState("pages/tables/" + actionListObj.relatedTable + "/" + "new");
    }

    let val: any = await this.formService.openAndCloseForm(null, actionListObj.relatedTable, this.tableData, false,
      null, false, null, null, null, null, null, null, status.label, this.fromBreadCrump);

    const ref = this.dialogService.open(val.formComp, {
      closeOnEsc: false,
      context: val.formObj,
    }).onClose.subscribe((res) => {
      this.location.replaceState(url);
    });

  }

  async triggerAction(action1, oldVlaue?, newVlaue?) {
    this.loading = true;
    let $Table = oldVlaue ? oldVlaue : null;
    let $Form = newVlaue ? newVlaue : null;
    let $Token = localStorage.getItem("userToken");
    let $api = `${environment.apiUrl}`;

    if ($Table.lookup && $Table.lookup.length) {
      $Table.lookup.forEach(element => {
        $Table[element.lookupName ? element.lookupName : element.lookupDataName] = element.lookupId;
      });
    }

    if (action1.actionsList && action1.actionsList.length) {

      action1.actionsList.forEach(async element => {
        let URL = "";

        if (element.actionType == "Email Template") {
          this.tableService
            .getDynamicTreeDataById(this.tableName, this.id, action1.actionName)
            .subscribe();
        } else if (element.actionType == 'Open New Record') {
          this.openForm(null, null, element)
        } else if (element.actionType == 'Update Current Record') {
          let tempObject = JSON.parse(element.jsonEditor);
          console.log(tempObject);

          this.tableService
            .updateDynamicFormData(
              this.Data._id,
              this.tableName,
              tempObject,
              this.tableId
            )
            .subscribe((res: any) => {
              if (res.statusCode == 200) {
                this.toastrService.success(
                  "Done",
                  "Updated Successfully!"
                );
              }
            });
        } else if (element.actionType == 'Craete Related Record') {

          if ($Table.lookup && $Table.lookup.length) {
            $Table.lookup.forEach(element => {
              $Table[element.lookupName ? element.lookupName : element.lookupDataName] = element.lookupId;
            });
          }

          while (element.jsonEditorForRelatedRecord.includes('$Table.')) {

            let wordIndex = element.jsonEditorForRelatedRecord.split('$Table.');
            let word = '';
            if (wordIndex && wordIndex.length > 1) {
              let wordCutted = wordIndex[1].substring(0, wordIndex[1].indexOf('"'));
              let wordToReplace = $Table[wordCutted];

              element.jsonEditorForRelatedRecord = element.jsonEditorForRelatedRecord.replace('$Table.' + wordCutted, wordToReplace);

            }
          }

          let tempObject = this.ejsPackage.render(
            element.jsonEditorForRelatedRecord,
            { $Table: $Table }
          );
          tempObject = JSON.parse(tempObject);

          let table = this.tableDataForForms.find(v => v.tableName == element.relatedTable);
          let id = null;
          if (table) {
            id = table._id;
          }

          tempObject['lookup'] = [];
          let keys = Object.keys(tempObject);
          if (keys && keys.length) {
            keys.forEach(val => {
              let find = this.form.find(it => it.name == val);
              if (find && find.type == 'lookup') {
                if (tempObject[val]) {

                  let lookup = table.columns.find(
                    (v: any) =>
                      v.type == "lookup" && v.name == val
                  );

                  if (lookup) {
                    let obj = {
                      lookupTableName: lookup.lookupTableName,
                      lookupId: tempObject[val],
                      lookupName: val,
                    };

                    tempObject['lookup'].push(obj);
                  }
                }

                delete tempObject[val];
              }
            })
          }

          tempObject["lookup"] = tempObject["lookup"].map((ele) => ({
            lookupId: ele.lookupId,
            lookupName: ele.lookupDataName ? ele.lookupDataName : ele.lookupName,
            lookupTableName: ele.lookupTableName,
          }));

          this.tableService
            .saveDynamicFormData(tempObject, element.relatedTable, id)
            .subscribe((res: any) => {
              if (res.statusCode == 200) {
                this.toastrService.success(
                  "Done",
                  "Created Successfully!"
                );
              }
            });
          console.log(tempObject);
        } else if (element.actionType == 'Update Lookup Record') {

          if (element.jsonEditorForLookupRelatedRecord && element.jsonEditorForLookupRelatedRecord.length) {

            for (let item of element.jsonEditorForLookupRelatedRecord) {

              while (item.jsonEditorForLookupRelatedRecord.includes('$Table.')) {

                let wordIndex = item.jsonEditorForLookupRelatedRecord.split('$Table.');
                let word = '';
                if (wordIndex && wordIndex.length > 1) {
                  let wordCutted = wordIndex[1].substring(0, wordIndex[1].indexOf('"'));
                  let wordToReplace = $Table[wordCutted];

                  item.jsonEditorForLookupRelatedRecord = item.jsonEditorForLookupRelatedRecord.replace('$Table.' + wordCutted, wordToReplace);

                }
              }

              let tempObject = this.ejsPackage.render(
                item.jsonEditorForLookupRelatedRecord,
                { $Table: $Table }
              );
              tempObject = JSON.parse(tempObject);

              let table = this.tableDataForForms.find(v => v.tableName == item.lookupTableName);
              let id = null;
              if (table) {
                id = table._id;
              }

              tempObject['lookup'] = [];
              let keys = Object.keys(tempObject);
              if (keys && keys.length) {
                keys.forEach(val => {
                  let find = this.form.find(it => it.name == val);
                  if (find && find.type == 'lookup') {
                    if (tempObject[val]) {

                      let lookup = table.columns.find(
                        (v: any) =>
                          v.type == "lookup" && v.name == val
                      );

                      if (lookup) {
                        let obj = {
                          lookupTableName: lookup.lookupTableName,
                          lookupId: tempObject[val],
                          lookupName: val,
                        };

                        tempObject['lookup'].push(obj);
                      }
                    }
                    let temp = [];
                    temp = this.forkJoinLookupResponse[tempObject[val]]?.data?.lookup;
                    tempObject['lookup'] = [...tempObject['lookup'], ...temp];
                    delete tempObject[val];
                  }
                })
              }

              tempObject["lookup"] = tempObject["lookup"].map((ele) => ({
                lookupId: ele.lookupId,
                lookupName: ele.lookupDataName ? ele.lookupDataName : ele.lookupName,
                lookupTableName: ele.lookupTableName,
              }));


              let ids = [];

              if (this.Data) {

                if (this.Data.lookup && this.Data.lookup.length) {
                  ids = this.Data.lookup.filter(v => v.lookupTableName == item.lookupTableName).map(a => a.lookupId);
                }
              }
              for (let val of ids) {

                let res: any = await this.tableService.updateDynamicFormDataWithPromise(val, item.lookupTableName, tempObject, id);
                if (res.statusCode == 200) {
                  this.toastrService.success("Done", "Updated Successfully!");
                }

              }

            }

          }


        } else {
          URL = eval(element.actionUrl);

          //-- Perform Action
          this.tableService.actionPerform(URL).subscribe((res) => {
            if (res) {
            }
            (error) => { };
          });
        }
      });
    } else {
      let URL = "";

      if (action1.actionType == "Email Template") {
        this.tableService
          .getDynamicTreeDataById(this.tableName, this.id, action1.actionName)
          .subscribe();
      } else {
        URL = eval(action1.actionUrl);

        //-- Perform Action
        this.tableService.actionPerform(URL).subscribe((res) => {
          if (res) {
          }
          (error) => { };
        });
      }
    }



    //-- Set timeout according to waitTime field
    setTimeout(() => {
      //-- Refresh page..

      this.refreshData(newVlaue);

      let $Table = oldVlaue ? oldVlaue : null;
      let $Form = newVlaue ? newVlaue : null;

      if (action1.messageList && action1.messageList.length > 0) {
        for (let k = 0; k < action1.messageList.length; k++) {
          if (eval(action1.messageList[k].condition)) {
            if (action1.messageList[k].message) {
              this.toastrService.show(
                eval(action1.messageList[k].message),
                action1.actionName,
                { status: action1.messageList[k].color.toLowerCase() }
              );
            }
          }
        }
      }
      this.loading = false;
    }, action1.waitTime);
  }

  calculateWidth() {
    //-- Need to handle this on update subscriber method
    if (this.subscribers.length > 4) {
      let currentMarginLeft = this.subscribers.length * 2 + 3;
      return 0 - currentMarginLeft + "px";
    }
  }

  step = 0;
  setStep(index: number) {
    this.step = index;
    this.stepperIndex = null;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  editDynamicTable() {
    if (this.inlineView) {
      this.tableService
        .getDynamicTreeDataById(this.tableName, this.id)
        .subscribe((res: any) => {
          if (res.statusCode == 200) {
            this.onUpdate(res.data);
            // console.log("🚀 ~ file: dynamic-form-dialog-new-design.component.ts:6472 ~ .subscribe ~ res.data", res.data)
            // this.Data = res.data;
            // this.viewFlag = false;
            // this.addEditFlag = true;
            this.actionSubscription.unsubscribe();
          }
        });
    } else {
      this.changeToEditViewMode();
    }
  }

  @Output() emitToView = new EventEmitter();
  async onUpdate(data) {

    let val: any = await this.formService.openAndCloseForm(data, this.tableName, this.tableDataForForms, false,
      null, false, null, null);

    const ref = this.dialogService.open(val.formComp, {
      closeOnEsc: false,
      context: val.formObj,
      autoFocus: true
    }).onClose.subscribe((name) => {

      if (name) {
        if (name.close && name.close == "yes") {
          this.emitToView.emit();
        }
      }

    });
  }

  statusEdited() {
    this.showEditIconForStatus = false;
  }

  saveStatusEdit() {
    // Handle save
  }

  cancelStatusEdit() {
    this.showEditIconForStatus = true;
  }
  // SubForm Code

  // Getting sub-form values
  getSubFormValue(data) {
    this.subFormDataValues[data.name] = [...data.data];

    this.validFlag = data.valid;
    if (data.callRollUp || this.Data)
      this.rollUpCalculationFunction(data, false);
    this.evalExpressionForFormulaField();

    this.evalExpressionForFormulaField();
    setTimeout(() => {
      this.tableService.runCustomStyling();
    }, 10);
  }
  // Getting sub-form fields/columns
  getSubFormFields(dataFields) {
    this.subFormDataDataFields[dataFields.name] = [...dataFields.data];
    this.evalExpressionForFormulaField();
    setTimeout(() => {
      this.tableService.runCustomStyling();
    }, 10);
  }

  //Getting sub-form lookup details with value
  getSubFormLookupData(data) {
    this.subFormLookupData[data.name] = [...data.data];
    this.subFormLookupFields[data.name] = [...data.lookupFields];
    this.subFormLookupFieldRequired[data.name] = [...data.lookupFieldRequired];
    this.subFormEditData[data.name] = [...data.editData];
    this.subFormRemoveLookup[data.name] = [...data.removeLookup];

    if (!data.atStart) {
      if (
        this.subFormDataValues[data.name] &&
        this.subFormDataValues[data.name].length == 1
      ) {
        let valid = false;
        for (let element of this.subFormDataValues[data.name]) {
          let keys = Object.keys(element.controls);
          for (let key of keys) {
            if (element.value[key]) {
              valid = true;
            }
          }
        }

        if (valid == false) {
          for (let element of this.subFormLookupData[data.name]) {
            let keys = Object.keys(element);
            for (let key of keys) {
              if (element[key] && element[key].length) {
                valid = true;
              }
            }
          }
        }

        if (valid == false) {
          this.showSubForm[data.name] = false;
        } else {
          this.showSubForm[data.name] = true;
        }
      } else if (
        this.subFormDataValues[data.name] &&
        this.subFormDataValues[data.name].length == 0
      ) {
        this.showSubForm[data.name] = false;
      }
    }

    this.evalExpressionForFormulaField();
    setTimeout(() => {
      this.tableService.runCustomStyling();
    }, 10);
  }

  rollUpCalculationFunction(data, fromBack) {
    let finalName = data.name ? data.name : data;

    if (this.rollupFields && this.rollupFields.length) {
      let rollupTable = this.rollupFields.filter(
        (v) => v.options[0].rollUptable == finalName.split("|")[1]
      );

      if (rollupTable.length == 0) {
        return;
      }

      rollupTable.forEach(val => {
        let option = val.options[0];

        let numFieldName = option.numberField;
        let fieldType = '';
        let table = this.tableDataForForms.find(v => v.tableName == option.rollUptable);
        if (table) {
          let col = table.columns.find(a => a.name == numFieldName);
          if (col) {
            fieldType = col.type;
          }
        }
        let sum = 0;
        let len = 0;

        if (
          this.subFormDataValues[finalName] &&
          this.subFormDataValues[finalName].length &&
          !fromBack
        ) {
          this.subFormDataValues[finalName].forEach((element) => {
            let $Table = element.value;

            if (option.aggregation == "count") {
              if (eval(option.filter)) {
                len = len + 1;
              }
              this.dynamicForm.get(val.name).setValue(len);
              if (this.Data) {
                this.Data[val.name] = len;
              }
            } else if (option.aggregation == "sum") {
              if (eval(option.filter)) {
                sum += element.value[numFieldName]
                  ? Number(element.value[numFieldName])
                  : 0;
              }

              this.dynamicForm.get(val.name).setValue(sum);
              if (this.Data) {
                this.Data[val.name] = sum;
              }
            }
          });

          if (option.aggregation == "min") {

            if (fieldType !== 'date') {

              if (this.subFormDataValues[finalName][0].value[numFieldName]) {
                let min = this.subFormDataValues[finalName][0].value[numFieldName];
                if (eval(option.filter)) {
                  for (let i = 1; i < this.subFormDataValues[finalName].length; ++i) {
                    if (this.subFormDataValues[finalName][i].value[numFieldName] < min) {
                      min = this.subFormDataValues[finalName][i].value[numFieldName];
                    }
                  }
                }
                this.dynamicForm.get(val.name).setValue(min);
                if (this.Data) {
                  this.Data[val.name] = min;
                }
              }

            } else {
              if (this.subFormDataValues[finalName][0].value[numFieldName]) {
                let min = moment(this.subFormDataValues[finalName][0].value[numFieldName]).format('L');
                if (eval(option.filter)) {
                  for (let i = 1; i < this.subFormDataValues[finalName].length; ++i) {
                    if (moment(this.subFormDataValues[finalName][i].value[numFieldName]).format('L') < min) {
                      min = moment(this.subFormDataValues[finalName][i].value[numFieldName]).format('L');
                    }
                  }
                }
                this.dynamicForm.get(val.name).setValue(min);
                if (this.Data) {
                  this.Data[val.name] = min;
                }
              }

            }

          }

          if (option.aggregation == "max") {

            if (fieldType == 'date') {
              if (this.subFormDataValues[finalName][0].value[numFieldName]) {
                let max = moment(this.subFormDataValues[finalName][0].value[numFieldName]).format('L');
                if (eval(option.filter)) {
                  for (let i = 1; i < this.subFormDataValues[finalName].length; ++i) {
                    if (moment(this.subFormDataValues[finalName][i].value[numFieldName]).format('L') > max) {
                      max = moment(this.subFormDataValues[finalName][i].value[numFieldName]).format('L');
                    }
                  }
                }

                this.dynamicForm.get(val.name).setValue(max);
                if (this.Data) {
                  this.Data[val.name] = max;
                }
              }

            } else {
              if (this.subFormDataValues[finalName][0].value[numFieldName]) {
                let max = this.subFormDataValues[finalName][0].value[numFieldName];
                if (eval(option.filter)) {
                  for (let i = 1; i < this.subFormDataValues[finalName].length; ++i) {
                    if (this.subFormDataValues[finalName][i].value[numFieldName] > max) {
                      max = this.subFormDataValues[finalName][i].value[numFieldName];
                    }
                  }
                }

                this.dynamicForm.get(val.name).setValue(max);
                if (this.Data) {
                  this.Data[val.name] = max;
                }
              }

            }

          }
        }

        if (
          fromBack &&
          this.Data &&
          this.Data["subForm"] &&
          this.Data["subForm"].length
        ) {
          this.Data["subForm"].forEach((ele) => {
            let keys = Object.keys(ele);
            if (keys[0] == finalName) {
              ele[finalName].forEach((element) => {
                let $Table = element;

                if (option.aggregation == "count") {
                  if (eval(option.filter)) {
                    len = len + 1;
                  }
                  this.dynamicForm.get(val.name).setValue(len);
                  if (this.Data) {
                    this.Data[val.name] = len;
                  }
                } else if (option.aggregation == "sum") {
                  if (eval(option.filter)) {
                    sum += element[numFieldName]
                      ? Number(element[numFieldName])
                      : 0;
                  }

                  this.dynamicForm.get(val.name).setValue(sum);
                  if (this.Data) {
                    this.Data[val.name] = sum;
                  }
                }
              });

              if (option.aggregation == "min") {

                if (fieldType == "date") {
                  if (ele[finalName][0][numFieldName]) {
                    let min = moment(ele[finalName][0][numFieldName]).format('L');
                    if (eval(option.filter)) {
                      for (let i = 1; i < ele[finalName].length; ++i) {
                        if (moment(ele[finalName][i][numFieldName]).format('L') < min) {
                          min = moment(ele[finalName][i][numFieldName]).format('L');
                        }
                      }
                    }
                    this.dynamicForm.get(val.name).setValue(min);
                    if (this.Data) {
                      this.Data[val.name] = min;
                    }
                  }
                } else {
                  let min = ele[finalName][0][numFieldName];
                  if (eval(option.filter)) {
                    for (let i = 1; i < ele[finalName].length; ++i) {
                      if (ele[finalName][i][numFieldName] < min) {
                        min = ele[finalName][i][numFieldName];
                      }
                    }
                  }

                  this.dynamicForm.get(val.name).setValue(min);
                  if (this.Data) {
                    this.Data[val.name] = min;
                  }
                }
              }

              if (option.aggregation == "max") {

                if (fieldType == 'date') {
                  if (ele[finalName][0][numFieldName]) {
                    let max = moment(ele[finalName][0][numFieldName]).format('L');
                    if (eval(option.filter)) {
                      for (let i = 1; i < ele[finalName].length; ++i) {
                        if (moment(ele[finalName][i][numFieldName]).format('L') > max) {
                          max = moment(ele[finalName][i][numFieldName]).format('L');
                        }
                      }
                    }

                    this.dynamicForm.get(val.name).setValue(max);
                    if (this.Data) {
                      this.Data[val.name] = max;
                    }
                  }

                } else {
                  let max = ele[finalName][0][numFieldName];
                  if (eval(option.filter)) {
                    for (let i = 1; i < ele[finalName].length; ++i) {
                      if (ele[finalName][i][numFieldName] > max) {
                        max = ele[finalName][i][numFieldName];
                      }
                    }
                  }

                  this.dynamicForm.get(val.name).setValue(max);
                  if (this.Data) {
                    this.Data[val.name] = max;
                  }
                }
              }
            }
          });
        }
      })


    }

    this.changeDetector.detectChanges();
  }

  showEditForSubForm = false;
  viewObjectForSubForm = {};
  cloneOfTempEditSubFormData = [];
  showSubFormToggle(value, field, editPop?) {
    if (!field.isPopOut) this.showSubForm[value.key] = true;

    this.cloneOfTempEditSubFormData = cloneDeep(this.tempEditFormData);

    if (this.viewFlag) this.changeToEditViewMode();

    if (field.isPopOut || editPop) {
      this.dialogService
        .open(DynamicSubformComponent, {
          context: {
            unwantedFieldsInOptions: this.unwantedFieldsInOptions,
            editFormData: this.cloneOfTempEditSubFormData,
            subFormLookupName: value.key,
            recordType: this.recordType,
            subFormFields: this.subFormFields[value.value],
            subFormName: value.value,
            lookUpNameId: this.lookUpNameId,
            parentTableName: this.parentTableName,
            formSubmitted: this.formSubmitted,
            tableDataForForms: this.tableDataForForms,
            isPopOut: field.isPopOut,
            tableId: this.tableId,
            viewFlagForSubForm: editPop
              ? false
              : this.viewFlagForSubForm[value.key],
          },
        })
        .onClose.subscribe((res) => {
          if (res) {
            this.showSubForm[value.key] = false;
            this.showEditForSubForm = false;

            if (this.viewFlag || this.editViewFlag) {
              this.backToView(true);
            }

            if (res.subFormBuilder) {
              this.getSubFormValue(res.subFormBuilder);
            }

            if (res.subFormData) {
              this.getSubFormFields(res.subFormData);
            }

            if (res.subFormLookupData) {
              this.getSubFormLookupData(res.subFormLookupData);
            }


            this.showEditForSubForm = true;

            let temp = {};
            let main = [];
            for (const form of Object.keys(this.subFormDataValues)) {
              temp[form] = [];
              // if (this.showSubForm[form]) {
              for (const field of this.subFormDataValues[form]) {
                this.viewObjectForSubForm = {};
                for (const [key, value] of Object.entries(field.controls)) {
                  let val = value as any;
                  if (!this.viewObjectForSubForm[key] && val.value) {
                    this.viewObjectForSubForm[key] = val.value;
                  }
                }
                this.viewObjectForSubForm["lookup"] = [];
                temp[form].push(this.viewObjectForSubForm);
              }
              // }
            }

            let keys = Object.keys(temp);
            keys.forEach((ele) => {
              let obj = {};
              obj[ele] = temp[ele];
              if (obj[ele] && obj[ele].length) {

                if (ele == value.key) {
                  this.showSubForm[value.key] = true;
                }
                main.push(obj);
              }

            });

            for (let [key, lookup] of Object.entries(
              this.subFormLookupFields
            )) {
              let idxOfLookup = 0;
              for (let [k, form] of Object.entries(lookup)) {
                let f = form as any;
                this.tableData = [];

                for (let data of f) {
                  if (data.type === "lookup") {
                    this.lookTable = data.lookupTableName;
                    this.subFormEditData[key][k][data.name].forEach(
                      (element) => {
                        if (element["id"]) {
                          if (this.tableData && this.tableData.length) {
                            let ind = this.tableData.findIndex(
                              (v) => v.lookupId == element["id"]
                            );
                            if (ind < 0) {
                              this.tableData.push({
                                lookupTableName: this.lookTable,
                                lookupId: element["id"],
                                lookupName: data.name,
                                [data.name]: [element["id"], element["value"]],
                              });
                            }
                          } else {
                            this.tableData.push({
                              lookupTableName: this.lookTable,
                              lookupId: element["id"],
                              lookupName: data.name,
                              [data.name]: [element["id"], element["value"]],
                            });
                          }
                        }
                        if (!this.subFormRemoveLookup[key][k][data.name]) {
                          this.tableData.pop({
                            lookupTableName: this.lookTable,
                            lookupId: element["id"],
                            lookupName: data.name,
                          });
                        }
                      }
                    );
                  }
                  let index = 0;
                  for (let f of main) {
                    let a = Object.keys(f);
                    let id = a.indexOf(key);
                    if (id > -1) break;
                    index++;
                  }

                  // If index is not available, setting empty value for that index
                  if (!main[index][key][idxOfLookup]) {
                    main[index][key][idxOfLookup] = {};
                  }
                  if (!main[index][key][idxOfLookup]["lookup"]) {
                    main[index][key][idxOfLookup]["lookup"] = [];
                  }

                  // Set value in temp subForm lookup array
                  main[index][key][idxOfLookup]["lookup"] = this.tableData;
                }
                idxOfLookup++;
              }
            }

            this.tempEditFormData = main;
            this.evalExpressionForFormulaField();
          } else {
            this.cloneOfTempEditSubFormData = cloneDeep(this.tempEditFormData);
          }

          this.changeDetector.detectChanges();

          setTimeout(() => {
            this.tableService.runCustomStyling();
          }, 10);

          if (res && (this.viewFlag || this.editViewFlag))
            this.changeToEditViewMode();
        });
    }

    setTimeout(() => {
      this.tableService.runCustomStyling();
    }, 10);
    this.changeDetector.detectChanges();
  }

  showProvideButtonFieldClick(field) {
    field["showProvideButtonField"] = true;
    setTimeout(() => {
      this.tableService.runCustomStyling();
    }, 10);
    this.changeDetector.detectChanges();
  }

  setIdFields() {
    if (this.recordData) {
      let tempName = "";

      let autoNumField = this.formColumns.find((a) => a.type == "autoNumber");
      if (autoNumField) {
        if (this.recordData[autoNumField.name]) {
          tempName = tempName + this.recordData[autoNumField.name];
          if (tempName)
            this.idValues = this.singularTableName + " - " + tempName;
        }
      }
    }
  }

  showNameInput = [];
  showItemInput = [];
  showList = [];
  addNewCheckList() {
    this.checkListArray.push(new checkListClass());
    this.showList.push(false);
    this.showNameInput.push(true);

    this.changeDetector.detectChanges();
  }

  bindNameOfCheckList(event, i) {
    this.checkListArray[i].name = event.target.value;

    if (event.keyCode == 13) {
      if (this.checkListArray[i].name == "") {
        this.toastrService.warning("Enter Checklist Name", "Validate");
      } else {
        this.showNameInput[i] = false;
        this.showList[i] = true;
        if (this.viewFlag) {
          this.updateCheckList();
        }
      }
    }
  }

  addItemInsideCheck(i) {
    this.checkListArray[i].value.push(new checkListItemClass());

    if (this.showItemInput[i] && this.showItemInput[i].length) {
      for (
        var a = this.showItemInput[i].length;
        a < this.checkListArray[i].value.length;
        a++
      ) {
        this.showItemInput[i].push(new Array(1));
        this.showDeleteIcon[i].push(new Array(1));
      }

      // Loop to initialize 2D array elements.

      this.showItemInput[i][this.showItemInput[i].length - 1] = true;
      this.showDeleteIcon[i][this.showDeleteIcon[i].length - 1] = false;
    } else {
      for (var x = 0; x < this.checkListArray[i].value.length; x++) {
        this.showItemInput[i] = new Array(this.checkListArray[i].value.length);
        this.showDeleteIcon[i] = new Array(this.checkListArray[i].value.length);
      }

      // Loop to initialize 2D array elements.
      for (var y = 0; y < this.checkListArray[i].value.length; y++) {
        for (var j = 0; j < this.checkListArray[i].value.length; j++) {
          this.showItemInput[i][j] = true;
          this.showDeleteIcon[i][j] = false;
        }
      }
    }

    // this.showItemInput[i].push([true]);
  }

  bindItemOfCheckList(event, i, ind) {
    if (event.keyCode == 13) {
      if (event.target.value == "") {
        this.toastrService.warning("Enter Item Name", "Validate");
      } else {
        this.checkListArray[i].value[ind].item = event.target.value;
        this.showItemInput[i][ind] = false;
        this.showDeleteIcon[i][ind] = false;
        if (this.viewFlag) {
          this.updateCheckList();
        }
      }
    }
  }

  onCheckedItem(event, item, ind, i) {
    this.checkListArray[i].value[ind].checked = event.target.checked;
    if (this.viewFlag) {
      this.updateCheckList();
    }
  }

  removeWholeCheckList(i) {
    const chkList: any = this.checkListArray[i].name;

    this.dialogService
      .open(ConfirmDeleteComponent, {
        context: {
          heading: "Delete " + chkList + " checklist",
          body: "Are you sure you want to delete this checklist?",
          btnText: "Delete",
        },
      })

      .onClose.subscribe((res) => {
        if (res) {
          this.showList.splice(i, 1);
          this.checkListArray.splice(i, 1);
          this.showNameInput.splice(i, 1);
          this.showItemInput.splice(i, 1);
          this.showDeleteIcon.splice(i, 1);
          this.changeDetector.detectChanges();
          if (this.viewFlag) {
            this.updateCheckList();
          }
        }
      });
  }

  removeItemFromCheckList(i, ind) {
    this.dialogService
      .open(ConfirmDeleteComponent, {
        context: {
          heading: "Delete checklist",
          body: "Are you sure you want to delete this checklist?",
          btnText: "Delete",
        },
      })
      .onClose.subscribe((res) => {
        if (res) {
          this.checkListArray[i].value.splice(ind, 1);
          this.changeDetector.detectChanges();
          if (this.viewFlag) {
            this.updateCheckList();
          }
        }
      });
  }

  showDeleteIcon = [];
  showDeleteIconForItem(i, ind) {
    this.showDeleteIcon[i][ind] = true;
  }

  hideDeleteIconForItem(i, ind) {
    this.showDeleteIcon[i][ind] = false;
  }

  identifyAction(value, data, i, field) {
    if (value == "Delete") {
      this.onDeleteConfirm(data, i, field);
    }
    this.popover.hide();
  }

  onDeleteConfirm(data, i, field): void {
    this.dialogService
      .open(ConfirmDeleteComponent, {
        context: {
          heading: "Delete File " + decodeURI(data),
          body:
            "Are you sure you want to delete the file " +
            decodeURI(data) +
            " ?",
          btnText: "Delete",
        },
      })
      .onClose.subscribe((name) => {
        if (name) {
          if (
            this.uploadedFiles[field.name] &&
            this.uploadedFiles[field.name].length
          ) {
            this.uploadedFiles[field.name].splice(i, 1);
          }

          if (this.viewFlag) {
            if (field.type === "file") {
              let temp = {};
              temp[field.name] = this.uploadedFiles[field.name];

              this.tableService
                .updateDynamicFormData(
                  this.Data._id,
                  this.tableName,
                  temp,
                  this.tableId
                )
                .subscribe((res: any) => {
                  if (res.statusCode == 200) {
                    this.toastrService.success(
                      "Done",
                      "File Deleted Successfully!"
                    );
                  }
                });
            }
          }
        }
      });
  }

  onFilePreview(filename, field, i) {
    let decodedFileName = decodeURI(filename);

    let temp = [];
    // temp = this.uploadedFiles[field.name].filter(v => v == (this.S3 + filename.toString().split('/').pop()));
    // temp = [...temp,...this.uploadedFiles[field.name].filter(v => v !== (this.S3 + filename.toString().split('/').pop()))]
    const fileDialog = this.dialogService.open(FilePreviewDialogComponent, {
      context: {
        uploadedFiles: this.uploadedFiles[field.name],
        currentFile: i,
        Data: decodedFileName,
        Ext: decodedFileName.split(".").pop(),
      },
    });
  }

  @HostListener("paste", ["$event"])
  onPaste(event) {
    let elem = this.document.getElementsByClassName("view-left-side");
    let elemRight = this.document.getElementsByClassName("view-right-side");
    if (elem && elem.length) {
      if (elem[0].contains(event.target)) {
        let fileField = this.form.find((v) => v.type == "file");
        if (fileField) {
          if (event.clipboardData.files && event.clipboardData.files.length) {
            this.loading = true;

            this.droppedFiles = {};
            this.buttonDisable = false;
            this.droppedFiles[fileField.name] = [];
            for (let i = 0; i < event.clipboardData.files.length; i++) {
              this.droppedFiles[fileField.name].push(
                event.clipboardData.files[i]
              );
            }
            this.onDroppedFile(
              this.droppedFiles[fileField.name],
              fileField.name
            );
          }
        }
      }
    }

    if (elemRight && elemRight.length) {
      if (elemRight[0].contains(event.target)) {
        this.messageService.getPasteEvent(event);
      }
    }
  }

  download(url) {

    // this.tableService.download(url).subscribe((res:any) => {

    const a = this.document.createElement('a');
    // const objectUrl = URL.createObjectURL(url);
    a.href = url;
    a.click();
    // URL.revokeObjectURL(objectUrl);
    // })
  }

  lookupRelateData = [];
  refRecordList = [];
  async getRelatedLookupData(tableName, resourceId) {
    this.loading = true;
    this.lookupRelateData = [];

    let res: any = await this.tableService.getRelatedLookupDataWithPromise(
      tableName,
      resourceId
    );

    if (res.statusCode == 200) {
      let copylookupRelateData = res.data;
      let val: any[] = Object.entries(copylookupRelateData);
      val.sort((a, b) => b[1].length - a[1].length);
      this.lookupRelateData = [...val];

      this.lookupRelateData.forEach((ele) => {
        let obj = {
          name: "",
          value: [],
          record: null,
        };
        if (
          (ele[1] && ele[1].length) ||
          (ele[1] && ele[1].getRecords && ele[1].getRecords.length)
        ) {
          obj.name = ele[0];

          if (ele[1].getRecords && ele[1].getRecords.length) {
            ele[1].getRecords.forEach((element) => {
              obj.record = element;
              let table = this.tableDataForForms.find(
                (v) => v.tableName == obj["name"]
              );
              if (table) {
                let idFields = table.columns.filter((v) => v.idField);
                let otherFields = table.columns.filter((v) => !v.idField);

                let valueObj = {
                  idFields: [],
                  otherFields: [],
                };

                if (idFields && idFields.length) {
                  idFields.forEach((idVal) => {
                    if (idVal.type == "date" || idVal.type == "dateTime") {
                      console.log("in date time")
                      const datepipe = new DateCustomPipe();
                      if (element[idVal.name]) {
                        let data = datepipe.transform(element[idVal.name]);
                        valueObj.idFields.push(data);
                        let temp = valueObj.idFields.join(",");
                        valueObj.idFields = [temp];
                      }
                    } else {
                      if (element[idVal.name]) {
                        valueObj.idFields.push(element[idVal.name]);
                        let temp = valueObj.idFields.join(",");
                        valueObj.idFields = [temp];
                      }
                    }
                  });
                }

                if (otherFields && otherFields.length) {
                  otherFields.forEach((othVal) => {
                    if (othVal.type == "date" || othVal.type == "dateTime") {
                      console.log("in date time 23232323")
                      const datepipe = new DateCustomPipe();
                      if (element[othVal.name]) {
                        let data = datepipe.transform(element[othVal.name]);
                        valueObj.otherFields.push(data);
                        let temp = valueObj.otherFields.join(",");
                        valueObj.otherFields = [temp];
                      }
                    } else {
                      if (element[othVal.name]) {
                        valueObj.otherFields.push(element[othVal.name]);
                        let temp = valueObj.otherFields.join(",");
                        valueObj.otherFields = [temp];
                      }
                    }
                  });
                }

                obj.value.push(valueObj);
              }
            });
          }
          this.refRecordList.push(obj);
        }
      });
    }
  }
}

export class checkListClass {
  name: string;
  value: checkListItemClass[];

  constructor() {
    this.name = "";
    this.value = [];
  }
}

export class checkListItemClass {
  item: string;
  checked: boolean;

  constructor() {
    this.item = "";
    this.checked = false;
  }
}
