<nb-card class="add-reminder-popup" cdkDrag>
  <nb-card-header>
    <span class="material-icons-outlined txt-clr reminder-icon">
      alarm
    </span>
    <span *ngIf="updateReminder" class="txt-clr">Edit Reminder</span>
    <span *ngIf="!updateReminder" class="txt-clr">Add Reminder</span>
    <!-- <img class="close-btn"  src="/assets/images/ic_close_24px.svg" (click)="close()"> -->
    <span class="material-icons pull-right cursor-pointer" [ngStyle]="{'color':systemConfigColor}" (click)="close()"> cancel </span>
  </nb-card-header>
  <nb-card-body>
    <div class="container">
      <div class="row field-row mb-4">
        <div class="col-md-12">
          <label for="title">Title <span style="color: red;">*</span> </label>
        </div>
        <div class="col-md-12">
          <input class="field-area" [ngStyle]="{'border':'1px solid'+ systemConfigColor}" type="text" matRipple placeholder="Title" name="title"
            [(ngModel)]="reminder.notes">
        </div>
      </div>

      <div class="row field-row last-field-row mb-4">
        <div class="col-md-12">
          <label for="dateTime">Date and Time <span style="color: red;">*</span> </label>
        </div>
        <div class="col-md-12">
          <app-date-time-picker name="dateTime" [(ngModel)]="reminder.dueDate">
          </app-date-time-picker>
        </div>
      </div>

      <div class="row field-row mb-4">
        <div class="col-md-12">
          <label for="title"> User </label>
        </div>
        <div *ngIf="fromViewPage && IdFieldData" class="col-md-12">
          <div class="field-area call-log-field" [ngStyle]="{'border':'1px solid'+ systemConfigColor}">
            <!-- <img *ngIf="tableIcon" class="tableIcon" [src]="tableIcon" /> -->
            <img *ngIf="this.currentTableIcon[0].iconLocation != '' && (!this.currentTableIcon[0].iconType || !this.currentTableIcon[0].iconName)"[src]="currentTableIcon[0].iconLocation">
            <nb-icon *ngIf="this.currentTableIcon[0].iconType == 'evaIcon' && this.currentTableIcon[0].iconType != ''" class="menu-icon float-left mr-1" [style.color]="systemConfigColor" [icon]="this.currentTableIcon[0].iconName"></nb-icon>
            <span *ngIf="this.currentTableIcon[0].iconType == 'matIcon' && this.currentTableIcon[0].iconName != ''" class="material-icons-outlined"[style.color]="systemConfigColor">{{currentTableIcon[0].iconName}}</span>
            <span class="call-log-title">{{tableName}}</span>
            <span>#{{IdFieldData}}</span>
            <img class="close-btn" src="/assets/images/ic_close_24px.svg" (click)="closeTableReference()">
          </div>
        </div>
        <div class="col-md-12">
          <label for="title">Notes<span style="color: red;">*</span> </label>
        </div>
        <div class="col-md-12">
          <textarea class="field-area" type="text" [ngStyle]="{'border':'1px solid'+ systemConfigColor}" matRipple placeholder="Notes" name="details"
            [(ngModel)]="reminder.details" rows="3"></textarea>
        </div>
      </div>

      <!-- <div class="row field-row">
          <button [ngStyle]="{'color':systemConfigColor}" class="recurringButton" (click)="showFrequency=!showFrequency;isRecurring=false;oneTime=false">
            <span class="plus">+</span>
            <span class="ml-2">Recurring Schedule</span>
          </button>
      </div> -->
      <div class="row field-row">
        <div class="col-md-12">
          <div class="form-group d-flex">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="recurringCheck" (change)="checkRecurring($event)">
              <label class="form-check-label" for="flexCheckDefault">
                Recurring
              </label>
            </div>
            <!-- <div class="form-check ml-2">
              <input class="form-check-input" type="checkbox" value="" id="oneTimeCheck" (change)="checkOneTime($event)">
              <label class="form-check-label" for="flexCheckDefault">
                One Time
              </label>
            </div> -->
            <!-- <label for="title" class="mt-2">Frequency</label>
            <select class="form-control select_recurring mt-1" name="frequency"  [(ngModel)]="reminder.frequency" [ngStyle]="{'border':'1px solid'+ systemConfigColor}" (change)="selectFrequency($event.target.value)">
              <option  [ngValue]="null" selected>Select Frequency</option>
              <option value="recurring">Recurring</option>
              <option value="oneTime">One Time</option>
            </select> -->
          </div>
        </div>

        <!-- Recurring -->
        <div class="col-md-12 recurring" *ngIf="isRecurring">
          <label for="title">Repeat Every <span style="color: red;">*</span></label>
          <div class="row">
            <div class="form-group" class="col-md-6">
              <select class="form-control select_recurring mt-1" name="repeatEveryValue" [(ngModel)]="reminder.repeatEveryValue" [ngStyle]="{'border':'1px solid'+ systemConfigColor}">
                <option [ngValue]="null" selected>select time</option>
                <option [ngValue]="val.id"  *ngFor="let val of repeatEveryValues;let i = index">{{val.id}}</option>
              </select>
            </div>
            <div class="form-group" class="col-md-6">
              <select class="form-control select_recurring mt-1" name="repeatEvery" [(ngModel)]="reminder.repeatEvery"  [ngStyle]="{'border':'1px solid'+ systemConfigColor}" (change)="repeatEvery($event.target.value)">
                <option [ngValue]="null" selected>select Unit</option>
                <option value="minute">Minute</option>
                <option value="hour">Hour</option>
                <option value="day">Day</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
                <option value="year">Year</option>
              </select>
            </div>
          </div>

          <div class="row start_end_date">
            <div class="form-group" class="col-md-6">
              <label for="title" class="mt-2">Start <span style="color: red;">*</span></label>
              <select class="form-control select_recurring mt-1" name="start" [(ngModel)]="reminder.start" [ngStyle]="{'border':'1px solid'+ systemConfigColor}" (change)="startDate($event.target.value)">
                <option [ngValue]="null" selected>select start</option>
                <option value="immediately">Immediately</option>
                <option value="Choose date">Choose date</option>
              </select><br>
              <div *ngIf="isStartDate">
                <app-date-time-picker name="startDate" [(ngModel)]="reminder.start" class="start_date">
                </app-date-time-picker>
              </div>
            </div>
            <div class="form-group" class="col-md-6">
              <label for="title" class="mt-2">End <span style="color: red;">*</span></label>
              <select class="form-control select_recurring mt-1" name="end" [(ngModel)]="reminder.end" [ngStyle]="{'border':'1px solid'+ systemConfigColor}" (change)="endDate($event.target.value)">
                <option [ngValue]="null" selected>select end</option>
                <option value="never">Never</option>
                <option value="Choose date">Choose date</option>
              </select><br>
              <div *ngIf="isEndDate">
                <app-date-time-picker name="endDate" [(ngModel)]="reminder.end" class="end_date">
                </app-date-time-picker>
              </div>
            </div>
          </div>
        </div>

        <!-- oneTime -->
        <div class="col-md-12 oneTime" *ngIf="oneTime">
          <label for="title" class="mt-2">Date and Time <span style="color: red;">*</span></label>
          <app-date-time-picker name="oneTimeDue" [(ngModel)]="reminder.oneTimeDue">
          </app-date-time-picker>
        </div>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class="row">
      <button class="btn btn-cancel outline-dynamic-color" (click)="close()">Cancel</button>
      <button class="btn btn-primary ml-4 btn-save filled-dynamic-color" (click)="saveReminder()" [disabled]="isSaveDisable">Save</button>
    </div>
  </nb-card-footer>
</nb-card>
