<div class="chipset-custom-main1">
  <a style="display: block;line-height: 30px;cursor: pointer;" *ngFor="let itm of itemForLookup[column.name]"
    (click)="onShowLookupDetail($event,itm, column.name,itemForLookup)"
    [ngbPopover]="templateRefInside" placement="bottom" container="body" class="chipset-custom1">
    <span class="dot_line">
      <ng-container *ngFor="let elem of itm.valueToDisplay">

        <span *ngIf="elem.isPhone">{{ elem.val | phone}}</span>
        <span *ngIf="!elem.isPhone">{{ elem.val}}</span>

      </ng-container>
    </span>
  </a>
  <ng-template #templateRefInside>
    <ngx-lookup-modal-detail
      [data]="lookupDetailItem" [name]="column.name" [tableData]="tableData"
      [dataForLookupDetail]="dataForLookupDetail" [tableName]="tableForLookupDetail"
      [formColumns]="tempParentTableHeader">
    </ngx-lookup-modal-detail>
  </ng-template>
</div>


