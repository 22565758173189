import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterOutSelectedItemFromDropdown'
})
export class FilterOutSelectedItemPipe implements PipeTransform {

  transform(mainList: any, seelectedItemList) {
    let temp = [];
    temp = [...seelectedItemList];
    temp.splice(temp.length - 1,1);
    if (mainList && mainList.length && temp && temp.length ) {
      return mainList.filter(
        a => temp.findIndex(v => v.field.name == a.name) == -1
      );
    } else {
      return mainList;
    }
  }

}
