<div *ngIf="showNotification" class="overlay-enabled"></div>
<div class="header-container">
  <div class="logo-container">
    <!-- <a href="#" class="sidebar-toggle" matRipple [matRippleUnbounded]="true" [matRippleCentered]="true"
      (click)="toggleSidebar()" [ngStyle]="{'color':systemConfigColor}">
      <nb-icon [icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'"></nb-icon>
    </a> -->
    <!-- <img [class]="cavalryLogo.cssClass" (click)="navigateHome()" [height]="cavalryLogo.height" [src]="cavalryLogo.path"
      alt="Cavalry">
    <nb-action class="control-item border-0 header-search">
      <div class="form-group">
        <input placeholder="search" autocorrect="off" autocapitalize="off" spellcheck="off" type="text"
          class="form-control search-data" #search (keyup.enter)="onSearch(search.value)">
      </div>
    </nb-action> -->
  </div>
</div>

<div class="header-container header-with-nb-icon">
  <nb-actions size="small">

    <nb-action *ngIf="this.activCallData" class="control-item p-0 header-activeCall" [ngStyle]="{'background':systemShadow}" >

      <!-- <span class="text-part">John Doe</span>
      <span class="text-part">Cavalry Associates</span> -->
      <span class="text-part">{{ getPhoneNumber(this?.activCallData?.phoneNumber) }}</span>
      <span>{{ getActiveTime(this?.activCallData?.timeOfCall) }}</span>
      <span class="plus-icon" (click)="openCallDetailModal(this.activCallData)">
        <!-- <img src="../../../../assets/images/plus-icon.svg" > -->
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9.74427" cy="9.74427" r="9.74427" fill="aliceblue" [ngStyle]="{'fill':systemConfigColor}" />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99926 5C9.56822 5 9.21879 5.34943 9.21879 5.78047V9.2196H5.78047C5.34943 9.2196 5 9.56902 5 10.0001C5 10.4311 5.34943 10.7805 5.78047 10.7805H9.21879V14.2195C9.21879 14.6506 9.56822 15 9.99926 15C10.4303 15 10.7797 14.6506 10.7797 14.2195V10.7805H14.2195C14.6506 10.7805 15 10.4311 15 10.0001C15 9.56902 14.6506 9.2196 14.2195 9.2196H10.7797V5.78047C10.7797 5.34943 10.4303 5 9.99926 5Z" fill="#fff" />
      </svg>
      </span>
  </nb-action>
    <nb-action class="control-item p-0 header-announcements" >
      <button type="button"  id="announcements-btn" class="btn announcements-btn py-1 px-3"
        [ngClass]="[anounceList?.length == 0 ? 'annRead txt-clr' : 'filled-dynamic-color']" (click)="openAnnouncementBox()">Announcements</button>
    </nb-action>

    <nb-action href="mailto:" class="control-item notification_dropdown" icon="email-outline" matRipple
      [matRippleUnbounded]="true" [matRippleCentered]="true"></nb-action>

    <!-- <nb-action class="control-item notification_dropdown " icon="file-text-outline" matRipple (click)="openReports()">
    </nb-action> -->

    <!-- <nb-action style="padding-right: 25px;" class="control-item notification_dropdown" icon="settings-outline" matRipple
      (click)="manageTables()" [matRippleUnbounded]="true" [matRippleCentered]="true" *ngIf="isGearIcon"></nb-action> -->

    <!-- <nb-action class="control-item notification_dropdown" icon="bell-outline" matRipple
      (click)="readNotifications($event)" badgeText="1" badgeDot="true" badgePosition="top right"  badgeStatus="danger"
      [matRippleUnbounded]="true" [matRippleCentered]="true"> -->
    <!-- [nbContextMenu]="notificationMenu" -->

    <!-- </nb-action> -->
      <nb-action class="control-item notification_dropdown "
      icon="calendar-outline" matRipple (click)="showCalendar($event)" >
    </nb-action>



    <nb-action class="control-item notification_dropdown " [ngClass]="{'show-dot' : notificationBadgeText !== ''}"
      icon="bell-outline" matRipple (click)="readNotifications($event)" [badgeText]=notificationBadgeText
      badgePosition="top right" badgeStatus="danger">
    </nb-action>

    <!-- Phone Dialer -->

    <nb-action class="control-item notification_dropdown" icon="phone-outline" href="tel:" nbPopoverTrigger="click"
      popoverTitle="dial" id="phoneDialer" nbPopoverPlacement="bottom" matRipple></nb-action>

    <!-- Phone Dialer? -->
    <!-- <nb-action class="control-item add-icon" icon="plus-circle" [nbPopover]="templateRef1" nbPopoverTrigger="click"
      nbPopoverPlacement="bottom" matRipple>
    </nb-action> -->

    <nb-action class="user-action" *nbIsGranted="['view', 'user']" matRipple [matRippleUnbounded]="true"
      [matRippleCentered]="true">
      <nb-user [nbPopover]="userProfileCard" (click)="showUserCard=true" nbPopoverTrigger="click"
        popoverTitle="userProfile" nbPopoverClass="userProfileClass" nbPopoverPlacement="bottom" matRipple
        [onlyPicture]="userPictureOnly" [name]="username" [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>
  <!-- User Profile Card -->
  <ng-template #userProfileCard class="userDetails">
    <div class="userCard" *ngIf="showUserCard">
      <div class="cardHeader d-flex">
        <div class="userProfile">
          <img src="{{user?.picture}}" class="rounded-pill" alt="" height="60px" width="60px">
        </div>
        <div class="userNameContainer overflow-hidden">
          <span class="userName font-weight-bolder">Welcome {{firstName |titlecase}}</span>
          <span class="userEmail text-muted">
            {{userEmail}}
          </span>
        </div>
        <span class="material-icons pull-right cursor-pointer" style="font-size: 19px; color: darkgray;"
          (click)="showUserCard=false"> close </span>
      </div>
      <hr style="margin: -7px 25px 9px 25px;">
      <div class="cardBody">
        <ul class="userMenu w-100" style="height: 375px;overflow-y: auto;">
          <li *ngFor="let user of userProfileMenu" (click)="profileMenuClick(user.title)">{{user.title}}</li>
          <hr style="margin: 10px 25px;">
          <li (click)="help()">Help</li>
          <hr style="margin: 10px 25px;">
          <li (click)="logOut()">Log Out </li>
        </ul>
      </div>
    </div>
  </ng-template>
  <!-- User Profile Card -->
  <!-- Phone dialer popover -->
  <!-- <ng-template #templateRef2>
    <div class="container1" id="dialContainer">
      <div id="output"></div>
      <div class="row dialerRow">
        <div class="digit" id="one" (click)="onDigitClick(1)">1</div>
        <div class="digit" id="two" (click)="onDigitClick(2)">2
          <div class="sub">ABC</div>
        </div>
        <div class="digit" id="three" (click)="onDigitClick(3)">3
          <div class="sub">DEF</div>
        </div>
      </div>
      <div class="row dialerRow">
        <div class="digit" id="four" (click)="onDigitClick(4)">4
          <div class="sub">GHI</div>
        </div>
        <div class="digit" id="five" (click)="onDigitClick(5)">5
          <div class="sub">JKL</div>
        </div>
        <div class="digit" (click)="onDigitClick(6)">6
          <div class="sub">MNO</div>
        </div>
      </div>
      <div class="row dialerRow">
        <div class="digit" (click)="onDigitClick(7)">7
          <div class="sub">PQRS</div>
        </div>
        <div class="digit" (click)="onDigitClick(8)">8
          <div class="sub">TUV</div>
        </div>
        <div class="digit" (click)="onDigitClick(9)">9
          <div class="sub">WXYZ</div>
        </div>
      </div>
      <div class="row dialerRow">
        <div class="digit" (click)="onDigitClick('*')">*
        </div>
        <div class="digit" (click)="onDigitClick(0)">0
        </div>
        <div class="digit" (click)="onDigitClick('#')">#
        </div>
      </div>
      <div class="botrow"><i class="fa fa-star-o dig" aria-hidden="true"></i>
        <div id="call"><i class="fa fa-phone" aria-hidden="true"></i></div>
        <i class="fa fa-long-arrow-left dig" (click)="leftArrowClick()" aria-hidden="true"></i>
      </div>
    </div>
  </ng-template> -->
  <!-- Phone dialer popover End -->

  <div id="rem-not" *ngIf="showNotification && (reminderList?.length > 0 || notificationList?.length > 0)"
    class="dropdown-menu notifi-drp-dwn">
    <ngx-reminder-notification [reminderList]="reminderList" [notificationList]="notificationList"
      [unreadReminderCount]="unreadReminderCount" [unreadNotificationCount]="unreadNotificationCount"
      (emitToOpen)="redirect($event)" (emitToEdit)="openReminderModal($event)"
      (emitToLoadNotification)="onScrollNotification($event)" (emitToLoadReminder)="onScrollReminder($event)"
      [loader]="loader" [getTablesForMenuData]="getTablesForMenuData" (emitToRead)="updateMessageStatus($event)"
      (emitReminderRead)="reminderCompleted($event)">
    </ngx-reminder-notification>
  </div>

</div>
